import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Button, Input } from "semantic-ui-react";
import Select from "react-select";
import { selectOptionsFromData } from "../../utilities";
import "../components.css";
import { Link } from "react-router-dom";
import _ from "lodash";
import { currencyMask } from "../../utilities";

import {
  getActivityConsumables,
  resetSaveSuccess,
  updateSelectedActivityConsumableState,
  updateActivityConsumablesState,
  getConsumables,
  getStages,
  getSORs,
} from "../../actions";

class CreateActivityConsumableForm extends React.Component {
  componentDidMount() {
    //for new activityConsumable, activityConsumables required to validate for duplicates
    if (!this.props.edit) {
      this.props.getActivityConsumables({});
    }
    this.props.getConsumables({});
    this.props.getStages({});
    this.props.getSORs({});
  }

  renderError({ submitFailed, error }) {
    if (submitFailed && error) {
      return (
        <div className="ui error message">
          <div className="header">{error}</div>
        </div>
      );
    }
  }

  renderResult = () => {
    if (this.props.busySaving) {
      return (
        <div className="ui success message" style={{ marginTop: "1em" }}>
          Saving..
        </div>
      );
    } else if (this.props.saveError) {
      return (
        <div className="ui error message" style={{ marginTop: "1em" }}>
          {this.props.saveError}
        </div>
      );
    } else if (this.props.successMessage) {
      return (
        <div className="ui success message" style={{ marginTop: "1em" }}>
          {this.props.successMessage}
        </div>
      );
    }
  };

  renderInput = ({
    input,
    id,
    style,
    meta,
    placeholder,
    disabled,
    label,
    autoFocus,
  }) => {
    const className = `field ${meta.error && meta.submitFailed ? "error" : ""}`;
    return (
      <div className={className}>
        <div className="formlabel">{label}</div>
        <Input
          {...input}
          id={id}
          style={style}
          placeholder={placeholder}
          disabled={disabled}
          autoFocus={autoFocus}
          autoComplete="off"
          ref={
            autoFocus
              ? (input) => {
                  this.focusInput = input;
                }
              : input
          }
        ></Input>
        {this.renderError(meta, id)}
      </div>
    );
  };

  renderSelect = ({
    input,
    id,
    options,
    meta,
    style,
    search,
    placeholder,
    label,
  }) => {
    const className = `field ${meta.error && meta.submitFailed ? "error" : ""}`;
    return (
      <div className={className} style={{ marginBottom: "1em" }}>
        <div className="formlabel">{label}</div>
        <Select
          {...input}
          fluid
          selection
          isSearchable={search}
          style={style}
          options={options}
          onChange={(value) => input.onChange(value)}
          onBlur={() => input.onBlur()}
          placeholder={placeholder}
        ></Select>
        {this.renderError(meta, id)}
      </div>
    );
  };

  createActivityConsumableForDisplay = (values) => {
    const _activityConsumable = {};
    _activityConsumable.id = this.props.activityConsumableId;
    _activityConsumable.price = values.price;
    _activityConsumable.consumableId = values.consumable.value;
    _activityConsumable.scopeId = values.SOR.value;
    _activityConsumable.stageId = values.stage.value;
    _activityConsumable.active = this.props.selectedActivityConsumable
      ? this.props.selectedActivityConsumable.active
      : true;
    return _activityConsumable;
  };

  onSubmit = (values) => {
    this.props.onSubmit(
      values,
      this.createActivityConsumableForDisplay(values)
    );
    setTimeout(() => {
      this.props.resetSaveSuccess();
    }, 2500);
    if (!this.props.edit) {
      this.props.reset();
      if (this.focusInput) {
        this.focusInput.focus();
      }
    }
  };

  render() {
    return (
      <div>
        <div className="content margin-bottom-small">{this.renderResult()}</div>
        <form
          className="ui form error"
          onSubmit={this.props.handleSubmit(this.onSubmit)}
        >
          <Field
            name="consumable"
            id="consumable"
            label="Consumable"
            component={this.renderSelect}
            options={selectOptionsFromData(this.props.consumables)}
            placeholder="Select consumable"
            search
          ></Field>
          <Field
            name="SOR"
            id="SOR"
            label="Scope of repair"
            component={this.renderSelect}
            options={selectOptionsFromData(this.props.SORs)}
            placeholder="Select scope of repair"
            search
          ></Field>
          <Field
            name="stage"
            id="stage"
            label="Stage"
            component={this.renderSelect}
            options={selectOptionsFromData(this.props.stages)}
            placeholder="Select stage"
            search
          ></Field>
          <Field
            name="price"
            id="price"
            label="Activity consumable price"
            component={this.renderInput}
            placeholder="Enter activity consumable price"
            {...currencyMask}
          ></Field>

          <div className="spacer-very-wide"></div>
          <div className="spacer-very-wide"></div>
          <div style={{ textAlign: "right" }}>
            <Button.Group>
              <Link to="/activityConsumables">
                <Button color="black" type="button">
                  Cancel
                </Button>
              </Link>
              <Button.Or />
              <Button primary>Save</Button>
            </Button.Group>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  //if editing set the default values

  let activityConsumableData = {};

  if (props.edit) {
    activityConsumableData = {
      activityConsumables: state.activityConsumables.activityConsumables,
      selectedActivityConsumable:
        state.activityConsumables.selectedActivityConsumable,
      initialValues: {
        consumable: {
          value:
            state.activityConsumables.selectedActivityConsumable.consumableId,
          label:
            state.activityConsumables.selectedActivityConsumable.consumableName,
        },
        SOR: {
          value: state.activityConsumables.selectedActivityConsumable.scopeId,
          label: state.activityConsumables.selectedActivityConsumable.scopeName,
        },
        stage: {
          value: state.activityConsumables.selectedActivityConsumable.stageId,
          label: state.activityConsumables.selectedActivityConsumable.stageName,
        },
        price: state.activityConsumables.selectedActivityConsumable.price,
      },
    };
  } else {
    activityConsumableData = {
      activityConsumables: state.activityConsumables.activityConsumables,
    };
  }

  return {
    ...activityConsumableData,
    stages: state.stages.stages,
    SORs: state.SORs.SORs,
    consumables: state.consumables.consumables,
    //api operations
    successMessage: state.api.saveSuccess,
    saveError: state.api.saveError,
    busySaving: state.api.busySaving,
  };
};

const validate = (formValues, props) => {
  const errors = {};

  if (!formValues.consumable) {
    //keys of object must match field names
    errors.consumable = "Please select a consumable.";
  }

  if (!formValues.SOR) {
    //keys of object must match field names
    errors.SOR = "Please select a scope of repair.";
  }

  if (!formValues.stage) {
    //keys of object must match field names
    errors.stage = "Please select a stage.";
  }

  if (
    formValues.consumable &&
    formValues.stage &&
    formValues.SOR &&
    !props.edit
  ) {
    if (
      _.find(props.activityConsumables, (activityConsumable) => {
        return (
          activityConsumable.consumableId.toLowerCase() +
            activityConsumable.scopeId.toLowerCase() +
            activityConsumable.stageId.toLowerCase() ===
          formValues.consumable.value.toLowerCase() +
            formValues.SOR.value.toLowerCase() +
            formValues.stage.value.toLowerCase()
        );
      }) !== undefined
    ) {
      //keys of object must match field names
      errors.consumable =
        "Record with the same consumable, scope and stage already exists.";
    }
  }

  if (!formValues.price) {
    //keys of object must match field names
    errors.price = "Please enter a price.";
  }

  return errors;
};

const formWrapped = reduxForm({
  form: "CreateActivityConsumableForm",
  enableReinitialize: true,
  validate: validate,
})(CreateActivityConsumableForm);

export default connect(mapStateToProps, {
  getConsumables,
  getStages,
  getSORs,
  resetSaveSuccess,
  updateSelectedActivityConsumableState,
  updateActivityConsumablesState,
  getActivityConsumables,
})(formWrapped);
