import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Input } from "semantic-ui-react";
import Select from "react-select";
import { selectOptionsFromData } from "../../utilities";
import "../components.css";
import _ from "lodash";
import { toastr } from "react-redux-toastr";
import { getModels, selectModel, selectModelPanel, updateReferencePanelState } from "../../actions";

class EditRefPanelForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      models: [],
      parts: [],
      part: {},
    };
  }

  renderError({ submitFailed, error }) {
    if (submitFailed && error) {
      return (
        <div className="ui error message">
          <div className="header">{error}</div>
        </div>
      );
    }
  }

  renderResult = () => {
    if (this.props.busySaving) {
      return (
        <div
          className="ui success message"
          style={{ marginTop: "1em" }}
        >
          Saving..
        </div>
      );
    } else if (this.props.saveError) {
      return (
        <div
          className="ui error message"
          style={{ marginTop: "1em" }}
        >
          {this.props.saveError}
        </div>
      );
    } else if (this.props.successMessage) {
      return (
        <div
          className="ui success message"
          style={{ marginTop: "1em" }}
        >
          {this.props.successMessage}
        </div>
      );
    }
  };

  renderInput = ({ input, id, style, meta, placeholder, disabled, label, autoFocus }) => {
    const className = `field ${meta.error && meta.submitFailed ? "error" : ""}`;
    return (
      <div className={className}>
        <div className="formlabel">{label}</div>
        <Input
          {...input}
          id={id}
          style={style}
          placeholder={placeholder}
          disabled={disabled}
          autoFocus={autoFocus}
          ref={
            autoFocus
              ? (input) => {
                  this.focusInput = input;
                }
              : input
          }
        ></Input>
        {this.renderError(meta, id)}
      </div>
    );
  };

  renderSelect = ({
    input,
    id,
    options,
    meta,
    style,
    search,
    placeholder,
    isMulti,
    label,
    isDisabled,
  }) => {
    const className = `field ${meta.error && meta.submitFailed ? "error" : ""}`;
    return (
      <div
        className={className}
        style={{ marginBottom: "1em" }}
      >
        <div className="formlabel">{label}</div>

        <Select
          {...input}
          fluid
          isMulti={isMulti}
          selection
          isSearchable={search}
          style={style}
          options={options}
          onChange={(value) => input.onChange(value)}
          onBlur={() => input.onBlur()}
          isDisabled={isDisabled}
          placeholder={placeholder}
        ></Select>
        {this.renderError(meta, id)}
      </div>
    );
  };

  onSubmit = (values) => {
    try {
      this.props.onSubmit(values);
      toastr.success("Success", "Record saved.", {
        icon: "success",
      });
    } catch (error) {
      toastr.error("Error", error);
    }
  };

  setModelsList = (selected) => {
    const { getModels, updateReferencePanelState, referencePanel } = this.props;
    getModels({
      manufacturerid: selected.value,
    }).then(() => {
      this.setState({
        models: this.props.models,
      });
      let _refPanel = _.cloneDeep(referencePanel);
      _refPanel.manufacturerId = selected.value;
      _refPanel.manufacturerName = selected.label;
      _refPanel.modelId = undefined;
      _refPanel.modelName = undefined;
      _refPanel.partId = undefined;
      _refPanel.partName = undefined;
      _refPanel.area = undefined;
      updateReferencePanelState(_refPanel);
    });
  };

  setPartsList = (selected) => {
    const { selectModel, updateReferencePanelState, models, referencePanel } = this.props;
    const _model = models.find((model) => {
      return model.id === selected.value;
    });
    selectModel(_model);
    this.setState({
      parts: _model.parts,
    });
    let _refPanel = _.cloneDeep(referencePanel);
    _refPanel.modelId = selected.value;
    _refPanel.modelName = selected.label;
    _refPanel.partId = undefined;
    _refPanel.partName = undefined;
    updateReferencePanelState(_refPanel);
  };

  selectPart = (selected) => {
    const { referencePanel, updateReferencePanelState, selectModelPanel } = this.props;
    const _panel = this.state.parts.find((part) => {
      return part.id === selected.value;
    });
    this.setState({
      part: _panel,
    });

    let _refPanel = _.cloneDeep(referencePanel);
    _refPanel.partId = selected.value;
    _refPanel.partName = selected.label;
    _refPanel.area = _panel.area;
    updateReferencePanelState(_refPanel);

    selectModelPanel(_panel);
  };

  render() {
    return (
      <div>
        <div className="content margin-bottom-small">{this.renderResult()}</div>
        <form className="ui form error">
          <div className="spacer-small"></div>
          <div className="ui stackable two column grid">
            <div
              className="eight wide column"
              style={{ textAlign: "center" }}
            >
              <h2>Reference Panel</h2>
            </div>
          </div>
          <div className="ui info message">
            <div class="header">Usage Instructions</div>
            <ul class="list">
              <li>
                Used to specify the paint quantity and labour for a panel of a specific size as
                indicated
              </li>
              <li>
                Labour units and paint volume (ml.) must be specified separately for each stage and
                activity
              </li>
              <li>
                The quoting model will read the labour and paint specified for the reference panel
                when a calculation is to be performed for a panel for the specific stage and
                activity. <b>If a record is not found for the specific stage and activity the costs
                will be ommitted from the quote.</b>
              </li>
              <li>
                If an activity has labour only e.g. preparation, the paint must be specified as 0
                (zero)
              </li>
              <li>Record ID's are not used in any way other than identifying each record.</li>
            </ul>
          </div>
          <div className="ui stackable two column grid">
            <div className="eight wide column">
              <Field
                name="manufacturer"
                id="manufacturer"
                label="Manufacturer"
                component={this.renderSelect}
                placeholder="Select Manufacturer"
                options={selectOptionsFromData(this.props.manufacturers)}
                onChange={this.setModelsList}
                autoFocus={true}
                search
              />
              <Field
                name="part"
                id="part"
                label="Part"
                component={this.renderSelect}
                options={selectOptionsFromData(this.state.parts)}
                placeholder="Select Part"
                isDisabled={this.state.parts.length === 0}
                onChange={this.selectPart}
                search
              />
            </div>
            <div className="eight wide column">
              <Field
                name="model"
                id="model"
                label="Model"
                component={this.renderSelect}
                placeholder="Select Model"
                options={this.state.models ? selectOptionsFromData(this.state.models) : undefined}
                onChange={this.setPartsList}
                isDisabled={this.state.models.length === 0}
                value={this.state.modelSelectValue}
                search
              />
              <Field
                name="size"
                id="size"
                label="size"
                component={this.renderInput}
                disabled={true}
                value={this.state.part.area}
                search
              />
              <div className="spacer-medium"></div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const handleOnFormChange = (newValues, dispatch, props) => {
  const { part: newPart } = newValues;
  if (newPart && props.selectedModel.parts.length > 0) {
    if (newPart.value) {
      const _area = props.selectedModel.parts.find((part) => {
        return part.id === newPart.value;
      }).area;
      dispatch(props.change("size", _area));
    }
  }
};

const mapStateToProps = (state, props) => {
  let refPanelData = {};

  refPanelData = {
    manufacturers: state.manufacturers.manufacturers,
    models: state.models.models,
    referencePanel: state.referencePanel.referencePanel,
    selectedModel: state.models.selectedModel,

    initialValues: {
      manufacturer: {
        value: state.referencePanel.referencePanel.manufacturerId,
        label: state.referencePanel.referencePanel.manufacturerName,
      },
      model: {
        value: state.referencePanel.referencePanel.modelId,
        label: state.referencePanel.referencePanel.modelName,
      },
      part: {
        value: state.referencePanel.referencePanel.partId,
        label: state.referencePanel.referencePanel.partName,
      },
      size: state.referencePanel.referencePanel.area,
    },
  };

  return {
    ...refPanelData,

    //api operations
    successMessage: state.api.saveSuccess,
    saveError: state.api.saveError,
    busySaving: state.api.busySaving,
  };
};

const formWrapped = reduxForm({
  form: "EditRefPanelForm",
  enableReinitialize: true,
  onChange: handleOnFormChange,
})(EditRefPanelForm);

export default connect(mapStateToProps, {
  getModels,
  selectModel,
  selectModelPanel,
  updateReferencePanelState,
})(formWrapped);
