import React from "react";
import Menu from "../components/Menu";
import CreateModelForm from "../components/vehicles/CreateModelForm";
import { compileModelObject } from "../apis/Models";
import {
  getModel,
  getModels,
  selectModel,
  getManufacturer,
  updateModelsState,
  updateAllModelsState,
  patchModelInDb,
  setModelSearchTerm,
  getBodyTypes,
  getLocations,
  getBodySubTypes,
} from "../actions";
import { replaceArrayObjectByKey } from "../utilities";
import { connect } from "react-redux";

class editModelView extends React.Component {
  onSubmit = (values, modelId) => {
    const {
      models,
      selectedModel,
      patchModelInDb,
      selectModel,
      updateModelsState,
      updateAllModelsState,
      allModels,
    } = this.props;
    const modelData = compileModelObject(
      { ...values, parts: selectedModel.parts },
      {
        id: modelId,
        state: true,
      }
    );
    patchModelInDb(modelData);
    //update Model changes back to state
    selectModel(modelData);
    updateModelsState(replaceArrayObjectByKey(models, "id", modelData));
    updateAllModelsState(replaceArrayObjectByKey(allModels, "id", modelData));
  };

  componentDidMount() {
    const { modelId } = this.props.match.params;
    const {
      getModel,
      getManufacturer,
      allModels,
      getModels,
      getBodyTypes,
      getLocations,
      getBodySubTypes,
    } = this.props;
    if (modelId) {
      getModel({ modelId }).then(() => {
        //reload paint colors on refresh
        if (allModels.length === 0) {
          getModels({
            manufacturerid: this.props.selectedModel.manufacturerId,
          });
          //set the searchterms to selected paintcolor manufacturer (for main form)
          this.props.setModelSearchTerm({
            value: this.props.selectedModel.manufacturerId,
            label: this.props.selectedModel.manufacturerName,
          });
        }
      });
      getManufacturer(this.props.selectedModel.manufacturerId);
    }
    getBodyTypes({});
    getLocations({});
    getBodySubTypes();
  }

  render() {
    const { modelId } = this.props.match.params;
    return (
      <>
        <Menu
          contentView={
            <CreateModelForm
              edit={true}
              onSubmit={this.onSubmit}
              modelId={modelId}
            />
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    models: state.models.models,
    selectedModel: state.models.selectedModel,
    allModels: state.models.allModels,
  };
};

export default connect(mapStateToProps, {
  getModel,
  getModels,
  selectModel,
  updateModelsState,
  updateAllModelsState,
  patchModelInDb,
  setModelSearchTerm,
  getBodyTypes,
  getLocations,
  getBodySubTypes,
  getManufacturer,
})(editModelView);
