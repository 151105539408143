import React from "react";
import Menu from "../components/Menu";
import Consumables from "../components/repairs/Consumables";

const consumablesView = () => {
  return (
    <>
      <Menu contentView={<Consumables />} />
    </>
  );
};

export default consumablesView;
