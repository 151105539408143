import React from "react";
import Menu from "../components/Menu";
import SORs from "../components/repairs/SORs";

const sorsView = () => {
  return (
    <>
      <Menu contentView={<SORs />} />
    </>
  );
};

export default sorsView;
