import {
  GET_BODYTYPES,
  SELECT_BODYTYPE,
  CLEAR_BODYTYPE,
  GET_BODYTYPE,
  ARCHIVE_BODYTYPE,
  GET_BODYSUBTYPES,
} from "../actions/types";
import _ from "lodash";

const INITIAL_STATE = {
  bodytypes: [],
  bodytype: {},
};

const bodyTypesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_BODYTYPES:
      return {
        ...state,
        bodytypes: action.payload,
      };
    case GET_BODYSUBTYPES:
      return {
        ...state,
        bodysubtypes: action.payload,
      };
    case GET_BODYTYPE:
      return {
        ...state,
        bodytype: action.payload,
      };
    case CLEAR_BODYTYPE:
      return { ...state, bodytype: {} };
    case SELECT_BODYTYPE:
      return {
        ...state,
        selectedId: action.payload.id,
        selectedName: action.payload.name,
      };
    case ARCHIVE_BODYTYPE:
      return _.omit(state, action.payload);
    default:
      return state;
  }
};

export default bodyTypesReducer;
