import React from "react";
import Menu from "../components/Menu";
import PaintColors from "../components/paint/PaintColors";

const paintColorsView = () => {
  return (
    <>
      <Menu contentView={<PaintColors />} />
    </>
  );
};

export default paintColorsView;
