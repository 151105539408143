import { GET_SETTINGS } from "../actions/types";

const INITIAL_STATE = {
  combined: {},
};

const settingsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_SETTINGS:
      return {
        ...state,
        combined: action.payload,
      };

    default:
      return state;
  }
};

export default settingsReducer;