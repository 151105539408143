import Axios from "axios";
import { env } from "./env";

export const PaintSystems = () => {
  const _env = env();
  const baseURL = _env.BASEURL;
  const key = _env.API_KEY;

  return Axios.create({
    baseURL: `${baseURL}/paint/paintsystems`,
    headers: { "x-api-key": key },
  });
};
