import React from "react";
import "../components.css";
import {
  clearPart,
  setPartsFilter,
  clearPartBatch,
  selectPart,
  removePartFromSelectedBatch,
  addPartToSelectedBatch,
  addAllPartsToSelectedBatch,
  deleteParts,
  deletePart,
  patchPartInDb,
  updatePartsState,
  updateAllPartsState,
  getParts,
  clearParts,
} from "../../actions";
import { connect } from "react-redux";
import { compilePartObject } from "../../apis/Parts";
import _ from "lodash";
import { SAVE, EDIT, NEW, DELETE } from "../../actions/types";
import { Link } from "react-router-dom";
import { Button, Confirm } from "semantic-ui-react";
import history from "../../history";
import DisplayTable from "../displayTable";
import { isMobile } from "react-device-detect";
import {
  removeObjectFromArrayById,
  filterArrayOnConcatenatedKeys,
} from "../../utilities";
import { SET_PART_FILTER_LABEL } from "../../actions/types";

class Parts extends React.Component {
  state = {
    confirmDeleteOpen: false,
    partDeleteId: null,
  };

  componentDidMount() {
    if (this.props.parts.length === 0) {
      this.props.getParts({});
    }
  }

  onPartsFilterChange = (filter) => {
    const {
      clearPart,
      partSearchTerms,
      setPartsFilter,
      updatePartsState,
      allParts,
    } = this.props;
   
      
      const _newfilter = filter === "#clear#" ? "" : partSearchTerms.filter.label;  
    if (_newfilter !== partSearchTerms.filter.value) {
      clearPart();
      if (_newfilter !== "") {
        
        updatePartsState(filterArrayOnConcatenatedKeys(
          allParts,
          ["l_name", "number"],
          _newfilter
        ));
      } else {
        updatePartsState(allParts);
      }
    }
    setPartsFilter(_newfilter);
  };

  onSelectPart = (id) => {
    const { selectPart, parts, clearPartBatch } = this.props;
    selectPart(parts.find((part) => part.id === id));
    clearPartBatch();
  };

  onAddPartToBatch = (part) => {
    if (this.props.partBatch.find((item) => item === part)) {
      this.props.removePartFromSelectedBatch(part);
    } else {
      this.props.addPartToSelectedBatch(part);
    }
  };

  onAddAllPartsToSelectedBatch = () => {
    if (this.props.partBatch.length === this.props.parts.length) {
      this.props.clearPartBatch();
    } else {
      this.props.addAllPartsToSelectedBatch(this.props.parts);
    }
  };

  onBatchDeleteParts = () => {
    const {
      parts,
      partBatch,
      deleteParts,
      clearPartBatch,
      clearPart,
      allParts,
    } = this.props;
    if (partBatch.length > 0) {
      deleteParts(parts, partBatch, allParts);
    }
    clearPartBatch();
    clearPart();
  };

  onDeletePart = () => {
    const {
      clearPart,
      updatePartsState,
      selectedPart,
      parts,
      allParts,
      updateAllPartsState,
    } = this.props;
    let _part = { ...selectedPart };
    //set to inactive
    _part.active = false;
    this.updatePart(_part);
    clearPart();
    updatePartsState(removeObjectFromArrayById(parts, _part));
    updateAllPartsState(removeObjectFromArrayById(allParts, _part));
  };

  updatePart = (part) => {
    const { patchPartInDb } = this.props;
    let _part = compilePartObject(
      {
        labourcomplexity: part.complexity.labour,
        materialcomplexity: part.complexity.material,
        name: part.name,
        hidden: part.hidden,
        accessory: part.accessory,
        positions: part.positions,
        paintlocation: { value: part.paintlocation },
      },
      part.id,
      part.number,
      part.active
    );
    patchPartInDb(_part);
  };

  //called from tables main menu to do ad-hoc save.
  onSaveChanges = () => {
    const _part = { ...this.props.selectedPart };
    if (!_.isEmpty(_part)) {
      this.updatePart(_part);
    }
  };

  renderPartsTable() {
    const menuItems = [
      {
        name: "Save Changes",
        key: SAVE,
        icon: "save",
        onClick: this.onSaveChanges,
        type: Button,
        disabled: this.props.parts.length === 0,
      },
      {
        name: "New Part",
        key: NEW,
        icon: "add square",
        to: `/parts/create`,
        type: Link,
        disabled: false,
      },
    ];

    const itemMenuActions = [
      {
        key: EDIT,
        text: "Edit Part",
        value: EDIT,
        icon: { name: "edit", color: "green" },
        idx: 0,
        executeAction: ({ itemId }) => {
          history.push(`/parts/edit/${itemId}`);
        },
      },
      {
        key: DELETE,
        text: "Delete",
        value: DELETE,
        icon: { name: "delete", color: "red" },
        idx: 1,
        executeAction: ({ itemId }) => {
          this.setState({ partDeleteId: itemId, confirmDeleteOpen: true });
        },
      },
    ];

    const tableMenuActions = [
      {
        key: DELETE,
        confirmation: 1,
        text: "Delete",
        value: DELETE,
        icon: { name: "remove", color: "red" },
        idx: 0,
        action: this.onBatchDeleteParts,
        header: "Confirm Delete",
      },
    ];

    const gridColumns = "six";
    const tableColumns = [
      {
        width: "two",
        heading: "Part Number",
        displayValues: [{ columnName: "number" }],
      },
      {
        width: "two",
        heading: "Paint Location",
        displayValues: [{ columnName: "paintlocation" }],
      },
      {
        width: "two",
        heading: "Labour Complexity",
        displayValues: [{ columnName: "complexity.labour" }],
      },
      {
        width: "two",
        heading: "Material Complexity",
        displayValues: [{ columnName: "complexity.material" }],
      },
      {
        width: "two",
        heading: "Position",
        displayValues: [{ columnName: "positions" }],
      },
    ];

    const primaryColumn = {
      heading: "Part Name",
      width: "three",
      displayValues: [{ columnName: "name" }],
    };

    const copyConfig = {
      active: false,
    };

    //check if "model panels message must be displayed"

    return (
      <>
        <DisplayTable
          idFieldName="id"
          primaryHeading="Parts"
          items={this.props.parts}
          selectedItemId={this.props.selectedPart.id}
          menuItems={menuItems}
          tableMenuActions={tableMenuActions}
          itemMenuActions={itemMenuActions}
          showSearch={0}
          searchTerms={this.props.partSearchTerms}
          onSelectItem={this.onSelectPart}
          onSearchTermChange={() => {
            return;
          }}
          onFilterChange={this.onPartsFilterChange}
          filterAction={SET_PART_FILTER_LABEL}
          batchItems={this.props.partBatch}
          onAddItemToBatch={this.onAddPartToBatch}
          onAddAllItemsToBatch={this.onAddAllPartsToSelectedBatch}
          primaryColumn={primaryColumn}
          gridColumns={gridColumns}
          tableColumns={tableColumns}
          loading={this.props.loadingData}
          copyItemsConfig={copyConfig}
        ></DisplayTable>
      </>
    );
  }

  renderDeleteConfirm() {
    return (
      <Confirm
        open={this.state.confirmDeleteOpen}
        header="Item Actions"
        content={`Confirm Delete?`}
        onCancel={() => {
          this.setState({ confirmDeleteOpen: false });
        }}
        onConfirm={() => {
          this.setState({ confirmDeleteOpen: false });
          this.onDeletePart();
        }}
      ></Confirm>
    );
  }

  render() {
    const className = `ui ${isMobile ? "" : "very padded"} segment`;
    return (
      <>
        <div className={className}>
          <div>{this.renderPartsTable()}</div>
          {this.renderDeleteConfirm()}
          <div className="spacer-very-wide"></div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    partSearchTerms: state.parts.partSearchTerms,
    allParts: state.parts.allParts,
    parts: state.parts.parts,
    partBatch: state.parts.partBatch,
    selectedPart: state.parts.selectedPart,
    loadingData: state.api.loadingData,
  };
};

export default connect(mapStateToProps, {
  clearPart,
  clearParts,
  setPartsFilter,
  clearPartBatch,
  selectPart,
  removePartFromSelectedBatch,
  addPartToSelectedBatch,
  addAllPartsToSelectedBatch,
  deleteParts,
  deletePart,
  patchPartInDb,
  updatePartsState,
  updateAllPartsState,
  getParts,
})(Parts);
