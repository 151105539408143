import Axios from "axios";
import { env } from "./env";

export const PrimerPrices = ({ id, stageId, supplierId, systemId, primerPriceIds }) => {
  const _env = env();
  const baseURL = _env.BASEURL_X;
  const key = _env.API_X_KEY;

  if (id) {
    return Axios.create({
      baseURL: `${baseURL}/paint/primerprices/${id}`,
      headers: { "x-api-key": key }
    });
  } else if (stageId) {
    return Axios.create({
      baseURL: `${baseURL}/paint/primerprices?stageId=${stageId}`,
      headers: { "x-api-key": key }
    });
  } else if (supplierId) {
    return Axios.create({
      baseURL: `${baseURL}/paint/primerprices?supplierId=${supplierId}`,
      headers: { "x-api-key": key }
    });
  } else if (systemId) {
    return Axios.create({
      baseURL: `${baseURL}/paint/primerprices?systemId=${systemId}`,
      headers: { "x-api-key": key }
    });
  } else if (primerPriceIds) {
    return Axios.create({
      baseURL: `${baseURL}/paint/primerprices?ids=${primerPriceIds}`,
      headers: { "x-api-key": key }
    });
  }
  return Axios.create({
    baseURL: `${baseURL}/paint/primerprices`,
    headers: { "x-api-key": key }
  });
};

export const compilePrimerPriceObject = ({ price, name, stage, supplier, system }, id, state) => {
  const primerPrice = {};
  primerPrice.id = id;
  primerPrice.name = name;
  primerPrice.stageId = stage.value;
  primerPrice.stageName = stage.label;
  primerPrice.supplierId = supplier.value;
  primerPrice.supplierName = supplier.label;
  primerPrice.price = parseFloat(price);
  primerPrice.systemId = system.value;
  primerPrice.systemName = system.label;
  primerPrice.active = state;
  primerPrice.l_name =
    name.toLowerCase() +
    "_" +
    supplier.label.toLowerCase() +
    "_" +
    stage.label.toLowerCase() +
    "_" +
    system.label.toLowerCase() +
    "_" +
    price;
  return primerPrice;
};
