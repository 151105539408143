import React from "react";
import Menu from "../components/Menu";
import Dashboard from "../components/Dashboard";

const dashboardView = () => {
  return (
    <>
      <Menu contentView={<Dashboard />} />
    </>
  );
};

export default dashboardView;
