import React from "react";
import Menu from "../components/Menu";
import { compilePaintColorObject } from "../apis/PaintColors";
import {
  getPaintColors,
  updatePaintColorsState,
  updateAllPaintColorsState,
  selectPaintColor,
  postPaintColorToDb,
  getSuppliers,
  getPaintSystems,
  getStages,
  getEffects,
  getColorGroups,
} from "../actions";
import { connect } from "react-redux";
import CreatePaintColorForm from "../components/paint/CreatePaintColorForm";
import { nanoid } from "nanoid";
import { updateStateAfterCreate } from "../utilities";

class createPaintColorView extends React.Component {
  componentDidMount() {
    if (this.props.allPaintColors.length === 0) {
      this.props.getPaintColors({});
    }
    this.props.getSuppliers({});
    this.props.getPaintSystems();
    this.props.getStages({});
    this.props.getEffects({});
    this.props.getColorGroups({});
    
  }

  onSubmit = (values, colorId) => {
    const {
      paintColors,
      postPaintColorToDb,
      selectPaintColor,
      updatePaintColorsState,
      updateAllPaintColorsState,
      allPaintColors,
    } = this.props;
    let paintColorData = compilePaintColorObject(values, {
      state: true,
    });
    postPaintColorToDb(paintColorData).then((result) => {
      paintColorData.id = result.data.id; //get new id returned by API
      updateStateAfterCreate(
        selectPaintColor,
        updatePaintColorsState,
        updateAllPaintColorsState,
        paintColors,
        allPaintColors,
        paintColorData
      );
    });
  };

  render() {
    const { manufacturerId } = this.props.match.params;
    return (
      <>
        <Menu
          contentView={
            <CreatePaintColorForm
              edit={false}
              onSubmit={this.onSubmit}
              manufacturerId={manufacturerId}
              colorId={nanoid()}
            />
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    paintColors: state.paintcolors.paintColors,
    allPaintColors: state.paintcolors.allPaintColors,
  };
};

export default connect(mapStateToProps, {
  getPaintColors,
  getColorGroups,
  getSuppliers,
  getStages,
  getEffects,
  getPaintSystems,
  selectPaintColor,
  updatePaintColorsState,
  updateAllPaintColorsState,
  postPaintColorToDb,
})(createPaintColorView);
