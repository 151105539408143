import React from "react";
import Menu from "../components/Menu";
import Parts from "../components/vehicles/Parts";

const partsView = () => {
  return (
    <>
      <Menu contentView={<Parts />} />
    </>
  );
};

export default partsView;
