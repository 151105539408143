import React from "react";
import Menu from "../components/Menu";
import BodyTypes from "../components/vehicles/BodyTypes";

const bodyTypesView = () => {
  return (
    <>
      <Menu contentView={<BodyTypes />} />
    </>
  );
};

export default bodyTypesView;
