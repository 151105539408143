import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Button, Input } from "semantic-ui-react";
import "../components.css";
import { Link } from "react-router-dom";
import _ from "lodash";
import { toastr } from "react-redux-toastr";

import {
  getLocation,
  resetSaveSuccess,
  updateSelectedLocationState,
  updateLocationsState,
} from "../../actions";

class CreateLocationForm extends React.Component {
  renderError({ submitFailed, error }) {
    if (submitFailed && error) {
      return (
        <div className="ui error message">
          <div className="header">{error}</div>
        </div>
      );
    }
  }

  renderResult = () => {
    if (this.props.busySaving) {
      return (
        <div className="ui success message" style={{ marginTop: "1em" }}>
          Saving..
        </div>
      );
    } else if (this.props.saveError) {
      return (
        <div className="ui error message" style={{ marginTop: "1em" }}>
          {this.props.saveError}
        </div>
      );
    } else if (this.props.successMessage) {
      return (
        <div className="ui success message" style={{ marginTop: "1em" }}>
          {this.props.successMessage}
        </div>
      );
    }
  };

  renderInput = ({
    input,
    id,
    style,
    meta,
    placeholder,
    disabled,
    label,
    autoFocus,
  }) => {
    const className = `field ${meta.error && meta.submitFailed ? "error" : ""}`;
    return (
      <div className={className}>
        <div className="formlabel">{label}</div>
        <Input
          {...input}
          id={id}
          style={style}
          placeholder={placeholder}
          disabled={disabled}
          autoFocus={autoFocus}
          ref={
            autoFocus
              ? (input) => {
                  this.focusInput = input;
                }
              : input
          }
        ></Input>
        {this.renderError(meta, id)}
      </div>
    );
  };

  onSubmit = (values) => {
    try {
      this.props.onSubmit(values, this.props.locationId);
      if (!this.props.edit) {
        this.props.reset();
        this.focusInput.focus();
      }
      toastr.success("Success", "Location record saved.", {
        icon: "success",
      });
    } catch (error) {
      toastr.error("Error", error);
    }
  };

  render() {
    return (
      <div>
        <div className="content margin-bottom-small">{this.renderResult()}</div>
        <form
          className="ui form error"
          onSubmit={this.props.handleSubmit(this.onSubmit)}
        >
          <Field
            name="name"
            id="name"
            label="Location Description"
            component={this.renderInput}
            placeholder="Enter location description"
            autoFocus={true}
          ></Field>

          <div className="spacer-very-wide"></div>

          <div style={{ textAlign: "right" }}>
            <Button.Group>
              <Link to="/locations">
                <Button color="black" type="button">
                  Cancel
                </Button>
              </Link>
              <Button.Or />
              <Button primary>Save</Button>
            </Button.Group>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  //if editing set the default values

  let locationData = {};

  if (props.edit) {
    locationData = {
      selectedLocation: state.locations.selectedLocation,
      initialValues: {
        name: state.locations.selectedLocation.name,
      },
    };
  } else {
    locationData = {
      allLocations: state.locations.allLocations,
    };
  }

  return {
    ...locationData,

    //api operations
    successMessage: state.api.saveSuccess,
    saveError: state.api.saveError,
    busySaving: state.api.busySaving,
  };
};

const validate = (formValues, props) => {
  const errors = {};

  if (!formValues.name) {
    //keys of object must match field names
    errors.name = "Please enter a location name.";
  }

  if (formValues.name) {
    if (
      _.find(props.allLocations, (location) => {
        return (
          location.name.toLowerCase() === formValues.name.toLowerCase()
        );
      }) !== undefined
    ) {
      //keys of object must match field names
      errors.name = "Location with same description already exists";
    }
  }

  return errors;
};

const formWrapped = reduxForm({
  form: "CreateLocationForm",
  enableReinitialize: true,
  validate: validate,
})(CreateLocationForm);

export default connect(mapStateToProps, {
  getLocation,
  resetSaveSuccess,
  updateSelectedLocationState,
  updateLocationsState,
})(formWrapped);
