import Axios from "axios";
import { env } from "./env";

export const PaintColors = ({
  manufacturerid,
  id,
  name,
  limit,
  startId,
  colorgroupId,
  colorIds,
}) => {
  const _env = env();
  const baseURL = _env.BASEURL;
  const key = _env.API_KEY;
  let startKeyFilter = "";

  if (id) {
    return Axios.create({
      baseURL: `${baseURL}/paint/colors/${id}`,
      headers: { "x-api-key": key },
    });
  } else if (name) {
    return Axios.create({
      baseURL: `${baseURL}/paint/colors?manufacturerId=${manufacturerid}&name=${name}`,
      headers: { "x-api-key": key },
    });
  } else if (colorgroupId) {
    return Axios.create({
      baseURL: `${baseURL}/paint/colors?manufacturerId=${manufacturerid}&colorgroupId=${colorgroupId}`,
      headers: { "x-api-key": key },
    });
  } else if (manufacturerid) {
    startKeyFilter = startId ? `&startid=${startId}&manufacturerId=${manufacturerid}` : "";
    const lmt = limit ? "&limit=" + limit : "";
    return Axios.create({
      baseURL: `${baseURL}/paint/colors?manufacturerId=${manufacturerid}${startKeyFilter}${lmt}`,
      headers: { "x-api-key": key },
    });
  } else if (colorIds) {
    return Axios.create({
      baseURL: `${baseURL}/paint/colors?ids=${colorIds}`,
      headers: { "x-api-key": key },
    });
  }
  return Axios.create({
    baseURL: `${baseURL}/paint/colors`,
    headers: { "x-api-key": key },
  });
};

export const compileDataForSave = (
  { code, colorgroupId, effectId, supplierId, colorName, manufacturerId, systemId, toners, testplateQty, reworkFactor },
  { id, state }
) => {
  const color = {};
  color.toners = [];
  color.id = id;
  color.active = state;
  color.code = code;
  color.colorgroupId = colorgroupId;
  color.effectId = effectId;
  color.supplierId = supplierId;
  color.name = colorName;
  color.l_name = colorName.toLowerCase();
  color.manufacturerId = manufacturerId;
  color.systemId = systemId;
  if (toners) {
    color.toners = toners.map((toner) => {
      return { id: toner.id, cost: toner.cost, stageId: toner.stageId, weight: toner.weight };
    });
  }
  color.testplateQty = testplateQty
  color.reworkFactor = reworkFactor
  return color;
};

export const compileColorTonerObject = ({ weight, toner, stage, cost, code }) => {
  let colorToner = {};
  colorToner.weight = parseFloat(weight);
  colorToner.code = code;
  colorToner.id = toner.value;
  colorToner.tonerName = toner.label;
  colorToner.stageId = stage.value;
  colorToner.stageName = stage.label;
  //colorToner.cost = parseFloat(cost); //not needed - calculated on the fly
  return colorToner;
};

export const compilePaintColorObject = (
  {
    code,
    name,
    manufacturerId,
    manufacturerName,
    effect,
    effectId,
    effectName,
    supplier,
    supplierId,
    supplierName,
    colorgroup,
    colorgroupId,
    testplateQty,
    reworkFactor,
    colorgroupName,
    system,
    systemId,
    systemName,
    toners,
  },
  { id, state }
) => {
  let color = {};
  color.id = id;
  color.active = state;
  color.code = code;
  color.effectId = effect ? effect.value : effectId;
  color.effectName = effect ? effect.label : effectName;
  color.supplierId = supplier ? supplier.value : supplierId;
  color.supplierName = supplier ? supplier.label : supplierName;
  color.name = name;
  color.l_name = name.toLowerCase();
  color.manufacturerId = manufacturerId;
  color.manufacturerName = manufacturerName;
  color.colorgroupId = colorgroup ? colorgroup.value : colorgroupId;
  color.testplateQty = testplateQty;
  color.reworkFactor = reworkFactor;
  color.colorgroupName = colorgroup ? colorgroup.label : colorgroupName;
  color.systemId = system ? system.value : systemId;
  color.systemName = system ? system.label : systemName;
  color.toners = toners ? toners : [];
  return color;
};
