import React from "react";
import _ from "lodash";
import { createNumberMask } from "redux-form-input-masks";

class utils extends React.Component {
  constructor(props) {
    super(props);
    this.index = 0;
  }

  nextNumber = () => {
    this.index = this.index + 1;
    return this.index;
  };
}

export default utils;

export const bathDeleteItems = (
  itemsToBeDeleted,
  filteredSet,
  fullSet,
  deleteMethod,
  selectedItemStateClearMethod,
  itemsToBeDeletedStateClearMethod,
  updateFilteredSetStateMethod,
  updateFullSetStateMethod
) => {
  if (itemsToBeDeleted.length > 0) {
    deleteMethod(itemsToBeDeleted);
    itemsToBeDeletedStateClearMethod();
    selectedItemStateClearMethod();
    updateFilteredSetStateMethod(arrayDelta(filteredSet, itemsToBeDeleted));
    updateFullSetStateMethod(arrayDelta(fullSet, itemsToBeDeleted));
 
  }
  
 
};

export const selectOptionsFromData = (data) => {
  if (data) {
    return data.map((item) => {
      return {
        value: item.id ? item.id : item.value,
        label: item.name ? item.name : item.label,
      };
    });
  }
};

export const selectOptionsFromPrimitiveArray = (array) => {
  if (array.length > 0) {
    return array.map((item) => {
      return { value: item, label: item };
    });
  }
};

export const primitiveArrayFromMultiSelectOptions = (options) => {
  if (options && options.length > 0) {
    return options.map((option) => {
      return option.label;
    });
  } else {
    return [];
  }
};

export const dropdownOptionsFromData = (data) => {
  if (data) {
    return data.map((item) => {
      return { value: item.id, text: item.name };
    });
  }
};

export const renderSuccessMessage = (message) => {
  if (message) {
    return (
      <div className="ui positive message">
        <div className="header">{message}</div>
      </div>
    );
  }
};

export const renderSaveError = (error) => {
  if (error) {
    return (
      <div className="ui negative message">
        <div className="header">{error}</div>
      </div>
    );
  }
};

export const updateStateAfterCreate = (
  setSelectedEntityState,
  updateEntityStateWorkingArray,
  updateEntityStateMasterArray,
  workingDataSet,
  completeDataSet,
  updatedEntity
) => {
  let _workingDataSet = [...workingDataSet];
  let _completeDataSet = [...completeDataSet];
  setSelectedEntityState(updatedEntity);
  _workingDataSet.unshift(updatedEntity);
  _completeDataSet.unshift(updatedEntity);
  updateEntityStateWorkingArray(_workingDataSet);
  updateEntityStateMasterArray(_completeDataSet);
};

export const updateStateAfterEdit = (
  setSelectedEntityStateMethod,
  updateEntityStateWorkingArrayMethod,
  updateEntityStateMasterArrayMethod,
  workingDataSet,
  completeDataSet,
  updatedEntity,
  updatedEntityKeyName
) => {
  setSelectedEntityStateMethod(updatedEntity);
  updateEntityStateWorkingArrayMethod(
    replaceArrayObjectByKey(workingDataSet, updatedEntityKeyName, updatedEntity)
  );
  updateEntityStateMasterArrayMethod(
    replaceArrayObjectByKey(completeDataSet, updatedEntityKeyName, updatedEntity)
  );
};

export const dateToLongString = (date) => {
  const month = [];
  month[0] = "January";
  month[1] = "February";
  month[2] = "March";
  month[3] = "April";
  month[4] = "May";
  month[5] = "June";
  month[6] = "July";
  month[7] = "August";
  month[8] = "September";
  month[9] = "October";
  month[10] = "November";
  month[11] = "December";
  return date.getDate() + " " + month[date.getMonth()] + " " + date.getFullYear();
};

export const dateToString = (date) => {
  return (
    (date.getDate().toString().length === 1 ? "0" + date.getDate() : date.getDate()) +
    "-" +
    ((date.getMonth() + 1).toString().length === 1
      ? "0" + (date.getMonth() + 1).toString()
      : date.getMonth() + 1
    ).toString() +
    "-" +
    date.getFullYear()
  );
};

export const currencyFormatter = new Intl.NumberFormat("en-ZA", {
  style: "currency",
  currency: "ZAR",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const dateToShortLocaleString = (date) => {
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  return date.toLocaleDateString(getBrowserLocales()[0], options);
};

function getBrowserLocales(options = {}) {
  const defaultOptions = {
    languageCodeOnly: false,
  };
  const opt = {
    ...defaultOptions,
    ...options,
  };
  const browserLocales =
    navigator.languages === undefined ? [navigator.language] : navigator.languages;
  if (!browserLocales) {
    return undefined;
  }
  return browserLocales.map((locale) => {
    const trimmedLocale = locale.trim();
    return opt.languageCodeOnly ? trimmedLocale.split(/-|_/)[0] : trimmedLocale;
  });
}

export const dateToDateTimeString = (date) => {
  const getTwoDigitDatePart = (datePart) => {
    if (datePart < 10) {
      return `0${datePart}`;
    }
    return datePart;
  };

  return (
    getTwoDigitDatePart(date.getDate()) +
    "-" +
    getTwoDigitDatePart(date.getMonth() + 1) +
    "-" +
    date.getFullYear() +
    " " +
    getTwoDigitDatePart(date.getHours()) +
    ":" +
    getTwoDigitDatePart(date.getMinutes())
  );
};

//remove array of objects from array
export const arrayDelta = (arr, itemsToRemove) => {
  return _.filter(arr, (obj) => {
    return !itemsToRemove.includes(obj);
  });
};

export const removeObjectFromArray = (arr, obj) => {
  return _.filter(arr, (array_itm) => {
    return array_itm !== obj;
  });
};

export const removeObjectFromArrayById = (arr, obj) => {
  return _.filter(arr, (array_itm) => {
    return array_itm.id !== obj.id;
  });
};

export const removeObjectFromArrayBySKey = (arr, obj) => {
  return _.filter(arr, (array_itm) => {
    return array_itm.skey !== obj.skey;
  });
};

export const removeObjectsFromArrayBySKeys = (arr, skeys) => {
  let res = [];
  res = _.filter(arr, (array_itm) => {
    return !_.includes(skeys, array_itm.skey);
  });
  return res;
};

//filters array on key field based on filter string
export const filterArray = (arr, keyName, filter) => {
  return _.filter(arr, (obj) => {
    return obj[keyName].toLowerCase().includes(filter.toLowerCase());
  });
};

export const filterArrayOnConcatenatedKeys = (arr, keys, filter) => {
  return _.filter(arr, (obj) => {
    let _concatKey = "";
    keys.forEach((key) => {
      _concatKey += obj[key].toString().toLowerCase();
    });
    return _concatKey.includes(filter.toLowerCase());
  });
};

//removes elements in 2nd array from 1st array and returns new array
export const createDeltaArrayByKey = (arr, keyName, arr2) => {
  return _.differenceBy(arr, arr2, keyName);
};

//replace object in array based on key
export const replaceArrayObjectByKey = (arr, keyName, newval) => {
  return arr.map((itm) => {
    return itm[keyName] === newval[keyName] ? newval : itm;
  });
};

export const currentDateAsInt = () => {
  return new Date(new Date().toLocaleDateString("en-za")).getTime();
};

export const currentDateAsShortDate = () => {
  const now = new Date();
  return (
    now.getFullYear().toString() +
    "-" +
    (now.getMonth() + 1).toString() +
    "-" +
    now.getDate().toString()
  );
};

export const dateStringToSeconds = (date) => {
  return new Date(new Date(date).toLocaleDateString("en-za")).getTime();
};

export const secondsToShortDateString = (date) => {
  return dateToString(new Date(date));
};

export const currencyMask = createNumberMask({
  decimalPlaces: 2,
});

export const addParamToQueryString = (qString, paramString) => {
  if (qString.includes("?")) {
    qString += "&" + paramString;
  } else {
    qString += "?" + paramString;
  }
};

export const getEntityNameById = (entityName, searchArray, id) => {
  let name = "";
  try {
    name = searchArray.find((_object) => {
      return _object.id === id;
    }).name;
  } catch (e) {
    console.log(`getEntityNameById('${id}') for entity ${entityName} failed.`, e);
    return name;
  }
  return name;
};
