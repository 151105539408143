import React from "react";
import Menu from "../components/Menu";
import { compileDataForSave } from "../apis/ActivityConsumables";
import {
  getActivityConsumables,
  updateActivityConsumablesState,
  selectActivityConsumable,
  postActivityConsumableToDb,
} from "../actions";
import { connect } from "react-redux";
import CreateActivityConsumableForm from "../components/repairs/CreateActivityConsumableForm";

import { nanoid } from "nanoid";

class createActivityConsumableView extends React.Component {
  componentDidMount() {
    this.props.getActivityConsumables({});
  }

  onSubmit = (values, activityConsumable) => {
    const {
      activityConsumables,
      postActivityConsumableToDb,
      selectActivityConsumable,
      updateActivityConsumablesState,
    } = this.props;
    const activityConsumableData = compileDataForSave(
      values,
      activityConsumable.id,
      true
    );

    postActivityConsumableToDb(activityConsumableData).then((result) => {
      let _activityConsumables = [...activityConsumables];
      let _activityConsumable = { ...result.data }; //merge objects - returned from db +  metadata if required
      //add names from dropdown values
      _activityConsumable.stageName = values.stage.label;
      _activityConsumable.scopeName = values.SOR.label;
      _activityConsumable.consumableName = values.consumable.label;
      selectActivityConsumable(_activityConsumable);
      _activityConsumables.splice(0, 0, _activityConsumable); //insert at top of array
      updateActivityConsumablesState(_activityConsumables);
    });
  };

  render() {
    return (
      <>
        <Menu
          contentView={
            <CreateActivityConsumableForm
              edit={false}
              onSubmit={this.onSubmit}
              activityConsumableId={nanoid()}
            />
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activityConsumables: state.activityConsumables.activityConsumables,
  };
};

export default connect(mapStateToProps, {
  getActivityConsumables,
  selectActivityConsumable,
  updateActivityConsumablesState,
  postActivityConsumableToDb,
})(createActivityConsumableView);
