import {
  LOADING_DATA,
  DATA_LOADED,
  BUSY_SAVING,
  SAVE_FAILED,
  CANCEL_SAVE,
  SAVED_RECORD,
} from "../actions/types";

const INITIAL_STATE = {
  loadingData: null,
  busySaving: false,
  saveSuccess: null,
  saveError: null,
};

const apiReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOADING_DATA:
      return {
        ...state,
        loadingData: true,
      };
    case DATA_LOADED:
      return {
        ...state,
        loadingData: false,
      };
    case BUSY_SAVING:
      return {
        ...state,
        busySaving: true,
        saveSuccess: null,
        saveError: null,
      };
    case SAVED_RECORD:
      return {
        ...state,
        saveSuccess: action.payload,
        busySaving: false,
        saveError: null,
      };
    case SAVE_FAILED:
      return {
        ...state,
        saveSuccess: null,
        saveError: action.payload,
        busySaving: false,
      };
    case CANCEL_SAVE:
      return {
        ...state,
        saveSuccess: null,
        saveError: null,
        busySaving: false,
      };
    default:
      return state;
  }
};

export default apiReducer;
