import React from "react";
import Menu from "../components/Menu";
import Stages from "../components/paint/Stages";

const stagesView = () => {
  return (
    <>
      <Menu contentView={<Stages />} />
    </>
  );
};

export default stagesView;
