import React from "react";
import Menu from "../components/Menu";
import CreateColorGroupForm from "../components/paint/CreateColorGroupForm";
import { compileColorGroupObject } from "../apis/ColorGroups";
import {
  getColorGroup,
  getColorGroups,
  selectColorGroup,
  updateColorGroupsState,
  updateAllColorGroupsState,
  patchColorGroupInDb,
} from "../actions";
import { replaceArrayObjectByKey } from "../utilities";
import { connect } from "react-redux";

class editColorGroupView extends React.Component {
  onSubmit = (values, colorGroupId) => {
    const {
      patchColorGroupInDb,
      colorGroups,
      allColorGroups,
      updateColorGroupsState,
      updateAllColorGroupsState,
    } = this.props;
    const colorGroupsData = compileColorGroupObject(values, colorGroupId, true);
    patchColorGroupInDb(colorGroupsData);
    //update PaintColor changes back to state
    selectColorGroup(colorGroupsData);
    updateColorGroupsState(
      replaceArrayObjectByKey(colorGroups, "id", colorGroupsData)
    );
    updateAllColorGroupsState(
      replaceArrayObjectByKey(allColorGroups, "id", colorGroupsData)
    );
  };

  componentDidMount() {
    const { colorgroupId } = this.props.match.params;
    if (colorgroupId) {
      this.props.getColorGroup(colorgroupId);
    }
    if (this.props.allColorGroups.length === 0) {
      this.props.getColorGroups({});
    }
  }

  render() {
    const { colorgroupId } = this.props.match.params;
    return (
      <>
        <Menu
          contentView={
            <CreateColorGroupForm
              edit={true}
              onSubmit={this.onSubmit}
              colorGroupId={colorgroupId}
            />
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    colorGroups: state.colorgroups.colorGroups,
    allColorGroups: state.colorgroups.allColorGroups,
  };
};

export default connect(mapStateToProps, {
  getColorGroup,
  getColorGroups,
  selectColorGroup,
  updateColorGroupsState,
  updateAllColorGroupsState,
  patchColorGroupInDb,
})(editColorGroupView);
