import React from "react";
import "../components.css";
import {
  clearSOR,
  setSORsFilter,
  clearSORBatch,
  selectSOR,
  removeSORFromSelectedBatch,
  addSORToSelectedBatch,
  addAllSORsToSelectedBatch,
  deleteSORs,
  deleteSOR,
  patchSORInDb,
  updateSORsState,
  updateAllSORsState,
  getSORs,
  clearSORs,
} from "../../actions";
import { connect } from "react-redux";
import { compileSORObject } from "../../apis/SORs";
import _ from "lodash";
import { SAVE, EDIT, NEW, DELETE } from "../../actions/types";
import { Link } from "react-router-dom";
import { Button, Confirm } from "semantic-ui-react";
import history from "../../history";
import DisplayTable from "../displayTable";
import { isMobile } from "react-device-detect";
import { removeObjectFromArrayById, filterArrayOnConcatenatedKeys } from "../../utilities";
import { SET_SOR_FILTER_LABEL } from "../../actions/types";

class SORs extends React.Component {
  state = {
    confirmDeleteOpen: false,
    sorDeleteId: null,
  };

  componentDidMount() {
    if (this.props.sors.length === 0) {
      this.props.getSORs({});
    }
  }

  onSORsFilterChange = (filter) => {
    const { clearSOR, sorSearchTerms, setSORsFilter, updateSORsState, allSORs } = this.props;
    const _filter = filter !== undefined ? filter : sorSearchTerms.filter.label;
    if (_filter !== sorSearchTerms.filter.value) {
      clearSOR();
      if (_filter !== "") {
        let _sors = [...allSORs];
        const _filteredSORs = [...filterArrayOnConcatenatedKeys(_sors, ["l_name"], _filter)];
        updateSORsState(_filteredSORs);
      } else {
        updateSORsState([...allSORs]);
      }
    }
    setSORsFilter(_filter);
  };

  onSelectSOR = (id) => {
    const { selectSOR, sors, clearSORBatch } = this.props;
    selectSOR(sors.find((sor) => sor.id === id));
    clearSORBatch();
  };

  onAddSORToBatch = (sor) => {
    if (this.props.sorBatch.find((item) => item === sor)) {
      this.props.removeSORFromSelectedBatch(sor);
    } else {
      this.props.addSORToSelectedBatch(sor);
    }
  };

  onAddAllSORsToSelectedBatch = () => {
    if (this.props.sorBatch.length === this.props.sors.length) {
      this.props.clearSORBatch();
    } else {
      this.props.addAllSORsToSelectedBatch(this.props.sors);
    }
  };

  onBatchDeleteSORs = () => {
    const { sors, sorBatch, deleteSORs, clearSORBatch, clearSOR, allSORs } = this.props;
    if (sorBatch.length > 0) {
      deleteSORs(sors, sorBatch, allSORs);
    }
    clearSORBatch();
    clearSOR();
  };

  onDeleteSOR = () => {
    const { clearSOR, updateSORsState, selectedSOR, sors, allSORs, updateAllSORsState } =
      this.props;
    let _sor = { ...selectedSOR };
    //set to inactive
    _sor.active = false;
    this.updateSOR(_sor);
    clearSOR();
    updateSORsState(removeObjectFromArrayById(sors, _sor));
    updateAllSORsState(removeObjectFromArrayById(allSORs, _sor));
  };

  updateSOR = (sor) => {
    const { patchSORInDb } = this.props;
    let _sor = compileSORObject(
      {
        name: sor.name,
        stages: sor.stages,
      },
      sor.id,
      sor.active
    );
    patchSORInDb(_sor);
  };

  //called from tables main menu to do ad-hoc save.
  onSaveChanges = () => {
    const _sor = { ...this.props.selectedSOR };
    if (!_.isEmpty(_sor)) {
      this.updateSOR(_sor);
    }
  };

  renderSORsTable() {
    const menuItems = [
      {
        name: "Save Changes",
        key: SAVE,
        icon: "save",
        onClick: this.onSaveChanges,
        type: Button,
        disabled: this.props.sors.length === 0,
      },
      {
        name: "New SOR",
        key: NEW,
        icon: "add square",
        to: `/sors/create`,
        type: Link,
        disabled: false,
      },
    ];

    const itemMenuActions = [
      {
        key: EDIT,
        text: "Edit SOR",
        value: EDIT,
        icon: { name: "edit", color: "green" },
        idx: 0,
        executeAction: ({ itemId }) => {
          history.push(`/sors/edit/${itemId}`);
        },
      },
      {
        key: DELETE,
        text: "Delete",
        value: DELETE,
        icon: { name: "delete", color: "red" },
        idx: 1,
        executeAction: ({ itemId }) => {
          this.setState({ sorDeleteId: itemId, confirmDeleteOpen: true });
        },
      },
    ];

    const tableMenuActions = [
      {
        key: DELETE,
        confirmation: 1,
        text: "Delete",
        value: DELETE,
        icon: { name: "remove", color: "red" },
        idx: 0,
        action: this.onBatchDeleteSORs,
        header: "Confirm Delete",
      },
    ];

    const gridColumns = "six";
    const tableColumns = [
      {
        width: "two",
        heading: "Inside Paint Qty Factor",
        displayValues: [{ columnName: "insidepaintqtyfactor" }],
      },
      {
        width: "eight",
        heading: "Applicable Stages (Activities)",
        displayValues: [{ columnName: "stagesDisplay.name" }],
      },
    ];

    const primaryColumn = {
      heading: "Scope Name",
      width: "three",
      displayValues: [{ columnName: "name" }],
    };

    const copyConfig = {
      active: false,
    };

    //check if "model panels message must be displayed"

    return (
      <>
        <DisplayTable
          idFieldName="id"
          primaryHeading="Repair Scopes"
          items={this.props.sors}
          selectedItemId={this.props.selectedSOR.id}
          menuItems={menuItems}
          tableMenuActions={tableMenuActions}
          itemMenuActions={itemMenuActions}
          showSearch={0}
          searchTerms={this.props.sorSearchTerms}
          onSelectItem={this.onSelectSOR}
          onSearchTermChange={() => {
            return;
          }}
          onFilterChange={this.onSORsFilterChange}
          filterAction={SET_SOR_FILTER_LABEL}
          batchItems={this.props.sorBatch}
          onAddItemToBatch={this.onAddSORToBatch}
          onAddAllItemsToBatch={this.onAddAllSORsToSelectedBatch}
          primaryColumn={primaryColumn}
          gridColumns={gridColumns}
          tableColumns={tableColumns}
          loading={this.props.loadingData}
          copyItemsConfig={copyConfig}
        ></DisplayTable>
      </>
    );
  }

  renderDeleteConfirm() {
    return (
      <Confirm
        open={this.state.confirmDeleteOpen}
        header="Item Actions"
        content={`Confirm Delete?`}
        onCancel={() => {
          this.setState({ confirmDeleteOpen: false });
        }}
        onConfirm={() => {
          this.setState({ confirmDeleteOpen: false });
          this.onDeleteSOR();
        }}
      ></Confirm>
    );
  }

  render() {
    const className = `ui ${isMobile ? "" : "very padded"} segment`;
    return (
      <>
        <div className={className}>
          <div className="ui info message">
            <div class="header">Usage Instructions</div>
            <ul class="list">
              <li>Used to specify the available repair scopes.</li>
              <li>
                The Inside Paint Quantity Factor is applied at the point of calculation. Should a
                panel require painting on the inside in addition to the outside, the outside paint
                cost will be multiplied with this factor to obtain the cost for the inside of the
                panel.
              </li>
              <li>
                <b>
                  The applicable stages field is NOT USED at the moment but this can become
                  relevant in future depending on the complexity of calculations.
                </b>
              </li>
            </ul>
          </div>
          <div>{this.renderSORsTable()}</div>
          {this.renderDeleteConfirm()}
          <div className="spacer-very-wide"></div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allSORs: state.SORs.allSORs,
    sorSearchTerms: state.SORs.SORSearchTerms,
    sors: state.SORs.SORs,
    sorBatch: state.SORs.SORBatch,
    selectedSOR: state.SORs.selectedSOR,
    loadingData: state.api.loadingData,
  };
};

export default connect(mapStateToProps, {
  clearSOR,
  clearSORs,
  setSORsFilter,
  clearSORBatch,
  selectSOR,
  removeSORFromSelectedBatch,
  addSORToSelectedBatch,
  addAllSORsToSelectedBatch,
  deleteSORs,
  deleteSOR,
  patchSORInDb,
  updateSORsState,
  updateAllSORsState,
  getSORs,
})(SORs);
