import React from "react";
import Menu from "../components/Menu";
import CreateSetupCostForm from "../components/repairs/CreateSetupCostForm";
import { compileSetupCostObject } from "../apis/SetupCosts";
import {
  getSetupCost,
  getSetupCosts,
  selectSetupCost,
  updateSetupCostsState,
  updateAllSetupCostsState,
  patchSetupCostInDb,
} from "../actions";
import { replaceArrayObjectByKey } from "../utilities";
import { connect } from "react-redux";

class editSetupCostView extends React.Component {
  onSubmit = (values, setupCostId) => {
    const {
      patchSetupCostInDb,
      setupCosts,
      allsetupCosts,
      updateSetupCostsState,
      updateAllSetupCostsState,
    } = this.props;
    const setupCostsData = compileSetupCostObject(values, setupCostId, true);
    patchSetupCostInDb(setupCostsData);
    //update Setup Cost changes back to state
    selectSetupCost(setupCostsData);
    updateSetupCostsState(
      replaceArrayObjectByKey(setupCosts, "id", setupCostsData)
    );
    updateAllSetupCostsState(
      replaceArrayObjectByKey(allsetupCosts, "id", setupCostsData)
    );
  };

  componentDidMount() {
    const { setupcostId } = this.props.match.params;
    if (setupcostId) {
      this.props.getSetupCost(setupcostId);
    }
    if (this.props.allsetupCosts.length === 0) {
      this.props.getSetupCosts({});
    }
  }

  render() {
    const { setupcostId } = this.props.match.params;
    return (
      <>
        <Menu
          contentView={
            <CreateSetupCostForm
              edit={true}
              onSubmit={this.onSubmit}
              setupCostId={setupcostId}
            />
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    setupCosts: state.setupcosts.setupCosts,
    allsetupCosts: state.setupcosts.allsetupCosts,
  };
};

export default connect(mapStateToProps, {
  getSetupCost,
  getSetupCosts,
  selectSetupCost,
  updateSetupCostsState,
  updateAllSetupCostsState,
  patchSetupCostInDb,
})(editSetupCostView);
