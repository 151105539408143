import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Button, Input } from "semantic-ui-react";
import Select from "react-select";
import {
  selectOptionsFromData,
} from "../../utilities";
import "../components.css";
import { Link } from "react-router-dom";
import _ from "lodash";

import {
  getEffects,
  resetSaveSuccess,
  updateSelectedEffectState,
  updateEffectsState,
} from "../../actions";

class CreateEffectForm extends React.Component {
  renderError({ submitFailed, error }) {
    if (submitFailed && error) {
      return (
        <div className="ui error message">
          <div className="header">{error}</div>
        </div>
      );
    }
  }

  renderResult = () => {
    if (this.props.busySaving) {
      return (
        <div className="ui success message" style={{ marginTop: "1em" }}>
          Saving..
        </div>
      );
    } else if (this.props.saveError) {
      return (
        <div className="ui error message" style={{ marginTop: "1em" }}>
          {this.props.saveError}
        </div>
      );
    } else if (this.props.successMessage) {
      return (
        <div className="ui success message" style={{ marginTop: "1em" }}>
          {this.props.successMessage}
        </div>
      );
    }
  };

  renderInput = ({
    input,
    id,
    style,
    meta,
    placeholder,
    disabled,
    label,
    autoFocus,
  }) => {
    const className = `field ${meta.error && meta.submitFailed ? "error" : ""}`;
    return (
      <div className={className}>
        <div className="formlabel">{label}</div>
        <Input
          {...input}
          id={id}
          style={style}
          placeholder={placeholder}
          disabled={disabled}
          autoFocus={autoFocus}
          ref={
            autoFocus
              ? (input) => {
                  this.focusInput = input;
                }
              : input
          }
        ></Input>
        {this.renderError(meta, id)}
      </div>
    );
  };

  renderSelect = ({
    input,
    id,
    options,
    meta,
    style,
    search,
    placeholder,
    isMulti,
    label,
  }) => {
    const className = `field ${meta.error && meta.submitFailed ? "error" : ""}`;
    return (
      <div className={className} style={{ marginBottom: "1em" }}>
        <div className="formlabel">{label}</div>

        <Select
          {...input}
          fluid
          isMulti={isMulti}
          selection
          isSearchable={search}
          style={style}
          options={options}
          onChange={(value) => input.onChange(value)}
          onBlur={() => input.onBlur()}
          placeholder={placeholder}
        ></Select>
        {this.renderError(meta, id)}
      </div>
    );
  };

  onSubmit = (values) => {
    this.props.onSubmit(values, this.props.effectId);
    if (!this.props.edit) {
      this.props.reset();
      this.focusInput.focus();
    }
    setTimeout(() => {
      this.props.resetSaveSuccess();
    }, 2500);
  };

  render() {
    return (
      <div>
        <div className="content margin-bottom-small">{this.renderResult()}</div>
        <form
          className="ui form error"
          onSubmit={this.props.handleSubmit(this.onSubmit)}
        >
          <Field
            name="name"
            id="name"
            label="Effect name"
            component={this.renderInput}
            placeholder="Enter effect name"
            autoFocus={true}
          ></Field>
          <Field
            name="stages"
            id="stages"
            isMulti
            label="Stages"
            component={this.renderSelect}
            options={selectOptionsFromData(this.props.stages)}
            placeholder="Stages Applicable to Effect"
            search
          />
          <div className="spacer-very-wide"></div>
          <div style={{ textAlign: "right" }}>
            <Button.Group>
              <Link to="/effects">
                <Button color="black" type="button">
                  Cancel
                </Button>
              </Link>
              <Button.Or />
              <Button primary>Save</Button>
            </Button.Group>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  //if editing set the default values

  let effectData = {};

  if (props.edit) {
    effectData = {
      selectedEffect: state.effects.selectedEffect,
      initialValues: {
        name: state.effects.selectedEffect.name,
        stages: selectOptionsFromData(
          state.effects.selectedEffect.stages
        ),
      },
    };
  } else {
    effectData = {
      allEffects: state.effects.allEffects,
    };
  }

  return {
    ...effectData,
    stages: state.stages.stages,
    //api operations
    successMessage: state.api.saveSuccess,
    saveError: state.api.saveError,
    busySaving: state.api.busySaving,
  };
};

const validate = (formValues, props) => {
  const errors = {};
  if (!formValues.stages) {
    //keys of object must match field names
    errors.stages = "Please select at least one stage for the paint effect.";
  }

  if (!formValues.name) {
    //keys of object must match field names
    errors.name = "Please enter a effect name.";
  }

  if (formValues.name) {
    if (
      _.find(props.allEffects, (effect) => {
        return effect.name.toLowerCase() === formValues.name.toLowerCase();
      }) !== undefined
    ) {
      //keys of object must match field names
      errors.name = "Effect with same name already exists";
    }
  }
  return errors;
};

const formWrapped = reduxForm({
  form: "CreateEffectForm",
  enableReinitialize: true,
  validate: validate,
})(CreateEffectForm);

export default connect(mapStateToProps, {
  getEffects,
  resetSaveSuccess,
  updateSelectedEffectState,
  updateEffectsState,
})(formWrapped);
