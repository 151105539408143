import React from "react";
import "../components.css";
import {
  clearActivityConsumable,
  setActivityConsumablesFilter,
  clearActivityConsumableBatch,
  selectActivityConsumable,
  removeActivityConsumableFromSelectedBatch,
  addActivityConsumableToSelectedBatch,
  addAllActivityConsumablesToSelectedBatch,
  deleteActivityConsumables,
  patchActivityConsumableInDb,
  updateActivityConsumablesState,
  getActivityConsumables,
  clearActivityConsumables,
} from "../../actions";
import { connect } from "react-redux";
import { compileDataForSave } from "../../apis/ActivityConsumables";
import _ from "lodash";
import { SAVE, EDIT, NEW, DELETE } from "../../actions/types";
import { Link } from "react-router-dom";
import { Button, Confirm } from "semantic-ui-react";
import history from "../../history";
import DisplayTable from "../displayTable";
import { isMobile } from "react-device-detect";
import {
  removeObjectFromArrayById,
  filterArrayOnConcatenatedKeys,
} from "../../utilities";
import { SET_ACTIVITY_CONSUMABLE_FILTER_LABEL } from "../../actions/types";

class ActivityConsumables extends React.Component {
  state = {
    confirmDeleteOpen: false,
    activityConsumableDeleteId: null,
  };

  componentDidMount() {
    if (this.props.activityConsumables.length === 0) {
      this.props.getActivityConsumables({});
    }
    //create a clone for filter search purposes. This full set used to "reset" filter to full set of objects
  }

  onActivityConsumablesFilterChange = (filter) => {
    const {
      clearActivityConsumable,
      activityConsumableSearchTerms,
      setActivityConsumablesFilter,
      updateActivityConsumablesState,
      allActivityConsumables,
    } = this.props;
    const _filter =
      filter !== undefined
        ? filter
        : activityConsumableSearchTerms.filter.label;
    if (_filter !== activityConsumableSearchTerms.filter.value) {
      clearActivityConsumable();
      if (_filter !== "") {
        let _activityConsumables = [...allActivityConsumables];
        const _filteredActivityConsumables = [
          ...filterArrayOnConcatenatedKeys(
            _activityConsumables,
            ["scopeName", "stageName", "consumableName"],
            _filter
          ),
        ];
        updateActivityConsumablesState(_filteredActivityConsumables);
      } else {
        updateActivityConsumablesState([...allActivityConsumables]);
      }
    }
    setActivityConsumablesFilter(_filter);
  };

  onSelectActivityConsumable = (id) => {
    const {
      selectActivityConsumable,
      activityConsumables,
      clearActivityConsumableBatch,
    } = this.props;
    selectActivityConsumable(
      activityConsumables.find(
        (activityConsumable) => activityConsumable.id === id
      )
    );
    clearActivityConsumableBatch();
  };

  onAddActivityConsumableToBatch = (activityConsumable) => {
    if (
      this.props.activityConsumableBatch.find(
        (item) => item === activityConsumable
      )
    ) {
      this.props.removeActivityConsumableFromSelectedBatch(activityConsumable);
    } else {
      this.props.addActivityConsumableToSelectedBatch(activityConsumable);
    }
  };

  onAddAllActivityConsumablesToSelectedBatch = () => {
    if (
      this.props.activityConsumableBatch.length ===
      this.props.activityConsumables.length
    ) {
      this.props.clearActivityConsumableBatch();
    } else {
      this.props.addAllActivityConsumablesToSelectedBatch(
        this.props.activityConsumables
      );
    }
  };

  onBatchDeleteActivityConsumables = () => {
    const {
      activityConsumables,
      activityConsumableBatch,
      deleteActivityConsumables,
      clearActivityConsumableBatch,
      clearActivityConsumable,
    } = this.props;
    if (activityConsumableBatch.length > 0) {
      deleteActivityConsumables(activityConsumables, activityConsumableBatch);
    }
    clearActivityConsumableBatch();
    clearActivityConsumable();
  };

  onDeleteActivityConsumable = () => {
    const { clearActivityConsumable, updateActivityConsumablesState } =
      this.props;
    let _activityConsumable = { ...this.props.selectedActivityConsumable };
    //set to inactive
    _activityConsumable.active = false;
    this.updateActivityConsumable(_activityConsumable);
    clearActivityConsumable();
    updateActivityConsumablesState(
      removeObjectFromArrayById(
        this.props.activityConsumables,
        _activityConsumable
      )
    );
  };

  updateActivityConsumable = (activityConsumable) => {
    const { patchActivityConsumableInDb } = this.props;
    let _activityConsumable = compileDataForSave(
      {
        consumable: activityConsumable.consumableId,
        SOR: activityConsumable.scopeId,
        stage: activityConsumable.stageId,
        price: activityConsumable.price,
      },
      activityConsumable.id,
      activityConsumable.active
    );
    patchActivityConsumableInDb(_activityConsumable);
  };

  //called from tables main menu to do ad-hoc save.
  onSaveChanges = () => {
    const _activityConsumable = { ...this.props.selectedActivityConsumable };
    if (!_.isEmpty(_activityConsumable)) {
      this.updateActivityConsumable(_activityConsumable);
    }
  };

  renderActivityConsumablesTable() {
    const menuItems = [
      {
        name: "Save Changes",
        key: SAVE,
        icon: "save",
        onClick: this.onSaveChanges,
        type: Button,
        disabled: this.props.activityConsumables.length === 0,
      },
      {
        name: "New",
        key: NEW,
        icon: "add square",
        to: `/activityconsumables/create`,
        type: Link,
        disabled: false,
      },
    ];

    const itemMenuActions = [
      {
        key: EDIT,
        text: "Edit Activity Consumable",
        value: EDIT,
        icon: { name: "edit", color: "green" },
        idx: 0,
        executeAction: ({ itemId }) => {
          history.push(`/activityconsumables/edit/${itemId}`);
        },
      },
      {
        key: DELETE,
        text: "Delete",
        value: DELETE,
        icon: { name: "delete", color: "red" },
        idx: 1,
        executeAction: ({ itemId }) => {
          this.setState({
            activityConsumableDeleteId: itemId,
            confirmDeleteOpen: true,
          });
        },
      },
    ];

    const tableMenuActions = [
      {
        key: DELETE,
        confirmation: 1,
        text: "Delete",
        value: DELETE,
        icon: { name: "remove", color: "red" },
        idx: 0,
        action: this.onBatchDeleteActivityConsumables,
        header: "Confirm Delete",
      },
    ];

    const gridColumns = "one";
    const tableColumns = [
      {
        width: "four",
        heading: "Stage Name (Activity)",
        displayValues: [{ columnName: "stageName" }],
      },
      {
        width: "three",
        heading: "Scope Name",
        displayValues: [{ columnName: "scopeName" }],
      },
      {
        width: "two",
        heading: "Price per dm²",
        displayValues: [{ columnName: "price" }],
        type: "Currency",
      },
    ];

    const primaryColumn = {
      heading: "Consumable Name",
      width: "three",
      displayValues: [{ columnName: "consumableName" }],
    };

    const copyConfig = {
      active: false,
    };

    //check if "model panels message must be displayed"

    return (
      <>
        <DisplayTable
          idFieldName="id"
          primaryHeading="Activity Consumables"
          items={this.props.activityConsumables}
          selectedItemId={this.props.selectedActivityConsumable.id}
          menuItems={menuItems}
          tableMenuActions={tableMenuActions}
          itemMenuActions={itemMenuActions}
          showSearch={0}
          searchTerms={this.props.activityConsumableSearchTerms}
          onSelectItem={this.onSelectActivityConsumable}
          onSearchTermChange={() => {
            return;
          }}
          onFilterChange={this.onActivityConsumablesFilterChange}
          filterAction={SET_ACTIVITY_CONSUMABLE_FILTER_LABEL}
          batchItems={this.props.activityConsumableBatch}
          onAddItemToBatch={this.onAddActivityConsumableToBatch}
          onAddAllItemsToBatch={this.onAddAllActivityConsumablesToSelectedBatch}
          primaryColumn={primaryColumn}
          gridColumns={gridColumns}
          tableColumns={tableColumns}
          loading={this.props.loadingData}
          copyItemsConfig={copyConfig}
        ></DisplayTable>
      </>
    );
  }

  renderDeleteConfirm() {
    return (
      <Confirm
        open={this.state.confirmDeleteOpen}
        header="Item Actions"
        content={`Confirm Delete?`}
        onCancel={() => {
          this.setState({ confirmDeleteOpen: false });
        }}
        onConfirm={() => {
          this.setState({ confirmDeleteOpen: false });
          this.onDeleteActivityConsumable();
        }}
      ></Confirm>
    );
  }

  render() {
    const className = `ui ${isMobile ? "" : "very padded"} segment`;
    return (
      <>
        <div className={className}>
          <div>{this.renderActivityConsumablesTable()}</div>
          {this.renderDeleteConfirm()}
          <div className="spacer-very-wide"></div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activityConsumableSearchTerms:
      state.activityConsumables.activityConsumableSearchTerms,
    activityConsumables: state.activityConsumables.activityConsumables,
    allActivityConsumables: state.activityConsumables.allActivityConsumables,
    activityConsumableBatch: state.activityConsumables.activityConsumableBatch,
    selectedActivityConsumable:
      state.activityConsumables.selectedActivityConsumable,
    loadingData: state.api.loadingData,
  };
};

export default connect(mapStateToProps, {
  clearActivityConsumable,
  clearActivityConsumables,
  setActivityConsumablesFilter,
  clearActivityConsumableBatch,
  selectActivityConsumable,
  removeActivityConsumableFromSelectedBatch,
  addActivityConsumableToSelectedBatch,
  addAllActivityConsumablesToSelectedBatch,
  deleteActivityConsumables,
  patchActivityConsumableInDb,
  updateActivityConsumablesState,
  getActivityConsumables,
})(ActivityConsumables);
