import {
  GET_PARTS,
  CLEAR_PART,
  SET_PART_FILTER,
  SET_PART_FILTER_LABEL,
  SELECT_PART,
  CLEAR_PART_BATCH,
  REMOVE_PART_FROM_BATCH,
  ADD_PART_TO_BATCH,
  ADD_ALL_PARTS_TO_BATCH,
  UPDATE_PARTS,
  UPDATE_ALL_PARTS,
  CLEAR_PARTS,
  DELETE_PART,
  CLONE_PARTS,
} from "../actions/types";
import _ from "lodash";
import { removeObjectFromArray } from "../utilities";

const INITIAL_STATE = {
  parts: [],
  allParts: [],
  partSearchTerms: {
    filter: { value: "", label: "" },
    term: {
      value: "",
      label: "",
    },
  },
  selectedPart: {
    complexity: { labour: null, material: null },
    positions: [],
  },
  partBatch: [],
};

const partsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PARTS:
      return {
        ...state,
        parts: action.payload,
      };
    case SET_PART_FILTER:
      return {
        ...state,
        partSearchTerms: {
          ...state.partSearchTerms,
          filter: {
            ...state.partSearchTerms.filter,
            value: action.payload,
          },
        },
      };
    case SET_PART_FILTER_LABEL:
      return {
        ...state,
        partSearchTerms: {
          ...state.partSearchTerms,
          filter: {
            ...state.partSearchTerms.filter,
            label: action.payload,
          },
        },
      };
    case SELECT_PART:
      return {
        ...state,
        selectedPart: action.payload,
      };
    case CLEAR_PART:
      return { ...state, selectedPart: {} };
    case CLEAR_PARTS:
      return { ...state, parts: [] };
    case CLEAR_PART_BATCH:
      return {
        ...state,
        partBatch: [],
      };
    case REMOVE_PART_FROM_BATCH:
      return {
        ...state,
        //removes 2nd argument from array and returns new array
        partBatch: _.remove(state.partBatch, (part) => part !== action.payload),
      };
    case ADD_PART_TO_BATCH:
      return {
        ...state,
        partBatch: [...state.partBatch, action.payload],
      };
    case ADD_ALL_PARTS_TO_BATCH:
      return {
        ...state,
        partBatch: action.payload,
      };
    case UPDATE_PARTS:
      return {
        ...state,
        parts: action.payload,
      };
    case UPDATE_ALL_PARTS:
      return {
        ...state,
        allParts: action.payload,
      };
    case DELETE_PART:
      return {
        ...state,
        parts: removeObjectFromArray(...state.parts, action.payload),
      };
    case CLONE_PARTS:
      return {
        ...state,
        allParts: action.payload,
      };
    default:
      return state;
  }
};

export default partsReducer;
