import React from "react";
import { Dropdown, Popup } from "semantic-ui-react";

const ItemActionsMenu = ({ actions, onSelectItem, itemId, itemSubId, masterId, masterId2 }) => {
  const handleChange = (e, data) => {
    if (data) {
      actions[data.idx].executeAction({ masterId, masterId2, itemId });
    }
  };

  const dropdownStyle = {
    marginBottom: "20px",
    float: "right",
  };

  const menuStyle = {
    borderStyle: "none",
    boxShadow: "none",
  };

  return (
    <>
      <Popup
        trigger={
          <Dropdown
            onClick={() => onSelectItem(itemId, itemSubId)}
            icon="ellipsis horizontal"
            pointing="top right"
            className="ui basic tiny green icon button"
            style={dropdownStyle}
          >
            <Dropdown.Menu>
              <Dropdown.Menu style={menuStyle} scrolling>
                {actions.map((action) => (
                  <Dropdown.Item
                    key={action.value}
                    {...action}
                    onClick={handleChange}
                  />
                ))}
              </Dropdown.Menu>
            </Dropdown.Menu>
          </Dropdown>
        }
        content="Actions"
        position="left center"
      />
    </>
  );
};

export default ItemActionsMenu;
