import {
  GET_MODELS,
  SELECT_MODEL,
  SET_LAST_MODEL_KEY,
  CLEAR_MODEL,
  ARCHIVE_MODEL,
  SELECT_MODEL_PANEL,
  CLEAR_MODEL_PANEL,
  ADD_MODEL_TO_BATCH,
  REMOVE_MODEL_FROM_BATCH,
  CLEAR_MODEL_BATCH,
  SET_MODEL_PARTS,
  REMOVE_MODEL_PANEL_FROM_BATCH,
  ADD_MODEL_PANEL_TO_BATCH,
  CLEAR_MODEL_PANEL_BATCH,
  ADD_ALL_MODELS_TO_BATCH,
  ADD_ALL_MODEL_PANELS_TO_BATCH,
  SET_MODEL_SEARCH_TERM,
  SET_MODEL_FILTER,
  SET_MODEL_FILTER_LABEL,
  SET_MODEL_PANEL_SEARCH_TERM,
  SET_MODEL_PANEL_FILTER,
  SET_MODEL_PANEL_FILTER_LABEL,
  SET_MODEL_PANEL_INCLUDE_EXCLUDE,
  UPDATE_MODELS,
  CLONE_MODELS,
  UPDATE_ALL_MODELS,
} from "../actions/types";
import _ from "lodash";

const INITIAL_STATE = {
  models: [],
  selectedModel: {
    body: { subtype: null, bodytype: null, size: null },
    parts: [],
    allParts: [],
  },
  lastModelKey: {},
  selectedModelPanel: {},
  modelBatch: [],
  modelPanelBatch: [],
  modelSearchTerms: {
    term: { label: "Select Manufacturer", value: "" },
    filter: { value: "", label: "" },
  },
  modelPanelSearchTerms: { term: { label: "", value: "" }, filter: "" },
  bulkCopyDone: false,
  allModels: [],
};

const compileModel = (model) => {
  let _model = { ...model };
  if (!model.allParts) {
    _model.allParts = [...model.parts];
  } else {
    _model.allParts = [...model.allParts];
  }
  return _model;
};

const modelsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_MODELS:
      return {
        ...state,
        models: action.payload,
      };
    case UPDATE_MODELS:
      return {
        ...state,
        models: action.payload,
      };
    case UPDATE_ALL_MODELS:
      return {
        ...state,
        allModels: action.payload,
      };
    case SELECT_MODEL:
      return {
        ...state,
        selectedModel: compileModel(action.payload.model),
        selectedModelPanel: action.payload.selectedPanel,
      };
    case CLEAR_MODEL:
      return { ...state, selectedModel: {} };
    case SELECT_MODEL_PANEL:
      return { ...state, selectedModelPanel: action.payload };
    case CLEAR_MODEL_PANEL:
      return { ...state, selectedModelPanel: {} };
    case SET_LAST_MODEL_KEY:
      return { ...state, lastModelKey: action.payload };
    case ADD_MODEL_TO_BATCH:
      return {
        ...state,
        modelBatch: [...state.modelBatch, action.payload],
      };
    case ADD_ALL_MODELS_TO_BATCH:
      return {
        ...state,
        modelBatch: action.payload,
      };
    case ADD_MODEL_PANEL_TO_BATCH:
      return {
        ...state,
        modelPanelBatch: [...state.modelPanelBatch, action.payload],
      };
    case ADD_ALL_MODEL_PANELS_TO_BATCH:
      return {
        ...state,
        modelPanelBatch: action.payload,
      };
    case REMOVE_MODEL_FROM_BATCH:
      return {
        ...state,
        //removes 2nd argument from array and returns new array
        modelBatch: _.remove(state.modelBatch, (model) => model !== action.payload),
      };
    case REMOVE_MODEL_PANEL_FROM_BATCH:
      return {
        ...state,
        modelPanelBatch: _.remove(state.modelPanelBatch, (id) => id !== action.payload),
      };
    case CLEAR_MODEL_BATCH:
      return {
        ...state,
        modelBatch: [],
      };
    case CLEAR_MODEL_PANEL_BATCH:
      return {
        ...state,
        modelPanelBatch: [],
      };
    case SET_MODEL_PARTS:
      return {
        ...state,
        selectedModel: { ...state.selectedModel, parts: action.payload },
      };
    case SET_MODEL_PANEL_INCLUDE_EXCLUDE:
      return {
        ...state,
        selectedModelPanel: {
          ...state.selectedModelPanel,
          exclude: action.payload,
        },
      };
    case ARCHIVE_MODEL:
      return _.omit(state, action.payload);
    case SET_MODEL_SEARCH_TERM:
      return {
        ...state,
        modelSearchTerms: { ...state.modelSearchTerms, term: action.payload },
      };
    case SET_MODEL_FILTER:
      return {
        ...state,
        modelSearchTerms: {
          ...state.modelSearchTerms,
          filter: {
            ...state.modelSearchTerms.filter,
            value: action.payload,
          },
        },
      };
    case SET_MODEL_FILTER_LABEL:
      return {
        ...state,
        modelSearchTerms: {
          ...state.modelSearchTerms,
          filter: {
            ...state.modelSearchTerms.filter,
            label: action.payload,
          },
        },
      };
    case SET_MODEL_PANEL_SEARCH_TERM:
      return {
        ...state,
        modelPanelSearchTerms: {
          ...state.modelPanelSearchTerms,
          term: action.payload,
        },
      };
    case SET_MODEL_PANEL_FILTER:
      return {
        ...state,
        modelPanelSearchTerms: {
          ...state.modelPanelSearchTerms,
          filter: {
            ...state.modelPanelSearchTerms.filter,
            value: action.payload,
          },
        },
      };
    case SET_MODEL_PANEL_FILTER_LABEL:
      return {
        ...state,
        modelPanelSearchTerms: {
          ...state.modelPanelSearchTerms,
          filter: {
            ...state.modelPanelSearchTerms.filter,
            label: action.payload,
          },
        },
      };
    case CLONE_MODELS:
      return {
        ...state,
        allModels: action.payload,
      };
    default:
      return state;
  }
};

export default modelsReducer;
