import React from "react";
import { Message } from "semantic-ui-react";

class Stages extends React.Component {
  render() {
    return (
      <>
        <Message warning>
          <Message.Header>Sorry, we're still working on this!</Message.Header>
          <p>Select another section from the menu.</p>
        </Message>
      </>
    );
  }
}

export default Stages;
