import Axios from "axios";
import { env } from "./env";

export const Models = ({ manufacturerid, id, name, limit, startId, modelIds }) => {
  const _env = env();
  const baseURL = _env.BASEURL;
  const key = _env.API_KEY;
  let startKeyFilter = "";

  if (id) {
    return Axios.create({
      baseURL: `${baseURL}/vehicles/models/${id}`,
      headers: { "x-api-key": key },
    });
  } else if (name) {
    return Axios.create({
      baseURL: `${baseURL}/vehicles/models?manufacturerId=${manufacturerid}&name=${name}`,
      headers: { "x-api-key": key },
    });
  } else if (manufacturerid) {
    startKeyFilter = startId ? `&startid=${startId}&manufacturerId=${manufacturerid}` : "";
    const lmt = limit ? "&limit=" + limit : "";
    return Axios.create({
      baseURL: `${baseURL}/vehicles/models?manufacturerId=${manufacturerid}${startKeyFilter}${lmt}`,
      headers: { "x-api-key": key },
    });
  } else if (modelIds) {
    return Axios.create({
      baseURL: `${baseURL}/vehicles/models?ids=${modelIds}`,
      headers: { "x-api-key": key },
    });
  }
  return Axios.create({
    baseURL: `${baseURL}/vehicles/models`,
    headers: { "x-api-key": key },
  });
};

export const compileDataForSave = (
  { bodytype, manufacturerid, modelname, subtype, size, year, yearto, paintcodelocation, parts },
  { id, state }
) => {
  const model = {};
  model.parts = [];
  model.id = id;
  model.active = state;
  model.body = {};
  model.body.subtypeId = subtype.value;
  model.body.typeId = bodytype.value;
  model.body.size = size.value;
  model.name = modelname;
  model.l_name = modelname.toLowerCase();
  model.manufacturerId = manufacturerid;
  model.year = year.value;
  model.yearto = yearto.value;
  model.paintcodelocation = paintcodelocation.value;
  if (parts) {
    //We don't want to save all the props in state like model desc. Just 3 fields
    model.parts = parts.map((part) => {
      return {
        id: part.id,
        area: part.area,
        exclude: part.exclude,
        variant: part.variant,
        variantname: part.variantname,
        supersedes: part.supersedes,
        supersedename: part.supersedename,
        visible: part.visible
      };
    });
  }
  return model;
};

export const compileModelPanelObject = ({ area, part, variant, supersedes }) => {
  let newPart = {};
  newPart.area = area;
  newPart.id = part.value;
  newPart.exclude = "FALSE";
  newPart.name = part.label;
  if (variant) {
    newPart.variant = variant.value;
    newPart.variantname = variant.label;
  }
  if (supersedes) {
    newPart.supersedes = supersedes.value;
    newPart.supersedename = supersedes.label;
  }
  newPart.visible = true;
  return newPart;
};

export const compileModelObject = (
  {
    body,
    bodytype,
    manufacturer,
    manufacturerId,
    manufacturerName,
    name,
    subtype,
    size,
    year,
    yearto,
    paintcodelocation,
    paintcodelocationId,
    paintcodelocationName,
    parts,
  },
  { id, state }
) => {
  const model = {};
  model.id = id;
  model.active = state;
  if (body) {
    model.body = body;
  } else {
    model.body = {};
    model.body.subtypeId = subtype.value;
    model.body.subtypeName = subtype.label;
    model.body.typeId = bodytype.value;
    model.body.typeName = bodytype.label;
    model.body.size = size.value;
  }
  model.name = name;
  model.l_name = name.toLowerCase();
  model.manufacturerId = manufacturer ? manufacturer.value : manufacturerId;
  model.manufacturerName = manufacturer ? manufacturer.label : manufacturerName;
  model.year = year.value;
  model.yearto = yearto.value;
  model.paintcodelocationId = paintcodelocation ? paintcodelocation.value : paintcodelocationId;
  model.paintcodelocationName = paintcodelocation ? paintcodelocation.label : paintcodelocationName;
  model.parts = parts ? parts : [];
  return model;
};
