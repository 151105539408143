import React from "react";
import Menu from "../components/Menu";
import { compileFuelPriceObject } from "../apis/FuelPrices";
import {
  getFuelPrices,
  updateFuelPricesState,
  updateAllFuelPricesState,
  selectFuelPrice,
  postFuelPriceToDb,
  getFuelTypes,
} from "../actions";
import { connect } from "react-redux";
import CreateFuelPriceForm from "../components/repairs/CreateFuelPriceForm";
import { nanoid } from "nanoid";
import { updateStateAfterCreate } from "../utilities";

class createFuelPriceView extends React.Component {
  componentDidMount() {
    if (this.props.allFuelPrices.length === 0) {
      this.props.getFuelPrices({});
    }
    this.props.getFuelTypes();
  }

  onSubmit = (values, fuelPriceId) => {
    const {
      fuelPrices,
      allFuelPrices,
      postFuelPriceToDb,
      selectFuelPrice,
      updateFuelPricesState,
      updateAllFuelPricesState,
    } = this.props;
    const fuelPriceData = compileFuelPriceObject(values, fuelPriceId, true);
    postFuelPriceToDb(fuelPriceData).then((result) => {
      fuelPriceData.id = result.data.id;
      updateStateAfterCreate(
        selectFuelPrice,
        updateFuelPricesState,
        updateAllFuelPricesState,
        fuelPrices,
        allFuelPrices,
        fuelPriceData
      );
    });
  };

  render() {
    return (
      <>
        <Menu
          contentView={
            <CreateFuelPriceForm
              edit={false}
              onSubmit={this.onSubmit}
              fuelPriceId={nanoid()}
            />
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fuelPrices: state.fuelprices.fuelPrices,
    allFuelPrices: state.fuelprices.allFuelPrices,
  };
};

export default connect(mapStateToProps, {
  getFuelPrices,
  selectFuelPrice,
  updateFuelPricesState,
  updateAllFuelPricesState,
  postFuelPriceToDb,
  getFuelTypes,
})(createFuelPriceView);
