import React from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import { connect } from "react-redux";
import { Button, Checkbox, Label, Icon } from "semantic-ui-react";
import Select from "react-select";
import {
  selectOptionsFromData,
  dateStringToSeconds,
  currentDateAsInt,
  secondsToShortDateString,
  currentDateAsShortDate
} from "../../utilities";
import "../components.css";
import { Link } from "react-router-dom";
import _ from "lodash";
import "../components.css";

import {
  getSuppliers,
  getPaintSystems,
  resetSaveSuccess,
  getToner,
  getTonerByKey,
  getSupplier,
  getTonerContainerMetrics,
  setTonerCalculatedVolume
} from "../../actions";
import { Fragment } from "react";

class CreateTonerForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      priceChanged: false,
      priceChangeFutureDated: false,
      effectiveDate: "",
      saveSubmitted: false
    };
  }

  componentDidMount() {
    if (this.props.edit) {
      this.props.getTonerByKey(this.props.skey);
    }
    if (!this.props.edit) {
      this.props.getSupplier(this.props.supplierId);
    }
    this.props.getPaintSystems();
    this.props.getTonerContainerMetrics({});
  }

  componentDidUpdate(prevProps) {
    const { price } = this.props;
    if (prevProps.price && price !== prevProps.price) {
      this.setState({ priceChanged: true });
    }
  }

  renderError({ submitFailed, error }) {
    if (submitFailed && error) {
      return (
        <div className="ui error message">
          <div className="header">
            {error}
          </div>
        </div>
      );
    }
  }

  renderResult = () => {
    if (this.props.busySaving) {
      return (
        <div className="ui success message" style={{ marginTop: "1em" }}>
          Saving..
        </div>
      );
    } else if (this.props.saveError) {
      return (
        <div className="ui error message" style={{ marginTop: "1em" }}>
          {this.props.saveError}
        </div>
      );
    } else if (this.props.successMessage) {
      return (
        <div className="ui success message" style={{ marginTop: "1em" }}>
          {this.props.successMessage}
        </div>
      );
    }
  };

  renderInput = ({ input, id, style, type, meta, placeholder, disabled, label }) => {
    const className = `field ${meta.error && meta.submitFailed ? "error" : ""}`;
    return (
      <div className={className}>
        <div className="formlabel">
          {label}
        </div>
        <input
          {...input}
          id={id}
          type={type}
          style={style}
          placeholder={placeholder}
          disabled={disabled}
        />
        {this.renderError(meta, id)}
      </div>
    );
  };

  renderDateInput = ({
    input,
    label,
    className,
    style,
    show,
    value,
    handleChange,
    minDate
  }) => {
    if (show) {
      return (
        <div className={className} style={{ marginBottom: "10px" }}>
          <div className="formlabel">
            {label}
          </div>
          <SemanticDatepicker
            {...input}
            style={style}
            minDate={minDate}
            autoComplete="off"
            value={value}
            pointing="top-right"
            format="YYYY-MM-DD"
            onChange={(e, { value }) => {
              handleChange(value);
            }}
          />
        </div>
      );
    } else return <Fragment />;
  };

  renderCheckBoxToggle = ({ input, show, label, className, handleChange }) => {
    if (show) {
      return (
        <div className={className} style={{ marginBottom: "10px" }}>
          <div className="formlabel">
            {label}
          </div>
          <Checkbox
            {...input}
            onChange={(e, data) => {
              handleChange(data.checked);
            }}
            type="checkbox"
          />
        </div>
      );
    } else return <Fragment />;
  };

  renderSelect = ({
    input,
    id,
    options,
    meta,
    style,
    search,
    placeholder,
    label
  }) => {
    const className = `field ${meta.error && meta.submitFailed ? "error" : ""}`;
    return (
      <div className={className} style={{ marginBottom: "1em" }}>
        <div className="formlabel">
          {label}
        </div>
        <Select
          {...input}
          fluid
          selection
          isSearchable={search}
          style={style}
          options={options}
          onChange={value => input.onChange(value)}
          onBlur={() => input.onBlur()}
          placeholder={placeholder}
        />
        {this.renderError(meta, id)}
      </div>
    );
  };

  createTonerForDisplay = values => {
    const _toner = {};
    const now = currentDateAsShortDate();
    _toner.id = this.props.tonerId;
    _toner.usedByColorCount = this.props.usedByColorCount;
    _toner.usedByColors = this.props.usedByColors;
    _toner.name = values.name;
    _toner.l_name = values.code.toLowerCase() + " - " + values.name.toLowerCase();
    _toner.supplierId = values.supplierid;
    _toner.wastage = values.wastage;
    _toner.system = values.system.value;
    _toner.containerweight = values.containerweight;
    //_toner.costpergram = values.costpergram;
    _toner.discount = values.discount;
    if (this.props.skey) {
      _toner.skey = this.props.skey;
    }
    _toner.weight = values.weight;
    _toner.nettweight = values.nettweight;
    _toner.active = true;
    _toner.code = values.code;
    _toner.cost = values.cost;
    _toner.containervol = values.containervol;
    _toner.discountperc = values.discountperc;
    _toner.price = values.price;
    _toner.supplierName = values.suppliername;
    _toner.systemName = values.system.label;
    _toner.futuredateyn = this.state.priceChangeFutureDated;
    _toner.startdate = dateStringToSeconds(
      this.state.effectiveDate ? this.state.effectiveDate : now
    );
    _toner.state = true;
    return _toner;
  };

  getContainerSizeByWeight = weight => {
    const { tonercontainermetrics } = this.props;
    const m = tonercontainermetrics.find(
      container =>
        parseInt(container.lowlimit) <= parseInt(weight) &&
        parseInt(container.uplimit) >= parseInt(weight)
    );
    if (m) {
      return m.volume;
    } else {
      return "";
    }
  };

  onSubmit = values => {
    this.setState({ saveSubmitted: true });
    this.props.onSubmit(values, this.createTonerForDisplay(values));
    setTimeout(() => {
      this.props.resetSaveSuccess();
    }, 3000);
  };

  showFutureDateLabel = () => {
    const cdate = currentDateAsInt();
    return this.props.startdate > cdate || this.props.enddate < cdate;
  };

  getDateLabelText = () => {
    const cdate = currentDateAsInt();
    if (this.showFutureDateLabel()) {
      if (this.props.enddate < cdate) {
        return `This record terminated on ${secondsToShortDateString(
          this.props.enddate
        )}`;
      } else {
        return `This record will only be active from ${secondsToShortDateString(
          this.props.startdate
        )}`;
      }
    } else {
      if (this.props.enddate === undefined) {
        return `This record is currently active.`;
      } else {
        return `This record is currently active and will terminate on ${secondsToShortDateString(
          this.props.enddate
        )} after which a new price record will become active.`;
      }
    }
  };

  showApplyPriceFromToggle = () => {
    const cdate = currentDateAsInt();
    return (
      this.state.priceChanged &&
      this.props.edit &&
      this.props.startdate <= cdate &&
      (this.props.enddate === undefined || this.props.enddate >= cdate) &&
      !this.props.hasFuturedDatedRecord
    );
  };

  toggleApplyPriceFromDate = checked => {
    this.setState({ priceChangeFutureDated: checked });
  };

  setPriceEffectiveDate = value => {
    this.setState({ effectiveDate: value });
  };

  containerWeightChanged = e => {
    let containerweight = e.target.value;
    this.props.change("containerweight", containerweight);
    this.changeNetWeight(this.props.weight, containerweight, this.props.wastage);
    this.changeCostPerGram(
      this.props.price,
      this.props.discountperc,
      this.props.weight,
      containerweight,
      this.props.wastage
    );
  };

  wastageChanged = e => {
    let wastage = e.target.value;
    this.props.change("wastage", wastage);
    this.changeNetWeight(this.props.weight, this.props.containerweight, wastage);
    this.changeCostPerGram(
      this.props.price,
      this.props.discountperc,
      this.props.weight,
      this.props.containerweight,
      wastage
    );
  };

  changeNetWeight = (weight, containerweight, wastage) => {
    this.props.change(
      "nettweight",
      (weight -
        containerweight -
        (weight - containerweight) * wastage / 100).toFixed(2)
    );
  };

  changeCostPerGram = (price, discountperc, weight, containerweight, wastage) => {
    this.props.change(
      "costpergram",
      ((price - price * discountperc / 100) /
        (weight -
          containerweight -
          (weight - containerweight) * wastage / 100)).toFixed(2)
    );
  };

  changeCost = (price, discountperc) => {
    this.props.change("cost", (price - price * discountperc / 100).toFixed(2));
  };

  changeDiscount = (price, discountperc) => {
    this.props.change("discount", (price * discountperc / 100).toFixed(2));
  };

  itemWeightChanged = e => {
    let itemweight = e.target.value;
    this.props.change("weight", itemweight);
    this.changeNetWeight(itemweight, this.props.containerweight, this.props.wastage);
    this.changeCostPerGram(
      this.props.price,
      this.props.discountperc,
      itemweight,
      this.props.containerweight,
      this.props.wastage
    );
  };

  discountPercChanged = e => {
    let discountperc = e.target.value;
    this.props.change("discountperc", discountperc);
    this.changeDiscount(this.props.price, discountperc);
    this.changeCost(this.props.price, discountperc);
    this.changeCostPerGram(
      this.props.price,
      discountperc,
      this.props.weight,
      this.props.containerweight,
      this.props.wastage
    );
  };

  priceChanged = e => {
    let price = e.target.value;
    this.props.change("price", price);
    this.changeDiscount(price, this.props.discountperc);
    this.changeCost(price, this.props.discountperc);
    this.changeCostPerGram(
      price,
      this.props.discountperc,
      this.props.weight,
      this.props.containerweight,
      this.props.wastage
    );
  };

  render() {
    const labelStyleFuture =
      this.showFutureDateLabel() && this.props.edit ? "inline" : "none";
    const labelStyleActive =
      !this.showFutureDateLabel() && this.props.edit ? "inline" : "none";
    return (
      <div>
        <div className="margin-bottom-small infolabel">
          <Label style={{ display: labelStyleFuture }} color="red">
            <Icon name="hourglass" />
            {this.getDateLabelText()}
          </Label>
        </div>
        <div className="margin-bottom-small infolabel">
          <Label style={{ display: labelStyleActive }} color="green">
            <Icon name="hourglass" />
            {this.getDateLabelText()}
          </Label>
        </div>
        <form
          className="ui form error"
          onSubmit={this.props.handleSubmit(this.onSubmit)}
        >
          <Field
            name="suppliername"
            type="text"
            id="suppliername"
            label="Supplier"
            component={this.renderInput}
            disabled
          />
          <Field
            name="supplierid"
            id="supplierid"
            type="text"
            component={this.renderInput}
            style={{ display: "none" }}
          />
          <Field
            name="system"
            id="system"
            label="Paint system"
            component={this.renderSelect}
            options={selectOptionsFromData(this.props.paintsystems)}
            placeholder="Select paint system"
            search
          />
          <Field
            name="name"
            id="name"
            type="text"
            label="Stock Description"
            component={this.renderInput}
            placeholder="Enter stock description"
          />
          <Field
            name="code"
            id="code"
            type="text"
            label="Stock Code"
            component={this.renderInput}
            placeholder="Enter stock code"
          />
          <Field
            name="weight"
            id="weight"
            type="number"
            label="Item Weight (g)"
            component={this.renderInput}
            onChange={this.itemWeightChanged}
            placeholder="Enter item weight"
            //onBlur={(e) => this.setContainerSizeByWeight(e.target.value)}
          />
          <Field
            name="containervol"
            id="containervol"
            type="number"
            label="Container Volume (ml)"
            component={this.renderInput}
            placeholder="Enter container volume"
          />
          <Field
            name="containerweight"
            id="containerweight"
            type="number"
            label="Container Weight (g)"
            component={this.renderInput}
            onChange={this.containerWeightChanged}
            placeholder="Enter container weight"
          />
          <Field
            name="price"
            id="price"
            type="number"
            label="Item Price"
            component={this.renderInput}
            onChange={this.priceChanged}
            placeholder="Enter price"
          />
          <Field
            name="futuredateyn"
            id="futuredateyn"
            label="Apply new price from specific date?"
            component={this.renderCheckBoxToggle}
            show={this.showApplyPriceFromToggle()}
            handleChange={this.toggleApplyPriceFromDate}
            checked={this.state.priceChangeFutureDated}
          />
          <Field
            name="startdate"
            id="startdate"
            label="Apply new price from"
            minDate={new Date()}
            component={this.renderDateInput}
            show={this.state.priceChangeFutureDated}
            value={this.state.effectiveDate}
            handleChange={this.setPriceEffectiveDate}
          />
          <Field
            name="discountperc"
            type="number"
            id="discountperc"
            label="Discount (%)"
            component={this.renderInput}
            onChange={this.discountPercChanged}
            placeholder="Enter discount percentage"
          />
          <Field
            name="wastage"
            id="wastage"
            type="number"
            label="Wastage (%)"
            component={this.renderInput}
            onChange={this.wastageChanged}
            placeholder="Enter wastage percentage"
          />
          <Field
            name="nettweight"
            id="nettweight"
            label="Usable Content Weight"
            component={this.renderInput}
            disabled
          />
          <Field
            name="discount"
            id="discount"
            label="Discount Amount"
            component={this.renderInput}
            disabled
          />
          <Field
            name="cost"
            id="cost"
            label="Discounted Unit Cost"
            component={this.renderInput}
            disabled
          />
          <Field
            name="costpergram"
            id="costpergram"
            label="Cost per gram"
            component={this.renderInput}
            disabled
          />

          <div className="spacer-very-wide" />

          <div style={{ textAlign: "right" }}>
            <Button.Group>
              <Link to="/toners">
                <Button color="black" type="button">
                  Cancel
                </Button>
              </Link>
              <Button.Or />
              <Button disabled={this.state.saveSubmitted} primary>
                Save
              </Button>
            </Button.Group>
          </div>
        </form>
        <div className="content">
          {this.renderResult()}
        </div>
      </div>
    );
  }
}

//form values selector API
const valueSelector = formValueSelector("CreateTonerForm");

const mapStateToProps = (state, props) => {
  //if editing set the default values

  let tonerData = {};

  if (props.edit) {
    tonerData = {
      skey: state.toners.selectedToner.skey,
      active: state.toners.selectedToner.active,
      startdate: state.toners.selectedToner.startdate,
      enddate: state.toners.selectedToner.enddate,
      usedByColorCount: state.toners.selectedToner.usedByColorCount,
      usedByColors: state.toners.selectedToner.usedByColors,
      initialValues: {
        supplierid: state.toners.selectedToner.supplierId,
        suppliername: state.toners.selectedToner.supplierName,
        system: {
          value: state.toners.selectedToner.systemId,
          label: state.toners.selectedToner.systemName
        },
        name: state.toners.selectedToner.name,
        code: state.toners.selectedToner.code,
        weight: state.toners.selectedToner.weight,
        containervol: state.toners.selectedToner.containervol,
        containerweight: state.toners.selectedToner.containerweight,
        price: state.toners.selectedToner.price,
        discountperc: state.toners.selectedToner.discountperc,
        wastage: state.toners.selectedToner.wastage,
        nettweight: state.toners.selectedToner.nettweight
          ? state.toners.selectedToner.nettweight
          : 0,
        discount: state.toners.selectedToner.discount
          ? state.toners.selectedToner.discount
          : 0,
        cost: state.toners.selectedToner.cost ? state.toners.selectedToner.cost : 0,
        costpergram: ((state.toners.selectedToner.cost
          ? state.toners.selectedToner.cost
          : 0) /
          (state.toners.selectedToner.nettweight
            ? state.toners.selectedToner.nettweight
            : 1)).toFixed(2)
      }
    };
  } else {
    tonerData = {
      allToners: state.toners.allToners,
      initialValues: {
        supplierid: props.supplierId,
        suppliername: state.toners.selectedSupplier.name,
        containervol: state.toners.selectedToner.tonercalculatedvolume
      }
    };
  }

  //get form values from value selector method
  tonerData = {
    ...tonerData,
    weight: valueSelector(state, "weight"),
    containerweight: valueSelector(state, "containerweight"),
    wastage: valueSelector(state, "wastage") ? valueSelector(state, "wastage") : 0,
    price: parseFloat(valueSelector(state, "price")),
    discountperc: valueSelector(state, "discountperc")
      ? valueSelector(state, "discountperc")
      : 0
  };

  return {
    ...tonerData,

    paintsystems: state.toners.systems,
    tonercalculatedvolume: state.toners.tonercalculatedvolume,
    tonercontainermetrics: state.toners.tonercontainermetrics,
    //api operations
    successMessage: state.api.saveSuccess,
    saveError: state.api.saveError,
    busySaving: state.api.busySaving
  };
};

const validate = (formValues, props) => {
  const errors = {};

  if (formValues.supplierid && formValues.code && formValues.system) {
    if (
      _.find(props.allToners, toner => {
        return (
          toner.code.toLowerCase() + toner.supplierId + toner.systemId ===
          formValues.code.toLowerCase() +
            formValues.supplierid +
            formValues.system.value
        );
      }) !== undefined
    ) {
      //keys of object must match field names
      errors.code =
        "Toner with same code for the supplier and paint system already exists";
    }
  }

  if (!formValues.system) {
    //keys of object must match field names
    errors.system = "Please select paint system";
  }
  if (!formValues.name) {
    //keys of object must match field names
    errors.name = "Please enter stock description ";
  }
  if (!formValues.weight) {
    //keys of object must match field names
    errors.weight = "Please enter item weight";
  }
  if (!formValues.containervol) {
    //keys of object must match field names
    errors.containervol = "Please enter container volume";
  }
  if (!formValues.containerweight) {
    //keys of object must match field names
    errors.containerweight = "Please enter empty container weight";
  }
  if (!formValues.price) {
    //keys of object must match field names
    errors.price = "Please enter item price";
  }
  return errors;
};

const formWrapped = reduxForm({
  form: "CreateTonerForm",
  enableReinitialize: true,
  validate: validate
})(CreateTonerForm);

export default connect(mapStateToProps, {
  getSuppliers,
  resetSaveSuccess,
  getPaintSystems,
  getSupplier,
  getTonerContainerMetrics,
  setTonerCalculatedVolume,
  getToner,
  getTonerByKey
})(formWrapped);
