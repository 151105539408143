import Axios from "axios";
import { env } from "./env";

export const FuelPrices = ({ id, country, fueltypId, fuelPriceIds }) => {
  const _env = env();
  const baseURL = _env.BASEURL_X;
  const key = _env.API_X_KEY;

  if (id) {
    return Axios.create({
      baseURL: `${baseURL}/repairs/fuelprices/${id}`,
      headers: { "x-api-key": key }
    });
  } else if (country) {
    return Axios.create({
      baseURL: `${baseURL}/repairs/fuelprices?country=${country}`,
      headers: { "x-api-key": key }
    });
  } else if (fueltypId) {
    return Axios.create({
      baseURL: `${baseURL}/repairs/fuelprices?fueltypId=${fueltypId}`,
      headers: { "x-api-key": key }
    });
  } else if (fuelPriceIds) {
    return Axios.create({
      baseURL: `${baseURL}/repairs/fuelprices?ids=${fuelPriceIds}`,
      headers: { "x-api-key": key }
    });
  }
  return Axios.create({
    baseURL: `${baseURL}/repairs/fuelprices`,
    headers: { "x-api-key": key }
  });
};

export const compileFuelPriceObject = ({ price, fueltype, country }, id, state) => {
  const fuelPrice = {};
  fuelPrice.id = id;
  fuelPrice.price = parseFloat(price);
  fuelPrice.fueltypId = fueltype.value;
  fuelPrice.fueltypName = fueltype.label;
  fuelPrice.active = state;
  fuelPrice.country = country.value;
  fuelPrice.countryName = country.label;
  fuelPrice.l_name = country.label.toLowerCase() + "_" + fueltype.label.toLowerCase() + "_" + price;
  return fuelPrice;
};
