import Axios from "axios";
import { env } from "./env";

export const ReferencePanel = ({ id }) => {
  const _env = env();
  const baseURL = _env.BASEURL;
  const key = _env.API_KEY;

  if (id) {
    return Axios.create({
      baseURL: `${baseURL}/repairs/refpanel/${id}`,
      headers: { "x-api-key": key }
    });
  } else {
    return Axios.create({
      baseURL: `${baseURL}/repairs/refpanel`,
      headers: { "x-api-key": key }
    });
  }
};

export const compileReferenceQuantity = ({ labour, paint, stage, scope }) => {
  let quantity = {};
  quantity.labour = parseInt(labour);
  quantity.paint = parseInt(paint);
  quantity.stage = stage.value;
  quantity.stageName = stage.label;
  quantity.scope = scope.value;
  quantity.scopeName = scope.label;
  return quantity;
};

export const compileReferencePanel = (
  {
    area,
    manufacturer,
    model,
    part,
  },
  { id, quantities }
) => {
  let refPanel = {};
  refPanel.id = id;
  refPanel.area = area;
  refPanel.manufacturerId = manufacturer.value;
  refPanel.manufacturerName = manufacturer.label;
  refPanel.modelId = model.value;
  refPanel.modelName = model.label;
  refPanel.partId = part.value;
  refPanel.partName = part.label;
  refPanel.quantities = quantities ? quantities : [];
  return refPanel;
};
