import React from "react";
import Menu from "../components/Menu";
import { compileModelPanelObject } from "../apis/Models";
import {
  selectModelPanel,
  getModel,
  getModels,
  selectModel,
  setModelSearchTerm,
  updateModelsState,
  updateAllModelsState,
  patchModelInDb,
  getParts
} from "../actions";
import { connect } from "react-redux";
import CreatePanelForm from "../components/vehicles/CreatePanelForm";
import { replaceArrayObjectByKey } from "../utilities";

class editModelPanelView extends React.Component {
  onSubmit = (values) => {
    const { selectedModel, patchModelInDb, updateModelsState, selectModel, selectModelPanel } =
      this.props;
    let updatedModelPanel = compileModelPanelObject(values);
  
    let _model = { ...selectedModel };
    //update unfiltered array of panels
    const _allModelPanels = replaceArrayObjectByKey(_model.allParts, "id", updatedModelPanel);
    //set of panels may be filtered. Updated filtered array of panels
    const _modelPanels = replaceArrayObjectByKey(_model.parts, "id", updatedModelPanel);
    //update full array of panels (unfiltered) back to db
    _model.parts = _allModelPanels;
    _model.allParts = _allModelPanels;
    patchModelInDb(_model);

    //update Paint Color changes back to state
    updateModelsState(replaceArrayObjectByKey(this.props.models, "id", _model));
    updateAllModelsState(replaceArrayObjectByKey(this.props.allModels, "id", _model));
    //modelpanels may have been filtered. Reapply filtered array
    let _filteredModel = {..._model};
    _filteredModel.parts = _modelPanels;
    selectModel(_filteredModel);
    selectModelPanel(updatedModelPanel);
    
  };

  componentDidMount() {
    const { modelId, panelId } = this.props.match.params;
    this.props.getParts({});
    if (!this.props.selectedModel.id) {
      if (modelId) {
        this.props.getModel({ modelId, panelId }).then(() => {
          if (this.props.allModels.length === 0) {
            this.props.getModels({
              manufacturerid: this.props.selectedModel.manufacturerId,
            });
            //set the searchterms to selected paintcolor manufacturer (for main form)
            this.props.setModelSearchTerm({
              value: this.props.selectedModel.manufacturerId,
              label: this.props.selectedModel.manufacturerName,
            });
          }
        });
      }
    }
  }

  render() {
    return (
      <>
        <Menu
          contentView={
            <CreatePanelForm
              edit={true}
              supersedeOptions = {this.props.parts}
              onSubmit={this.onSubmit}
            />
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedModel: state.models.selectedModel,
    selectedModelPanel: state.models.selectedModelPanel,
    models: state.models.models,
    allModels: state.models.allModels,
    parts: state.parts.parts,
  };
};

export default connect(mapStateToProps, {
  selectModel,
  updateModelsState,
  updateAllModelsState,
  selectModelPanel,
  getModel,
  getModels,
  patchModelInDb,
  setModelSearchTerm,
  getParts
})(editModelPanelView);
