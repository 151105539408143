import React from "react";
import Menu from "../components/Menu";
import CreatePaintColorForm from "../components/paint/CreatePaintColorForm";
import { compilePaintColorObject } from "../apis/PaintColors";
import {
  getPaintColor,
  getPaintColors,
  selectPaintColor,
  updatePaintColorsState,
  updateAllPaintColorsState,
  patchPaintColorInDb,
  getManufacturer,
  getSuppliers,
  getPaintSystems,
  getEffects,
  getStages,
  getColorGroups,
  setPaintColorSearchTerm,
} from "../actions";
import { replaceArrayObjectByKey } from "../utilities";
import { connect } from "react-redux";

class editPaintColorView extends React.Component {
  onSubmit = (values, colorId) => {
    const {
      paintColors,
      selectedPaintColor,
      patchPaintColorInDb,
      selectPaintColor,
      updatePaintColorsState,
      updateAllPaintColorsState,
      allPaintColors,
    } = this.props;
    const paintColorData = compilePaintColorObject(
      { ...values, toners: selectedPaintColor.allToners },
      {
        id: colorId,
        state: true,
      }
    );
    patchPaintColorInDb(paintColorData);
    //update PaintColor changes back to state
    //first make sure to set toners to full set in case the toners were filtered
    let _paintColor = {...selectedPaintColor};
    _paintColor.colorgroupId = values.colorgroup.value;
    _paintColor.colorgroupName = values.colorgroup.label;
    _paintColor.name = values.name;
    _paintColor.l_name = values.name.toLowerCase();
    _paintColor.code = values.code;
    _paintColor.testplateQty = values.testplateQty;
    _paintColor.reworkFactor = values.reworkFactor;

    selectPaintColor(_paintColor);

    _paintColor.toners = [..._paintColor.allToners];
    delete _paintColor.allToners;

    updatePaintColorsState(
      replaceArrayObjectByKey(paintColors, "id", _paintColor)
    );
    updateAllPaintColorsState(
      replaceArrayObjectByKey(allPaintColors, "id", _paintColor)
    );
  };

  componentDidMount() {
    const { paintcolorId } = this.props.match.params;
    const {
      getSuppliers,
      getPaintSystems,
      getStages,
      getEffects,
      getColorGroups,
      getManufacturer,
      allPaintColors,
      getPaintColors,
      getPaintColor,
      selectedPaintColor,
    } = this.props;
    if (!selectedPaintColor.id) {
      if (paintcolorId) {
        getPaintColor({ colorId: paintcolorId }).then(() => {
          //reload paint colors on refresh
          if (allPaintColors.length === 0) {
            getPaintColors({
              manufacturerid: this.props.selectedPaintColor.manufacturerId,
            });
            //set the searchterms to selected paintcolor manufacturer (for main form)
            this.props.setPaintColorSearchTerm({
              value: this.props.selectedPaintColor.manufacturerId,
              label: this.props.selectedPaintColor.manufacturerName,
            });
          }
        });
        getManufacturer(paintcolorId);
      }
    }

    getSuppliers({});
    getPaintSystems();
    getStages({});
    getEffects({});
    getColorGroups({});
  }

  render() {
    const { paintcolorId } = this.props.match.params;
    return (
      <>
        <Menu
          contentView={
            <CreatePaintColorForm
              edit={true}
              onSubmit={this.onSubmit}
              colorId={paintcolorId}
            />
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    paintColors: state.paintcolors.paintColors,
    allPaintColors: state.paintcolors.allPaintColors,
    selectedPaintColor: state.paintcolors.selectedPaintColor,
  };
};

export default connect(mapStateToProps, {
  getPaintColor,
  getPaintColors,
  selectPaintColor,
  updatePaintColorsState,
  updateAllPaintColorsState,
  patchPaintColorInDb,
  getManufacturer,
  getSuppliers,
  getPaintSystems,
  getStages,
  getEffects,
  getColorGroups,
  setPaintColorSearchTerm,

})(editPaintColorView);
