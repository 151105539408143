import React from "react";
import Menu from "../components/Menu";
import CreateConsumableForm from "../components/repairs/CreateConsumableForm";
import { compileDataForSave } from "../apis/Consumables";
import {
  getConsumable,
  getConsumables,
  selectConsumable,
  updateConsumablesState,
  patchConsumableInDb,
} from "../actions";
import { replaceArrayObjectByKey } from "../utilities";
import { connect } from "react-redux";

class editConsumableView extends React.Component {
  onSubmit = (values, consumable) => {
    const { patchConsumableInDb, selectConsumable, consumables, updateConsumablesState } = this.props;
    const consumablesData = compileDataForSave(
      values,
      consumable.id,
      consumable.active
    );
    patchConsumableInDb(consumablesData);

    //update Consumable changes back to state
    selectConsumable(consumable);
    let _consumables = replaceArrayObjectByKey([...consumables], "id", consumable);
    updateConsumablesState(_consumables);
  };

  componentDidMount() {
    const { consumableId } = this.props.match.params;
    if (consumableId) {
      this.props.getConsumable(consumableId);
    }
    if (this.props.allConsumables.length === 0) {
      this.props.getConsumables({});
    }
  }

  render() {
    const { consumableId } = this.props.match.params;
    return (
      <>
        <Menu
          contentView={
            <CreateConsumableForm
              edit={true}
              onSubmit={this.onSubmit}
              consumableId={consumableId}
            />
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    consumables: state.consumables.consumables,
    allConsumables: state.consumables.allConsumables,
  };
};

export default connect(mapStateToProps, {
  getConsumable,
  getConsumables,
  selectConsumable,
  updateConsumablesState,
  patchConsumableInDb,
})(editConsumableView);
