import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Button, Input } from "semantic-ui-react";
import Select from "react-select";
import "../components.css";
import { Link } from "react-router-dom";
import { selectOptionsFromData } from "../../utilities";
import {
  resetSaveSuccess,
  updateSelectedPrimerPriceState,
  updatePrimerPricesState
} from "../../actions";

class CreatePrimerPriceForm extends React.Component {
  renderError({ submitFailed, error }) {
    if (submitFailed && error) {
      return (
        <div className="ui error message">
          <div className="header">{error}</div>
        </div>
      );
    }
  }

  renderResult = () => {
    if (this.props.busySaving) {
      return (
        <div className="ui success message" style={{ marginTop: "1em" }}>
          Saving..
        </div>
      );
    } else if (this.props.saveError) {
      return (
        <div className="ui error message" style={{ marginTop: "1em" }}>
          {this.props.saveError}
        </div>
      );
    } else if (this.props.successMessage) {
      return (
        <div className="ui success message" style={{ marginTop: "1em" }}>
          {this.props.successMessage}
        </div>
      );
    }
  };

  renderInput = ({ input, id, style, meta, placeholder, disabled, label, autoFocus }) => {
    const className = `field ${meta.error && meta.submitFailed ? "error" : ""}`;
    return (
      <div className={className}>
        <div className="formlabel">{label}</div>
        <Input
          {...input}
          id={id}
          style={style}
          placeholder={placeholder}
          disabled={disabled}
          autoFocus={autoFocus}
          ref={
            autoFocus
              ? (input) => {
                  this.focusInput = input;
                }
              : input
          }></Input>
        {this.renderError(meta, id)}
      </div>
    );
  };

  renderSelect = ({ input, id, options, meta, style, search, placeholder, label, isDisabled }) => {
    const className = `field ${meta.error && meta.submitFailed ? "error" : ""}`;
    return (
      <div className={className} style={{ marginBottom: "1em" }}>
        <div className="formlabel">{label}</div>
        <Select
          {...input}
          fluid
          selection
          isSearchable={search}
          style={style}
          options={options}
          onChange={(value) => input.onChange(value)}
          onBlur={() => input.onBlur()}
          isDisabled={isDisabled}
          placeholder={placeholder}></Select>
        {this.renderError(meta, id)}
      </div>
    );
  };

  onSubmit = (values) => {
    this.props.onSubmit(values, this.props.primerPriceId);
    if (!this.props.edit) {
      this.props.reset();
    }
    setTimeout(() => {
      this.props.resetSaveSuccess();
    }, 2500);
  };

  render() {
    return (
      <div>
        <div className="content margin-bottom-small">{this.renderResult()}</div>
        <form className="ui form error" onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <Field
            name="name"
            id="name"
            label="Description"
            component={this.renderInput}
            placeholder="Primer Description"
            autoFocus={true}></Field>
          <Field
            name="stage"
            id="stage"
            label="Paint Stage"
            component={this.renderSelect}
            isDisabled={this.props.edit}
            options={selectOptionsFromData(this.props.stages)}
            placeholder="Select Stage"></Field>
          <Field
            name="supplier"
            id="supplier"
            label="Paint Supplier"
            component={this.renderSelect}
            isDisabled={this.props.edit}
            options={selectOptionsFromData(this.props.suppliers)}
            placeholder="Select Supplier"></Field>
          <Field
            name="system"
            id="system"
            label="Paint System"
            component={this.renderSelect}
            isDisabled={this.props.edit}
            options={selectOptionsFromData(this.props.systems)}
            placeholder="Select System"></Field>
          <Field
            name="price"
            id="price"
            label="Price"
            component={this.renderInput}
            placeholder="Enter price"></Field>

          <div className="spacer-very-wide"></div>

          <div style={{ textAlign: "right" }}>
            <Button.Group>
              <Link to="/primerPrices">
                <Button color="black" type="button">
                  Cancel
                </Button>
              </Link>
              <Button.Or />
              <Button primary>Save</Button>
            </Button.Group>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  //if editing set the default values

  let primerPriceData = {};

  if (props.edit) {
    primerPriceData = {
      selectedPrimerPrice: state.primerprices.selectedPrimerPrice,
      stages: state.stages.stages,
      suppliers: state.toners.suppliers,
      systems: state.toners.systems,
      initialValues: {
        name: state.primerprices.selectedPrimerPrice.name,
        price: state.primerprices.selectedPrimerPrice.price,
        stage: {
          value: state.primerprices.selectedPrimerPrice.stageId,
          label: state.primerprices.selectedPrimerPrice.stageName
        },
        supplier: {
          value: state.primerprices.selectedPrimerPrice.supplierId,
          label: state.primerprices.selectedPrimerPrice.supplierName
        },
        system: {
          value: state.primerprices.selectedPrimerPrice.systemId,
          label: state.primerprices.selectedPrimerPrice.systemName
        }
      }
    };
  } else {
    primerPriceData = {
      allPrimerPrices: state.primerprices.allPrimerPrices,
      stages: state.stages.stages,
      suppliers: state.toners.suppliers,
      systems: state.toners.systems
    };
  }

  return {
    ...primerPriceData,

    //api operations
    successMessage: state.api.saveSuccess,
    saveError: state.api.saveError,
    busySaving: state.api.busySaving
  };
};

const validate = (formValues, props) => {
  const errors = {};

  if (!formValues.name) {
    //keys of object must match field names
    errors.name = "Please enter a name for the primer.";
  }

  if (!formValues.stage) {
    //keys of object must match field names
    errors.stage = "Please select a paint stage.";
  }

  if (!formValues.supplier) {
    //keys of object must match field names
    errors.supplier = "Please select a paint supplier.";
  }

  if (!formValues.system) {
    //keys of object must match field names
    errors.system = "Please select a paint system.";
  }

  if (!formValues.price || !typeof formValues.price === "number") {
    //keys of object must match field names
    errors.price = "Please enter a numeric value for the primer price.";
  }

  // if (formValues.price) {
  //   if (
  //     _.find(props.allPrimerPrices, (primerPrice) => {
  //       return (
  //         primerPrice.stageId === formValues.stage.value &&
  //         primerPrice.supplierId === formValues.supplier.value &&
  //         primerPrice.systemId === formValues.system.value
  //       );
  //     }) !== undefined
  //   ) {
  //     //keys of object must match field names
  //     errors.price =
  //       "Primer price record for the selected stage, supplier and paint system already exists";
  //   }
  // }

  return errors;
};

const formWrapped = reduxForm({
  form: "CreatePrimerPriceForm",
  enableReinitialize: true,
  validate: validate
})(CreatePrimerPriceForm);

export default connect(mapStateToProps, {
  resetSaveSuccess,
  updateSelectedPrimerPriceState,
  updatePrimerPricesState
})(formWrapped);
