import React from "react";
import ActionsMenu from "./ActionsMenu";
import SearchBar from "./Searchbar";
import DisplayTableItem from "./displayTableItem";
import utils, { dropdownOptionsFromData } from "../utilities";
import TableActionsMenu from "./TableActionsMenu";
import {
  Icon,
  Dropdown,
  Confirm,
  Loader,
  Button,
  Segment,
  Divider,
  Header,
} from "semantic-ui-react";
import "./components.css";

class displayTable extends React.Component {
  state = {
    confirmOpen: false,
    confirmCopyOpen: false,
    copyFromId: null,
  };

  renderSearchBar() {
    const {
      onSearchTermChange,
      onFilterChange,
      filterAction,
      searchBarOptions,
      searchBarPlaceholder,
      searchTerms,
      showSearch,
    } = this.props;

    return (
      <div className="margin-top-large">
        <div className="ui two column grid">
          <SearchBar
            searchTerms={searchTerms}
            onSearchTermChange={onSearchTermChange}
            filterAction={filterAction}
            onFilterChange={onFilterChange}
            options={searchBarOptions}
            placeholder={searchBarPlaceholder}
            showSearch={showSearch}
          ></SearchBar>
        </div>
      </div>
    );
  }

  onCopyFromSelectChange = (e, { value }) => {
    this.setState({ copyFromId: value });
  };

  onCopyItemsConfirm = () => {
    this.setState({ confirmCopyOpen: false });
  };

  renderCopyFromExistingListSelector = () => {
    const { items, copyItemsConfig, searchTerms } = this.props;
    const hideOrDisplayCopyFromButton = this.state.copyFromId
      ? "inline"
      : "none";
    const hideOrDisplayCopyFrom = copyItemsConfig.active ? "inline" : "none";
    if (copyItemsConfig.active && searchTerms.filter) {
      return (
        <div className="ui two column grid">
          <div className="six wide column"></div>
          <div
            className="ten wide column"
            style={{
              display: hideOrDisplayCopyFrom,
              textAlign: "right",
            }}
          >
            <div style={{ display: items.length > 1 ? "none" : "inline" }}>
              <span className="inlineheading">Quick Setup</span>
              <span>
                <Dropdown
                  search
                  selection
                  value={this.state.copyFromId}
                  placeholder="Copy sizes from..."
                  options={dropdownOptionsFromData(copyItemsConfig.sourceData)}
                  onChange={this.onCopyFromSelectChange}
                ></Dropdown>
              </span>
              <span
                style={{
                  marginLeft: "0.5em",
                  display: hideOrDisplayCopyFromButton,
                }}
              >
                <Button
                  inverted
                  circular
                  icon
                  color="green"
                  onClick={() => this.setState({ confirmCopyOpen: true })}
                >
                  <Icon name="check" />
                </Button>
              </span>

              <Confirm
                open={this.state.confirmCopyOpen}
                header="Quick Setup"
                content={`Confirm Copy?`}
                onCancel={() => {
                  this.setState({ confirmCopyOpen: false });
                }}
                onConfirm={() => {
                  this.setState({ confirmCopyOpen: false });
                  copyItemsConfig.onConfirm(this.state.copyFromId);
                }}
              ></Confirm>
            </div>
          </div>
        </div>
      );
    }
  };

  renderTableHeaderAndMenu() {
    const { tableMenuActions } = this.props;
    const hideOrDisplayBulkActions =
      (this.props.batchItems.length > 0 && tableMenuActions.length > 0 ) ? "inline" : "none";
    const hideOrDisplayIcon = this.props.primaryHeadIcon ? "inline" : "none";
    return (
      <>
        <div className="ui two column grid">
          <div className="eight wide column">
            <Header
              style={{
                marginTop: "0.8em",
                color: "#234d41",
                textAlign: "center",
              }}
              as="h2"
            >
              <Icon
                style={{ display: hideOrDisplayIcon }}
                name={this.props.primaryHeadIcon}
              />
              {this.props.primaryHeading}
            </Header>
          </div>
          <div className="three wide column">
            <div style={{ display: hideOrDisplayBulkActions }}>
              <TableActionsMenu actions={tableMenuActions}></TableActionsMenu>
            </div>
          </div>
          <div className="five wide column" style={{ textAlign: "right" }}>
            <ActionsMenu
              menuItems={this.props.menuItems}
              disabled={this.props.menuItemsDisabled}
              icon_size="large"
              visible
            ></ActionsMenu>
          </div>
        </div>
      </>
    );
  }

  renderConfirmationModal() {
    return (
      <Confirm
        open={this.state.confirmOpen}
        onCancel={this.onConfirmationCancel}
        onConfirm={this.onConfirmationConfirm}
      ></Confirm>
    );
  }

  setBatchCheckIcon = (list, batch) => {
    if (!list || list.length === 0) {
      return "square outline";
    } else {
      if (batch.length === list.length) {
        return "check square outline";
      } else return "square outline";
    }
  };

  renderColumnHeadings(tableColumns) {
    return tableColumns.map((col) => {
      return (
        <div className={`${col.width} wide column margin-bottom-large`}>
          <div style={{ color: "blue", fontWeight: "bold" }}>{col.heading}</div>
        </div>
      );
    });
  }

  renderTableHeadings() {
    const {
      items,
      batchItems,
      onAddAllItemsToBatch,
      tableColumns,
      primaryColumn,
      gridColumns,
    } = this.props;
    const primaryColHeadClassName =
      primaryColumn.width + " wide column margin-bottom-large";
    const icon = this.setBatchCheckIcon(items, batchItems);
    if (items) {
      if (items.length > 0) {
        return (
          <div className="margin-top-medium">
            <div className={`ui stackable ${gridColumns} column grid`}>
              <div className="two wide column" onClick={onAddAllItemsToBatch}>
                <Icon color="blue" name={icon} size="large" />
              </div>
              <div className={primaryColHeadClassName}>
                <h4>{primaryColumn.heading}</h4>
              </div>
              {this.renderColumnHeadings(tableColumns)}
              <div className="one wide column" style={{ textAlign: "right" }}>
                <h4>Actions</h4>
              </div>
            </div>
          </div>
        );
      }
    }
  }

  renderTableItems() {
    const u = new utils();
    const {
      idFieldName,
      subIdFieldName,
      items,
      itemMenuActions,
      onSelectItem,
      batchItems,
      selectedItemId,
      onAddItemToBatch,
      primaryColumn,
      tableColumns,
      interActiveCheckBox,
      masterId,
      masterId2,
      gridColumns,
    } = this.props;
    if (items) {
      if (items.length > 0) {
        return items.map((item) => {
          return (
            <>
              <DisplayTableItem
                idFieldName={idFieldName}
                subIdFieldName={subIdFieldName}
                item={item}
                itemNumber={u.nextNumber()}
                itemMenuActions={itemMenuActions}
                onSelectItem={onSelectItem}
                selectedItemId={selectedItemId}
                masterId={masterId}
                masterId2={masterId2}
                batchItems={batchItems}
                onAddItemToBatch={onAddItemToBatch}
                primaryColumn={primaryColumn}
                tableColumns={tableColumns}
                interActiveCheckBox={interActiveCheckBox}
                gridColumns={gridColumns}
              ></DisplayTableItem>
            </>
          );
        });
      }
    }
  }

  renderSaveButton() {
    const { onSaveChanges } = this.props;
    if (onSaveChanges) {
      return (
        <div>
          <Button className="primary" onClick={onSaveChanges}>
            Save Changes
          </Button>
        </div>
      );
    }
  }

  renderSaveReminderMessage() {
    const hideOrDisplay = this.props.saveInfoMessageVisible ? "inline" : "none";
    return (
      <div
        className="margin-top-large margin-bottom-large"
        style={{ textAlign: "center" }}
      >
        <div style={{ display: hideOrDisplay }} className="ui warning message">
          <i className="pointing up"></i>
          Please remember to save changes.
        </div>
      </div>
    );
  }

  renderNoItemsMessage() {
    const { primaryHeading, items } = this.props;
    if (items) {
      if (items.length === 0) {
        return (
          <div style={{ textAlign: "center" }} className="ui positive message">
            <i className="big cog icon"></i>
            No {primaryHeading} to display.
          </div>
        );
      }
    }
  }

  renderLoader() {
    const { loading } = this.props;
    if (loading) {
      return (
        <Segment style={{ backgroundColor: "whitesmoke", color: "blue" }}>
          <Loader active inline="centered">
            Loading...
          </Loader>
        </Segment>
      );
    }
  }

  render() {
    return (
      <>
        {this.renderTableHeaderAndMenu()}
        {this.renderSaveButton()}
        {this.renderSearchBar()}
        {this.renderSaveReminderMessage()}
        {this.renderLoader()}
        {this.renderTableHeadings()}
        {this.renderTableItems()}
        <div className="spacer-small"></div>
        <Divider></Divider>
        {this.renderCopyFromExistingListSelector()}
        {this.renderNoItemsMessage()}
      </>
    );
  }
}

export default displayTable;
