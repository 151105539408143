import React from "react";
import Menu from "../components/Menu";
import Toners from "../components/paint/Toners";

const tonersView = () => {
  return (
    <>
      <Menu contentView={<Toners />} />
    </>
  );
};

export default tonersView;
