import React from "react";
import { Popup, Table } from "semantic-ui-react";

/*NOTE: to close modal call () => this.setState({ open: false })*/

const DisplayTableItemDetailPopup = (props) => {
  const style = {
    color: "navy",
    fontWeight: "bold",
    padding: "0.5em",
    borderRadius: "4px",
  };

  const renderContentObject = (item) => {
    let _items = [];
    for (let index = 0; index < Object.keys(item).length; index++) {
      _items.push(Object.values(item)[index]);
    }
    return (
      <Table.Row>
        {_items.map((item) => {
          return (
            <Popup
              trigger={
                <Table.Cell
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigator.clipboard.writeText(item);
                  }}>
                  {item}
                </Table.Cell>
              }
              style={style}
              on={"hover"}>
              Click to copy
            </Popup>
          );
        })}
      </Table.Row>
    );
  };

  const renderContentHeaderRow = (item) => {
    if (item) {
      let _items = [];
      for (let index = 0; index < Object.keys(item).length; index++) {
        _items.push(Object.keys(item)[index]);
      }
      return _items.map((item) => {
        return <Table.HeaderCell>{item}</Table.HeaderCell>;
      });
    }
  };

  const renderContent = (content) => {
    if (Array.isArray(content) && content.length > 0) {
      //*other types can be catered for later if required

      return (
        <div style={{ width: "600px", height: "400px", overflowX: "scroll" }}>
          <Table>
            <Table.Header>
              <Table.Row>{renderContentHeaderRow(content[0])}</Table.Row>
            </Table.Header>
            <Table.Body>
              {content.map((item) => {
                if (typeof item === "object") {
                  //*other types can be catered for later if required
                  return renderContentObject(item);
                }
                return "";
              })}
            </Table.Body>
          </Table>
        </div>
      );
    } else  {
      return "Toner not used";
    }
  };
  return (
    <Popup
      trigger={props.trigger}
      wide="very"
      content={renderContent(props.content)}
      on="click"></Popup>
  );
};

export default DisplayTableItemDetailPopup;
