import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Button, Message } from "semantic-ui-react";
import Select from "react-select";
import { selectOptionsFromData } from "../../utilities";
import "../components.css";
import { Link } from "react-router-dom";
import _ from "lodash";
import { toastr } from "react-redux-toastr";
import { resetSaveSuccess, getPaintColor } from "../../actions";

class CreateColorTonerForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { selectedToner: null, tonerWeight: 0, tonerHasPrice: true, toners: null };
  }

  renderError({ submitFailed, error }) {
    if (submitFailed && error) {
      return (
        <div className="ui error message">
          <div className="header">{error}</div>
        </div>
      );
    }
  }

  renderResult = () => {
    if (this.props.busySaving) {
      return (
        <div className="ui success message" style={{ marginTop: "1em" }}>
          Saving..
        </div>
      );
    } else if (this.props.saveError) {
      return (
        <div className="ui error message" style={{ marginTop: "1em" }}>
          {this.props.saveError}
        </div>
      );
    } else if (this.props.successMessage) {
      return (
        <div className="ui success message" style={{ marginTop: "1em" }}>
          {this.props.successMessage}
        </div>
      );
    }
  };

  renderInput = ({ input, id, style, meta, placeholder, readOnly, label, disabled, className }) => {
    let cn = `field ${meta.error && meta.submitFailed ? "error" : ""}`;
    cn = className === "hidden" ? className : cn;
    return (
      <div className={cn}>
        <div style={{ marginBottom: "1em" }}>
          <div className="formlabel">{label}</div>
          <div>
            <input
              {...input}
              id={id}
              style={style}
              placeholder={placeholder}
              readOnly={readOnly}
              disabled={disabled}></input>
          </div>
          {this.renderError(meta, id)}
        </div>
      </div>
    );
  };

  renderSelect = ({
    input,
    options,
    display,
    search,
    placeholder,
    readOnly,
    isDisabled,
    label,
    meta,
  }) => {
    const className = `field ${meta.error && meta.submitFailed ? "error" : ""}`;
    return (
      <div className={className}>
        <div style={{ display: display }}>
          <div className="formlabel">{label}</div>
          <Select
            {...input}
            fluid
            selection
            isSearchable={search}
            options={options}
            onChange={(value) => {
              input.onChange(value);
            }}
            onBlur={() => input.onBlur()}
            placeholder={placeholder}
            isDisabled={isDisabled}
            readOnly={readOnly}></Select>
        </div>
      </div>
    );
  };

  renderTonerSelect = ({ input, options, display, search, placeholder, readOnly, label, meta }) => {
    const className = `field ${meta.error && meta.submitFailed ? "error" : ""}`;
    return (
      <div className={className}>
        <div style={{ display: display }}>
          <div className="formlabel">{label}</div>
          <Select
            {...input}
            fluid
            selection
            isSearchable={search}
            options={options}
            onChange={(value) => {
              input.onChange(value);
              this.onTonerCustomChange(value);
            }}
            onBlur={() => input.onBlur()}
            placeholder={placeholder}
            readOnly={readOnly}></Select>
        </div>
      </div>
    );
  };

  onTonerCustomChange = (value) => {
    this.setState({
      tonerHasPrice:
        this.state.toners.find((toner) => {
          return toner.id === value.value;
        }).costpergram !== undefined,
    });
  };

  onSubmit = (values) => {
    try {
      this.props.onSubmit(values);
      if (!this.props.edit) {
        this.props.reset();
      }
      toastr.success("Success", "Color Toner record saved.");
    } catch (error) {
      toastr.error("Error", error);
    }
  };

  renderCostPerGramWarning = () => {
    if (!this.state.tonerHasPrice) {
      return (
        <div className="margin-bottom-small">
          <Message warning>
            <Message.Header>Incomplete Data Detected!</Message.Header>
            <p>
              The selected toner does not have a specified cost per gram. To ensure the cost of the
              toner is added to the paint formula, update the toner record with a price to ensure
              the system calculates a cost per gram for the toner. Then try to update this formula
              again.
            </p>
          </Message>
        </div>
      );
    }
  };

  onStageSelectChange = (selected) => {
    this.setState({ toners: this.props.onStageSelect(selected.value) });
  };

  render() {
    const { edit } = this.props;
    return (
      <div>
        <div className="content">{this.renderResult()}</div>
        {this.renderCostPerGramWarning()}
        <form className="ui form error" onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <Field
            name="color"
            id="color"
            label="Paint Color"
            component={this.renderInput}
            disabled></Field>
          <Field
            display="inline"
            name="stage"
            id="stage"
            label="Stage"
            onChange={this.onStageSelectChange}
            component={this.renderSelect}
            options={selectOptionsFromData(this.props.stages)}
            search
            isDisabled={edit}
            placeholder="Select Stage"></Field>
          <Field
            display={edit ? "none" : "inline"}
            name="toner"
            id="toner"
            label="Toner"
            component={this.renderTonerSelect}
            options={selectOptionsFromData(this.state.toners)}
            search
            placeholder="Select Toner"></Field>
          <Field
            name="code"
            id="code"
            label="Code"
            component={this.renderInput}
            className="hidden"
            placeholder="Toner Code"></Field>
          <Field
            style={edit ? { color: "silver" } : { display: "none" }}
            name="tonerDisplay"
            id="tonerDisplay"
            label={edit ? "Toner" : undefined}
            component={this.renderInput}
            readOnly={true}></Field>
          <Field
            name="weight"
            id="weight"
            label="Weight (grams)"
            component={this.renderInput}
            placeholder="Color Toner Weight (grams)"></Field>
          <Field
            name="cost"
            id="cost"
            label="Cost (Calculated)"
            component={this.renderInput}
            readOnly={true}
            placeholder="Toner Cost"></Field>
          <div className="spacer-very-wide"></div>
          <div style={{ textAlign: "right" }}>
            <Button.Group>
              <Link to="/paintcolors">
                <Button color="black" type="button">
                  Cancel
                </Button>
              </Link>
              <Button.Or />
              <Button primary>Save</Button>
            </Button.Group>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  let tonerData = null;
  //if editing set the default values.
  if (props.edit) {
    tonerData = {
      selectedColorToner: state.paintcolors.selectedColorToner,
      selectedPaintColor: state.paintcolors.selectedPaintColor,
      initialValues: {
        weight: state.paintcolors.selectedColorToner.weight,
        toner: {
          value: state.paintcolors.selectedColorToner.id,
          label: state.paintcolors.selectedColorToner.tonerName,
        },
        stage: {
          value: state.paintcolors.selectedColorToner.stageId,
          label: state.paintcolors.selectedColorToner.stageName,
        },
        tonerDisplay: state.paintcolors.selectedColorToner.tonerName,
        color: state.paintcolors.selectedPaintColor.name,
        cost: state.paintcolors.selectedColorToner.cost,
      },
    };
  } else {
    tonerData = {
      selectedPaintColor: state.paintcolors.selectedPaintColor,
      initialValues: {
        color: state.paintcolors.selectedPaintColor.name,
      },
    };
  }

  return {
    ...tonerData,
    stages: state.effects.selectedEffect.stages,
    //api operations
    successMessage: state.api.saveSuccess,
    saveError: state.api.saveError,
    busySaving: state.api.busySaving,
  };
};

//specified below in form definition. Called when form changes
//used to calculate the toner price when weight has been specified.
//note that toners are passed from parent component (not loaded from state
//as it is filtered to remove already selected toners for the paint formula)
const handleOnFormChange = (newValues, dispatch, props) => {
  const { toner: newToner, weight: newWeight } = newValues;
  if (newToner && props.toners) {
    const _toner = props.toners.find((toner) => {
      return toner.id === newToner.value;
    });
    if (_toner) {
      if (_toner.costpergram) {
        dispatch(props.change("code", _toner.code));
        if (newWeight) {
          dispatch(props.change("cost", (_toner.costpergram * newWeight).toFixed(2)));
        }
      }
    }
  }
};

const validate = (formValues, props) => {
  const errors = {};
  if (!formValues.toner) {
    //keys of object must match field names
    errors.toner = "Please select a toner";
  }
  if (!formValues.stage) {
    //keys of object must match field names
    errors.stage = "Please select a stage";
  }
  if (!formValues.weight) {
    //keys of object must match field names
    errors.weight = "Please enter a toner weight in grams";
  }
  if (formValues.toner && formValues.stage) {
    if (formValues.toner.value && formValues.stage.value) {
      if (
        _.find(props.selectedPaintColor.toners, (toner) => {
          return (
            toner.id.toLowerCase() + toner.stageId ===
            formValues.toner.value.toLowerCase() + formValues.stage.value
          );
        }) !== undefined
      ) {
        //keys of object must match field names
        errors.colorname =
          "Record with this color and stage combination already exists for this paint formula.";
      }
    }
  }
  return errors;
};

const formWrapped = reduxForm({
  form: "CreateColorTonerForm",
  enableReinitialize: true,
  validate: validate,
  onChange: handleOnFormChange,
})(CreateColorTonerForm);

export default connect(mapStateToProps, {
  resetSaveSuccess,
  getPaintColor,
})(formWrapped);
