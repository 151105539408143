import React from "react";
import Menu from "../components/Menu";
import {
  getModel,
  getManufacturer,
  getModels,
  getParts,
  selectModel,
  updateModelsState,
  updateAllModelsState,
  patchModelInDb,
  setModelSearchTerm,
} from "../actions";
import { connect } from "react-redux";
import CreatePanelForm from "../components/vehicles/CreatePanelForm";
import { createDeltaArrayByKey, replaceArrayObjectByKey } from "../utilities";
import { compileModelPanelObject } from "../apis/Models";

class createModelPanelView extends React.Component {

  componentDidMount() {
    this.props.getParts({});
    const { modelId } = this.props.match.params;
    if (modelId && !this.props.selectedModel.id) {
      this.props.getModel({ modelId }).then(() => {
        if (this.props.allModels.length === 0) {
          this.props.getModels({
            manufacturerid: this.props.selectedModel.manufacturerId,
          });
        }
        //set the searchterms to selected paintcolor manufacturer (for main form)
        this.props.setModelSearchTerm({
          value: this.props.selectedModel.manufacturerId,
          label: this.props.selectedModel.manufacturerName,
        });
      });
    }
  }
  
  onSubmit = (values) => {
    const {
      selectedModel,
      selectModel,
      updateModelsState,
      updateAllModelsState,
      patchModelInDb,
    } = this.props;
    let _model = { ...selectedModel };
    let _modelPanels = [...selectedModel.parts];
    let _allModelPanels = [...selectedModel.allParts];
    let newModelPanel = compileModelPanelObject(values);
    //add new model panel to top of array
    _modelPanels.splice(0, 0, newModelPanel);
    _allModelPanels.splice(0,0,newModelPanel);

    //*Need to save full set of panels.
    _model.parts = _allModelPanels;
    patchModelInDb(_model);
    delete _model.allParts;
    //update Model changes back to state
    selectModel(_model);
    updateModelsState(replaceArrayObjectByKey(this.props.models, "id", _model));
    updateAllModelsState(
      replaceArrayObjectByKey(this.props.allModels, "id", _model)
    );
  };

  

  removeAlreadySelectedParts = () => {
    const _parts = createDeltaArrayByKey(
      this.props.parts,
      "id",
      this.props.selectedParts
    );
    return _parts;
  };

  render() {
    return (
      <>
        <Menu
          contentView={
            <CreatePanelForm
              edit={false}
              options={this.removeAlreadySelectedParts()}
              supersedeOptions = {this.props.parts}
              onSubmit={this.onSubmit}
            />
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedModel: state.models.selectedModel,
    selectedManufacturer: state.manufacturers.selectedManufacturer,
    selectedParts: state.models.selectedModel.parts,
    parts: state.parts.parts,
    models: state.models.models,
    allModels: state.models.allModels,
    manufacturers: state.manufacturers.manufacturers,
    bodytypes: state.bodytypes.bodytypes,
  };
};

export default connect(mapStateToProps, {
  getModel,
  getModels,
  getParts,
  selectModel,
  updateModelsState,
  updateAllModelsState,
  patchModelInDb,
  getManufacturer,
  setModelSearchTerm,
})(createModelPanelView);
