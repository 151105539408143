import React from "react";
import Menu from "../components/Menu";
import CreateFuelPriceForm from "../components/repairs/CreateFuelPriceForm";
import { compileFuelPriceObject } from "../apis/FuelPrices";
import {
  getFuelPrice,
  getFuelPrices,
  selectFuelPrice,
  updateFuelPricesState,
  updateAllFuelPricesState,
  patchFuelPriceInDb,
} from "../actions";
import { replaceArrayObjectByKey } from "../utilities";
import { connect } from "react-redux";

class editFuelPriceView extends React.Component {
  onSubmit = (values, fuelPriceId) => {
    const {
      patchFuelPriceInDb,
      fuelPrices,
      allFuelPrices,
      updateFuelPricesState,
      updateAllFuelPricesState,
    } = this.props;
    const fuelPricesData = compileFuelPriceObject(values, fuelPriceId, true);
    patchFuelPriceInDb(fuelPricesData);
    //update fuel price changes back to state
    selectFuelPrice(fuelPricesData);
    updateFuelPricesState(
      replaceArrayObjectByKey(fuelPrices, "id", fuelPricesData)
    );
    updateAllFuelPricesState(
      replaceArrayObjectByKey(allFuelPrices, "id", fuelPricesData)
    );
  };

  componentDidMount() {
    const { fuelpriceId } = this.props.match.params;
    if (fuelpriceId) {
      this.props.getFuelPrice(fuelpriceId);
    }
    if (this.props.allFuelPrices.length === 0) {
      this.props.getFuelPrices({});
    }
  }

  render() {
    const { fuelpriceId } = this.props.match.params;
    return (
      <>
        <Menu
          contentView={
            <CreateFuelPriceForm
              edit={true}
              onSubmit={this.onSubmit}
              fuelPriceId={fuelpriceId}
            />
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fuelPrices: state.fuelprices.fuelPrices,
    allFuelPrices: state.fuelprices.allFuelPrices,
  };
};

export default connect(mapStateToProps, {
  getFuelPrice,
  getFuelPrices,
  selectFuelPrice,
  updateFuelPricesState,
  updateAllFuelPricesState,
  patchFuelPriceInDb,
})(editFuelPriceView);
