import React from "react";
import Menu from "../components/Menu";
import { compileDataForSave } from "../apis/Consumables";
import {
  getConsumables,
  updateConsumablesState,
  updateAllConsumablesState,
  selectConsumable,
  postConsumableToDb,
} from "../actions";
import { connect } from "react-redux";
import { updateStateAfterCreate } from "../utilities";
import CreateConsumableForm from "../components/repairs/CreateConsumableForm";

import { nanoid } from "nanoid";

class createConsumableView extends React.Component {
  componentDidMount() {
    if (this.props.allConsumables.length === 0) {
      this.props.getConsumables({});
    }
  }

  onSubmit = (values, consumable) => {
    const {
      consumables,
      allConsumables,
      postConsumableToDb,
      selectConsumable,
      updateConsumablesState,
      updateAllConsumablesState,
    } = this.props;
    const consumableData = compileDataForSave(values, consumable.id, true);

    postConsumableToDb(consumableData).then((result) => {
      consumableData.id = result.data.id;
      updateStateAfterCreate(
        selectConsumable,
        updateConsumablesState,
        updateAllConsumablesState,
        consumables,
        allConsumables,
        consumableData
      );
    });
  };

  render() {
    return (
      <>
        <Menu
          contentView={
            <CreateConsumableForm
              edit={false}
              onSubmit={this.onSubmit}
              consumableId={nanoid()}
            />
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    consumables: state.consumables.consumables,
    allConsumables: state.consumables.allConsumables,
  };
};

export default connect(mapStateToProps, {
  getConsumables,
  selectConsumable,
  updateConsumablesState,
  postConsumableToDb,
  updateAllConsumablesState,
})(createConsumableView);
