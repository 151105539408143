import React from "react";
import Menu from "../components/Menu";
import { compileEffectObject } from "../apis/Effects";
import {
  getEffects,
  getStages,
  updateEffectsState,
  updateAllEffectsState,
  selectEffect,
  postEffectToDb,
} from "../actions";
import { connect } from "react-redux";
import CreateEffectForm from "../components/paint/CreateEffectForm";
import { updateStateAfterCreate } from "../utilities";
import { nanoid } from "nanoid";

class createEffectView extends React.Component {
  componentDidMount() {
    if (this.props.allEffects.length === 0) {
      this.props.getEffects({});
    }
    this.props.getStages({}); //for select on form
  }

  onSubmit = (values, effectId) => {
    const {
      effects,
      allEffects,
      postEffectToDb,
      selectEffect,
      updateEffectsState,
      updateAllEffectsState,
    } = this.props;
    const effectData = compileEffectObject(values, effectId, true);
    postEffectToDb(effectData).then((result) => {
      effectData.id = result.data.id;
      updateStateAfterCreate(
        selectEffect,
        updateEffectsState,
        updateAllEffectsState,
        effects,
        allEffects,
        effectData
      );
    });
  };

  render() {
    return (
      <>
        <Menu
          contentView={
            <CreateEffectForm
              edit={false}
              onSubmit={this.onSubmit}
              effectId={nanoid()}
            />
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    effects: state.effects.effects,
    allEffects: state.effects.allEffects,
  };
};

export default connect(mapStateToProps, {
  getEffects,
  getStages,
  selectEffect,
  updateEffectsState,
  updateAllEffectsState,
  postEffectToDb,
})(createEffectView);
