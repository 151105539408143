import {
  GET_REFERENCEPANEL,
  GET_REFERENCEQUANTITIES,
  UPDATE_ALL_REFERENCEQUANTITIES,
  SELECT_REFERENCEQUANTITY,
  CLEAR_REFERENCEQUANTITY,
  ADD_REFERENCEQUANTITY_TO_BATCH,
  ADD_ALL_REFERENCEQUANTITIES_TO_BATCH,
  REMOVE_REFERENCEQUANTITY_FROM_BATCH,
  CLEAR_REFERENCEQUANTITY_BATCH,
  ARCHIVE_REFERENCEQUANTITY,
  SET_REFERENCEQUANTITY_SEARCH_TERM,
  SET_REFERENCEQUANTITY_FILTER,
  SET_REFERENCEQUANTITY_FILTER_LABEL,
  CLONE_REFERENCEQUANTITIES,
} from "../actions/types";
import _ from "lodash";

const INITIAL_STATE = {
  referencePanel: {},
  referencePanelQuantities: [],
  allReferenceQuantities: [],
  selectedReferenceQuantity: {},
  referenceQuantityBatch: [],
  referenceQuantitySearchTerms: { term: { label: "", value: "" }, filter: "" }
};

const sortReferencePanelQuantities = (quantities) => {
  quantities.sort((a, b) => (a.stageName > b.stageName ? 1 : -1));
  return quantities;
};

const referencePanelReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_REFERENCEPANEL:
      return {
        ...state,
        referencePanel: action.payload
      };
    case GET_REFERENCEQUANTITIES:
      return {
        ...state,
        referencePanelQuantities: sortReferencePanelQuantities(action.payload)
      };
    case UPDATE_ALL_REFERENCEQUANTITIES:
      return {
        ...state,
        allReferenceQuantities: action.payload
      };
    case SELECT_REFERENCEQUANTITY:
      return {
        ...state,
        selectedReferenceQuantity: action.payload
      };
    case CLEAR_REFERENCEQUANTITY:
      return { ...state, selectedReferenceQuantity: {} };

    case ADD_REFERENCEQUANTITY_TO_BATCH:
      return {
        ...state,
        referenceQuantityBatch: [...state.referenceQuantityBatch, action.payload]
      };
    case ADD_ALL_REFERENCEQUANTITIES_TO_BATCH:
      return {
        ...state,
        referenceQuantityBatch: action.payload
      };

    case REMOVE_REFERENCEQUANTITY_FROM_BATCH:
      return {
        ...state,
        //removes 2nd argument from array and returns new array
        referenceQuantityBatch: _.remove(
          state.referenceQuantityBatch,
          (referenceQuantity) => referenceQuantity !== action.payload
        )
      };

    case CLEAR_REFERENCEQUANTITY_BATCH:
      return {
        ...state,
        referenceQuantityBatch: []
      };
    case ARCHIVE_REFERENCEQUANTITY:
      return _.omit(state, action.payload);
    case SET_REFERENCEQUANTITY_SEARCH_TERM:
      return {
        ...state,
        referenceQuantitySearchTerms: {
          ...state.referenceQuantitySearchTerms,
          term: action.payload
        }
      };
    case SET_REFERENCEQUANTITY_FILTER:
      return {
        ...state,
        referenceQuantitySearchTerms: {
          ...state.referenceQuantitySearchTerms,
          filter: {
            ...state.referenceQuantitySearchTerms.filter,
            value: action.payload
          }
        }
      };
    case SET_REFERENCEQUANTITY_FILTER_LABEL:
      return {
        ...state,
        referenceQuantitySearchTerms: {
          ...state.referenceQuantitySearchTerms,
          filter: {
            ...state.referenceQuantitySearchTerms.filter,
            label: action.payload
          }
        }
      };
    case CLONE_REFERENCEQUANTITIES:
      return {
        ...state,
        allReferenceQuantities: action.payload
      };
    default:
      return state;
  }
};

export default referencePanelReducer;
