import Axios from "axios";
import { env } from "./env";

export const Effects = ({ id, name, effectIds }) => {
  const _env = env();
  const baseURL = _env.BASEURL;
  const key = _env.API_KEY;

  if (id) {
    return Axios.create({
      baseURL: `${baseURL}/paint/effects/${id}`,
      headers: { "x-api-key": key },
    });
  } else if (name) {
    return Axios.create({
      baseURL: `${baseURL}/paint/effects?name=${name}`,
      headers: { "x-api-key": key },
    });
  } else if (effectIds) {
    return Axios.create({
      baseURL: `${baseURL}/paint/effects?ids=${effectIds}`,
      headers: { "x-api-key": key },
    });
  }

  return Axios.create({
    baseURL: `${baseURL}/paint/effects`,
    headers: { "x-api-key": key },
  });
};

export const compileEffectObject = ({ name, stages }, id, state) => {
  const effect = {};
  effect.stages = [];
  effect.id = id;
  effect.name = name;
  effect.l_name = name.toLowerCase();
  effect.active = state;
  if (stages) {
    //Only add if specified
    effect.stages = stages.map((stage) => {
      return stage.value ? stage.value : stage;
    });
    effect.stagesDisplay = stages.map((stage) => {
      return { id: stage.value, name: stage.label };
    });
  }
  return effect;
};
