import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Button } from "semantic-ui-react";
import Select from "react-select";
import { selectOptionsFromData } from "../../utilities";
import { currentYearOption, yearOptions, sizeOptions } from "../../defaults";
import "../components.css";
import { Link } from "react-router-dom";
import _ from "lodash";
import { toastr } from "react-redux-toastr";

import {
  getManufacturer,
  getBodyTypes,
  getBodySubTypes,
  getModel,
  resetSaveSuccess,
  getLocations,
} from "../../actions";

class CreateModelForm extends React.Component {
  renderError({ submitFailed, error }) {
    if (submitFailed && error) {
      return (
        <div className="ui error message">
          <div className="header">{error}</div>
        </div>
      );
    }
  }

  renderResult = () => {
    if (this.props.busySaving) {
      return (
        <div
          className="ui success message"
          style={{ marginTop: "1em" }}
        >
          Saving..
        </div>
      );
    } else if (this.props.saveError) {
      return (
        <div
          className="ui error message"
          style={{ marginTop: "1em" }}
        >
          {this.props.saveError}
        </div>
      );
    } else if (this.props.successMessage) {
      return (
        <div
          className="ui success message"
          style={{ marginTop: "1em" }}
        >
          {this.props.successMessage}
        </div>
      );
    }
  };

  renderInput = ({ input, id, style, meta, placeholder, disabled, label, autoFocus }) => {
    const className = `field ${meta.error || meta.submitFailed ? "error" : ""}`;
    return (
      <div className={className}>
        <div className="formlabel">{label}</div>
        <input
          {...input}
          id={id}
          style={style}
          placeholder={placeholder}
          disabled={disabled}
          autoFocus={autoFocus}
          ref={
            autoFocus
              ? (input) => {
                  this.focusInput = input;
                }
              : input
          }
        ></input>
        {this.renderError(meta, id)}
      </div>
    );
  };

  renderSelect = ({ input, id, options, meta, style, search, placeholder, label }) => {
    const className = `field ${meta.error && meta.submitFailed ? "error" : ""}`;
    return (
      <div
        className={className}
        style={{ marginBottom: "1em" }}
      >
        <div className="formlabel">{label}</div>
        <Select
          {...input}
          fluid
          selection
          isSearchable={search}
          style={style}
          options={options}
          onChange={(value) => input.onChange(value)}
          onBlur={() => input.onBlur()}
          placeholder={placeholder}
        ></Select>
        {this.renderError(meta, id)}
      </div>
    );
  };

  onSubmit = (values) => {
    try {
      this.props.onSubmit(values, this.props.modelId);
      if (!this.props.edit) {
        this.props.reset();
        this.focusInput.focus();
      }
      toastr.success("Success", "Model record saved.", {
        icon: "success",
      });
    } catch (error) {
      toastr.error("Error", error);
    }
  };

  render() {
    return (
      <div>
        <form
          className="ui form error"
          onSubmit={this.props.handleSubmit(this.onSubmit)}
        >
          <Field
            name="manufacturerName"
            id="manufacturerName"
            label="Manufacturer"
            component={this.renderInput}
            disabled
          ></Field>
          <Field
            name="manufacturerId"
            id="manufacturerId"
            component={this.renderInput}
            style={{ display: "none" }}
          ></Field>
          <Field
            name="name"
            id="name"
            label="Model name"
            component={this.renderInput}
            placeholder="Enter model name"
            autoFocus={true}
          ></Field>
          <Field
            name="year"
            id="year"
            label="Year introduced"
            component={this.renderSelect}
            options={yearOptions}
            placeholder="Select year introduced"
            search
          />
          <Field
            name="yearto"
            id="yearto"
            label="Year discontinued"
            component={this.renderSelect}
            options={currentYearOption.concat(yearOptions)}
            placeholder="Select year discontinued"
            search
          />
          <Field
            name="bodytype"
            id="bodytype"
            label="Vehicle type"
            component={this.renderSelect}
            options={selectOptionsFromData(this.props.bodytypes)}
            placeholder="Select vehicle type"
            search
          />
          <Field
            name="subtype"
            id="subtype"
            label="Body Type"
            component={this.renderSelect}
            options={selectOptionsFromData(this.props.bodysubtypes)}
            placeholder="Select body type"
            search
          />
          <Field
            name="size"
            id="size"
            label="Model size"
            component={this.renderSelect}
            options={sizeOptions}
            placeholder="Select size"
            search={false}
          />
          <Field
            name="paintcodelocation"
            id="paintcodelocation"
            label="Paint Code Location"
            component={this.renderSelect}
            options={selectOptionsFromData(this.props.paintcodelocations)}
            placeholder="Select paint code location"
          />
          <div className="spacer-very-wide"></div>

          <div style={{ textAlign: "right" }}>
            <Button.Group>
              <Link to="/models">
                <Button
                  color="black"
                  type="button"
                >
                  Cancel
                </Button>
              </Link>
              <Button.Or />
              <Button primary>Save</Button>
            </Button.Group>
          </div>
        </form>
        <div className="content">{this.renderResult()}</div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  //if editing set the default values

  let modelData = {};

  if (props.edit) {
    modelData = {
      selectedModel: state.models.selectedModel,
      initialValues: {
        manufacturerId: state.models.selectedModel.manufacturerId,
        manufacturerName: state.models.selectedModel.manufacturerName,
        name: state.models.selectedModel.name,
        year: {
          value: state.models.selectedModel.year,
          label: state.models.selectedModel.year,
        },
        yearto: {
          value: state.models.selectedModel.yearto,
          label: state.models.selectedModel.yearto,
        },
        bodytype: {
          value: state.models.selectedModel.body.typeId,
          label: state.models.selectedModel.body.typeName,
        },
        subtype: {
          value: state.models.selectedModel.body.subtypeId,
          label: state.models.selectedModel.body.subtypeName,
        },
        size: {
          value: state.models.selectedModel.body.size,
          label: state.models.selectedModel.body.size,
        },
        paintcodelocation: {
          value: state.models.selectedModel.paintcodelocationId,
          label: state.models.selectedModel.paintcodelocationName,
        },
      },
    };
  } else {
    modelData = {
      models: state.models.models,
      initialValues: {
        manufacturerId: state.manufacturers.selectedManufacturer.id,
        manufacturerName: state.manufacturers.selectedManufacturer.name,
      },
    };
  }

  return {
    ...modelData,
    bodytypes: state.bodytypes.bodytypes,
    bodysubtypes: state.bodytypes.bodysubtypes,
    paintcodelocations: state.locations.allLocations,
    //api operations
    successMessage: state.api.saveSuccess,
    saveError: state.api.saveError,
    busySaving: state.api.busySaving,
  };
};

const validate = (formValues, props) => {
  const errors = {};
  // if (!formValues.manufacturer) {
  //   //keys of object must match field names
  //   errors.manufacturer = "Please select a manufacturer";
  // }

  if (!formValues.name) {
    errors.name = "Please enter a name";
  }

  if (formValues.name && formValues.year) {
    if (
      _.find(props.models, (model) => {
        return (
          model.name.toLowerCase() + model.year ===
          formValues.name.toLowerCase() + formValues.year.value
        );
      }) !== undefined
    ) {
      //keys of object must match field names
      errors.name = "Model with same name and year of introduction already exists";
    }
  }

  if (formValues.year && formValues.yearto) {
    if (formValues.year.value && formValues.yearto.value) {
      if (formValues.year.value > formValues.yearto.value) {
        //keys of object must match field names
        errors.yearto = "Year discontinued cannot be before year introduced ";
      }
    }
  }

  if (!formValues.year) {
    //keys of object must match field names
    errors.year = "Please select year introduced";
  }
  if (!formValues.yearto) {
    //keys of object must match field names
    errors.year = "Please select year discontinued";
  }
  if (!formValues.bodytype) {
    //keys of object must match field names
    errors.bodytype = "Please select vehicle type";
  }
  if (!formValues.subtype) {
    //keys of object must match field names
    errors.subtype = "Please select body type";
  }
  if (!formValues.size) {
    //keys of object must match field names
    errors.size = "Please select vehicle size";
  }
  return errors;
};

const formWrapped = reduxForm({
  form: "CreateModelForm",
  enableReinitialize: true,
  validate: validate,
})(CreateModelForm);

export default connect(mapStateToProps, {
  getManufacturer,
  getBodySubTypes,
  getBodyTypes,
  getModel,
  resetSaveSuccess,
  getLocations,
})(formWrapped);
