import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Button, Input } from "semantic-ui-react";
import Select from "react-select";
import { selectOptionsFromData } from "../../utilities";
import "../components.css";
import { Link } from "react-router-dom";
import _ from "lodash";

import { getSORs, resetSaveSuccess, updateSelectedSORState, updateSORsState } from "../../actions";

class CreateSORForm extends React.Component {
  renderError({ submitFailed, error }) {
    if (submitFailed && error) {
      return (
        <div className="ui error message">
          <div className="header">{error}</div>
        </div>
      );
    }
  }

  renderResult = () => {
    if (this.props.busySaving) {
      return (
        <div
          className="ui success message"
          style={{ marginTop: "1em" }}
        >
          Saving..
        </div>
      );
    } else if (this.props.saveError) {
      return (
        <div
          className="ui error message"
          style={{ marginTop: "1em" }}
        >
          {this.props.saveError}
        </div>
      );
    } else if (this.props.successMessage) {
      return (
        <div
          className="ui success message"
          style={{ marginTop: "1em" }}
        >
          {this.props.successMessage}
        </div>
      );
    }
  };

  renderInput = ({ input, id, style, meta, placeholder, disabled, label, autoFocus }) => {
    const className = `field ${meta.error && meta.submitFailed ? "error" : ""}`;
    return (
      <div className={className}>
        <div className="formlabel">{label}</div>
        <Input
          {...input}
          id={id}
          style={style}
          placeholder={placeholder}
          disabled={disabled}
          autoFocus={autoFocus}
          ref={
            autoFocus
              ? (input) => {
                  this.focusInput = input;
                }
              : input
          }
        ></Input>
        {this.renderError(meta, id)}
      </div>
    );
  };

  renderSelect = ({ input, id, options, meta, style, search, placeholder, isMulti, label }) => {
    const className = `field ${meta.error && meta.submitFailed ? "error" : ""}`;
    return (
      <div
        className={className}
        style={{ marginBottom: "1em" }}
      >
        <div className="formlabel">{label}</div>

        <Select
          {...input}
          fluid
          isMulti={isMulti}
          selection
          isSearchable={search}
          style={style}
          options={options}
          onChange={(value) => input.onChange(value)}
          onBlur={() => input.onBlur()}
          placeholder={placeholder}
        ></Select>
        {this.renderError(meta, id)}
      </div>
    );
  };

  onSubmit = (values) => {
    this.props.onSubmit(values, this.props.sorId);
    if (!this.props.edit) {
      this.props.reset();
      this.focusInput.focus();
    }
    setTimeout(() => {
      this.props.resetSaveSuccess();
    }, 2500);
  };

  render() {
    return (
      <div>
        <div className="content margin-bottom-small">{this.renderResult()}</div>
        <form
          className="ui form error"
          onSubmit={this.props.handleSubmit(this.onSubmit)}
        >
          <Field
            name="name"
            id="name"
            label="SOR name"
            component={this.renderInput}
            placeholder="Enter scope of repair name"
            autoFocus={true}
          ></Field>
          <Field
            name="insidepaintqtyfactor"
            id="insidepaintqtyfactor"
            label="Inside Paint Quantity Factor"
            component={this.renderInput}
            placeholder="Enter the inside paint quantity factor"
          ></Field>
          <Field
            name="stages"
            id="stages"
            isMulti
            label="Stages"
            component={this.renderSelect}
            options={selectOptionsFromData(this.props.stages)}
            placeholder="Stages Applicable to SOR"
            search
          />
          <div className="spacer-very-wide"></div>
          <div style={{ textAlign: "right" }}>
            <Button.Group>
              <Link to="/sors">
                <Button
                  color="black"
                  type="button"
                >
                  Cancel
                </Button>
              </Link>
              <Button.Or />
              <Button primary>Save</Button>
            </Button.Group>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  //if editing set the default values

  let sorData = {};

  if (props.edit) {
    sorData = {
      selectedSOR: state.SORs.selectedSOR,
      initialValues: {
        name: state.SORs.selectedSOR.name,
        insidepaintqtyfactor: state.SORs.selectedSOR.insidepaintqtyfactor,
        stages: selectOptionsFromData(state.SORs.selectedSOR.stages),
      },
    };
  } else {
    sorData = {
      allSORs: state.SORs.allSORs,
      initialValues: {
        insidepaintqtyfactor: 0,
      },
    };
  }

  return {
    ...sorData,
    stages: state.stages.stages,
    //api operations
    successMessage: state.api.saveSuccess,
    saveError: state.api.saveError,
    busySaving: state.api.busySaving,
  };
};

const validate = (formValues, props) => {
  const errors = {};
  if (!formValues.stages) {
    //keys of object must match field names
    errors.stages = "Please select at least one stage for the Scope of Repair.";
  }

  if (!formValues.name) {
    //keys of object must match field names
    errors.name = "Please enter a Scope name.";
  }

  if (formValues.name) {
    if (
      _.find(props.allSORs, (sor) => {
        return sor.name.toLowerCase() === formValues.name.toLowerCase();
      }) !== undefined
    ) {
      //keys of object must match field names
      errors.name = "SOR with same name already exists";
    }
  }
  return errors;
};

const formWrapped = reduxForm({
  form: "CreateSORForm",
  enableReinitialize: true,
  validate: validate,
})(CreateSORForm);

export default connect(mapStateToProps, {
  getSORs,
  resetSaveSuccess,
  updateSelectedSORState,
  updateSORsState,
})(formWrapped);
