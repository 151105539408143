import React from "react";
import Menu from "../components/Menu";
import CreateManufacturerForm from "../components/vehicles/CreateManufacturerForm";
import { compileManufacturerObject } from "../apis/Manufacturers";
import {
  getManufacturer,
  getManufacturers,
  selectManufacturer,
  updateManufacturersState,
  updateAllManufacturersState,
  patchManufacturerInDb
} from "../actions";
import { updateStateAfterEdit } from "../utilities";
import { connect } from "react-redux";
import { Fragment } from "react";

class editManufacturerView extends React.Component {
  onSubmit = (values, manufacturerId) => {
    const {
      patchManufacturerInDb,
      selectManufacturer,
      manufacturers,
      allManufacturers,
      updateManufacturersState,
      updateAllManufacturersState
    } = this.props;
    const manufacturersData = compileManufacturerObject(
      values,
      manufacturerId,
      true
    );
    patchManufacturerInDb(manufacturersData).then(() => {
      //update PaintColor changes back to state
      updateStateAfterEdit(
        selectManufacturer,
        updateManufacturersState,
        updateAllManufacturersState,
        manufacturers,
        allManufacturers,
        manufacturersData,
        "id"
      );
    });
  };

  componentDidMount() {
    const { manufacturerId } = this.props.match.params;
    if (manufacturerId) {
      this.props.getManufacturer(manufacturerId);
    }
    if (this.props.allManufacturers.length === 0) {
      this.props.getManufacturers({});
    }
  }

  render() {
    const { manufacturerId } = this.props.match.params;
    return (
      <Fragment>
        <Menu
          contentView={
            <CreateManufacturerForm
              edit={true}
              onSubmit={this.onSubmit}
              manufacturerId={manufacturerId}
            />
          }
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    manufacturers: state.manufacturers.manufacturers,
    allManufacturers: state.manufacturers.allManufacturers
  };
};

export default connect(mapStateToProps, {
  getManufacturer,
  getManufacturers,
  selectManufacturer,
  updateManufacturersState,
  updateAllManufacturersState,
  patchManufacturerInDb
})(editManufacturerView);
