import {
  GET_STAGES,
  SELECT_STAGE,
  CLEAR_STAGE,
  GET_STAGE,
  SET_STAGE_FILTER,
  SET_STAGE_FILTER_LABEL,
  CLEAR_STAGES,
  CLEAR_STAGE_BATCH,
  REMOVE_STAGE_FROM_BATCH,
  ADD_STAGE_TO_BATCH,
  UPDATE_STAGES,
  DELETE_STAGE,
  ADD_ALL_STAGES_TO_BATCH,
} from "../actions/types";
import _ from "lodash";
import { removeObjectFromArray } from "../utilities";

const INITIAL_STATE = {
  stages: [],
  stage: {},
  selectedStage: {},
  stageSearchTerms: {
    filter: { value: "", label: "" },
    term: {
      value: "",
      label: "",
    },
  },
  stageBatch: [],
};

const stagesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_STAGES:
      return {
        ...state,
        stages: action.payload,
      };
    case SET_STAGE_FILTER:
      return {
        ...state,
        stageSearchTerms: {
          ...state.stageSearchTerms,
          filter: {
            ...state.stageSearchTerms.filter,
            value: action.payload,
          },
        },
      };
    case SET_STAGE_FILTER_LABEL:
      return {
        ...state,
        stageSearchTerms: {
          ...state.stageSearchTerms,
          filter: {
            ...state.stageSearchTerms.filter,
            label: action.payload,
          },
        },
      };
    case SELECT_STAGE:
      return {
        ...state,
        selectedStage: action.payload,
      };
    case GET_STAGE:
      return {
        ...state,
        stage: action.payload,
      };
    case CLEAR_STAGE:
      return { ...state, selectedStage: {} };
    case CLEAR_STAGES:
      return { ...state, stages: [] };
    case CLEAR_STAGE_BATCH:
      return {
        ...state,
        stageBatch: [],
      };
    case REMOVE_STAGE_FROM_BATCH:
      return {
        ...state,
        //removes 2nd argument from array and returns new array
        stageBatch: _.remove(
          state.stageBatch,
          (stage) => stage !== action.payload
        ),
      };
    case ADD_STAGE_TO_BATCH:
      return {
        ...state,
        stageBatch: [...state.stageBatch, action.payload],
      };
    case ADD_ALL_STAGES_TO_BATCH:
      return {
        ...state,
        stageBatch: action.payload,
      };
    case UPDATE_STAGES:
      return {
        ...state,
        stages: action.payload,
      };
    case DELETE_STAGE:
      return {
        ...state,
        stages: removeObjectFromArray(...state.stages, action.payload),
      };
    default:
      return state;
  }
};

export default stagesReducer;
