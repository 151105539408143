import {
  GET_COLORGROUPS,
  SELECT_COLORGROUP,
  CLEAR_COLORGROUP,
  GET_COLORGROUP,
  SET_COLORGROUP_FILTER,
  SET_COLORGROUP_FILTER_LABEL,
  CLEAR_COLORGROUPS,
  CLEAR_COLORGROUP_BATCH,
  REMOVE_COLORGROUP_FROM_BATCH,
  ADD_COLORGROUP_TO_BATCH,
  UPDATE_COLORGROUPS,
  DELETE_COLORGROUP,
  ADD_ALL_COLORGROUPS_TO_BATCH,
  CLONE_COLORGROUPS,
} from "../actions/types";
import _ from "lodash";
import { removeObjectFromArray } from "../utilities";

const INITIAL_STATE = {
  colorGroups: [],
  allColorGroups: [],
  colorGroup: {},
  selectedColorGroup: {},
  colorGroupSearchTerms: {
    filter: { value: "", label: "" },
    term: {
      value: "",
      label: "",
    },
  },
  colorGroupBatch: [],
};

const colorGroupsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_COLORGROUPS:
      return {
        ...state,
        colorGroups: action.payload,
      };
    case SET_COLORGROUP_FILTER:
      return {
        ...state,
        colorGroupSearchTerms: {
          ...state.colorGroupSearchTerms,
          filter: {
            ...state.colorGroupSearchTerms.filter,
            value: action.payload,
          },
        },
      };
    case SET_COLORGROUP_FILTER_LABEL:
      return {
        ...state,
        colorGroupSearchTerms: {
          ...state.colorGroupSearchTerms,
          filter: {
            ...state.colorGroupSearchTerms.filter,
            label: action.payload,
          },
        },
      };
    case SELECT_COLORGROUP:
      return {
        ...state,
        selectedColorGroup: action.payload,
      };
    case GET_COLORGROUP:
      return {
        ...state,
        colorGroup: action.payload,
      };
    case CLEAR_COLORGROUP:
      return { ...state, selectedColorGroup: {} };
    case CLEAR_COLORGROUPS:
      return { ...state, colorGroups: [] };
    case CLEAR_COLORGROUP_BATCH:
      return {
        ...state,
        colorGroupBatch: [],
      };
    case REMOVE_COLORGROUP_FROM_BATCH:
      return {
        ...state,
        //removes 2nd argument from array and returns new array
        colorGroupBatch: _.remove(
          state.colorGroupBatch,
          (colorgroup) => colorgroup !== action.payload
        ),
      };
    case ADD_COLORGROUP_TO_BATCH:
      return {
        ...state,
        colorGroupBatch: [...state.colorGroupBatch, action.payload],
      };
    case ADD_ALL_COLORGROUPS_TO_BATCH:
      return {
        ...state,
        colorGroupBatch: action.payload,
      };
    case UPDATE_COLORGROUPS:
      return {
        ...state,
        colorGroups: action.payload,
      };
    case DELETE_COLORGROUP:
      return {
        ...state,
        colorGroups: removeObjectFromArray(
          ...state.colorGroups,
          action.payload
        ),
      };
    case CLONE_COLORGROUPS:
      return {
        ...state,
        allColorGroups: action.payload,
      };
    default:
      return state;
  }
};

export default colorGroupsReducer;
