import React from "react";
import Menu from "../components/Menu";
import {
  updateReferencePanelState,
  patchReferencePanelInDb,
  getReferencePanel,
  getManufacturers
} from "../actions";
import { connect } from "react-redux";
import ReferencePanel from "../components/repairs/ReferencePanel";

class referencePanelView extends React.Component {
  onPatchPanel = () => {
    const { referencePanel, patchReferencePanelInDb } = this.props;
    patchReferencePanelInDb(referencePanel);

  };

  componentDidMount() {
    this.props.getReferencePanel();
    this.props.getManufacturers({});
  }

  render() {
    return (
      <>
        <Menu contentView={<ReferencePanel onSave={this.onPatchPanel} />} />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    referencePanel: state.referencePanel.referencePanel
  };
};

export default connect(mapStateToProps, {
  patchReferencePanelInDb,
  updateReferencePanelState,
  getManufacturers,
  getReferencePanel
})(referencePanelView);
