import React from "react";
import Menu from "../components/Menu";
import { compileSetupCostObject } from "../apis/SetupCosts";
import {
  getSetupCosts,
  updateSetupCostsState,
  updateAllSetupCostsState,
  selectSetupCost,
  postSetupCostToDb,
} from "../actions";
import { connect } from "react-redux";
import CreateSetupCostForm from "../components/repairs/CreateSetupCostForm";
import { nanoid } from "nanoid";
import { updateStateAfterCreate } from "../utilities";

class createSetupCostView extends React.Component {
  componentDidMount() {
    if (this.props.allsetupCosts.length === 0) {
      this.props.getSetupCosts({});
    }
  }

  onSubmit = (values, setupCostId) => {
    const {
      setupCosts,
      allsetupCosts,
      postSetupCostToDb,
      selectSetupCost,
      updateSetupCostsState,
      updateAllSetupCostsState,
    } = this.props;
    const setupCostData = compileSetupCostObject(values, setupCostId, true);
    postSetupCostToDb(setupCostData).then((result) => {
      setupCostData.id = result.data.id;
      updateStateAfterCreate(
        selectSetupCost,
        updateSetupCostsState,
        updateAllSetupCostsState,
        setupCosts,
        allsetupCosts,
        setupCostData
      );
    });
  };

  render() {
    return (
      <>
        <Menu
          contentView={
            <CreateSetupCostForm
              edit={false}
              onSubmit={this.onSubmit}
              setupCostId={nanoid()}
            />
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    setupCosts: state.setupcosts.setupCosts,
    allsetupCosts: state.setupcosts.allsetupCosts,
  };
};

export default connect(mapStateToProps, {
  getSetupCosts,
  selectSetupCost,
  updateSetupCostsState,
  updateAllSetupCostsState,
  postSetupCostToDb,
})(createSetupCostView);
