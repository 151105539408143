import Axios from "axios";
import { env } from "./env";

export const SetupCosts = ({ id, name, setupCostIds }) => {
  const _env = env();
  const baseURL = _env.BASEURL;
  const key = _env.API_KEY;

  if (id) {
    return Axios.create({
      baseURL: `${baseURL}/repairs/setupcosts/${id}`,
      headers: { "x-api-key": key },
    });
  } else if (name) {
    return Axios.create({
      baseURL: `${baseURL}/repairs/setupcosts?name=${name}`,
      headers: { "x-api-key": key },
    });
  } else if (setupCostIds) {
    return Axios.create({
      baseURL: `${baseURL}/repairs/setupcosts?ids=${setupCostIds}`,
      headers: { "x-api-key": key },
    });
  }

  return Axios.create({
    baseURL: `${baseURL}/repairs/setupcosts`,
    headers: { "x-api-key": key },
  });
};

export const compileSetupCostObject = ({ name, labour }, id, state) => {
  const setupCost = {};
  setupCost.id = id;
  setupCost.name = name;
  setupCost.labour = parseInt(labour);
  setupCost.l_name = name.toLowerCase();
  setupCost.active = state;
  return setupCost;
};
