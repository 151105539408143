import React from "react";
import { Fragment } from "react";
import Menu from "../components/Menu";
import {
  getPaintColor,
  getPaintColors,
  setPaintColorSearchTerm,  
  getToners,
  selectPaintColor,
  updatePaintColorsState,
  updateAllPaintColorsState,
  patchPaintColorInDb,
  getEffect,
  updateTonersState
} from "../actions";
import { connect } from "react-redux";
import CreateColorTonerForm from "../components/paint/CreateColorTonerForm";
import {
  createDeltaArrayByKey,
  replaceArrayObjectByKey,
} from "../utilities";
import { compileColorTonerObject } from "../apis/PaintColors";

class createColorTonerView extends React.Component {
  onSubmit = values => {
    const {
      selectedPaintColor,
      selectPaintColor,
      updatePaintColorsState,
      updateAllPaintColorsState,
      patchPaintColorInDb,
      paintColors,
      allPaintColors,
    } = this.props;
    let _paintColor = { ...selectedPaintColor };
    let _colorToners = [...selectedPaintColor.toners];
    let _allColorToners = [...selectedPaintColor.allToners];
    let newColorToner = compileColorTonerObject(values);
    //add new color toner to top of array
    _colorToners.splice(0, 0, newColorToner);
    _allColorToners.splice(0, 0, newColorToner);
    
    //*Need to save full set of toners.
    _paintColor.toners = _allColorToners;
    patchPaintColorInDb(_paintColor);

    //calculate the toner cost based on CPG and weight
    //get CPG on main toner record
    const _toner = this.props.toners.find(toner => {
      return (toner.id === values.toner.value);
    });
    const _tonerCost = parseFloat(
      (parseFloat(_toner.cost) /
        parseFloat(_toner.nettweight) *
        parseFloat(values.weight)).toFixed(2)
    );
    _colorToners[0].cost = _tonerCost;
    _allColorToners[0].cost = _tonerCost;
    _paintColor.toners = _colorToners;
    _paintColor.allToners = _allColorToners;

    //recalculate the paint total weight and cost
    let colorTotalTonerWeight = 0.0;
    let colorTotalTonerCost = 0.0;
    _allColorToners.forEach(toner => {
      colorTotalTonerWeight += toner.weight;
      colorTotalTonerCost += toner.cost;
    });
    _paintColor.cost = colorTotalTonerCost.toFixed(2);
    _paintColor.weight = colorTotalTonerWeight.toFixed(2);
 
    selectPaintColor(_paintColor);

    //set color toners back to full set to update color records state.
    _paintColor.toners = _allColorToners;
    delete _paintColor.allToners;

    updatePaintColorsState(replaceArrayObjectByKey(paintColors, "id", _paintColor));
    updateAllPaintColorsState(
      replaceArrayObjectByKey(allPaintColors, "id", _paintColor)
    );
  };

  componentDidMount() {
    const { paintcolorId } = this.props.match.params;
    const {
      setPaintColorSearchTerm,
      allPaintColors,
      getPaintColors,
      getPaintColor,
      getEffect,
      getToners
    } = this.props;
    if (paintcolorId && !this.props.selectedPaintColor.id) {
      getPaintColor({ colorId: paintcolorId }).then(() => {
        //reload paint colors on refresh
        if (allPaintColors.length === 0) {
          getPaintColors({
            manufacturerid: this.props.selectedPaintColor.manufacturerId
          });
          //set the searchterms to selected paintcolor manufacturer (for main form)
          setPaintColorSearchTerm({
            value: this.props.selectedPaintColor.manufacturerId,
            label: this.props.selectedPaintColor.manufacturerName
          });

          getToners({
            supplierId: this.props.selectedPaintColor.supplierId,
            current: 1
          });
          //for stage dropdown on color toner form. We only want the stages linked to the effect
          //to be selectable
          getEffect({
            effectId: this.props.selectedPaintColor.effectId
          });
        }
      });
    } else {
      getToners({
        supplierId: this.props.selectedPaintColor.supplierId,
        current: 1
      });
      //for stage dropdown on color toner form. We only want the stages linked to the effect
      //to be selectable
      getEffect({
        effectId: this.props.selectedPaintColor.effectId
      });
    }
  }

  removeAlreadySelectedToners = stageId => {
    const _toners = createDeltaArrayByKey(
      this.props.toners,
      "id",
      this.props.selectedToners.filter(toner => {
        return toner.stageId === stageId;
      })
    );
    return _toners;
  };

  render() {
    return (
      <Fragment>
        <Menu
          contentView={
            <CreateColorTonerForm
              edit={false}
              onStageSelect={this.removeAlreadySelectedToners}
              toners={this.props.toners}
              onSubmit={this.onSubmit}
            />
          }
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    selectedPaintColor: state.paintcolors.selectedPaintColor,
    selectedToners: state.paintcolors.selectedPaintColor.toners,
    toners: state.toners.toners,
    paintColors: state.paintcolors.paintColors,
    allPaintColors: state.paintcolors.allPaintColors,
    manufacturers: state.manufacturers.manufacturers
  };
};

export default connect(mapStateToProps, {
  getPaintColor,
  getPaintColors,
  getToners,
  getEffect,
  selectPaintColor,
  updatePaintColorsState,
  updateAllPaintColorsState,
  patchPaintColorInDb,
  setPaintColorSearchTerm,
  updateTonersState
})(createColorTonerView);
