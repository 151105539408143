import Axios from "axios";
import { env } from "./env";

export const TonerContainerMetrics = ({ weight }) => {
  const _env = env();
  const baseURL = _env.BASEURL;
  const key = _env.API_KEY;

  if (weight) {
    return Axios.create({
      baseURL: `${baseURL}/paint/tonercontainermetrics?weight=${weight}`,
      headers: { "x-api-key": key },
    });
  } else {
    return Axios.create({
      baseURL: `${baseURL}/paint/tonercontainermetrics`,
      headers: { "x-api-key": key },
    });
  }
};
