import {
  GET_EFFECTS,
  CLEAR_EFFECT,
  SET_EFFECT_FILTER,
  SET_EFFECT_FILTER_LABEL,
  SELECT_EFFECT,
  CLEAR_EFFECT_BATCH,
  REMOVE_EFFECT_FROM_BATCH,
  ADD_EFFECT_TO_BATCH,
  ADD_ALL_EFFECTS_TO_BATCH,
  UPDATE_EFFECTS,
  UPDATE_ALL_EFFECTS,
  CLEAR_EFFECTS,
  DELETE_EFFECT,
  CLONE_EFFECTS,
} from "../actions/types";
import _ from "lodash";
import { removeObjectFromArray } from "../utilities";

const INITIAL_STATE = {
  effects: [],
  allEffects: [],
  effectSearchTerms: {
    filter: { value: "", label: "" },
    term: {
      value: "",
      label: "",
    },
  },
  selectedEffect: {
    complexity: { labour: null, material: null },
    positions: [],
  },
  effectBatch: [],
};

const effectsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_EFFECTS:
      return {
        ...state,
        effects: action.payload,
      };
    case SET_EFFECT_FILTER:
      return {
        ...state,
        effectSearchTerms: {
          ...state.effectSearchTerms,
          filter: {
            ...state.effectSearchTerms.filter,
            value: action.payload,
          },
        },
      };
    case SET_EFFECT_FILTER_LABEL:
      return {
        ...state,
        effectSearchTerms: {
          ...state.effectSearchTerms,
          filter: {
            ...state.effectSearchTerms.filter,
            label: action.payload,
          },
        },
      };
    case SELECT_EFFECT:
      return {
        ...state,
        selectedEffect: action.payload,
      };
    case CLEAR_EFFECT:
      return { ...state, selectedEffect: {} };
    case CLEAR_EFFECTS:
      return { ...state, effects: [] };
    case CLEAR_EFFECT_BATCH:
      return {
        ...state,
        effectBatch: [],
      };
    case REMOVE_EFFECT_FROM_BATCH:
      return {
        ...state,
        //removes 2nd argument from array and returns new array
        effectBatch: _.remove(
          state.effectBatch,
          (effect) => effect !== action.payload
        ),
      };
    case ADD_EFFECT_TO_BATCH:
      return {
        ...state,
        effectBatch: [...state.effectBatch, action.payload],
      };
    case ADD_ALL_EFFECTS_TO_BATCH:
      return {
        ...state,
        effectBatch: action.payload,
      };
    case UPDATE_EFFECTS:
      return {
        ...state,
        effects: action.payload,
      };
    case UPDATE_ALL_EFFECTS:
      return {
        ...state,
        allEffects: action.payload,
      };
    case DELETE_EFFECT:
      return {
        ...state,
        effects: removeObjectFromArray(...state.effects, action.payload),
      };
    case CLONE_EFFECTS:
      return {
        ...state,
        allEffects: action.payload,
      };
    default:
      return state;
  }
};

export default effectsReducer;
