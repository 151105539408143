import {
  GET_MANUFACTURERS,
  CLONE_MANUFACTURERS,
  SELECT_MANUFACTURER,
  CLEAR_MANUFACTURER,
  GET_MANUFACTURER,
  SET_MANUFACTURER_FILTER,
  SET_MANUFACTURER_FILTER_LABEL,
  CLEAR_MANUFACTURERS,
  CLEAR_MANUFACTURER_BATCH,
  REMOVE_MANUFACTURER_FROM_BATCH,
  ADD_MANUFACTURER_TO_BATCH,
  UPDATE_MANUFACTURERS,
  UPDATE_ALL_MANUFACTURERS,
  DELETE_MANUFACTURER,
  ADD_ALL_MANUFACTURERS_TO_BATCH,
} from "../actions/types";
import _ from "lodash";
import { removeObjectFromArray } from "../utilities";

const INITIAL_STATE = {
  manufacturers: [],
  allManufacturers: [],
  manufacturer: {},
  selectedManufacturer: {},
  manufacturerSearchTerms: {
    filter: { value: "", label: "" },
    term: {
      value: "",
      label: "",
    },
  },
  manufacturerBatch: [],
};

const manufacturersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_MANUFACTURERS:
      return {
        ...state,
        manufacturers: action.payload,
      };
    case SET_MANUFACTURER_FILTER:
      return {
        ...state,
        manufacturerSearchTerms: {
          ...state.manufacturerSearchTerms,
          filter: {
            ...state.manufacturerSearchTerms.filter,
            value: action.payload,
          },
        },
      };
    case SET_MANUFACTURER_FILTER_LABEL:
      return {
        ...state,
        manufacturerSearchTerms: {
          ...state.manufacturerSearchTerms,
          filter: {
            ...state.manufacturerSearchTerms.filter,
            label: action.payload,
          },
        },
      };
    case SELECT_MANUFACTURER:
      return {
        ...state,
        selectedManufacturer: action.payload,
      };
    case GET_MANUFACTURER:
      return {
        ...state,
        manufacturer: action.payload,
      };
    case CLEAR_MANUFACTURER:
      return { ...state, selectedManufacturer: {} };
    case CLEAR_MANUFACTURERS:
      return { ...state, manufacturers: [] };
    case CLEAR_MANUFACTURER_BATCH:
      return {
        ...state,
        manufacturerBatch: [],
      };
    case REMOVE_MANUFACTURER_FROM_BATCH:
      return {
        ...state,
        //removes 2nd argument from array and returns new array
        manufacturerBatch: _.remove(
          state.manufacturerBatch,
          (manufacturer) => manufacturer !== action.payload
        ),
      };
    case ADD_MANUFACTURER_TO_BATCH:
      return {
        ...state,
        manufacturerBatch: [...state.manufacturerBatch, action.payload],
      };
    case ADD_ALL_MANUFACTURERS_TO_BATCH:
      return {
        ...state,
        manufacturerBatch: action.payload,
      };
    case UPDATE_MANUFACTURERS:
      return {
        ...state,
        manufacturers: action.payload,
      };
    case UPDATE_ALL_MANUFACTURERS:
      return {
        ...state,
        allManufacturers: action.payload,
      };
    case DELETE_MANUFACTURER:
      return {
        ...state,
        manufacturers: removeObjectFromArray(
          ...state.manufacturers,
          action.payload
        ),
      };
    case CLONE_MANUFACTURERS:
      return {
        ...state,
        allManufacturers: action.payload,
      };
    default:
      return state;
  }
};

export default manufacturersReducer;
