import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Button } from "semantic-ui-react";
import Select from "react-select";
import { selectOptionsFromData } from "../../utilities";
import "../components.css";
import { Link } from "react-router-dom";
import _ from "lodash";
import { toastr } from "react-redux-toastr";

import {
  getManufacturer,
  getSuppliers,
  getPaintSystems,
  getStages,
  getPaintColor,
  resetSaveSuccess,
  getEffects,
  getColorGroups,
} from "../../actions";

class CreatePaintColorForm extends React.Component {
  renderError({ submitFailed, error }) {
    if (submitFailed && error) {
      return (
        <div className="ui error message">
          <div className="header">{error}</div>
        </div>
      );
    }
  }

  renderResult = () => {
    if (this.props.busySaving) {
      return (
        <div className="ui success message" style={{ marginTop: "1em" }}>
          Saving..
        </div>
      );
    } else if (this.props.saveError) {
      return (
        <div className="ui error message" style={{ marginTop: "1em" }}>
          {this.props.saveError}
        </div>
      );
    } else if (this.props.successMessage) {
      return (
        <div className="ui success message" style={{ marginTop: "1em" }}>
          {this.props.successMessage}
        </div>
      );
    }
  };

  renderInput = ({
    input,
    id,
    style,
    meta,
    placeholder,
    disabled,
    label,
    autoFocus,
  }) => {
    const className = `field ${meta.error && meta.submitFailed ? "error" : ""}`;
    return (
      <div className={className}>
        <div className="formlabel">{label}</div>
        <input
          {...input}
          id={id}
          style={style}
          placeholder={placeholder}
          disabled={disabled}
          autoFocus={autoFocus}
          ref={
            autoFocus
              ? (input) => {
                  this.focusInput = input;
                }
              : input
          }
        ></input>
        {this.renderError(meta, id)}
      </div>
    );
  };

  renderSelect = ({
    input,
    id,
    options,
    meta,
    style,
    search,
    placeholder,
    label,
    disabled,
  }) => {
    const className = `field ${meta.error && meta.submitFailed ? "error" : ""}`;
    return (
      <div className={className} style={{ marginBottom: "1em" }}>
        <div className="formlabel">{label}</div>
        <Select
          {...input}
          fluid
          selection
          isSearchable={search}
          style={style}
          options={options}
          onChange={(value) => input.onChange(value)}
          onBlur={() => input.onBlur()}
          placeholder={placeholder}
          isDisabled={disabled}
        ></Select>
        {this.renderError(meta, id)}
      </div>
    );
  };

  onSubmit = (values) => {
    try {
      this.props.onSubmit(values, this.props.colorId);
      if (!this.props.edit) {
        this.props.reset();
        this.focusInput.focus();
      }
      toastr.success("Success", "Paint formula record saved.", {
        icon: "success",
      });
    } catch (error) {
      toastr.error("Error", error);
    }
  };

  render() {
    return (
      <div>
        <div className="content">{this.renderResult()}</div>
        <form
          className="ui form error"
          onSubmit={this.props.handleSubmit(this.onSubmit)}
        >
          <Field
            name="manufacturerName"
            id="manufacturerName"
            label="Manufacturer"
            component={this.renderInput}
            disabled
          ></Field>
          <Field
            name="manufacturerId"
            id="manufacturerId"
            component={this.renderInput}
            style={{ display: "none" }}
          ></Field>
          <Field
            name="name"
            id="name"
            label="Paint Color name"
            component={this.renderInput}
            placeholder="Enter color name"
            autoFocus={true}
          ></Field>
          <Field
            name="code"
            id="code"
            label="Paint Code"
            component={this.renderInput}
            placeholder="Enter paint code"
          ></Field>
          <Field
            name="supplier"
            id="supplier"
            label="Paint supplier"
            component={this.renderSelect}
            options={selectOptionsFromData(this.props.suppliers)}
            placeholder="Select paint supplier"
            disabled={this.props.edit} //uneditable
          />
          <Field
            name="system"
            id="system"
            label="Paint system"
            component={this.renderSelect}
            options={selectOptionsFromData(this.props.paintsystems)}
            placeholder="Select paint system"
            disabled={this.props.edit} //uneditable
          />
          <Field
            name="effect"
            id="effect"
            label="Paint Effect"
            component={this.renderSelect}
            options={selectOptionsFromData(this.props.effects)}
            placeholder="Select paint effect"
            disabled={this.props.edit} //uneditable
          />
          <Field
            name="colorgroup"
            id="colorgroup"
            label="Color group"
            component={this.renderSelect}
            options={selectOptionsFromData(this.props.colorgroups)}
            placeholder="Select color group"
            search
          />
          <Field
            name="testplateQty"
            id="testplateQty"
            label="No of testplates required"
            component={this.renderInput}
            placeholder="Enter numeric value"
          ></Field>
          <Field
            name="reworkFactor"
            id="reworkFactor"
            label="Possibility of rework required"
            component={this.renderInput}
            placeholder="Enter numeric value"
          ></Field>
          <div className="spacer-very-wide"></div>

          <div style={{ textAlign: "right" }}>
            <Button.Group>
              <Link to="/paintcolors">
                <Button color="black" type="button">
                  Cancel
                </Button>
              </Link>
              <Button.Or />
              <Button primary>Save</Button>
            </Button.Group>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  //if editing set the default values

  let colorData = {};

  if (props.edit) {
    colorData = {
      selectedPaintColor: state.paintcolors.selectedPaintColor,
      initialValues: {
        manufacturerId: state.paintcolors.selectedPaintColor.manufacturerId,
        manufacturerName: state.paintcolors.selectedPaintColor.manufacturerName,
        name: state.paintcolors.selectedPaintColor.name,
        code: state.paintcolors.selectedPaintColor.code,
        testplateQty: state.paintcolors.selectedPaintColor.testplateQty,
        reworkFactor: state.paintcolors.selectedPaintColor.reworkFactor,
        supplier: {
          value: state.paintcolors.selectedPaintColor.supplierId,
          label: state.paintcolors.selectedPaintColor.supplierName,
        },
        system: {
          value: state.paintcolors.selectedPaintColor.systemId,
          label: state.paintcolors.selectedPaintColor.systemName,
        },
        effect: {
          value: state.paintcolors.selectedPaintColor.effectId,
          label: state.paintcolors.selectedPaintColor.effectName,
        },
        colorgroup: {
          value: state.paintcolors.selectedPaintColor.colorgroupId,
          label: state.paintcolors.selectedPaintColor.colorgroupName,
        },
      },
    };
  } else {
    colorData = {
      paintcolors: state.paintcolors.paintColors,
      initialValues: {
        manufacturerId: state.paintcolors.paintColorSearchTerms.term.value,
        manufacturerName: state.paintcolors.paintColorSearchTerms.term.label,
        testplateQty: 2,
        reworkFactor: 1
      },
    };
  }

  return {
    ...colorData,
    suppliers: state.toners.suppliers,
    paintsystems: state.toners.systems,
    effects: state.effects.effects,
    colorgroups: state.colorgroups.colorGroups,

    //api operations
    successMessage: state.api.saveSuccess,
    saveError: state.api.saveError,
    busySaving: state.api.busySaving,
  };
};

const validate = (formValues, props) => {
  const errors = {};

  if (formValues.code && formValues.supplier) {
    if (
      _.find(props.paintcolors, (color) => {
        return (
          color.code.toLowerCase() + color.supplierId ===
          formValues.code.toLowerCase() + formValues.supplier.value
        );
      }) !== undefined
    ) {
      //keys of object must match field names
      errors.name =
        "Paint Color with the same code and supplier already exists.";
    }
  }

  if (!formValues.name) {
    //keys of object must match field names
    errors.name = "Please enter a color name";
  }
  if (!formValues.code) {
    //keys of object must match field names
    errors.code = "Please enter a color code";
  }
  if (!formValues.supplier) {
    //keys of object must match field names
    errors.supplier = "Please select a supplier";
  }
  if (!formValues.system) {
    //keys of object must match field names
    errors.system = "Please select a paint system";
  }
  if (!formValues.effect) {
    //keys of object must match field names
    errors.effect = "Please select a paint effect";
  }
  if (
    !formValues.testplateQty ||
    !typeof formValues.testplateQty === "number"
  ) {
    //keys of object must match field names
    errors.testplateQty =
      "Please enter a numeric value for the number of testplates required for this color";
  }
  if (
    !formValues.reworkFactor ||
    !typeof formValues.reworkFactor === "number"
  ) {
    //keys of object must match field names
    errors.reworkFactor =
      "Please enter a numeric value for the rework factor for this color";
  }

  
  return errors;
};

const formWrapped = reduxForm({
  form: "CreatePaintColorForm",
  enableReinitialize: true,
  validate: validate,
})(CreatePaintColorForm);

export default connect(mapStateToProps, {
  getManufacturer,
  getSuppliers,
  getPaintSystems,
  getStages,
  getEffects,
  getPaintColor,
  resetSaveSuccess,
  getColorGroups,
})(formWrapped);
