import React from "react";
import Menu from "../components/Menu";
import CreateActivityConsumableForm from "../components/repairs/CreateActivityConsumableForm";
import { compileDataForSave } from "../apis/ActivityConsumables";
import {
  getActivityConsumable,
  selectActivityConsumable,
  updateActivityConsumablesState,
  patchActivityConsumableInDb,
} from "../actions";
import { replaceArrayObjectByKey } from "../utilities";
import { connect } from "react-redux";

class editActivityConsumableView extends React.Component {
  onSubmit = (values, activityConsumable) => {
    const {
      patchActivityConsumableInDb,
      selectActivityConsumable,
      activityConsumables,
      updateActivityConsumablesState,
    } = this.props;
    const activityConsumablesData = compileDataForSave(
      values,
      activityConsumable.id,
      activityConsumable.active
    );

    patchActivityConsumableInDb(activityConsumablesData);

    //add names from form values to object for state management
    let _activityConsumable = {
      ...activityConsumable,
      stageName: values.stage.label,
      scopeName: values.SOR.label,
      consumableName: values.consumable.label,
    };

    //update ActivityConsumable changes back to state
    selectActivityConsumable(_activityConsumable);
    let _activityConsumables = replaceArrayObjectByKey(
      activityConsumables,
      "id",
      _activityConsumable
    );
    updateActivityConsumablesState(_activityConsumables);
  };

  componentDidMount() {
    const { activityConsumableId } = this.props.match.params;
    if (activityConsumableId) {
      this.props.getActivityConsumable(activityConsumableId);
    }
  }

  render() {
    const { activityConsumableId } = this.props.match.params;
    return (
      <>
        <Menu
          contentView={
            <CreateActivityConsumableForm
              edit={true}
              onSubmit={this.onSubmit}
              activityConsumableId={activityConsumableId}
            />
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activityConsumables: state.activityConsumables.activityConsumables,
    
  };
};

export default connect(mapStateToProps, {
  getActivityConsumable,
  selectActivityConsumable,
  updateActivityConsumablesState,
  patchActivityConsumableInDb,
})(editActivityConsumableView);
