import Axios from "axios";
import { env } from "./env";

export const Settings = () => {
    const _env = env();
    const baseURL = _env.BASEURL;
    const key = _env.API_KEY;
  
  return Axios.create({
    baseURL: `${baseURL}/repairs/settings`,
    headers: { "x-api-key": key },
  });
};