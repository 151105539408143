import {
  GET_SETUPCOSTS,
  CLONE_SETUPCOSTS,
  SELECT_SETUPCOST,
  CLEAR_SETUPCOST,
  GET_SETUPCOST,
  SET_SETUPCOST_FILTER,
  SET_SETUPCOST_FILTER_LABEL,
  CLEAR_SETUPCOSTS,
  CLEAR_SETUPCOST_BATCH,
  REMOVE_SETUPCOST_FROM_BATCH,
  ADD_SETUPCOST_TO_BATCH,
  UPDATE_SETUPCOSTS,
  UPDATE_ALL_SETUPCOSTS,
  DELETE_SETUPCOST,
  ADD_ALL_SETUPCOSTS_TO_BATCH,
} from "../actions/types";
import _ from "lodash";
import { removeObjectFromArray } from "../utilities";

const INITIAL_STATE = {
  setupCosts: [],
  allsetupCosts: [],
  setupCost: {},
  selectedSetupCost: {},
  setupCostSearchTerms: {
    filter: { value: "", label: "" },
    term: {
      value: "",
      label: "",
    },
  },
  setupCostBatch: [],
};

const setupCostsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_SETUPCOSTS:
      return {
        ...state,
        setupCosts: action.payload,
      };
    case SET_SETUPCOST_FILTER:
      return {
        ...state,
        setupCostSearchTerms: {
          ...state.setupCostSearchTerms,
          filter: {
            ...state.setupCostSearchTerms.filter,
            value: action.payload,
          },
        },
      };
    case SET_SETUPCOST_FILTER_LABEL:
      return {
        ...state,
        setupCostSearchTerms: {
          ...state.setupCostSearchTerms,
          filter: {
            ...state.setupCostSearchTerms.filter,
            label: action.payload,
          },
        },
      };
    case SELECT_SETUPCOST:
      return {
        ...state,
        selectedSetupCost: action.payload,
      };
    case GET_SETUPCOST:
      return {
        ...state,
        setupCost: action.payload,
      };
    case CLEAR_SETUPCOST:
      return { ...state, selectedSetupCost: {} };
    case CLEAR_SETUPCOSTS:
      return { ...state, setupCosts: [] };
    case CLEAR_SETUPCOST_BATCH:
      return {
        ...state,
        setupCostBatch: [],
      };
    case REMOVE_SETUPCOST_FROM_BATCH:
      return {
        ...state,
        //removes 2nd argument from array and returns new array
        setupCostBatch: _.remove(
          state.setupCostBatch,
          (setupCost) => setupCost !== action.payload
        ),
      };
    case ADD_SETUPCOST_TO_BATCH:
      return {
        ...state,
        setupCostBatch: [...state.setupCostBatch, action.payload],
      };
    case ADD_ALL_SETUPCOSTS_TO_BATCH:
      return {
        ...state,
        setupCostBatch: action.payload,
      };
    case UPDATE_SETUPCOSTS:
      return {
        ...state,
        setupCosts: action.payload,
      };
    case UPDATE_ALL_SETUPCOSTS:
      return {
        ...state,
        allsetupCosts: action.payload,
      };
    case DELETE_SETUPCOST:
      return {
        ...state,
        setupCosts: removeObjectFromArray(...state.setupCosts, action.payload),
      };
    case CLONE_SETUPCOSTS:
      return {
        ...state,
        allsetupCosts: action.payload,
      };
    default:
      return state;
  }
};

export default setupCostsReducer;
