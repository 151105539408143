import {
  GET_SORS,
  SELECT_SOR,
  CLEAR_SOR,
  GET_SOR,
  SET_SOR_FILTER,
  SET_SOR_FILTER_LABEL,
  CLEAR_SORS,
  CLEAR_SOR_BATCH,
  REMOVE_SOR_FROM_BATCH,
  ADD_SOR_TO_BATCH,
  UPDATE_SORS,
  UPDATE_ALL_SORS,
  DELETE_SOR,
  ADD_ALL_SORS_TO_BATCH,
  CLONE_SORS,
} from "../actions/types";
import _ from "lodash";
import { removeObjectFromArray } from "../utilities";

const INITIAL_STATE = {
  SORs: [],
  allSORs: [],
  SOR: {},
  selectedSOR: {},
  SORSearchTerms: {
    filter: { value: "", label: "" },
    term: {
      value: "",
      label: "",
    },
  },
  SORBatch: [],
};

const SORsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_SORS:
      return {
        ...state,
        SORs: action.payload,
      };
    case SET_SOR_FILTER:
      return {
        ...state,
        SORSearchTerms: {
          ...state.SORSearchTerms,
          filter: {
            ...state.SORSearchTerms.filter,
            value: action.payload,
          },
        },
      };
    case SET_SOR_FILTER_LABEL:
      return {
        ...state,
        SORSearchTerms: {
          ...state.SORSearchTerms,
          filter: {
            ...state.SORSearchTerms.filter,
            label: action.payload,
          },
        },
      };
    case SELECT_SOR:
      return {
        ...state,
        selectedSOR: action.payload,
      };
    case GET_SOR:
      return {
        ...state,
        SOR: action.payload,
      };
    case CLEAR_SOR:
      return { ...state, selectedSOR: {} };
    case CLEAR_SORS:
      return { ...state, SORs: [] };
    case CLEAR_SOR_BATCH:
      return {
        ...state,
        SORBatch: [],
      };
    case REMOVE_SOR_FROM_BATCH:
      return {
        ...state,
        //removes 2nd argument from array and returns new array
        SORBatch: _.remove(state.SORBatch, (SOR) => SOR !== action.payload),
      };
    case ADD_SOR_TO_BATCH:
      return {
        ...state,
        SORBatch: [...state.SORBatch, action.payload],
      };
    case ADD_ALL_SORS_TO_BATCH:
      return {
        ...state,
        SORBatch: action.payload,
      };
    case UPDATE_SORS:
      return {
        ...state,
        SORs: action.payload,
      };
    case UPDATE_ALL_SORS:
      return {
        ...state,
        allSORs: action.payload,
      };
    case DELETE_SOR:
      return {
        ...state,
        SORs: removeObjectFromArray(...state.SORs, action.payload),
      };
    case CLONE_SORS:
      return {
        ...state,
        allSORs: action.payload,
      };
    default:
      return state;
  }
};

export default SORsReducer;
