import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Button, Input } from "semantic-ui-react";
import Select from "react-select";
import { selectOptionsFromData } from "../../utilities";
import "../components.css";
import { Link } from "react-router-dom";
import _ from "lodash";
import { toastr } from "react-redux-toastr";

class CreateRefPanelQtyForm extends React.Component {
  renderError({ submitFailed, error }) {
    if (submitFailed && error) {
      return (
        <div className="ui error message">
          <div className="header">{error}</div>
        </div>
      );
    }
  }

  renderResult = () => {
    if (this.props.busySaving) {
      return (
        <div className="ui success message" style={{ marginTop: "1em" }}>
          Saving..
        </div>
      );
    } else if (this.props.saveError) {
      return (
        <div className="ui error message" style={{ marginTop: "1em" }}>
          {this.props.saveError}
        </div>
      );
    } else if (this.props.successMessage) {
      return (
        <div className="ui success message" style={{ marginTop: "1em" }}>
          {this.props.successMessage}
        </div>
      );
    }
  };

  renderInput = ({ input, id, style, meta, placeholder, disabled, label, autoFocus }) => {
    const className = `field ${meta.error && meta.submitFailed ? "error" : ""}`;
    return (
      <div className={className}>
        <div className="formlabel">{label}</div>
        <Input
          {...input}
          id={id}
          style={style}
          placeholder={placeholder}
          disabled={disabled}
          autoFocus={autoFocus}
          ref={
            autoFocus
              ? (input) => {
                  this.focusInput = input;
                }
              : input
          }></Input>
        {this.renderError(meta, id)}
      </div>
    );
  };

  renderSelect = ({ input, id, options, meta, style, search, placeholder, isMulti, label, isDisabled }) => {
    const className = `field ${meta.error && meta.submitFailed ? "error" : ""}`;
    return (
      <div className={className} style={{ marginBottom: "1em" }}>
        <div className="formlabel">{label}</div>

        <Select
          {...input}
          fluid
          isMulti={isMulti}
          selection
          isSearchable={search}
          style={style}
          options={options}
          onChange={(value) => input.onChange(value)}
          onBlur={() => input.onBlur()}
          isDisabled={isDisabled}
          placeholder={placeholder}></Select>
        {this.renderError(meta, id)}
      </div>
    );
  };

  onSubmit = (values) => {
    try {
      this.props.onSubmit(values, this.props.partId, this.props.partNumber);
      if (!this.props.edit) {
        this.props.reset();
        this.focusInput.focus();
      }
      toastr.success("Success", "Panel quantity record saved.", {
        icon: "success"
      });
    } catch (error) {
      toastr.error("Error", error);
    }
  };

  render() {
    return (
      <div>
        <div className="content margin-bottom-small">{this.renderResult()}</div>
        <form className="ui form error" onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <Field
            name="labour"
            id="labour"
            label="Labour Units"
            component={this.renderInput}
            placeholder="Enter labour units"
            autoFocus={true}></Field>
          <Field
            name="paint"
            id="paint"
            label="Paint Quantity (ml)"
            component={this.renderInput}
            placeholder="Enter paint quantity"
          />
          <Field
            name="scope"
            id="scope"
            label="Scope of Repair"
            component={this.renderSelect}
            isDisabled={this.props.edit}
            options={selectOptionsFromData(this.props.SORs)}
            placeholder="Select Scope"
            search
          />

          <Field
            name="stage"
            id="stage"
            label="Stage"
            component={this.renderSelect}
            isDisabled={this.props.edit}
            options={selectOptionsFromData(this.props.stages)}
            placeholder="Select Stage"
            search
          />
          <div className="spacer-very-wide"></div>

          <div style={{ textAlign: "right" }}>
            <Button.Group>
              <Link to="/referencepanel">
                <Button color="black" type="button">
                  Cancel
                </Button>
              </Link>
              <Button.Or />
              <Button primary>Save</Button>
            </Button.Group>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  //if editing set the default values

  let refPanelData = {};

  if (props.edit) {
    refPanelData = {
      selectedQuantity: state.referencePanel.selectedReferenceQuantity,
      stages: state.stages.stages,
      SORs: state.SORs.SORs,
      initialValues: {
        labour: state.referencePanel.selectedReferenceQuantity.labour,
        paint: state.referencePanel.selectedReferenceQuantity.paint,
        scope: {
          value: state.referencePanel.selectedReferenceQuantity.scope,
          label: state.referencePanel.selectedReferenceQuantity.scopeName
        },

        stage: {
          value: state.referencePanel.selectedReferenceQuantity.stage,
          label: state.referencePanel.selectedReferenceQuantity.stageName
        }
      }
    };
  } else {
    refPanelData = {
      stages: state.stages.stages,
      SORs: state.SORs.SORs,
      allQuantities: state.referencePanel.allReferenceQuantities
    };
  }

  return {
    ...refPanelData,

    //api operations
    successMessage: state.api.saveSuccess,
    saveError: state.api.saveError,
    busySaving: state.api.busySaving
  };
};

const validate = (formValues, props) => {
  const errors = {};

  if (
    !formValues.labour ||
    !typeof formValues.labour === "number"
  ) {
    //keys of object must match field names
    errors.labour =
      "Please enter a numeric value for the labour units";
  }

  if (
    !formValues.paint ||
    !typeof formValues.paint === "number"
  ) {
    //keys of object must match field names
    errors.paint =
      "Please enter a numeric value for the paint quantity";
  }

  if (!formValues.scope) {
    //keys of object must match field names
    errors.scope = "Please select the Scope of Repair";
  }

  if (!formValues.stage) {
    //keys of object must match field names
    errors.stage = "Please select the stage";
  }

  if (formValues.stage) {
    if (
      _.find(props.allQuantities, (qty) => {
        return qty.scope === formValues.scope.value && qty.stage === formValues.stage.value;
      }) !== undefined
    ) {
      //keys of object must match field names
      errors.stage = "Quantities record with selected Scope of Repair and Stage combination already exists";
    }
  }


  return errors;
};

const formWrapped = reduxForm({
  form: "CreateRefPanelQtyForm",
  enableReinitialize: true,
  validate: validate
})(CreateRefPanelQtyForm);

export default connect(mapStateToProps, {})(formWrapped);
