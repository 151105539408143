import React from "react";
import { Dropdown, Confirm } from "semantic-ui-react";

class TableActionsMenu extends React.Component {
  state = { confirmOpen: false, selectedAction: null };

  handleChange = (e, data) => {
    const { actions } = this.props;
    if (data) {
      if (actions[data.idx].confirmation === 1) {
        this.setState({ confirmOpen: true, selectedAction: data.idx });
      } else {
        actions[data.idx].action();
      }
    }
  };

  onConfirmationCancel = () => {
    this.setState({ confirmOpen: false });
  };

  onConfirmationConfirm = () => {
    const { actions } = this.props;
    this.setState({ confirmOpen: false });
    actions[this.state.selectedAction].action();
    this.setState({ confirmOpen: false });
  };

  renderConfirmationModal(header, text) {
    return (
      <Confirm
        header="Confirm Action"
        text="Are you sure?"
        open={this.state.confirmOpen}
        onCancel={this.onConfirmationCancel}
        onConfirm={this.onConfirmationConfirm}
      ></Confirm>
    );
  }

  render() {
    const { actions } = this.props;
    return (
      <>
        <Dropdown
          text="Bulk Actions"
          icon="angle double down"
          floating
          labeled
          button
          className="icon"
          style={{ width: "175px" }}
        >
          <Dropdown.Menu>
            {actions.map((action) => (
              <Dropdown.Item
                key={action.value}
                {...action}
                onClick={this.handleChange}
                icon={action.icon.name}
                color={action.icon.color}
              />
            ))}
          </Dropdown.Menu>
        </Dropdown>
        {this.renderConfirmationModal(actions.header, "some message")}
      </>
    );
  }
}
export default TableActionsMenu;
