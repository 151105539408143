import React from "react";
import Menu from "../components/Menu";
import { compileManufacturerObject } from "../apis/Manufacturers";
import {
  getManufacturers,
  updateManufacturersState,
  updateAllManufacturersState,
  selectManufacturer,
  postManufacturerToDb,
} from "../actions";
import { connect } from "react-redux";
import CreateManufacturerForm from "../components/vehicles/CreateManufacturerForm";
import { nanoid } from "nanoid";
import { updateStateAfterCreate } from "../utilities";

class createManufacturerView extends React.Component {
  componentDidMount() {
    if (this.props.allManufacturers.length === 0) {
      this.props.getManufacturers({});
    }
  }

  onSubmit = (values, manufacturer) => {
    const {
      manufacturers,
      allManufacturers,
      postManufacturerToDb,
      selectManufacturer,
      updateManufacturersState,
      updateAllManufacturersState,
    } = this.props;
    let manufacturerData = compileManufacturerObject(
      values,
      manufacturer.id,
      true
    );
    postManufacturerToDb(manufacturerData).then((result) => {
      manufacturerData.id = result.data.id;
      manufacturerData.createdat = result.data.createdat;
      manufacturerData.updatedat = result.data.updatedat;
      updateStateAfterCreate(
        selectManufacturer,
        updateManufacturersState,
        updateAllManufacturersState,
        manufacturers,
        allManufacturers,
        manufacturerData
      );
    });
  };

  render() {
    return (
      <>
        <Menu
          contentView={
            <CreateManufacturerForm
              edit={false}
              onSubmit={this.onSubmit}
              manufacturerId={nanoid()}
            />
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    manufacturers: state.manufacturers.manufacturers,
    allManufacturers: state.manufacturers.allManufacturers,
  };
};

export default connect(mapStateToProps, {
  getManufacturers,
  selectManufacturer,
  updateManufacturersState,
  updateAllManufacturersState,
  postManufacturerToDb,
})(createManufacturerView);
