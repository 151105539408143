import { CognitoUserPool } from "amazon-cognito-identity-js";
import { env } from "../../apis/env";

const createPool = () => {
  const _env = env();
  const poolData = {
    UserPoolId: _env.COGNITO_USER_POOL_ID,
    ClientId: _env.COGNITO_APP_CLIENT_ID,
  };
  return new CognitoUserPool(poolData);
};

export default createPool();
