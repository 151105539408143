import React from "react";
import Menu from "../components/Menu";
import { updateReferencePanelState, patchReferencePanelInDb, getReferencePanel, getSORs, getStages } from "../actions";
import { connect } from "react-redux";
import CreateRefPanelQtyForm from "../components/repairs/CreateRefPanelQtyForm";
import { compileReferenceQuantity } from "../apis/ReferencePanel";

class createRefPanelQtyView extends React.Component {
  onSubmit = (values) => {
    const { referencePanel, patchReferencePanelInDb } = this.props;
    let _refPanel = { ...referencePanel };
    let _refPanelQuantities = [...referencePanel.quantities];
    let newPanelQuantity = compileReferenceQuantity(values);
    //add new quantity to top of array
    _refPanelQuantities.splice(0, 0, newPanelQuantity);
    _refPanel.quantities = _refPanelQuantities;
    patchReferencePanelInDb(_refPanel);
    //update Ref Panel changes back to state
    updateReferencePanelState(_refPanel);
  };

  componentDidMount() {
    this.props.getReferencePanel();
    this.props.getSORs({});
    this.props.getStages({});
  }

  render() {
    return (
      <>
        <Menu contentView={<CreateRefPanelQtyForm edit={false} onSubmit={this.onSubmit} />} />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    referencePanel: state.referencePanel.referencePanel
  };
};

export default connect(mapStateToProps, {
  patchReferencePanelInDb,
  getReferencePanel,
  getSORs,
  getStages,  
})(createRefPanelQtyView);
