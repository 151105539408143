import Axios from "axios";
import { env } from "./env";

export const ColorGroups = ({ id, name, colorGroupIds }) => {
  const _env = env();
  const baseURL = _env.BASEURL;
  const key = _env.API_KEY;

  if (id) {
    return Axios.create({
      baseURL: `${baseURL}/paint/colorgroups/${id}`,
      headers: { "x-api-key": key },
    });
  } else if (name) {
    return Axios.create({
      baseURL: `${baseURL}/paint/colorgroups?name=${name}`,
      headers: { "x-api-key": key },
    });
  } else if (colorGroupIds) {
    return Axios.create({
      baseURL: `${baseURL}/paint/colorgroups?ids=${colorGroupIds}`,
      headers: { "x-api-key": key },
    });
  }

  return Axios.create({
    baseURL: `${baseURL}/paint/colorgroups`,
    headers: { "x-api-key": key },
  });
};

export const compileColorGroupObject = ({ name }, id, state) => {
  const colorGroup = {};
  colorGroup.id = id;
  colorGroup.name = name;
  colorGroup.l_name = name.toLowerCase();
  colorGroup.active = state;
  return colorGroup;
};
