import React from "react";
import "../components.css";
import {
  clearManufacturer,
  setManufacturersFilter,
  clearManufacturerBatch,
  selectManufacturer,
  removeManufacturerFromSelectedBatch,
  addManufacturerToSelectedBatch,
  addAllManufacturersToSelectedBatch,
  deleteManufacturers,
  patchManufacturerInDb,
  updateManufacturersState,
  updateAllManufacturersState,
  getManufacturers,
  clearManufacturers,
} from "../../actions";
import { connect } from "react-redux";
import { compileManufacturerObject } from "../../apis/Manufacturers";
import _ from "lodash";
import { SAVE, EDIT, NEW, DELETE } from "../../actions/types";
import { Link } from "react-router-dom";
import { Button, Confirm } from "semantic-ui-react";
import history from "../../history";
import DisplayTable from "../displayTable";
import { isMobile } from "react-device-detect";
import { removeObjectFromArrayById, filterArray, bathDeleteItems } from "../../utilities";
import { SET_MANUFACTURER_FILTER_LABEL } from "../../actions/types";

class Manufacturers extends React.Component {
  state = {
    confirmDeleteOpen: false,
    manufacturerDeleteId: null,
  };

  componentDidMount() {
    if (this.props.manufacturers.length === 0) {
      this.props.getManufacturers({});
    }
  }

  onManufacturersFilterChange = (filter) => {
    const {
      clearManufacturer,
      manufacturerSearchTerms,
      setManufacturersFilter,
      updateManufacturersState,
      allManufacturers,
    } = this.props;

    const _newfilter = filter === "#clear#" ? "" : manufacturerSearchTerms.filter.label;

    if (_newfilter !== manufacturerSearchTerms.filter.value) {
      clearManufacturer();

      if (_newfilter !== "") {
        updateManufacturersState(filterArray(allManufacturers, "l_name", _newfilter));
      } else {
        updateManufacturersState(allManufacturers);
      }
    }
    setManufacturersFilter(_newfilter);

    // const _filter =
    //   filter !== undefined ? filter : manufacturerSearchTerms.filter.label;
    // if (_filter !== manufacturerSearchTerms.filter.value) {
    //   clearManufacturer();
    //   if (_filter !== "") {
    //     let _manufacturers = [...allManufacturers];
    //     const _filteredManufacturers = [
    //       ...filterArrayOnConcatenatedKeys(_manufacturers, ["l_name"], _filter),
    //     ];
    //     updateManufacturersState(_filteredManufacturers);
    //   } else {
    //     updateManufacturersState([...allManufacturers]);
    //   }
    // }
    // setManufacturersFilter(_filter);
  };

  onSelectManufacturer = (id) => {
    const { selectManufacturer, manufacturers, clearManufacturerBatch } = this.props;
    selectManufacturer(manufacturers.find((manufacturer) => manufacturer.id === id));
    clearManufacturerBatch();
  };

  onAddManufacturerToBatch = (manufacturer) => {
    if (this.props.manufacturerBatch.find((item) => item === manufacturer)) {
      this.props.removeManufacturerFromSelectedBatch(manufacturer);
    } else {
      this.props.addManufacturerToSelectedBatch(manufacturer);
    }
  };

  onAddAllManufacturersToSelectedBatch = () => {
    if (this.props.manufacturerBatch.length === this.props.manufacturers.length) {
      this.props.clearManufacturerBatch();
    } else {
      this.props.addAllManufacturersToSelectedBatch(this.props.manufacturers);
    }
  };

  onBatchDeleteManufacturers = () => {
   
    //*below needs to be converted to generics.
   
    bathDeleteItems(
      this.props.manufacturerBatch,
      this.props.manufacturers,
      this.props.allManufacturers,
      this.props.deleteManufacturers,
      this.props.clearManufacturer,
      this.props.clearManufacturerBatch,
      this.props.updateManufacturersState,
      this.props.updateAllManufacturersState,
    );
  };

  onDeleteManufacturer = () => {
    const { clearManufacturer, updateManufacturersState, updateAllManufacturersState } = this.props;
    let _manufacturer = { ...this.props.selectedManufacturer };
    //set to inactive
    _manufacturer.active = false;
    this.updateManufacturer(_manufacturer);
    clearManufacturer();
    updateManufacturersState(removeObjectFromArrayById(this.props.manufacturers, _manufacturer));
    updateAllManufacturersState(
      removeObjectFromArrayById(this.props.allManufacturers, _manufacturer)
    );
  };

  updateManufacturer = (manufacturer) => {
    const { patchManufacturerInDb } = this.props;
    let _manufacturer = compileManufacturerObject(
      {
        name: manufacturer.name,
      },
      manufacturer.id,
      manufacturer.active
    );
    patchManufacturerInDb(_manufacturer);
  };

  //called from tables main menu to do ad-hoc save.
  onSaveChanges = () => {
    const _manufacturer = { ...this.props.selectedManufacturer };
    if (!_.isEmpty(_manufacturer)) {
      this.updateManufacturer(_manufacturer);
    }
  };

  renderManufacturersTable() {
    const menuItems = [
      {
        name: "Save Changes",
        key: SAVE,
        icon: "save",
        onClick: this.onSaveChanges,
        type: Button,
        disabled: this.props.manufacturers.length === 0,
      },
      {
        name: "New",
        key: NEW,
        icon: "add square",
        to: `/manufacturers/create`,
        type: Link,
        disabled: false,
      },
    ];

    const itemMenuActions = [
      {
        key: EDIT,
        text: "Edit Manufacturer",
        value: EDIT,
        icon: { name: "edit", color: "green" },
        idx: 0,
        executeAction: ({ itemId }) => {
          history.push(`/manufacturers/edit/${itemId}`);
        },
      },
      {
        key: DELETE,
        text: "Delete",
        value: DELETE,
        icon: { name: "delete", color: "red" },
        idx: 1,
        executeAction: ({ itemId }) => {
          this.setState({
            manufacturerDeleteId: itemId,
            confirmDeleteOpen: true,
          });
        },
      },
    ];

    const tableMenuActions = [
      {
        key: DELETE,
        confirmation: 1,
        text: "Delete",
        value: DELETE,
        icon: { name: "remove", color: "red" },
        idx: 0,
        action: this.onBatchDeleteManufacturers,
        header: "Confirm Delete",
      },
    ];

    const gridColumns = "one";
    const tableColumns = [];

    const primaryColumn = {
      heading: "Manufacturer Name",
      width: "four",
      displayValues: [{ columnName: "name" }],
    };

    const copyConfig = {
      active: false,
    };

    //check if "model panels message must be displayed"

    return (
      <>
        <DisplayTable
          idFieldName="id"
          primaryHeading="Manufacturers"
          items={this.props.manufacturers}
          selectedItemId={this.props.selectedManufacturer.id}
          menuItems={menuItems}
          tableMenuActions={tableMenuActions}
          itemMenuActions={itemMenuActions}
          showSearch={0}
          searchTerms={this.props.manufacturerSearchTerms}
          onSelectItem={this.onSelectManufacturer}
          onSearchTermChange={() => {
            return;
          }}
          onFilterChange={this.onManufacturersFilterChange}
          filterAction={SET_MANUFACTURER_FILTER_LABEL}
          batchItems={this.props.manufacturerBatch}
          onAddItemToBatch={this.onAddManufacturerToBatch}
          onAddAllItemsToBatch={this.onAddAllManufacturersToSelectedBatch}
          primaryColumn={primaryColumn}
          gridColumns={gridColumns}
          tableColumns={tableColumns}
          loading={this.props.loadingData}
          copyItemsConfig={copyConfig}
        ></DisplayTable>
      </>
    );
  }

  renderDeleteConfirm() {
    return (
      <Confirm
        open={this.state.confirmDeleteOpen}
        header="Item Actions"
        content={`Confirm Delete?`}
        onCancel={() => {
          this.setState({ confirmDeleteOpen: false });
        }}
        onConfirm={() => {
          this.setState({ confirmDeleteOpen: false });
          this.onDeleteManufacturer();
        }}
      ></Confirm>
    );
  }

  render() {
    const className = `ui ${isMobile ? "" : "very padded"} segment`;
    return (
      <>
        <div className={className}>
          <div>{this.renderManufacturersTable()}</div>
          {this.renderDeleteConfirm()}
          <div className="spacer-very-wide"></div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    manufacturerSearchTerms: state.manufacturers.manufacturerSearchTerms,
    manufacturers: state.manufacturers.manufacturers,
    allManufacturers: state.manufacturers.allManufacturers,
    manufacturerBatch: state.manufacturers.manufacturerBatch,
    selectedManufacturer: state.manufacturers.selectedManufacturer,
    loadingData: state.api.loadingData,
  };
};

export default connect(mapStateToProps, {
  clearManufacturer,
  clearManufacturers,
  setManufacturersFilter,
  clearManufacturerBatch,
  selectManufacturer,
  removeManufacturerFromSelectedBatch,
  addManufacturerToSelectedBatch,
  addAllManufacturersToSelectedBatch,
  deleteManufacturers,
  patchManufacturerInDb,
  updateManufacturersState,
  updateAllManufacturersState,
  getManufacturers,
})(Manufacturers);
