import React from "react";
import Menu from "../components/Menu";
import LabourAdjustments from "../components/repairs/LabourAdjustments";

const labourAdjustmentsView = () => {
  return (
    <>
      <Menu contentView={<LabourAdjustments />} />
    </>
  );
};

export default labourAdjustmentsView;
