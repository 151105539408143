export const emptyModel = {
  name: null,
  year: null,
  yearto: null,
  bodytype: null,
  manufacturer: null,
  subtype: null,
  size: null,
  paintcodelocation: null,
};

export const yearOptions = [
  { value: "2023", label: "2024" },
  { value: "2023", label: "2023" },
  { value: "2022", label: "2022" },
  { value: "2021", label: "2021" },
  { value: "2020", label: "2020" },
  { value: "2019", label: "2019" },
  { value: "2018", label: "2018" },
  { value: "2017", label: "2017" },
  { value: "2016", label: "2016" },
  { value: "2015", label: "2015" },
  { value: "2014", label: "2014" },
  { value: "2013", label: "2013" },
  { value: "2012", label: "2012" },
  { value: "2011", label: "2011" },
  { value: "2010", label: "2010" },
  { value: "2009", label: "2009" },
  { value: "2008", label: "2008" },
  { value: "2007", label: "2007" },
  { value: "2006", label: "2006" },
  { value: "2005", label: "2005" },
  { value: "2004", label: "2004" },
  { value: "2003", label: "2003" },
  { value: "2002", label: "2002" },
  { value: "2001", label: "2001" },
  { value: "2000", label: "2000" },
];

export const currentYearOption = [
  { value: null, label: "Current" }
]

export const unitOptions = [
  { value: "centimetre", label: "centimetre" },
  { value: "gram", label: "gram" },
  { value: "each", label: "each" },
  { value: "kilogram", label: "kilogram" },
  { value: "litre", label: "litre" },
  { value: "meter", label: "meter" },
  { value: "sq centimeter", label: "sq centimeter" },
  { value: "sq decimeter", label: "sq decimeter" },
  { value: "sq meter", label: "sq meter" },
];

export const sizeOptions = [
  { value: "Small", label: "Small" },
  { value: "Medium", label: "Medium" },
  { value: "Large", label: "Large" },
];

export const paintlocationOptions = [
  { value: "On", label: "On" },
  { value: "Off", label: "Off" },
];


export const partHiddenOptions = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

export const accessoryOptions = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

export const partpositionOptions = [
  { value: "front", label: "front" },
  { value: "left", label: "left" },
  { value: "rear", label: "rear" },
  { value: "right", label: "right" },
  { value: "top", label: "top" },
  { value: "internal", label: "internal" },
];

export const countries = [
  {
    value: "ZA",
    label: "South Africa",
    phoneCode: "+27",
  },
  {
    value: "AUS",
    label: "Australia",
    phoneCode: "+61",
  },
  {
    value: "GB",
    label: "United Kingdom",
    phoneCode: "+44",
  },
  {
    value: "US",
    label: "United States",
    phoneCode: "+1",
  },
];
