import {
    GET_LOCATIONS,
    CLONE_LOCATIONS,
    SELECT_LOCATION,
    CLEAR_LOCATION,
    GET_LOCATION,
    SET_LOCATION_FILTER,
    SET_LOCATION_FILTER_LABEL,
    CLEAR_LOCATIONS,
    CLEAR_LOCATION_BATCH,
    REMOVE_LOCATION_FROM_BATCH,
    ADD_LOCATION_TO_BATCH,
    UPDATE_LOCATIONS,
    UPDATE_ALL_LOCATIONS,
    DELETE_LOCATION,
    ADD_ALL_LOCATIONS_TO_BATCH,
  } from "../actions/types";
  import _ from "lodash";
  import { removeObjectFromArray } from "../utilities";
  
  const INITIAL_STATE = {
    locations: [],
    allLocations: [],
    location: {},
    selectedLocation: {},
    locationSearchTerms: {
      filter: { value: "", label: "" },
      term: {
        value: "",
        label: "",
      },
    },
    locationBatch: [],
  };
  
  const locationsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case GET_LOCATIONS:
        return {
          ...state,
          locations: action.payload,
        };
      case SET_LOCATION_FILTER:
        return {
          ...state,
          locationSearchTerms: {
            ...state.locationSearchTerms,
            filter: {
              ...state.locationSearchTerms.filter,
              value: action.payload,
            },
          },
        };
      case SET_LOCATION_FILTER_LABEL:
        return {
          ...state,
          locationSearchTerms: {
            ...state.locationSearchTerms,
            filter: {
              ...state.locationSearchTerms.filter,
              label: action.payload,
            },
          },
        };
      case SELECT_LOCATION:
        return {
          ...state,
          selectedLocation: action.payload,
        };
      case GET_LOCATION:
        return {
          ...state,
          location: action.payload,
        };
      case CLEAR_LOCATION:
        return { ...state, selectedLocation: {} };
      case CLEAR_LOCATIONS:
        return { ...state, locations: [] };
      case CLEAR_LOCATION_BATCH:
        return {
          ...state,
          locationBatch: [],
        };
      case REMOVE_LOCATION_FROM_BATCH:
        return {
          ...state,
          //removes 2nd argument from array and returns new array
          locationBatch: _.remove(
            state.locationBatch,
            (location) => location !== action.payload
          ),
        };
      case ADD_LOCATION_TO_BATCH:
        return {
          ...state,
          locationBatch: [...state.locationBatch, action.payload],
        };
      case ADD_ALL_LOCATIONS_TO_BATCH:
        return {
          ...state,
          locationBatch: action.payload,
        };
      case UPDATE_LOCATIONS:
        return {
          ...state,
          locations: action.payload,
        };
      case UPDATE_ALL_LOCATIONS:
        return {
          ...state,
          allLocations: action.payload,
        };
      case DELETE_LOCATION:
        return {
          ...state,
          locations: removeObjectFromArray(
            ...state.locations,
            action.payload
          ),
        };
      case CLONE_LOCATIONS:
        return {
          ...state,
          allLocations: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default locationsReducer;
  