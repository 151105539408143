import React from "react";
import ItemActionsMenu from "./ItemActionsMenu";
import { Icon } from "semantic-ui-react";
import "./components.css";
import { currencyFormatter, dateToString } from "../utilities";
import DisplayTableItemDetailPopup from "./displayTableItemDetailPopup";
import { countries } from "../defaults";

class displayTableItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      itemDetailOpen: false
    };
  }

  stringifyDisplayValues(valueArray, dataType, item) {
    let s = "";
    let _fieldName = "";
    let fieldName = "";
    let subFieldName = "";
    valueArray.forEach((v) => {
      _fieldName = v.columnName;
      const closeparenthesis = v.columnName.indexOf(")");
      if (closeparenthesis >= 0) {
        _fieldName = v.columnName.substring(1, v.columnName.toString().length - 1);
      }
      const dotpos = v.columnName.indexOf(".");
      if (dotpos >= 0) {
        fieldName = _fieldName.substring(0, dotpos);
        subFieldName = _fieldName.substring(dotpos + 1, _fieldName.toString().length);
        if (Array.isArray(item[fieldName])) {
          if (item[fieldName][0][subFieldName] === undefined) {
            return "";
          }
        } else {
          if (item[fieldName][subFieldName] === undefined) {
            return "";
          }
        }
        if (closeparenthesis >= 0 && item[fieldName][subFieldName].toString().length > 0) {
          if (dataType === "Date") {
            s = s + " (" + dateToString(new Date(item[fieldName][subFieldName])) + ")";
          } else {
            s = s + " (" + item[fieldName][subFieldName] + ")";
          }
        } else {
          if (dataType === "Date") {
            if (Array.isArray(item[fieldName])) {
              s = item[fieldName]
                .map((obj) => {
                  return dateToString(new Date(obj[subFieldName]));
                })
                .join(", ");
            } else {
              s = s + " " + dateToString(new Date(item[fieldName][subFieldName]));
            }
          } else if (dataType === "Currency") {
            if (Array.isArray(item[fieldName])) {
              s = item[fieldName]
                .map((obj) => {
                  return currencyFormatter.format(obj[subFieldName]);
                })
                .join(", ");
            } else {
              s = s + " " + currencyFormatter.format(item[fieldName][subFieldName]);
            }
          } else if (dataType === "Boolean") {
            if (Array.isArray(item[fieldName])) {
              s = item[fieldName]
                .map((obj) => {
                  return obj[subFieldName].toString(); //boolean to string => "true" or "false"
                })
                .join(", ");
            } else {
              s = s + " " + item[fieldName][subFieldName].toString();
            }
          } else if (dataType === "Float") {
            if (Array.isArray(item[fieldName])) {
              s = item[fieldName]
                .map((obj) => {
                  return parseFloat(obj[subFieldName]).toFixed(2);
                })
                .join(", ");
            } else {
              s = s + " " + parseFloat(item[fieldName][subFieldName]).toFixed(2);
            }
          } else {
            if (Array.isArray(item[fieldName])) {
              s = item[fieldName]
                .map((obj) => {
                  return obj[subFieldName];
                })
                .join(", ");
            } else {
              s = s + " " + item[fieldName][subFieldName];
            }
          }
        }
      } else {
        if (item[_fieldName] === undefined) {
          return "";
        }
        if (closeparenthesis >= 0 && item[_fieldName].toString().length > 0) {
          if (dataType === "Date") {
            s = s + " (" + dateToString(new Date(item[_fieldName])) + ")";
          } else if (dataType === "Currency") {
            s = s + " (" + currencyFormatter.format(item[_fieldName]) + ")";
          } else if (dataType === "Boolean") {
            s = s + " (" + item[_fieldName].toString() + ")";
          } else if (dataType === "Float") {
            s = s + " (" + parseFloat(item[_fieldName]).toFixed(2) + ")";
          } else {
            s = s + " (" + item[_fieldName] + ")";
          }
        } else {
          if (dataType === "Date") {
            s = s + " " + dateToString(new Date(item[_fieldName]));
          } else if (dataType === "Currency") {
            s = s + " " + currencyFormatter.format(item[_fieldName]);
          } else if (dataType === "Boolean") {
            s = s + " " + item[_fieldName].toString();
          } else if (dataType === "Float") {
            s = s + " " + parseFloat(item[_fieldName]).toFixed(2);
          } else {
            //*special handling of country display field. Get long name from code e.g. ZA = South Africa
            if (_fieldName === "country") {
              const _country = countries.find((country) => {
                return country.value === item[_fieldName];
              }).label;
              s = s + " " + _country;
            } else {
              s = s + " " + item[_fieldName];
            }
          }
        }
      }
    });
    return s.includes("null") ? "" : s;
  }

  closeItemDetail = () => {
    this.setState({ itemDetailOpen: false });
  };

  openItemDetail = () => {
    this.setState({ itemDetailOpen: true });
  };

  renderItemDetail = (content, trigger) => {
    return <DisplayTableItemDetailPopup content={content} trigger={trigger} />;
  };

  renderItemColumns(columnsArray, item) {
    return columnsArray.map((col) => {
      if (col.interActiveCheckBox) {
        const icon =
          col.interActiveCheckBox.checkedValue ===
          this.stringifyDisplayValues(col.displayValues, col.type, item).trim()
            ? "check square outline"
            : "square outline";
        return (
          <div
            className={`${col.width} wide column`}
            onClick={() => col.interActiveCheckBox.onClick(item[this.props.idFieldName])}>
            <Icon size="large" name={icon} color="grey" />
          </div>
        );
      }
      if (item[col.detailField]) {
        let fieldData = null;
        if (col.convertMethod) {
          fieldData = col.convertMethod(item[col.detailField]);
        } else {
          fieldData = item[col.detailField];
        }
        return this.renderItemDetail(
          fieldData,
          <div className={`${col.width} wide column`}>
            <div
              style={{ cursor: "pointer", color: "blue", fontWeight: "bold" }}
              onClick={this.openItemDetail}>
              {this.stringifyDisplayValues(col.displayValues, col.type, item)}
            </div>
          </div>
        );
      } else {
        return (
          <div className={`${col.width} wide column`}>
            <div>{this.stringifyDisplayValues(col.displayValues, col.type, item)}</div>
          </div>
        );
      }
    });
  }

  render() {
    const {
      idFieldName,
      subIdFieldName,
      itemMenuActions,
      onSelectItem,
      batchItems,
      item,
      selectedItemId,
      onAddItemToBatch,
      itemNumber,
      primaryColumn,
      tableColumns,
      gridColumns,
      masterId,
      masterId2
    } = this.props;
    const isSelected =
      item.id === selectedItemId &&
      (item[subIdFieldName] ? item[subIdFieldName] : undefined) === masterId2;
    const icon = batchItems.find((batchItem) => batchItem === item)
      ? "check square outline"
      : "square outline";
    const nameColor = isSelected ? "blue" : "black";
    const nameStyle = isSelected ? "underline" : "";
    const nameWeight = isSelected ? "bold" : "";
    const primaryColClassName = primaryColumn.width + " wide column";
    return (
      <>
        <div
          style={{ fontFamily: "Raleway" }}
          className={`ui stackable ${gridColumns} column grid`}
          key={item.id}>
          <div className="two wide column">
            <div style={{ cursor: "pointer", fontWeight: "bold" }} className="header">
              <span onClick={() => onAddItemToBatch(item)}>
                <Icon name={icon} color="blue" size="large" />
              </span>
              <span style={{ color: "blue", marginLeft: "3em" }}>{itemNumber}</span>
            </div>
          </div>
          <div
            className={primaryColClassName}
            style={{
              color: nameColor,
              textDecorationLine: nameStyle,
              fontWeight: nameWeight,
              cursor: "pointer"
            }}
            onClick={() => onSelectItem(item[idFieldName], item[subIdFieldName])}>
            <span>
              {this.stringifyDisplayValues(primaryColumn.displayValues, primaryColumn.type, item)}
            </span>
          </div>
          {this.renderItemColumns(tableColumns, item)}
          <div className="one wide column">
            <ItemActionsMenu
              idFieldName={idFieldName}
              subIdFieldName={subIdFieldName}
              actions={itemMenuActions}
              onSelectItem={onSelectItem}
              itemId={item[idFieldName]}
              itemSubId={item[subIdFieldName]}
              masterId={masterId}
              masterId2={masterId2}></ItemActionsMenu>
          </div>
        </div>
      </>
    );
  }
}
export default displayTableItem;
