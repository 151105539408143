import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Button } from "semantic-ui-react";
import Select from "react-select";
import { selectOptionsFromData } from "../../utilities";
import "../components.css";
import { Link } from "react-router-dom";
import { toastr } from "react-redux-toastr";

import { resetSaveSuccess } from "../../actions";

class CreatePanelForm extends React.Component {
  renderError({ submitFailed, error }) {
    if (submitFailed && error) {
      return (
        <div className="ui error message">
          <div className="header">{error}</div>
        </div>
      );
    }
  }

  renderResult = () => {
    if (this.props.busySaving) {
      return (
        <div
          className="ui success message"
          style={{ marginTop: "1em" }}
        >
          Saving..
        </div>
      );
    } else if (this.props.saveError) {
      return (
        <div
          className="ui error message"
          style={{ marginTop: "1em" }}
        >
          {this.props.saveError}
        </div>
      );
    } else if (this.props.successMessage) {
      return (
        <div
          className="ui success message"
          style={{ marginTop: "1em" }}
        >
          {this.props.successMessage}
        </div>
      );
    }
  };

  renderInput = ({ input, id, style, meta, placeholder, readOnly, label }) => {
    const className = `field ${meta.error && meta.submitFailed ? "error" : ""}`;
    return (
      <div className={className}>
        <div className="formlabel">{label}</div>
        <div>
          <input
            {...input}
            id={id}
            style={style}
            placeholder={placeholder}
            readOnly={readOnly}
          ></input>
        </div>
        {this.renderError(meta, id)}
      </div>
    );
  };

  renderSelect = ({
    input,
    id,
    options,
    meta,
    display,
    search,
    placeholder,
    readOnly,
    label,
    isClearable,
  }) => {
    const className = `field ${meta.error && meta.submitFailed ? "error" : ""}`;
    return (
      <div
        className={className}
        style={{ marginBottom: "1em", display: display }}
      >
        <div className="formlabel">{label}</div>
        <Select
          {...input}
          fluid
          selection
          isSearchable={search}
          options={options}
          onChange={(value) => input.onChange(value)}
          onBlur={() => input.onBlur()}
          placeholder={placeholder}
          readOnly={readOnly}
          isClearable={isClearable}
          backspaceRemovesValue
        ></Select>
        {this.renderError(meta, id)}
      </div>
    );
  };

  onSubmit = (values) => {
    try {
      this.props.onSubmit(values);
      toastr.success("Success", "Model panel record saved.");
    } catch (error) {
      toastr.error("Error", error);
    }
  };

  render() {
    const { options, edit, supersedeOptions } = this.props;
    return (
      <div>
        <form
          className="ui form error"
          onSubmit={this.props.handleSubmit(this.onSubmit)}
        >
          <Field
            display={edit ? "none" : "inline"}
            name="part"
            id="part"
            component={this.renderSelect}
            options={selectOptionsFromData(options)}
            search
            placeholder="Select Part"
          ></Field>
          <Field
            style={edit ? { color: "silver" } : { display: "none" }}
            name="partDisplay"
            id="partDisplay"
            component={this.renderInput}
            readOnly={true}
          ></Field>
          <Field
            name="area"
            id="area"
            label="Area"
            component={this.renderInput}
            placeholder="Panel Size (SQ DM)"
          ></Field>
          <Field
            name="variant"
            id="variant"
            label="Variant Of"
            component={this.renderSelect}
            options={selectOptionsFromData(supersedeOptions)}
            isClearable={true}
            search
            placeholder="Select part this is a variant of"
          ></Field>
          <Field
            name="supersedes"
            id="supersedes"
            label="Replaced By"
            component={this.renderSelect}
            options={selectOptionsFromData(supersedeOptions)}
            isClearable={true}
            search
            placeholder="Select part to replace this part"
          ></Field>
          <Field
            name="exclude"
            id="exclude"
            component={this.renderInput}
            placeholder="Exclude"
            style={{ display: "none" }}
          ></Field>
          <div className="spacer-very-wide"></div>
          <div style={{ textAlign: "right" }}>
            <Button.Group>
              <Link to="/models">
                <Button
                  color="black"
                  type="button"
                >
                  Cancel
                </Button>
              </Link>
              <Button.Or />
              <Button primary>Save</Button>
            </Button.Group>
          </div>
        </form>
        <div className="content">{this.renderResult()}</div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  let panelData = null;
  //if editing set the default values.
  if (props.edit) {
    panelData = {
      selectedPanel: state.models.selectedModelPanel,
      initialValues: {
        exclude: state.models.selectedModelPanel.exclude,
        area: state.models.selectedModelPanel.area,
        part: {
          value: state.models.selectedModelPanel.id,
          label: state.models.selectedModelPanel.name,
        },
        variant: {
          value: state.models.selectedModelPanel.variant,
          label: state.models.selectedModelPanel.variantname,
        },
        supersedes: {
          value: state.models.selectedModelPanel.supersedes,
          label: state.models.selectedModelPanel.supersedename,
        },
        partDisplay: state.models.selectedModelPanel.name,
      },
    };
  }

  return {
    ...panelData,
    manufacturers: state.manufacturers.manufacturers,
    bodytypes: state.bodytypes.bodytypes,
    bodysubtypes: state.bodytypes.bodysubtypes,

    //api operations
    successMessage: state.api.saveSuccess,
    saveError: state.api.saveError,
    busySaving: state.api.busySaving,
  };
};

const validate = (formValues) => {
  const errors = {};
  if (!formValues.part) {
    //keys of object must match field names
    errors.part = "Please select a part";
  }
  if (!formValues.area) {
    errors.area = "Please enter the panel size in square decimeter";
  }
  if (formValues.variant && formValues.supersedes) {
    if (formValues.variant.value && formValues.supersedes.value) {
      errors.variant =
        "You cannot specify a part to supersede and replace. Please select either replace or supersede";
      errors.supersedes =
        "You cannot specify a part to supersede and replace. Please select either replace or supersede";
    }
  }
  return errors;
};

const formWrapped = reduxForm({
  form: "CreatePanelForm",
  enableReinitialize: true,
  validate: validate,
})(CreatePanelForm);

export default connect(mapStateToProps, {
  resetSaveSuccess,
})(formWrapped);
