import React from "react";
import Menu from "../components/Menu";
import ColorGroups from "../components/paint/ColorGroups";

const ColorGroupsView = () => {
  return (
    <>
      <Menu contentView={<ColorGroups />} />
    </>
  );
};

export default ColorGroupsView;
