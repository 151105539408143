import React from "react";
import Menu from "../components/Menu";
import CreateEffectForm from "../components/paint/CreateEffectForm";
import { compileEffectObject } from "../apis/Effects";
import {
  getEffect,
  getEffects,
  selectEffect,
  updateEffectsState,
  patchEffectInDb,
  updateAllEffectsState,
  getStages,
} from "../actions";
import { connect } from "react-redux";
import { replaceArrayObjectByKey } from "../utilities";

class editEffectView extends React.Component {
  onSubmit = (values, effectId) => {
    const {
      patchEffectInDb,
      effects,
      allEffects,
      updateEffectsState,
      updateAllEffectsState,
    } = this.props;
    const effectsData = compileEffectObject(values, effectId, true);
    patchEffectInDb(effectsData);
    //update Effect changes back to state
    selectEffect(effectsData);
    updateEffectsState(replaceArrayObjectByKey(effects, "id", effectsData));
    updateAllEffectsState(
      replaceArrayObjectByKey(allEffects, "id", effectsData)
    );
  };

  componentDidMount() {
    const { effectId } = this.props.match.params;
    if (effectId) {
      this.props.getEffect({ effectId });
    }
    if (this.props.allEffects.length === 0) {
      this.props.getEffects({});
    }
    this.props.getStages({});
    
  }

  render() {
    const { effectId } = this.props.match.params;
    return (
      <>
        <Menu
          contentView={
            <CreateEffectForm
              edit={true}
              onSubmit={this.onSubmit}
              effectId={effectId}
            />
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    effects: state.effects.effects,
    allEffects: state.effects.allEffects,
  };
};

export default connect(mapStateToProps, {
  getEffect,
  getEffects,
  selectEffect,
  updateEffectsState,
  updateAllEffectsState,
  patchEffectInDb,
  getStages,
})(editEffectView);
