import React from "react";
import Menu from "../components/Menu";
import CreatePartForm from "../components/vehicles/CreatePartForm";
import { compilePartObject } from "../apis/Parts";
import {
  getPart,
  getParts,
  selectPart,
  updatePartsState,
  patchPartInDb,
  updateAllPartsState,
} from "../actions";
import { connect } from "react-redux";
import { replaceArrayObjectByKey } from "../utilities";

class editPartView extends React.Component {
  onSubmit = (values, partId, partNumber) => {
    const {
      patchPartInDb,
      parts,
      allParts,
      updatePartsState,
      updateAllPartsState,
    } = this.props;
    const partsData = compilePartObject(values, partId, partNumber, true);
    patchPartInDb(partsData);
    //update Part changes back to state
    selectPart(partsData);
    updatePartsState(replaceArrayObjectByKey(parts, "id", partsData));
    updateAllPartsState(replaceArrayObjectByKey(allParts, "id", partsData));
  };

  componentDidMount() {
    const { partId } = this.props.match.params;
    if (partId) {
      this.props.getPart(partId);
    }
    if (this.props.allParts.length === 0) {
      this.props.getParts({});
    }
  }

  render() {
    const { partId } = this.props.match.params;
    return (
      <>
        <Menu
          contentView={
            <CreatePartForm
              edit={true}
              onSubmit={this.onSubmit}
              partId={partId}
              partNumber={this.props.part.number}
            />
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    parts: state.parts.parts,
    allParts: state.parts.allParts,
    part: state.parts.selectedPart,
  };
};

export default connect(mapStateToProps, {
  getPart,
  getParts,
  selectPart,
  updatePartsState,
  updateAllPartsState,
  patchPartInDb,
})(editPartView);
