import React from "react";
import Menu from "../components/Menu";
import CreateSORForm from "../components/repairs/CreateSORForm";
import { compileSORObject } from "../apis/SORs";
import {
  getSOR,
  getSORs,
  selectSOR,
  updateSORsState,
  patchSORInDb,
  updateAllSORsState,
  getStages,
} from "../actions";
import { connect } from "react-redux";
import { replaceArrayObjectByKey } from "../utilities";

class editSORView extends React.Component {
  onSubmit = (values, sorId) => {
    const {
      patchSORInDb,
      sors,
      allSORs,
      updateSORsState,
      updateAllSORsState,
    } = this.props;
    const sorsData = compileSORObject(values, sorId, true);
    patchSORInDb(sorsData);
    //update SOR changes back to state
    selectSOR(sorsData);
    updateSORsState(replaceArrayObjectByKey(sors, "id", sorsData));
    updateAllSORsState(
      replaceArrayObjectByKey(allSORs, "id", sorsData)
    );
  };

  componentDidMount() {
    const { sorId } = this.props.match.params;
    if (sorId) {
      this.props.getSOR({ sorId });
    }
    if (this.props.allSORs.length === 0) {
      this.props.getSORs({});
    }
    this.props.getStages({});
    
  }

  render() {
    const { sorId } = this.props.match.params;
    return (
      <>
        <Menu
          contentView={
            <CreateSORForm
              edit={true}
              onSubmit={this.onSubmit}
              sorId={sorId}
            />
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    sors: state.SORs.SORs,
    allSORs: state.SORs.allSORs,
  };
};

export default connect(mapStateToProps, {
  getSOR,
  getSORs,
  selectSOR,
  updateSORsState,
  updateAllSORsState,
  patchSORInDb,
  getStages,
})(editSORView);
