import { combineReducers } from "redux";
import authReducer from "./authReducer";
import { reducer as formReducer } from "redux-form";
import modelsReducer from "./modelsReducer";
import bodyTypesReducer from "./bodyReducer";
import manufacturersReducer from "./manufacturersReducer";
import locationsReducer from "./locationsReducer";
import apiReducer from "./apiReducer";
import partsReducer from "./partsReducer";
import tonersReducer from "./tonersReducer";
import consumablesReducer from "./consumablesReducer";
import activityConsumablesReducer from "./activityConsumablesReducer";
import stagesReducer from "./stagesReducer";
import SORsReducer from "./SORsReducer";
import labourAdjustmentsReducer from "./labourAdjustmentsReducer";
import colorGroupsReducer from "./colorGroupsReducer";
import paintColorsReducer from "./paintColorsReducer";
import effectsReducer from "./effectsReducer";
import setupCostsReducer from "./setupCostsReducer";
import referencePanelReducer from "./referencePanelReducer";
import fuelPricesReducer from "./fuelPricesReducer";
import primerPricesReducer from "./primerPricesReducer";
import fuelTypesReducer from "./fuelTypesReducer";
import settingsReducer from "./settingsReducer";
import { reducer as toastrReducer } from "react-redux-toastr";

export default combineReducers({
  toastr: toastrReducer, //for popup confirmation messages
  auth: authReducer,
  form: formReducer,
  models: modelsReducer,
  paintcolors: paintColorsReducer,
  bodytypes: bodyTypesReducer,
  manufacturers: manufacturersReducer,
  locations: locationsReducer,
  consumables: consumablesReducer,
  toners: tonersReducer,
  parts: partsReducer,
  api: apiReducer,
  activityConsumables: activityConsumablesReducer,
  labourAdjustments: labourAdjustmentsReducer,
  effects: effectsReducer,
  stages: stagesReducer,
  SORs: SORsReducer,
  colorgroups: colorGroupsReducer,
  fuelprices: fuelPricesReducer,
  primerprices: primerPricesReducer,
  fueltypes: fuelTypesReducer,
  setupcosts: setupCostsReducer,
  referencePanel: referencePanelReducer,
  settings: settingsReducer,
});
