import Axios from "axios";
import { env } from "./env";

export const Manufacturers = ({ all, id, name, manufacturerIds }) => {
  const _env = env();
  const baseURL = _env.BASEURL;
  const key = _env.API_KEY;

  if (all) {
    return Axios.create({
      baseURL: `${baseURL}/vehicles/manufacturers?all=1`,
      headers: { "x-api-key": key },
    });
  } else if (id) {
    return Axios.create({
      baseURL: `${baseURL}/vehicles/manufacturers/${id}`,
      headers: { "x-api-key": key },
    });
  } else if (name) {
    return Axios.create({
      baseURL: `${baseURL}/vehicles/manufacturers?name=${name}`,
      headers: { "x-api-key": key },
    });
  } else if (manufacturerIds) {
    return Axios.create({
      baseURL: `${baseURL}/vehicles/manufacturers?ids=${manufacturerIds}`,
      headers: { "x-api-key": key },
    });
  }

  return Axios.create({
    baseURL: `${baseURL}/vehicles/manufacturers`,
    headers: { "x-api-key": key },
  });
};

export const compileManufacturerObject = ({ name }, id, state) => {
  const manufacturer = {};
  manufacturer.id = id;
  manufacturer.name = name;
  manufacturer.l_name = name.toLowerCase();
  manufacturer.active = state;
  return manufacturer;
};
