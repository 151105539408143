import React from "react";
import Menu from "../components/Menu";
import { compileLocationObject } from "../apis/Locations";
import {
  getLocations,
  updateLocationsState,
  updateAllLocationsState,
  selectLocation,
  postLocationToDb,
} from "../actions";
import { connect } from "react-redux";
import CreateLocationForm from "../components/vehicles/CreateLocationForm";
import { nanoid } from "nanoid";
import { updateStateAfterCreate } from "../utilities";

class createLocationView extends React.Component {
  componentDidMount() {
    if (this.props.allLocations.length === 0) {
      this.props.getLocations({});
    }
  }

  onSubmit = (values, location) => {
    const {
      locations,
      allLocations,
      postLocationToDb,
      selectLocation,
      updateLocationsState,
      updateAllLocationsState,
    } = this.props;
    let locationData = compileLocationObject(
      values,
      location.id,
      true
    );
    postLocationToDb(locationData).then((result) => {
      locationData.id = result.data.id;
      locationData.createdat = result.data.createdat;
      locationData.updatedat = result.data.updatedat;
      updateStateAfterCreate(
        selectLocation,
        updateLocationsState,
        updateAllLocationsState,
        locations,
        allLocations,
        locationData
      );
    });
  };

  render() {
    return (
      <>
        <Menu
          contentView={
            <CreateLocationForm
              edit={false}
              onSubmit={this.onSubmit}
              locationId={nanoid()}
            />
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    locations: state.locations.locations,
    allLocations: state.locations.allLocations,
  };
};

export default connect(mapStateToProps, {
  getLocations,
  selectLocation,
  updateLocationsState,
  updateAllLocationsState,
  postLocationToDb,
})(createLocationView);
