import React from "react";
import Menu from "../components/Menu";
import { compilePartObject } from "../apis/Parts";
import {
  getParts,
  updatePartsState,
  updateAllPartsState,
  selectPart,
  postPartToDb,
} from "../actions";
import { connect } from "react-redux";
import CreatePartForm from "../components/vehicles/CreatePartForm";
import { updateStateAfterCreate } from "../utilities";
import _ from "lodash";
import { nanoid } from "nanoid";

class createPartView extends React.Component {
  componentDidMount() {
    if (this.props.allParts.length === 0) {
      this.props.getParts({});
    }
  }

  generatePartNumber = () => {
    const {parts} = this.props;
    return (_.map(parts, "number").reduce((a,b) => Math.max(a,b), -Infinity) + 1).toString();
  }

  onSubmit = (values, partId, partNumber) => {
    const {
      parts,
      allParts,
      postPartToDb,
      selectPart,
      updatePartsState,
      updateAllPartsState,
    } = this.props;
    const partData = compilePartObject(values, partId, partNumber, true);
    postPartToDb(partData).then((result) => {
      partData.id = result.data.id;
      updateStateAfterCreate(
        selectPart,
        updatePartsState,
        updateAllPartsState,
        parts,
        allParts,
        partData
      );
    });
  };

  render() {
    return (
      <>
        <Menu
          contentView={
            <CreatePartForm
              edit={false}
              onSubmit={this.onSubmit}
              partNumber={this.generatePartNumber()}
              partId={nanoid()}
            />
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    parts: state.parts.parts,
    allParts: state.parts.allParts,
  };
};

export default connect(mapStateToProps, {
  getParts,
  selectPart,
  updatePartsState,
  updateAllPartsState,
  postPartToDb,
})(createPartView);
