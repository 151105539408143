import React from "react";
import Menu from "../components/Menu";
import { compilePrimerPriceObject } from "../apis/PrimerPrices";
import {
  getPrimerPrices,
  updatePrimerPricesState,
  updateAllPrimerPricesState,
  selectPrimerPrice,
  postPrimerPriceToDb,
  getStages,
  getSuppliers,
  getPaintSystems,
} from "../actions";
import { connect } from "react-redux";
import CreatePrimerPriceForm from "../components/paint/CreatePrimerPriceForm";
import { nanoid } from "nanoid";
import { updateStateAfterCreate } from "../utilities";

class createPrimerPriceView extends React.Component {
  componentDidMount() {
    if (this.props.allPrimerPrices.length === 0) {
      this.props.getPrimerPrices({});
    }
    this.props.getStages({});
    this.props.getSuppliers({});
    this.props.getPaintSystems();
  }

  onSubmit = (values, primerPriceId) => {
    const {
      primerPrices,
      allPrimerPrices,
      postPrimerPriceToDb,
      selectPrimerPrice,
      updatePrimerPricesState,
      updateAllPrimerPricesState,
    } = this.props;
    const primerPriceData = compilePrimerPriceObject(values, primerPriceId, true);
    postPrimerPriceToDb(primerPriceData).then((result) => {
      primerPriceData.id = result.data.id;
      updateStateAfterCreate(
        selectPrimerPrice,
        updatePrimerPricesState,
        updateAllPrimerPricesState,
        primerPrices,
        allPrimerPrices,
        primerPriceData
      );
    });
  };

  render() {
    return (
      <>
        <Menu
          contentView={
            <CreatePrimerPriceForm
              edit={false}
              onSubmit={this.onSubmit}
              primerPriceId={nanoid()}
            />
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    primerPrices: state.primerprices.primerPrices,
    allPrimerPrices: state.primerprices.allPrimerPrices,
  };
};

export default connect(mapStateToProps, {
  getPrimerPrices,
  selectPrimerPrice,
  updatePrimerPricesState,
  updateAllPrimerPricesState,
  postPrimerPriceToDb,
  getStages,
  getSuppliers,
  getPaintSystems,
})(createPrimerPriceView);
