import {
  GET_TONERS,
  CLEAR_TONER,
  SET_TONER_FILTER,
  SET_TONER_FILTER_LABEL,
  SELECT_TONER,
  SELECT_TONERS,
  CLEAR_TONER_BATCH,
  REMOVE_TONER_FROM_BATCH,
  ADD_TONER_TO_BATCH,
  ADD_ALL_TONERS_TO_BATCH,
  UPDATE_TONERS,
  CLEAR_TONERS,
  DELETE_TONER,
  GET_SYSTEMS,
  GET_SUPPLIERS,
  SELECT_SUPPLIER,
  SET_TONER_SEARCH_TERM,
  GET_TONER_CONTAINER_METRICS,
  SET_TONER_CALCULATED_VOL,
  UPDATE_ALL_TONERS,
  CLONE_TONERS
} from "../actions/types";
import _ from "lodash";
import { removeObjectFromArray } from "../utilities";

const INITIAL_STATE = {
  toners: [],
  allToners: [],
  tonerSearchTerms: {
    filter: { value: "", label: "" },
    term: {
      value: "",
      label: ""
    }
  },
  selectedToner: {},
  tonersWithSelectedId: [],
  tonerBatch: [],
  suppliers: [],
  tonercontainermetrics: [],
  selectedSupplier: {}
};

const tonersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_TONERS:
      return {
        ...state,
        toners: [...action.payload].sort((a, b) => {
          return a.code.localeCompare(b.code) || b.startdate - a.startdate;
        })
      };
    case GET_SUPPLIERS:
      return {
        ...state,
        suppliers: action.payload
      };
    case SELECT_SUPPLIER:
      return {
        ...state,
        selectedSupplier: action.payload
      };
    case GET_TONER_CONTAINER_METRICS:
      return {
        ...state,
        tonercontainermetrics: action.payload
      };
    case SET_TONER_CALCULATED_VOL:
      return {
        ...state,
        tonercalculatedvolume: action.payload
      };
    case GET_SYSTEMS:
      return {
        ...state,
        systems: action.payload
      };
    case SET_TONER_FILTER:
      return {
        ...state,
        tonerSearchTerms: {
          ...state.tonerSearchTerms,
          filter: {
            ...state.tonerSearchTerms.filter,
            value: action.payload
          }
        }
      };
    case SET_TONER_FILTER_LABEL:
      return {
        ...state,
        tonerSearchTerms: {
          ...state.tonerSearchTerms,
          filter: {
            ...state.tonerSearchTerms.filter,
            label: action.payload
          }
        }
      };
    case SELECT_TONER:
      return {
        ...state,
        selectedToner: action.payload
      };
    case SELECT_TONERS:
      return {
        ...state,
        tonersWithSelectedId: action.payload
      };
    case CLEAR_TONER:
      return { ...state, selectedToner: {} };
    case CLEAR_TONERS:
      return { ...state, toners: [] };
    case CLEAR_TONER_BATCH:
      return {
        ...state,
        tonerBatch: []
      };
    case REMOVE_TONER_FROM_BATCH:
      return {
        ...state,
        //removes 2nd argument from array and returns new array
        tonerBatch: _.remove(state.tonerBatch, toner => toner !== action.payload)
      };
    case ADD_TONER_TO_BATCH:
      return {
        ...state,
        tonerBatch: [...state.tonerBatch, action.payload]
      };
    case ADD_ALL_TONERS_TO_BATCH:
      return {
        ...state,
        tonerBatch: action.payload
      };
    case UPDATE_TONERS:
      return {
        ...state,
        toners: action.payload
      };
    case UPDATE_ALL_TONERS:
      return {
        ...state,
        allToners: action.payload
      };
    case DELETE_TONER:
      return {
        ...state,
        toners: removeObjectFromArray(...state.toners, action.payload)
      };
    case SET_TONER_SEARCH_TERM:
      return {
        ...state,
        tonerSearchTerms: { ...state.tonerSearchTerms, term: action.payload }
      };
    case CLONE_TONERS:
      return {
        ...state,
        allToners: action.payload
      };
    default:
      return state;
  }
};

export default tonersReducer;
