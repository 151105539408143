import React from "react";
import Menu from "../components/Menu";
import Manufacturers from "../components/vehicles/Manufacturers";

const manufacturersView = () => {
  return (
    <>
      <Menu contentView={<Manufacturers />} />
    </>
  );
};

export default manufacturersView;
