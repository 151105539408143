import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Button, Input } from "semantic-ui-react";
import Select from "react-select";
import "../components.css";
import { Link } from "react-router-dom";
import _ from "lodash";
import { selectOptionsFromData } from "../../utilities";
import {
  getFuelPrice,
  resetSaveSuccess,
  updateSelectedFuelPriceState,
  updateFuelPricesState
} from "../../actions";
import { countries } from "../../defaults";

class CreateFuelPriceForm extends React.Component {
  renderError({ submitFailed, error }) {
    if (submitFailed && error) {
      return (
        <div className="ui error message">
          <div className="header">{error}</div>
        </div>
      );
    }
  }

  renderResult = () => {
    if (this.props.busySaving) {
      return (
        <div className="ui success message" style={{ marginTop: "1em" }}>
          Saving..
        </div>
      );
    } else if (this.props.saveError) {
      return (
        <div className="ui error message" style={{ marginTop: "1em" }}>
          {this.props.saveError}
        </div>
      );
    } else if (this.props.successMessage) {
      return (
        <div className="ui success message" style={{ marginTop: "1em" }}>
          {this.props.successMessage}
        </div>
      );
    }
  };

  renderInput = ({ input, id, style, meta, placeholder, disabled, label, autoFocus }) => {
    const className = `field ${meta.error && meta.submitFailed ? "error" : ""}`;
    return (
      <div className={className}>
        <div className="formlabel">{label}</div>
        <Input
          {...input}
          id={id}
          style={style}
          placeholder={placeholder}
          disabled={disabled}
          autoFocus={autoFocus}
          ref={
            autoFocus
              ? (input) => {
                  this.focusInput = input;
                }
              : input
          }></Input>
        {this.renderError(meta, id)}
      </div>
    );
  };

  renderSelect = ({ input, id, options, meta, style, search, placeholder, label, isDisabled }) => {
    const className = `field ${meta.error && meta.submitFailed ? "error" : ""}`;
    return (
      <div className={className} style={{ marginBottom: "1em" }}>
        <div className="formlabel">{label}</div>
        <Select
          {...input}
          fluid
          selection
          isSearchable={search}
          style={style}
          options={options}
          onChange={(value) => input.onChange(value)}
          onBlur={() => input.onBlur()}
          isDisabled={isDisabled}
          placeholder={placeholder}></Select> 
        {this.renderError(meta, id)}
      </div>
    );
  };

  onSubmit = (values) => {
    this.props.onSubmit(values, this.props.fuelPriceId);
    if (!this.props.edit) {
      this.props.reset();
    }
    setTimeout(() => {
      this.props.resetSaveSuccess();
    }, 2500);
  };

  render() {
    return (
      <div>
        <div className="content margin-bottom-small">{this.renderResult()}</div>
        <form className="ui form error" onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <Field
            name="fueltype"
            id="fueltype"
            label="Fuel Type"
            component={this.renderSelect}
            isDisabled={this.props.edit}
            options={selectOptionsFromData(this.props.fuelTypes)}
            placeholder="Select Fuel Type"
            autoFocus={true}></Field>
          <Field
            name="country"
            id="country"
            label="Country"
            component={this.renderSelect}
            isDisabled={this.props.edit}
            options={selectOptionsFromData(countries)}
            placeholder="Select Country"></Field>
          <Field
            name="price"
            id="price"
            label="Price"
            component={this.renderInput}
            placeholder="Enter price"></Field>

          <div className="spacer-very-wide"></div>

          <div style={{ textAlign: "right" }}>
            <Button.Group>
              <Link to="/fuelPrices">
                <Button color="black" type="button">
                  Cancel
                </Button>
              </Link>
              <Button.Or />
              <Button primary>Save</Button>
            </Button.Group>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  //if editing set the default values

  let fuelPriceData = {};

  if (props.edit) {
    fuelPriceData = {
      selectedFuelPrice: state.fuelprices.selectedFuelPrice,
      fuelTypes: state.fueltypes.fuelTypes,
      initialValues: {
        price: state.fuelprices.selectedFuelPrice.price,
        fueltype: {
          value: state.fuelprices.selectedFuelPrice.fueltypId,
          label: state.fuelprices.selectedFuelPrice.fueltypName
        },
        country: {
          value: state.fuelprices.selectedFuelPrice.country,
          label: countries.find((country) => {
            return country.value === state.fuelprices.selectedFuelPrice.country;
          }).label
        }
      }
    };
  } else {
    fuelPriceData = {
      fuelTypes: state.fueltypes.fuelTypes,
      allFuelPrices: state.fuelprices.allFuelPrices
    };
  }

  return {
    ...fuelPriceData,

    //api operations
    successMessage: state.api.saveSuccess,
    saveError: state.api.saveError,
    busySaving: state.api.busySaving
  };
};

const validate = (formValues, props) => {
  const errors = {};

  if (!formValues.fueltype) {
    //keys of object must match field names
    errors.fueltype = "Please select a fuel type.";
  }

  if (!formValues.country) {
    //keys of object must match field names
    errors.country = "Please select a country.";
  }

  if (!formValues.price || !typeof formValues.price === "number") {
    //keys of object must match field names
    errors.price = "Please enter a numeric value for the fuel price.";
  }

  if (formValues.price) {
    if (
      _.find(props.allFuelPrices, (fuelPrice) => {
        return (
          fuelPrice.country.toLowerCase() === formValues.country.value.toLowerCase() &&
          fuelPrice.fueltypId === formValues.fueltype.value
        );
      }) !== undefined
    ) {
      //keys of object must match field names
      errors.price = "Fuel price record for the specific fuel type and country already exists";
    }
  }

  return errors;
};

const formWrapped = reduxForm({
  form: "CreateFuelPriceForm",
  enableReinitialize: true,
  validate: validate
})(CreateFuelPriceForm);

export default connect(mapStateToProps, {
  getFuelPrice,
  resetSaveSuccess,
  updateSelectedFuelPriceState,
  updateFuelPricesState
})(formWrapped);
