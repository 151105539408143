import {
  GET_ACTIVITY_CONSUMABLES,
  SELECT_ACTIVITY_CONSUMABLE,
  CLEAR_ACTIVITY_CONSUMABLE,
  GET_ACTIVITY_CONSUMABLE,
  SET_ACTIVITY_CONSUMABLE_FILTER,
  SET_ACTIVITY_CONSUMABLE_FILTER_LABEL,
  CLEAR_ACTIVITY_CONSUMABLES,
  CLEAR_ACTIVITY_CONSUMABLE_BATCH,
  REMOVE_ACTIVITY_CONSUMABLE_FROM_BATCH,
  ADD_ACTIVITY_CONSUMABLE_TO_BATCH,
  UPDATE_ACTIVITY_CONSUMABLES,
  DELETE_ACTIVITY_CONSUMABLE,
  ADD_ALL_ACTIVITY_CONSUMABLES_TO_BATCH,
  CLONE_ACTIVITY_CONSUMABLES, //unique to activity consumables entity
} from "../actions/types";
import _ from "lodash";
import { removeObjectFromArray } from "../utilities";

const INITIAL_STATE = {
  activityConsumables: [],
  allActivityConsumables: [],
  activityConsumable: {},
  selectedActivityConsumable: {},
  activityConsumableSearchTerms: {
    filter: { value: "", label: "" },
    term: {
      value: "",
      label: "",
    },
  },
  activityConsumableBatch: [],
};

const activityConsumablesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ACTIVITY_CONSUMABLES:
      return {
        ...state,
        activityConsumables: action.payload,
      };
    case SET_ACTIVITY_CONSUMABLE_FILTER:
      return {
        ...state,
        activityConsumableSearchTerms: {
          ...state.activityConsumableSearchTerms,
          filter: {
            ...state.activityConsumableSearchTerms.filter,
            value: action.payload,
          },
        },
      };
    case SET_ACTIVITY_CONSUMABLE_FILTER_LABEL:
      return {
        ...state,
        activityConsumableSearchTerms: {
          ...state.activityConsumableSearchTerms,
          filter: {
            ...state.activityConsumableSearchTerms.filter,
            label: action.payload,
          },
        },
      };
    case SELECT_ACTIVITY_CONSUMABLE:
      return {
        ...state,
        selectedActivityConsumable: action.payload,
      };
    case GET_ACTIVITY_CONSUMABLE:
      return {
        ...state,
        activityConsumable: action.payload,
      };
    case CLEAR_ACTIVITY_CONSUMABLE:
      return { ...state, selectedConsumable: {} };
    case CLEAR_ACTIVITY_CONSUMABLES:
      return { ...state, activityConsumables: [] };
    case CLEAR_ACTIVITY_CONSUMABLE_BATCH:
      return {
        ...state,
        activityConsumableBatch: [],
      };
    case REMOVE_ACTIVITY_CONSUMABLE_FROM_BATCH:
      return {
        ...state,
        //removes 2nd argument from array and returns new array
        activityConsumableBatch: _.remove(
          state.activityConsumableBatch,
          (activityConsumable) => activityConsumable !== action.payload
        ),
      };
    case ADD_ACTIVITY_CONSUMABLE_TO_BATCH:
      return {
        ...state,
        activityConsumableBatch: [
          ...state.activityConsumableBatch,
          action.payload,
        ],
      };
    case ADD_ALL_ACTIVITY_CONSUMABLES_TO_BATCH:
      return {
        ...state,
        activityConsumableBatch: action.payload,
      };
    case UPDATE_ACTIVITY_CONSUMABLES:
      return {
        ...state,
        activityConsumables: action.payload,
      };
    case DELETE_ACTIVITY_CONSUMABLE:
      return {
        ...state,
        activityConsumables: removeObjectFromArray(
          ...state.activityConsumables,
          action.payload
        ),
      };
    case CLONE_ACTIVITY_CONSUMABLES:
      return {
        ...state,
        allActivityConsumables: action.payload,
      };
    default:
      return state;
  }
};

export default activityConsumablesReducer;
