import Axios from "axios";
import { env } from "./env";

export const Parts = ({ name, partIds, id }) => {
  const _env = env();
  const baseURL = _env.BASEURL;
  const key = _env.API_KEY;

  if (id) {
    return Axios.create({
      baseURL: `${baseURL}/vehicles/parts/${id}`,
      headers: { "x-api-key": key },
    });
  } else if (name) {
    return Axios.create({
      baseURL: `${baseURL}/vehicles/parts?name=${name}`,
      headers: { "x-api-key": key },
    });
  } else if (partIds) {
    return Axios.create({
      baseURL: `${baseURL}/vehicles/parts?ids=${partIds}`,
      headers: { "x-api-key": key },
    });
  }

  return Axios.create({
    baseURL: `${baseURL}/vehicles/parts`,
    headers: { "x-api-key": key },
  });
};

export const compilePartObject = (
  { labourcomplexity, materialcomplexity, name, hidden, accessory, positions, paintlocation },
  id,
  number,
  state
) => {
  const part = {};
  part.positions = [];
  part.id = id;
  part.name = name;
  part.l_name = name.toLowerCase();
  part.complexity = {};
  part.complexity.labour = parseFloat(labourcomplexity);
  part.complexity.material = parseFloat(materialcomplexity);
  part.number = number;
  part.paintlocation = paintlocation.value;
  part.hidden = typeof hidden.value === "boolean" ? hidden.value : hidden;
  part.accessory = typeof accessory.value === "boolean" ? accessory.value : accessory;
  part.active = state;
  if (positions) {
    //Only add if specified
    part.positions = positions.map((position) => {
      return position.value ? position.value : position;
    });
  }
    return part;
};
