const _env = "prod";

const _variables = {};

export const env = () => {
  _variables.ENV = _env;
  if (_env === "uat") {
    _variables.BASEURL = process.env.REACT_APP_BASEURL_UAT;
    _variables.API_KEY = process.env.REACT_APP_DEV_API_KEY;
    _variables.COGNITO_APP_CLIENT_ID =
      process.env.REACT_APP_COGNITO_APP_CLIENT_ID_UAT;
    _variables.COGNITO_USER_POOL_ID =
      process.env.REACT_APP_COGNITO_USER_POOL_ID_UAT;
  } else if (_env === "prod") {
    _variables.BASEURL = process.env.REACT_APP_BASEURL_PROD;
    _variables.BASEURL_X = process.env.REACT_APP_BASEURL_X_PROD;
    _variables.API_KEY = process.env.REACT_APP_PROD_API_KEY;
    _variables.API_X_KEY = process.env.REACT_APP_PROD_API_X_KEY;
    _variables.COGNITO_APP_CLIENT_ID =
      process.env.REACT_APP_COGNITO_APP_CLIENT_ID_PROD;
    _variables.COGNITO_USER_POOL_ID =
      process.env.REACT_APP_COGNITO_USER_POOL_ID_PROD;
  }
  _variables.TTL_MINS = process.env.REACT_APP_TTL_MINS;
  _variables.DIRECT_AFTER_LOGIN = process.env.REACT_APP_DIRECT_AFTER_LOGIN;
  _variables.RECORD_SET_SIZE = process.env.REACT_APP_RECORD_SET_SIZE;
  _variables.REDIRECT_TIMEOUT = process.env.REACT_APP_REDIRECT_TIMEOUT;
  _variables.VERSION = process.env.REACT_APP_VERSION;
  return _variables;
};
