import {
  GET_CONSUMABLES,
  SELECT_CONSUMABLE,
  CLEAR_CONSUMABLE,
  GET_CONSUMABLE,
  SET_CONSUMABLE_FILTER,
  SET_CONSUMABLE_FILTER_LABEL,
  CLEAR_CONSUMABLES,
  CLEAR_CONSUMABLE_BATCH,
  REMOVE_CONSUMABLE_FROM_BATCH,
  ADD_CONSUMABLE_TO_BATCH,
  UPDATE_CONSUMABLES,
  UPDATE_ALL_CONSUMABLES,
  DELETE_CONSUMABLE,
  ADD_ALL_CONSUMABLES_TO_BATCH,
  CLONE_CONSUMABLES,
} from "../actions/types";
import _ from "lodash";
import { removeObjectFromArray } from "../utilities";

const INITIAL_STATE = {
  consumables: [],
  allConsumables: [],
  consumable: {},
  selectedConsumable: {},
  consumableSearchTerms: {
    filter: { value: "", label: "" },
    term: {
      value: "",
      label: "",
    },
  },
  consumableBatch: [],
};

const consumablesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CONSUMABLES:
      return {
        ...state,
        consumables: action.payload,
      };
    case SET_CONSUMABLE_FILTER:
      return {
        ...state,
        consumableSearchTerms: {
          ...state.consumableSearchTerms,
          filter: {
            ...state.consumableSearchTerms.filter,
            value: action.payload,
          },
        },
      };
    case SET_CONSUMABLE_FILTER_LABEL:
      return {
        ...state,
        consumableSearchTerms: {
          ...state.consumableSearchTerms,
          filter: {
            ...state.consumableSearchTerms.filter,
            label: action.payload,
          },
        },
      };
    case SELECT_CONSUMABLE:
      return {
        ...state,
        selectedConsumable: action.payload,
      };
    case GET_CONSUMABLE:
      return {
        ...state,
        consumable: action.payload,
      };
    case CLEAR_CONSUMABLE:
      return { ...state, selectedConsumable: {} };
    case CLEAR_CONSUMABLES:
      return { ...state, consumables: [] };
    case CLEAR_CONSUMABLE_BATCH:
      return {
        ...state,
        consumableBatch: [],
      };
    case REMOVE_CONSUMABLE_FROM_BATCH:
      return {
        ...state,
        //removes 2nd argument from array and returns new array
        consumableBatch: _.remove(
          state.consumableBatch,
          (consumable) => consumable !== action.payload
        ),
      };
    case ADD_CONSUMABLE_TO_BATCH:
      return {
        ...state,
        consumableBatch: [...state.consumableBatch, action.payload],
      };
    case ADD_ALL_CONSUMABLES_TO_BATCH:
      return {
        ...state,
        consumableBatch: action.payload,
      };
    case UPDATE_CONSUMABLES:
      return {
        ...state,
        consumables: action.payload,
      };
    case UPDATE_ALL_CONSUMABLES:
      return {
        ...state,
        allConsumables: action.payload,
      };
    case DELETE_CONSUMABLE:
      return {
        ...state,
        consumables: removeObjectFromArray(...state.consumables, action.payload),
      };
    case CLONE_CONSUMABLES:
      return {
        ...state,
        allConsumables: action.payload,
      };
    default:
      return state;
  }
};

export default consumablesReducer;
