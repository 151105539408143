import Axios from "axios";
import { env } from "./env";

export const Toners = ({
  all,
  skey,
  id,
  deletekey,
  deleteKeys,
  deleteKey,
  startdate,
  current,
  name,
  supplierId,
  tonerIds,
  updatePrice,
  priceStartDate,
  keyget,
  systemId,
}) => {
  const _env = env();
  const baseURL = _env.BASEURL;
  const key = _env.API_KEY;

  if (all) {
    return Axios.create({
      baseURL: `${baseURL}/paint/toners?all=1`,
      headers: { "x-api-key": key },
    });
  } else if (id) {
    if (current === 1) {
      return Axios.create({
        baseURL: `${baseURL}/paint/toners/${id}?current=1`,
        headers: { "x-api-key": key },
      });
    } else {
      return Axios.create({
        baseURL: `${baseURL}/paint/toners/${id}`,
        headers: { "x-api-key": key },
      });
    }
  } else if (skey) {
    if (startdate) {
      if (deletekey) {
        return Axios.create({
          baseURL: `${baseURL}/paint/toners/updateprice/${skey}?startdate=${startdate}&deletekey=${deletekey}`,
          headers: { "x-api-key": key },
        });
      } else {
        return Axios.create({
          baseURL: `${baseURL}/paint/toners/updateprice/${skey}?startdate=${startdate}`,
          headers: { "x-api-key": key },
        });
      }
    }
    if (keyget) {
      return Axios.create({
        baseURL: `${baseURL}/paint/toners/getByKey/${skey}`,
        headers: { "x-api-key": key },
      });
    } else {
      return Axios.create({
        baseURL: `${baseURL}/paint/toners/${skey}`,
        headers: { "x-api-key": key },
      });
    }
  } else if (name) {
    if (current) {
      return Axios.create({
        baseURL: `${baseURL}/paint/toners?supplierId=${supplierId}&name=${name}&current=1`,
        headers: { "x-api-key": key },
      });
    } else {
      return Axios.create({
        baseURL: `${baseURL}/paint/toners?supplierId=${supplierId}&name=${name}`,
        headers: { "x-api-key": key },
      });
    }
  } else if (supplierId) {
    if (current) {
      if (systemId) {
        return Axios.create({
          baseURL: `${baseURL}/paint/toners?supplierId=${supplierId}&systemId=${systemId}&current=1`,
          headers: { "x-api-key": key },
        });
      } else {
        return Axios.create({
          baseURL: `${baseURL}/paint/toners?supplierId=${supplierId}&current=1`,
          headers: { "x-api-key": key },
        });
      }
    } else {
      if (systemId) {
        return Axios.create({
          baseURL: `${baseURL}/paint/toners?supplierId=${supplierId}&systemId=${systemId}`,
          headers: { "x-api-key": key },
        });
      } else {
        return Axios.create({
          baseURL: `${baseURL}/paint/toners?supplierId=${supplierId}`,
          headers: { "x-api-key": key },
        });
      }
    }
  } else if (tonerIds) {
    return Axios.create({
      baseURL: `${baseURL}/paint/toners?ids=${tonerIds}`,
      headers: { "x-api-key": key },
    });
  } else if (updatePrice) {
    return Axios.create({
      baseURL: `${baseURL}/paint/toners/updateprice/${id}?startdate=${priceStartDate}`,
      headers: { "x-api-key": key },
    });
  } else if (deleteKeys) {
    return Axios.create({
      baseURL: `${baseURL}/paint/toners/delete?tonerKeys=${deleteKeys}`,
      headers: { "x-api-key": key },
    });
  } else if (deleteKey) {
    return Axios.create({
      baseURL: `${baseURL}/paint/toners/deleteByKey/${deleteKey}`,
      headers: { "x-api-key": key },
    });
  }

  return Axios.create({
    baseURL: `${baseURL}/paint/toners`,
    headers: { "x-api-key": key },
  });
};

export const compileDataForSave = (
  {
    name,
    code,
    weight,
    containervol,
    containerweight,
    discount,
    discountperc,
    price,
    supplierid,
    systemId,
    system, //system can be object from dropdown
    wastage,
    nettweight,
    cost,
    costpergram,
    suppliername,
  },
  { id, skey, state, usedByColorCount, usedByColors, startdate, enddate }
) => {
  const toner = {};
  toner.id = id;
  toner.skey = skey ? skey : id;
  toner.name = name;
  toner.code = code;
  toner.l_name = code.toLowerCase() + " - " + name.toLowerCase();
  toner.weight = weight;
  toner.containervol = containervol;
  toner.containerweight = containerweight;
  toner.wastage = wastage ? wastage : "0";
  toner.nettweight = nettweight;
  toner.price = price;
  toner.discountperc = discountperc ? discountperc : "0";
  toner.discount = discount;
  toner.cost = cost;
  toner.costpergram = costpergram;
  toner.supplierId = supplierid;
  toner.usedByColorCount = usedByColorCount;
  toner.usedByColors = usedByColors;
  toner.systemId = system.value ? system.value : systemId;
  toner.systemName = system.label ? system.label : "";
  toner.supplierName = suppliername;
  toner.startdate = startdate;
  if (enddate) {
    toner.enddate = enddate;
  }
  toner.active = state;
  return toner;
};
