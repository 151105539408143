import React from "react";
import Menu from "../components/Menu";
import { compileSORObject } from "../apis/SORs";
import {
  getSORs,
  getStages,
  updateSORsState,
  updateAllSORsState,
  selectSOR,
  postSORToDb,
} from "../actions";
import { connect } from "react-redux";
import CreateSORForm from "../components/repairs/CreateSORForm";
import { updateStateAfterCreate } from "../utilities";
import { nanoid } from "nanoid";

class createSORView extends React.Component {
  componentDidMount() {
    if (this.props.allSORs.length === 0) {
      this.props.getSORs({});
    }
    this.props.getStages({}); //for select on form
  }

  onSubmit = (values, sorId) => {
    const {
      sors,
      allSORs,
      postSORToDb,
      selectSOR,
      updateSORsState,
      updateAllSORsState,
    } = this.props;
    const sorData = compileSORObject(values, sorId, true);
    postSORToDb(sorData).then((result) => {
      sorData.id = result.data.id;
      updateStateAfterCreate(
        selectSOR,
        updateSORsState,
        updateAllSORsState,
        sors,
        allSORs,
        sorData
      );
    });
  };

  render() {
    return (
      <>
        <Menu
          contentView={
            <CreateSORForm
              edit={false}
              onSubmit={this.onSubmit}
              sorId={nanoid()}
            />
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    sors: state.SORs.SORs,
    allSORs: state.SORs.allSORs,
  };
};

export default connect(mapStateToProps, {
  getSORs,
  getStages,
  selectSOR,
  updateSORsState,
  updateAllSORsState,
  postSORToDb,
})(createSORView);
