import Axios from "axios";
import { env } from "./env";

export const SORs = ({ id }) => {
  const _env = env();
  const baseURL = _env.BASEURL;
  const key = _env.API_KEY;

  if (id) {
    return Axios.create({
      baseURL: `${baseURL}/repairs/SOR/${id}`,
      headers: { "x-api-key": key },
    });
  }

  return Axios.create({
    baseURL: `${baseURL}/repairs/SOR`,
    headers: { "x-api-key": key },
  });
};

export const compileSORObject = (
  { name, insidepaintqtyfactor, stages },
  id,
  state
) => {
  const SOR = {};
  SOR.stages = [];
  SOR.id = id;
  SOR.name = name;
  SOR.l_name = name.toLowerCase();
  SOR.insidepaintqtyfactor = parseFloat(insidepaintqtyfactor);
  SOR.active = state;
  if (stages) {
    //Only add if specified
    SOR.stages = stages.map((stage) => {
      return stage.value ? stage.value : stage;
    });
    SOR.stagesDisplay = stages.map((stage) => {
      return { id: stage.value, name: stage.label };
    });
  }
  return SOR;
};
