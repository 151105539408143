import {
    GET_FUELPRICES,
    SELECT_FUELPRICE,
    CLEAR_FUELPRICE,
    GET_FUELPRICE,
    SET_FUELPRICE_FILTER,
    SET_FUELPRICE_FILTER_LABEL,
    CLEAR_FUELPRICES,
    CLEAR_FUELPRICE_BATCH,
    REMOVE_FUELPRICE_FROM_BATCH,
    ADD_FUELPRICE_TO_BATCH,
    UPDATE_FUELPRICES,
    DELETE_FUELPRICE,
    ADD_ALL_FUELPRICES_TO_BATCH,
    CLONE_FUELPRICES,
  } from "../actions/types";
  import _ from "lodash";
  import { removeObjectFromArray } from "../utilities";
  
  const INITIAL_STATE = {
    fuelPrices: [],
    allFuelPrices: [],
    fuelPrice: {},
    selectedFuelPrice: {},
    fuelPriceSearchTerms: {
      filter: { value: "", label: "" },
      term: {
        value: "",
        label: "",
      },
    },
    fuelPriceBatch: [],
  };
  
  const fuelPricesReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case GET_FUELPRICES:
        return {
          ...state,
          fuelPrices: action.payload,
        };
      case SET_FUELPRICE_FILTER:
        return {
          ...state,
          fuelPriceSearchTerms: {
            ...state.fuelPriceSearchTerms,
            filter: {
              ...state.fuelPriceSearchTerms.filter,
              value: action.payload,
            },
          },
        };
      case SET_FUELPRICE_FILTER_LABEL:
        return {
          ...state,
          fuelPriceSearchTerms: {
            ...state.fuelPriceSearchTerms,
            filter: {
              ...state.fuelPriceSearchTerms.filter,
              label: action.payload,
            },
          },
        };
      case SELECT_FUELPRICE:
        return {
          ...state,
          selectedFuelPrice: action.payload,
        };
      case GET_FUELPRICE:
        return {
          ...state,
          fuelPrice: action.payload,
        };
      case CLEAR_FUELPRICE:
        return { ...state, selectedFuelPrice: {} };
      case CLEAR_FUELPRICES:
        return { ...state, fuelPrices: [] };
      case CLEAR_FUELPRICE_BATCH:
        return {
          ...state,
          fuelPriceBatch: [],
        };
      case REMOVE_FUELPRICE_FROM_BATCH:
        return {
          ...state,
          //removes 2nd argument from array and returns new array
          fuelPriceBatch: _.remove(
            state.fuelPriceBatch,
            (fuelprice) => fuelprice !== action.payload
          ),
        };
      case ADD_FUELPRICE_TO_BATCH:
        return {
          ...state,
          fuelPriceBatch: [...state.fuelPriceBatch, action.payload],
        };
      case ADD_ALL_FUELPRICES_TO_BATCH:
        return {
          ...state,
          fuelPriceBatch: action.payload,
        };
      case UPDATE_FUELPRICES:
        return {
          ...state,
          fuelPrices: action.payload,
        };
      case DELETE_FUELPRICE:
        return {
          ...state,
          fuelPrices: removeObjectFromArray(
            ...state.fuelPrices,
            action.payload
          ),
        };
      case CLONE_FUELPRICES:
        return {
          ...state,
          allFuelPrices: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default fuelPricesReducer;
  