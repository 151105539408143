import {
  GET_LABOUR_ADJUSTMENTS,
  SELECT_LABOUR_ADJUSTMENT,
  CLEAR_LABOUR_ADJUSTMENT,
  GET_LABOUR_ADJUSTMENT,
  SET_LABOUR_ADJUSTMENT_FILTER,
  SET_LABOUR_ADJUSTMENT_FILTER_LABEL,
  CLEAR_LABOUR_ADJUSTMENTS,
  CLEAR_LABOUR_ADJUSTMENT_BATCH,
  REMOVE_LABOUR_ADJUSTMENT_FROM_BATCH,
  ADD_LABOUR_ADJUSTMENT_TO_BATCH,
  UPDATE_LABOUR_ADJUSTMENTS,
  DELETE_LABOUR_ADJUSTMENT,
  ADD_ALL_LABOUR_ADJUSTMENTS_TO_BATCH,
  CLONE_LABOUR_ADJUSTMENTS, //unique to activity consumables entity
} from "../actions/types";
import _ from "lodash";
import { removeObjectFromArray } from "../utilities";

const INITIAL_STATE = {
  labourAdjustments: [],
  allLabourAdjustments: [],
  labourAdjustment: {},
  selectedLabourAdjustment: {},
  labourAdjustmentSearchTerms: {
    filter: { value: "", label: "" },
    term: {
      value: "",
      label: "",
    },
  },
  labourAdjustmentBatch: [],
};

const labourAdjustmentsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_LABOUR_ADJUSTMENTS:
      return {
        ...state,
        labourAdjustments: action.payload,
      };
    case SET_LABOUR_ADJUSTMENT_FILTER:
      return {
        ...state,
        labourAdjustmentSearchTerms: {
          ...state.labourAdjustmentSearchTerms,
          filter: {
            ...state.labourAdjustmentSearchTerms.filter,
            value: action.payload,
          },
        },
      };
    case SET_LABOUR_ADJUSTMENT_FILTER_LABEL:
      return {
        ...state,
        labourAdjustmentSearchTerms: {
          ...state.labourAdjustmentSearchTerms,
          filter: {
            ...state.labourAdjustmentSearchTerms.filter,
            label: action.payload,
          },
        },
      };
    case SELECT_LABOUR_ADJUSTMENT:
      return {
        ...state,
        selectedLabourAdjustment: action.payload,
      };
    case GET_LABOUR_ADJUSTMENT:
      return {
        ...state,
        labourAdjustment: action.payload,
      };
    case CLEAR_LABOUR_ADJUSTMENT:
      return { ...state, selectedConsumable: {} };
    case CLEAR_LABOUR_ADJUSTMENTS:
      return { ...state, labourAdjustments: [] };
    case CLEAR_LABOUR_ADJUSTMENT_BATCH:
      return {
        ...state,
        labourAdjustmentBatch: [],
      };
    case REMOVE_LABOUR_ADJUSTMENT_FROM_BATCH:
      return {
        ...state,
        //removes 2nd argument from array and returns new array
        labourAdjustmentBatch: _.remove(
          state.labourAdjustmentBatch,
          (labourAdjustment) => labourAdjustment !== action.payload
        ),
      };
    case ADD_LABOUR_ADJUSTMENT_TO_BATCH:
      return {
        ...state,
        labourAdjustmentBatch: [...state.labourAdjustmentBatch, action.payload],
      };
    case ADD_ALL_LABOUR_ADJUSTMENTS_TO_BATCH:
      return {
        ...state,
        labourAdjustmentBatch: action.payload,
      };
    case UPDATE_LABOUR_ADJUSTMENTS:
      return {
        ...state,
        labourAdjustments: action.payload,
      };
    case DELETE_LABOUR_ADJUSTMENT:
      return {
        ...state,
        labourAdjustments: removeObjectFromArray(
          ...state.labourAdjustments,
          action.payload
        ),
      };
    case CLONE_LABOUR_ADJUSTMENTS:
      return {
        ...state,
        allLabourAdjustments: action.payload,
      };
    default:
      return state;
  }
};

export default labourAdjustmentsReducer;
