import React from "react";
import Menu from "../components/Menu";
import { compileColorTonerObject } from "../apis/PaintColors";
import {
  selectColorToner,
  getPaintColor,
  getPaintColors,
  selectPaintColor,
  updatePaintColorsState,
  updateAllPaintColorsState,
  patchPaintColorInDb,
  getToners,
  getEffect,
  getStages,
  setPaintColorSearchTerm,
} from "../actions";
import { connect } from "react-redux";
import CreateColorTonerForm from "../components/paint/CreateColorTonerForm";
import { createDeltaArrayByKey, replaceArrayObjectByKey } from "../utilities";

class editColorTonerView extends React.Component {
  onSubmit = (values) => {
    const replaceColorTonerWithUpdated = (colorToners, updatedColorToner) => {
      return colorToners.map((toner) => {
        return toner.id === updatedColorToner.id && toner.stageId === updatedColorToner.stageId
          ? updatedColorToner
          : toner;
      });
    };

    const {
      selectedPaintColor,
      patchPaintColorInDb,
      updatePaintColorsState,
      selectPaintColor,
      selectColorToner,
      allPaintColors,
      paintColors,
    } = this.props;
    let updatedColorToner = compileColorTonerObject(values);
    let _paintColor = { ...selectedPaintColor };
    let _colorToners = replaceColorTonerWithUpdated(_paintColor.toners, updatedColorToner);
    let _allColorToners = replaceColorTonerWithUpdated(_paintColor.allToners, updatedColorToner);
  
    _paintColor.toners = _colorToners;
    _paintColor.allToners = _allColorToners;

    patchPaintColorInDb(_paintColor);

    const _toner = this.props.toners.find((toner) => {return toner.id === values.toner.value});
    let i = _colorToners.findIndex((toner) => {return toner.id === _toner.id});
    const _newCost = parseFloat(( parseFloat(_toner.cost) / parseFloat(_toner.nettweight) * parseFloat(values.weight)).toFixed(2));
    _colorToners[i].cost = _newCost;
    i = _allColorToners.findIndex((toner) => {return toner.id === _toner.id});
    _allColorToners[i].cost = _newCost;
   
    _paintColor.toners = _colorToners;
    _paintColor.allToners = _allColorToners;

    
    //recalculate the paint total weight and cost
    let colorTotalTonerWeight = 0.0;
    let colorTotalTonerCost = 0.0;
    _allColorToners.forEach(toner => {
      colorTotalTonerWeight += toner.weight;
      colorTotalTonerCost += toner.cost;
    });
    _paintColor.cost = colorTotalTonerCost.toFixed(2);
    _paintColor.weight = colorTotalTonerWeight.toFixed(2)
    //_paintColor.toners = _colorToners;
      
    //update Paint Color changes back to state
    selectPaintColor(_paintColor);
    selectColorToner(updatedColorToner);
    updatePaintColorsState(replaceArrayObjectByKey(paintColors, "id", _paintColor));
    updateAllPaintColorsState(replaceArrayObjectByKey(allPaintColors, "id", _paintColor));
  };

  componentDidMount() {
    const { paintcolorId, colortonerId, stageId } = this.props.match.params;
    const {
      setPaintColorSearchTerm,
      allPaintColors,
      getPaintColors,
      getPaintColor,
      selectColorToner,
      getEffect,
      getToners,
    } = this.props;
    if (paintcolorId && !this.props.selectedPaintColor.id) {
      getPaintColor({ colorId: paintcolorId }).then(() => {
        //reload paint colors on refresh
        if (allPaintColors.length === 0) {
          getPaintColors({
            manufacturerid: this.props.selectedPaintColor.manufacturerId,
          });
          //set the searchterms to selected paintcolor manufacturer (for main form)
          setPaintColorSearchTerm({
            value: this.props.selectedPaintColor.manufacturerId,
            label: this.props.selectedPaintColor.manufacturerName,
          });

          selectColorToner(
            this.props.selectedPaintColor.toners.find((toner) => {
              return toner.id === colortonerId && toner.stageId === stageId;
            })
          );
          //for stage dropdown on color toner form. We only want the stages linked to the effect
          //to be selectable
          getEffect({
            effectId: this.props.selectedPaintColor.effectId,
          });
          getToners({ supplierId: this.props.selectedPaintColor.supplierId, current: 1 });
        }
      });
    } else {
      getToners({ supplierId: this.props.selectedPaintColor.supplierId, current: 1 });
      getEffect({
        effectId: this.props.selectedPaintColor.effectId,
      });
    }
  }
  removeAlreadySelectedToners = (stageId) => {
    const _toners = createDeltaArrayByKey(
      this.props.toners,
      "id",
      this.props.selectedToners.filter((toner) => {
        return toner.stageId === stageId;
      })
    );
    
    return _toners;
    
  };

  render() {
    return (
      <>
        <Menu
          contentView={
            <CreateColorTonerForm
              edit={true}
              onStageSelect={this.removeAlreadySelectedToners}
              toners={this.props.toners}
              onSubmit={this.onSubmit}
            />
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedPaintColor: state.paintcolors.selectedPaintColor,
    selectedToners: state.paintcolors.selectedPaintColor.toners,
    selectedColorToner: state.paintcolors.selectedColorToner,
    toners: state.toners.toners,
    paintColors: state.paintcolors.paintColors,
    allPaintColors: state.paintcolors.allPaintColors,
    stages: state.effects.selectedEffect.stages,
  };
};

export default connect(mapStateToProps, {
  getToners,
  getStages,
  getEffect,
  selectPaintColor,
  updatePaintColorsState,
  updateAllPaintColorsState,
  selectColorToner,
  getPaintColor,
  patchPaintColorInDb,
  getPaintColors,
  setPaintColorSearchTerm,
})(editColorTonerView);
