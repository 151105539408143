import React from "react";
import Menu from "../components/Menu";
import CreateLocationForm from "../components/vehicles/CreateLocationForm";
import { compileLocationObject } from "../apis/Locations";
import {
  getLocation,
  getLocations,
  selectLocation,
  updateLocationsState,
  updateAllLocationsState,
  patchLocationInDb
} from "../actions";
import { updateStateAfterEdit } from "../utilities";
import { connect } from "react-redux";
import { Fragment } from "react";

class editLocationView extends React.Component {
  onSubmit = (values, locationId) => {
    const {
      patchLocationInDb,
      selectLocation,
      locations,
      allLocations,
      updateLocationsState,
      updateAllLocationsState
    } = this.props;
    const locationsData = compileLocationObject(
      values,
      locationId,
      true
    );
    patchLocationInDb(locationsData).then(() => {
      //update PaintColor changes back to state
      updateStateAfterEdit(
        selectLocation,
        updateLocationsState,
        updateAllLocationsState,
        locations,
        allLocations,
        locationsData,
        "id"
      );
    });
  };

  componentDidMount() {
    const { locationId } = this.props.match.params;
    if (locationId) {
      this.props.getLocation(locationId);
    }
    if (this.props.allLocations.length === 0) {
      this.props.getLocations({});
    }
  }

  render() {
    const { locationId } = this.props.match.params;
    return (
      <Fragment>
        <Menu
          contentView={
            <CreateLocationForm
              edit={true}
              onSubmit={this.onSubmit}
              locationId={locationId}
            />
          }
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    locations: state.locations.locations,
    allLocations: state.locations.allLocations
  };
};

export default connect(mapStateToProps, {
  getLocation,
  getLocations,
  selectLocation,
  updateLocationsState,
  updateAllLocationsState,
  patchLocationInDb
})(editLocationView);
