import Axios from "axios";
import { env } from "./env";

export const FuelTypes = () => {
  const _env = env();
  const baseURL = _env.BASEURL_X;
  const key = _env.API_X_KEY;

  return Axios.create({ 
    baseURL: `${baseURL}/repairs/fueltypes`,
    headers: { "x-api-key": key }
  });
};
