import React from "react";
import Menu from "../components/Menu";
import { compileReferenceQuantity } from "../apis/ReferencePanel";
import {
  patchReferencePanelInDb,
  selectReferenceQuantity,
  updateReferencePanelState,
  updateReferenceQuantitiesState,
  updateAllReferenceQuantitiesState,
  getReferencePanel,
  getSORs,
  getStages,
} from "../actions";
import { connect } from "react-redux";
import CreateRefPanelQtyForm from "../components/repairs/CreateRefPanelQtyForm";
import { replaceArrayObjectByKey } from "../utilities";

class editModelPanelView extends React.Component {
  onSubmit = (values) => {
    const {
      patchReferencePanelInDb,
      selectReferenceQuantity,
      updateReferencePanelState,
      updateReferenceQuantitiesState,
      updateAllReferenceQuantitiesState,
      referencePanel
    } = this.props;
    let updatedQuantity = compileReferenceQuantity(values);
    //set surrogate key
    updatedQuantity.id = updatedQuantity.scope.toString() + "-" + updatedQuantity.stage.toString();
    let _refPanel = { ...referencePanel };
    const _refPanelQuantities = replaceArrayObjectByKey(
      _refPanel.quantities,
      "id",
      updatedQuantity
    );
    _refPanel.quantities = _refPanelQuantities;
    patchReferencePanelInDb(_refPanel);
    //update Reference Panel changes back to state
    selectReferenceQuantity(updatedQuantity);
    updateReferencePanelState(_refPanel);
    updateReferenceQuantitiesState(_refPanelQuantities);
    updateAllReferenceQuantitiesState(_refPanelQuantities);
  };

  componentDidMount() {
    this.props.getReferencePanel();
    this.props.getSORs({});
    this.props.getStages({});
  }

  render() {
    return (
      <>
        <Menu
          contentView={<CreateRefPanelQtyForm edit={true} onSubmit={this.onSubmit} />}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    referencePanel: state.referencePanel.referencePanel
  };
};

export default connect(mapStateToProps, {
patchReferencePanelInDb,
  selectReferenceQuantity,
  updateReferencePanelState,
  updateReferenceQuantitiesState,
  updateAllReferenceQuantitiesState,
  getReferencePanel,
  getSORs,
  getStages,
})(editModelPanelView);
