import React from "react";
import Menu from "../components/Menu";
import Effects from "../components/paint/Effects";

const effectsView = () => {
  return (
    <>
      <Menu contentView={<Effects />} />
    </>
  );
};

export default effectsView;
