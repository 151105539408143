import Axios from "axios";
import { env } from "./env";

export const LabourAdjustments = ({ id, adjustmentIds }) => {
  const _env = env();
  const baseURL = _env.BASEURL;
  const key = _env.API_KEY;

  if (id) {
    return Axios.create({
      baseURL: `${baseURL}/repairs/labouradjustments/${id}`,
      headers: { "x-api-key": key },
    });
  } else if (adjustmentIds) {
    return Axios.create({
      baseURL: `${baseURL}/repairs/labouradjustments?ids=${adjustmentIds}`,
      headers: { "x-api-key": key },
    });
  }
  return Axios.create({
    baseURL: `${baseURL}/repairs/labouradjustments`,
    headers: { "x-api-key": key },
  });
};

export const compileDataForSave = ({ start, end, factor }, id, state) => {
  const labourAdjustment = {};
  labourAdjustment.id = id;
  labourAdjustment.start = parseFloat(start);
  labourAdjustment.end = parseFloat(end);
  labourAdjustment.factor = parseFloat(factor);
  labourAdjustment.active = state;
  return labourAdjustment;
};
