import React from "react";
import Menu from "../components/Menu";
import CreateTonerForm from "../components/paint/CreateTonerForm";
import { compileDataForSave } from "../apis/Toners";
import {
  getTonersById,
  selectToner,
  updateTonersState,
  patchTonerInDb,
  updateTonerPriceInDb,
  getTonerByKey,
  getToners
} from "../actions";
import { connect } from "react-redux";
import { nanoid } from "nanoid";
import {
  dateStringToSeconds,
  currentDateAsInt,
  replaceArrayObjectByKey
} from "../utilities";
import { Fragment } from "react";

class editTonerView extends React.Component {
  onSubmit = (values, toner) => {
    const {
      patchTonerInDb,
      tonersWithSelectedId,
      updateTonerPriceInDb,
      selectToner,
      toners,
      updateTonersState
    } = this.props;
    let existingFutureDatedRecId = null;

    let tonerData = compileDataForSave(values, {
      id: toner.id,
      skey: toner.skey,
      state: toner.state,
      usedByColorCount: toner.usedByColorCount,
      usedByColors: toner.usedByColors,
      startdate: toner.startdate,
      supplierName: toner.supplierName,
      systemName: toner.systemName,
      enddate: this.props.selectedToner.enddate
    });

    if (toner.futuredateyn) {
      const _startDate = dateStringToSeconds(toner.startdate);
      const cdate = currentDateAsInt();

      if (tonersWithSelectedId.length > 1) {
        tonersWithSelectedId.forEach(toner => {
          if (toner.startdate > cdate) {
            existingFutureDatedRecId = toner.skey;
          }
        });
      }

      const _key = nanoid();
      const usedByColors = [];

      updateTonerPriceInDb({
        skey: _key,
        toner: tonerData,
        existingFutureDatedTonerId: existingFutureDatedRecId,
        startdate: _startDate
      }); //!transactWrite done in API no data returned.

      //update existing toner enddate in state
      let _existingtoner = { ...this.props.selectedToner };
      _existingtoner.enddate = _startDate - 24 * 3600 * 1000;
      selectToner(_existingtoner);
      //replace updated toner in toner array.
      let _toners = replaceArrayObjectByKey([...toners], "skey", _existingtoner);

      //create new toner to push to state
      let _toner = { ...toner };
      _toner.costpergram = values.costpergram;
      _toner.skey = _key;
      _toner.usedByColors = usedByColors;
      _toner.usedByColorCount = 0;

      //push new toner to toners array
      _toners.unshift(_toner);
      //update state
      updateTonersState(_toners);
    } else {
      patchTonerInDb(toner.skey, tonerData);
      updateTonersState(
        replaceArrayObjectByKey(this.props.toners, "skey", tonerData)
      );
      selectToner(tonerData);
    }
  };

  hasFuturedDatedRecord = () => {
    const { tonersWithSelectedId } = this.props;
    let existingFutureDatedRecId = null;
    if (tonersWithSelectedId) {
      if (tonersWithSelectedId.length > 0) {
        tonersWithSelectedId.forEach(toner => {
          if (toner.startdate > currentDateAsInt()) {
            existingFutureDatedRecId = toner.skey;
          }
        });
      }
    }
    return existingFutureDatedRecId !== null;
  };

  componentDidMount() {
    const { skey } = this.props.match.params;
    if (skey) {
      this.props.getTonerByKey(skey); //current active
      if (this.props.selectedToner) {
        this.props.getTonersById(this.props.selectedToner.id);
      }
      if (this.props.allToners.length === 0) {
        this.props.getToners({ suppliedId: this.props.selectedToner.supplierId });
      }
    }
  }

  render() {
    const { skey } = this.props.match.params;
    return (
      <Fragment>
        <Menu
          contentView={
            <CreateTonerForm
              edit={true}
              onSubmit={this.onSubmit}
              tonerId={this.props.selectedToner.id}
              skey={skey}
              hasFuturedDatedRecord={this.hasFuturedDatedRecord()}
            />
          }
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    toners: state.toners.toners,
    allToners: state.toners.allToners,
    selectedToner: state.toners.selectedToner,
    tonersWithSelectedId: state.toners.tonersWithSelectedId
  };
};

export default connect(mapStateToProps, {
  getTonersById,
  getTonerByKey,
  selectToner,
  updateTonersState,
  patchTonerInDb,
  updateTonerPriceInDb,
  getToners
})(editTonerView);
