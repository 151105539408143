import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Button, Input } from "semantic-ui-react";
import Select from "react-select";
import { unitOptions } from "../../defaults";
import "../components.css";
import { Link } from "react-router-dom";
import _ from "lodash";
import { currencyMask } from "../../utilities";

import {
  getConsumables,
  resetSaveSuccess,
  updateSelectedConsumableState,
  updateConsumablesState,
} from "../../actions";

class CreateConsumableForm extends React.Component {
  componentDidMount() {
    //for new consumable, consumables required to validate for duplicates
    if (!this.props.edit) {
      this.props.getConsumables({});
    }
  }

  renderError({ submitFailed, error }) {
    if (submitFailed && error) {
      return (
        <div className="ui error message">
          <div className="header">{error}</div>
        </div>
      );
    }
  }

  renderResult = () => {
    if (this.props.busySaving) {
      return (
        <div className="ui success message" style={{ marginTop: "1em" }}>
          Saving..
        </div>
      );
    } else if (this.props.saveError) {
      return (
        <div className="ui error message" style={{ marginTop: "1em" }}>
          {this.props.saveError}
        </div>
      );
    } else if (this.props.successMessage) {
      return (
        <div className="ui success message" style={{ marginTop: "1em" }}>
          {this.props.successMessage}
        </div>
      );
    }
  };

  renderInput = ({
    input,
    id,
    style,
    meta,
    placeholder,
    disabled,
    label,
    autoFocus,
    normalize,
    format,
  }) => {
    const className = `field ${meta.error && meta.submitFailed ? "error" : ""}`;
    return (
      <div className={className}>
        <div className="formlabel">{label}</div>
        <Input
          {...input}
          id={id}
          style={style}
          placeholder={placeholder}
          disabled={disabled}
          autoFocus={autoFocus}
          autoComplete="off"
          ref={
            autoFocus
              ? (input) => {
                  this.focusInput = input;
                }
              : input
          }
          format={format}
          normalize={normalize}
        ></Input>
        {this.renderError(meta, id)}
      </div>
    );
  };

  renderSelect = ({
    input,
    id,
    options,
    meta,
    style,
    search,
    placeholder,
    label,
  }) => {
    const className = `field ${meta.error && meta.submitFailed ? "error" : ""}`;
    return (
      <div className={className} style={{ marginBottom: "1em" }}>
        <div className="formlabel">{label}</div>
        <Select
          {...input}
          fluid
          selection
          isSearchable={search}
          style={style}
          options={options}
          onChange={(value) => input.onChange(value)}
          onBlur={() => input.onBlur()}
          placeholder={placeholder}
        ></Select>
        {this.renderError(meta, id)}
      </div>
    );
  };

  createConsumableForDisplay = (values) => {
    const _consumable = {};
    _consumable.id = this.props.consumableId;
    _consumable.name = values.consumablename;
    _consumable.price = values.price;
    _consumable.unit = values.unit.value;
    _consumable.active = this.props.selectedConsumable
      ? this.props.selectedConsumable.active
      : true;
    return _consumable;
  };

  onSubmit = (values) => {
    this.props.onSubmit(values, this.createConsumableForDisplay(values));
    setTimeout(() => {
      this.props.resetSaveSuccess();
    }, 2500);
    if (!this.props.edit) {
      this.props.reset();
      this.focusInput.focus();
    }
  };

  render() {
    return (
      <div>
        <div className="content margin-bottom-small">{this.renderResult()}</div>
        <form
          className="ui form error"
          onSubmit={this.props.handleSubmit(this.onSubmit)}
        >
          <Field
            name="consumablename"
            id="consumablename"
            label="Consumable Name"
            component={this.renderInput}
            placeholder="Enter consumable name"
            autoFocus={true}
          ></Field>
          <Field
            name="unit"
            id="unit"
            label="Unit of Measurement"
            component={this.renderSelect}
            options={unitOptions}
            placeholder="Select unit"
            search
          ></Field>
          <Field
            name="price"
            id="price"
            label="Consumable Price"
            component={this.renderInput}
            placeholder="Enter consumable price"
            {...currencyMask}
          ></Field>

          <div className="spacer-very-wide"></div>
          <div className="spacer-very-wide"></div>
          <div style={{ textAlign: "right" }}>
            <Button.Group>
              <Link to="/consumables">
                <Button color="black" type="button">
                  Cancel
                </Button>
              </Link>
              <Button.Or />
              <Button primary>Save</Button>
            </Button.Group>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  //if editing set the default values

  let consumableData = {};

  if (props.edit) {
    consumableData = {
      consumables: state.consumables.consumables,
      selectedConsumable: state.consumables.selectedConsumable,
      initialValues: {
        consumablename: state.consumables.selectedConsumable.name,
        price: state.consumables.selectedConsumable.price,
        unit: {
          value: state.consumables.selectedConsumable.unit,
          label: state.consumables.selectedConsumable.unit,
        },
      },
    };
  } else {
    consumableData = {
      allConsumables: state.toners.allConsumables,
      consumables: state.consumables.consumables,
    };
  }

  return {
    ...consumableData,

    //api operations
    successMessage: state.api.saveSuccess,
    saveError: state.api.saveError,
    busySaving: state.api.busySaving,
  };
};

const validate = (formValues, props) => {
  const errors = {};

  if (!formValues.consumablename) {
    //keys of object must match field names
    errors.consumablename = "Please enter a consumable name.";
  }

  if (formValues.consumablename && !props.edit) {
    if (
      _.find(props.allConsumables, (consumable) => {
        return (
          consumable.name.toLowerCase() ===
          formValues.consumablename.toLowerCase()
        );
      }) !== undefined
    ) {
      //keys of object must match field names
      errors.consumablename = "Consumable with same name already exists";
    }
  }

  if (!formValues.price) {
    //keys of object must match field names
    errors.price = "Please enter a price.";
  }

  if (!formValues.unit) {
    //keys of object must match field names
    errors.unit = "Please enter a unit.";
  }

  return errors;
};

const formWrapped = reduxForm({
  form: "CreateConsumableForm",
  enableReinitialize: true,
  validate: validate,
})(CreateConsumableForm);

export default connect(mapStateToProps, {
  getConsumables,
  resetSaveSuccess,
  updateSelectedConsumableState,
  updateConsumablesState,
})(formWrapped);
