import React from "react";
import Menu from "../components/Menu";
import { compileDataForSave } from "../apis/LabourAdjustments";
import {
  getLabourAdjustments,
  updateLabourAdjustmentsState,
  selectLabourAdjustment,
  postLabourAdjustmentToDb,
} from "../actions";
import { connect } from "react-redux";
import CreateLabourAdjustmentForm from "../components/repairs/CreateLabourAdjustmentForm";

import { nanoid } from "nanoid";

class createLabourAdjustmentView extends React.Component {
  componentDidMount() {
    this.props.getLabourAdjustments({});
  }

  onSubmit = (values, labourAdjustment) => {
    const {
      labourAdjustments,
      postLabourAdjustmentToDb,
      selectLabourAdjustment,
      updateLabourAdjustmentsState,
    } = this.props;
    const labourAdjustmentData = compileDataForSave(
      values,
      labourAdjustment.id,
      true
    );

    postLabourAdjustmentToDb(labourAdjustmentData).then((result) => {
      let _labourAdjustments = [...labourAdjustments];
      let _labourAdjustment = { ...result.data }; //merge objects - returned from db +  metadata if required
      _labourAdjustment.name = `Panel size from ${_labourAdjustment.start} dm² to ${_labourAdjustment.end} dm²`;
      selectLabourAdjustment(_labourAdjustment);
      _labourAdjustments.splice(0, 0, _labourAdjustment); //insert at top of array
      updateLabourAdjustmentsState(_labourAdjustments);
    });
  };

  render() {
    return (
      <>
        <Menu
          contentView={
            <CreateLabourAdjustmentForm
              edit={false}
              onSubmit={this.onSubmit}
              labourAdjustmentId={nanoid()}
            />
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    labourAdjustments: state.labourAdjustments.labourAdjustments,
  };
};

export default connect(mapStateToProps, {
  getLabourAdjustments,
  selectLabourAdjustment,
  updateLabourAdjustmentsState,
  postLabourAdjustmentToDb,
})(createLabourAdjustmentView);
