import {
  GET_PAINTCOLORS,
  SELECT_PAINTCOLOR,
  SET_LAST_PAINTCOLOR_KEY,
  CLEAR_PAINTCOLOR,
  ARCHIVE_PAINTCOLOR,
  SELECT_COLOR_TONER,
  CLEAR_COLOR_TONER,
  ADD_PAINTCOLOR_TO_BATCH,
  REMOVE_PAINTCOLOR_FROM_BATCH,
  CLEAR_PAINTCOLOR_BATCH,
  SET_COLOR_TONERS,
  REMOVE_COLOR_TONER_FROM_BATCH,
  ADD_COLOR_TONER_TO_BATCH,
  CLEAR_COLOR_TONER_BATCH,
  ADD_ALL_PAINTCOLORS_TO_BATCH,
  ADD_ALL_COLOR_TONERS_TO_BATCH,
  SET_PAINTCOLOR_SEARCH_TERM,
  SET_PAINTCOLOR_FILTER,
  SET_PAINTCOLOR_FILTER_LABEL,
  SET_COLOR_TONER_SEARCH_TERM,
  SET_COLOR_TONER_FILTER,
  SET_COLOR_TONER_FILTER_LABEL,
  UPDATE_PAINTCOLORS,
  CLONE_PAINTCOLORS,
  UPDATE_ALL_PAINTCOLORS,
} from "../actions/types";
import _ from "lodash";

const INITIAL_STATE = {
  paintColors: [],
  selectedPaintColor: {
    toners: [],
    allToners: [],
  },
  lastPaintColorKey: {},
  selectedColorToner: {},
  paintColorBatch: [],
  colorTonerBatch: [],
  paintColorSearchTerms: {
    term: { label: "Select Manufacturer", value: "" },
    filter: { value: "", label: "" },
  },
  colorTonerSearchTerms: { term: { label: "", value: "" }, filter: "" },
  bulkCopyDone: false,
  allPaintColors: [],
};

// const sortPaintColorToners = (paintColor) => {
//   if (paintColor.toners) {
//     if (paintColor.toners.length > 0) {
//       paintColor.toners.sort((a, b) => (a.stageName > b.stageName ? 1 : -1));
//     }
//   }
//   return paintColor;
// };

const compilePaintColor = (color) => {
  let _color = { ...color };
  if (!color.allToners) {
    _color.allToners = [...color.toners];
  } else {
    _color.allToners = [...color.allToners];
  }
  return _color;
};


const paintColorsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PAINTCOLORS:
      return {
        ...state,
        paintColors: action.payload,
      };
    case UPDATE_PAINTCOLORS:
      return {
        ...state,
        paintColors: action.payload,
      };
    case UPDATE_ALL_PAINTCOLORS:
      return {
        ...state,
        allPaintColors: action.payload,
      };
    case SELECT_PAINTCOLOR:
      return {
        ...state,
        selectedPaintColor: compilePaintColor(action.payload.paintColor),
        selectedColorToner: action.payload.selectedToner,
      };
    case CLEAR_PAINTCOLOR:
      return { ...state, selectedPaintColor: {} };
    case SELECT_COLOR_TONER:
      return { ...state, selectedColorToner: action.payload };
    case CLEAR_COLOR_TONER:
      return { ...state, selectedColorToner: {} };
    case SET_LAST_PAINTCOLOR_KEY:
      return { ...state, lastPaintColorKey: action.payload };
    case ADD_PAINTCOLOR_TO_BATCH:
      return {
        ...state,
        paintColorBatch: [...state.paintColorBatch, action.payload],
      };
    case ADD_ALL_PAINTCOLORS_TO_BATCH:
      return {
        ...state,
        paintColorBatch: action.payload,
      };
    case ADD_COLOR_TONER_TO_BATCH:
      return {
        ...state,
        colorTonerBatch: [...state.colorTonerBatch, action.payload],
      };
    case ADD_ALL_COLOR_TONERS_TO_BATCH:
      return {
        ...state,
        colorTonerBatch: action.payload,
      };
    case REMOVE_PAINTCOLOR_FROM_BATCH:
      return {
        ...state,
        //removes 2nd argument from array and returns new array
        paintColorBatch: _.remove(
          state.paintColorBatch,
          (paintColor) => paintColor !== action.payload
        ),
      };
    case REMOVE_COLOR_TONER_FROM_BATCH:
      return {
        ...state,
        colorTonerBatch: _.remove(
          state.colorTonerBatch,
          (id) => id !== action.payload
        ),
      };
    case CLEAR_PAINTCOLOR_BATCH:
      return {
        ...state,
        paintColorBatch: [],
      };
    case CLEAR_COLOR_TONER_BATCH:
      return {
        ...state,
        colorTonerBatch: [],
      };
    case SET_COLOR_TONERS:
      return {
        ...state,
        selectedPaintColor: {
          ...state.selectedPaintColor,
          toners: action.payload,
        },
      };
    case ARCHIVE_PAINTCOLOR:
      return _.omit(state, action.payload);
    case SET_PAINTCOLOR_SEARCH_TERM:
      return {
        ...state,
        paintColorSearchTerms: {
          ...state.paintColorSearchTerms,
          term: action.payload,
        },
      };
    case SET_PAINTCOLOR_FILTER:
      return {
        ...state,
        paintColorSearchTerms: {
          ...state.paintColorSearchTerms,
          filter: {
            ...state.paintColorSearchTerms.filter,
            value: action.payload,
          },
        },
      };
    case SET_PAINTCOLOR_FILTER_LABEL:
      return {
        ...state,
        paintColorSearchTerms: {
          ...state.paintColorSearchTerms,
          filter: {
            ...state.paintColorSearchTerms.filter,
            label: action.payload,
          },
        },
      };
    case SET_COLOR_TONER_SEARCH_TERM:
      return {
        ...state,
        colorTonerSearchTerms: {
          ...state.colorTonerSearchTerms,
          term: action.payload,
        },
      };
    case SET_COLOR_TONER_FILTER:
      return {
        ...state,
        colorTonerSearchTerms: {
          ...state.colorTonerSearchTerms,
          filter: {
            ...state.colorTonerSearchTerms.filter,
            value: action.payload,
          },
        },
      };
    case SET_COLOR_TONER_FILTER_LABEL:
      return {
        ...state,
        colorTonerSearchTerms: {
          ...state.colorTonerSearchTerms,
          filter: {
            ...state.colorTonerSearchTerms.filter,
            label: action.payload,
          },
        },
      };
    case CLONE_PAINTCOLORS:
      return {
        ...state,
        allPaintColors: action.payload,
      };
    default:
      return state;
  }
};

export default paintColorsReducer;
