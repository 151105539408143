import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Button, Input } from "semantic-ui-react";
import Select from "react-select";
import {
  paintlocationOptions,
  partpositionOptions,
  partHiddenOptions,
  accessoryOptions,
} from "../../defaults";
import { selectOptionsFromPrimitiveArray } from "../../utilities";
import "../components.css";
import { Link } from "react-router-dom";
import _ from "lodash";
import { toastr } from "react-redux-toastr";
import {
  getParts,
  resetSaveSuccess,
  updateSelectedPartState,
  updatePartsState,
} from "../../actions";

class CreatePartForm extends React.Component {
  renderError({ submitFailed, error }) {
    if (submitFailed && error) {
      return (
        <div className="ui error message">
          <div className="header">{error}</div>
        </div>
      );
    }
  }

  renderResult = () => {
    if (this.props.busySaving) {
      return (
        <div className="ui success message" style={{ marginTop: "1em" }}>
          Saving..
        </div>
      );
    } else if (this.props.saveError) {
      return (
        <div className="ui error message" style={{ marginTop: "1em" }}>
          {this.props.saveError}
        </div>
      );
    } else if (this.props.successMessage) {
      return (
        <div className="ui success message" style={{ marginTop: "1em" }}>
          {this.props.successMessage}
        </div>
      );
    }
  };

  renderInput = ({
    input,
    id,
    style,
    meta,
    placeholder,
    disabled,
    label,
    autoFocus,
  }) => {
    const className = `field ${meta.error && meta.submitFailed ? "error" : ""}`;
    return (
      <div className={className}>
        <div className="formlabel">{label}</div>
        <Input
          {...input}
          id={id}
          style={style}
          placeholder={placeholder}
          disabled={disabled}
          autoFocus={autoFocus}
          ref={
            autoFocus
              ? (input) => {
                  this.focusInput = input;
                }
              : input
          }
        ></Input>
        {this.renderError(meta, id)}
      </div>
    );
  };

  renderSelect = ({
    input,
    id,
    options,
    meta,
    style,
    search,
    placeholder,
    isMulti,
    label,
  }) => {
    const className = `field ${meta.error && meta.submitFailed ? "error" : ""}`;
    return (
      <div className={className} style={{ marginBottom: "1em" }}>
        <div className="formlabel">{label}</div>

        <Select
          {...input}
          fluid
          isMulti={isMulti}
          selection
          isSearchable={search}
          style={style}
          options={options}
          onChange={(value) => input.onChange(value)}
          onBlur={() => input.onBlur()}
          placeholder={placeholder}
        ></Select>
        {this.renderError(meta, id)}
      </div>
    );
  };

  onSubmit = (values) => {
    try {
      this.props.onSubmit(values, this.props.partId, this.props.partNumber);
      if (!this.props.edit) {
        this.props.reset();
        this.focusInput.focus();
      }
      toastr.success("Success", "Part record saved.", {
        icon: "success",
      });
    } catch (error) {
      toastr.error("Error", error);
    }
  };

  render() {
    return (
      <div>
        <div className="content margin-bottom-small">{this.renderResult()}</div>
        <form
          className="ui form error"
          onSubmit={this.props.handleSubmit(this.onSubmit)}
        >
          <Field
            name="name"
            id="name"
            label="Part name"
            component={this.renderInput}
            placeholder="Enter part name"
            autoFocus={true}
          ></Field>
          <Field
            name="paintlocation"
            id="paintlocation"
            label="Paint location"
            component={this.renderSelect}
            options={paintlocationOptions}
            placeholder="Normally painted on/off vehicle"
            search
          />
          <Field
            name="hidden"
            id="hidden"
            label="Hidden part?"
            component={this.renderSelect}
            options={partHiddenOptions}
            placeholder="Hidden part?"
            search
          />
          <Field
            name="accessory"
            id="accessory"
            label="Is Accessory?"
            component={this.renderSelect}
            options={accessoryOptions}
            placeholder="Is accessory?"
            search
          />
          <Field
            name="labourcomplexity"
            id="labourcomplexity"
            label="Labour complexity factor"
            component={this.renderInput}
            placeholder="Enter labour complexity factor"
          ></Field>
          <Field
            name="materialcomplexity"
            id="materialcomplexity"
            label="Material complexity factor"
            component={this.renderInput}
            placeholder="Enter material complexity factor"
          ></Field>
          <Field
            name="positions"
            id="positions"
            isMulti
            label="Position on vehicle"
            component={this.renderSelect}
            options={partpositionOptions}
            placeholder="Location of part on vehicle"
            search
          />
          <div className="spacer-very-wide"></div>

          <div style={{ textAlign: "right" }}>
            <Button.Group>
              <Link to="/parts">
                <Button color="black" type="button">
                  Cancel
                </Button>
              </Link>
              <Button.Or />
              <Button primary>Save</Button>
            </Button.Group>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  //if editing set the default values

  let partData = {};

  if (props.edit) {
    partData = {
      selectedPart: state.parts.selectedPart,
      initialValues: {
        name: state.parts.selectedPart.name,
        paintlocation: {
          value: state.parts.selectedPart.paintlocation,
          label: state.parts.selectedPart.paintlocation,
        },
        labourcomplexity: state.parts.selectedPart.complexity.labour,
        materialcomplexity: state.parts.selectedPart.complexity.material,
        hidden: {
          value: state.parts.selectedPart.hidden,
          label: state.parts.selectedPart.hidden ? "Yes" : "No",
        },
        accessory: {
          value: state.parts.selectedPart.accessory,
          label: state.parts.selectedPart.accessory ? "Yes" : "No",
        },
        positions: selectOptionsFromPrimitiveArray(
          state.parts.selectedPart.positions
        ),
      },
    };
  } else {
    partData = {
      allParts: state.parts.allParts,
      initialValues: {
        labourcomplexity: 1.0,
        materialcomplexity: 1.0,
      },
    };
  }

  return {
    ...partData,

    //api operations
    successMessage: state.api.saveSuccess,
    saveError: state.api.saveError,
    busySaving: state.api.busySaving,
  };
};

const validate = (formValues, props) => {
  const errors = {};
  if (!formValues.paintlocation) {
    //keys of object must match field names
    errors.paintlocation =
      "Please select if the part is normally painted on or off the vehicle ";
  }

  if (!formValues.name) {
    //keys of object must match field names
    errors.name = "Please enter a part name.";
  }

  if (formValues.name) {
    if (
      _.find(props.allParts, (part) => {
        return part.name.toLowerCase() === formValues.name.toLowerCase();
      }) !== undefined
    ) {
      //keys of object must match field names
      errors.name = "Part with same name already exists";
    }
  }

  if (
    !formValues.labourcomplexity ||
    !typeof formValues.labourcomplexity === "number"
  ) {
    //keys of object must match field names
    errors.labourcomplexity =
      "Please enter a numeric value for the labour complexity (default = 1)";
  }

  if (
    !formValues.materialcomplexity ||
    !typeof formValues.materialcomplexity === "number"
  ) {
    //keys of object must match field names
    errors.materialcomplexity =
      "Please enter a numeric value for the material complexity (default = 1)";
  }

  return errors;
};

const formWrapped = reduxForm({
  form: "CreatePartForm",
  enableReinitialize: true,
  validate: validate,
})(CreatePartForm);

export default connect(mapStateToProps, {
  getParts,
  resetSaveSuccess,
  updateSelectedPartState,
  updatePartsState,
})(formWrapped);
