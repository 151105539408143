import React from "react";
import Menu from "../components/Menu";
import Models from "../components/vehicles/Models";

const modelsView = () => {
  return (
    <>
      <Menu
        contentView={
          <Models/>
        }
      />
    </>
  );
};

export default modelsView;
