import React from "react";
import "../components.css";
import {
  clearConsumable,
  setConsumablesFilter,
  clearConsumableBatch,
  selectConsumable,
  removeConsumableFromSelectedBatch,
  addConsumableToSelectedBatch,
  addAllConsumablesToSelectedBatch,
  deleteConsumables,
  patchConsumableInDb,
  updateConsumablesState,
  getConsumables,
  clearConsumables,
  updateAllConsumablesState,
} from "../../actions";
import { connect } from "react-redux";
import { compileDataForSave } from "../../apis/Consumables";
import _ from "lodash";
import { SAVE, EDIT, NEW, DELETE } from "../../actions/types";
import { Link } from "react-router-dom";
import { Button, Confirm } from "semantic-ui-react";
import history from "../../history";
import DisplayTable from "../displayTable";
import { isMobile } from "react-device-detect";
import { removeObjectFromArrayById, filterArray, arrayDelta } from "../../utilities";
import { SET_CONSUMABLE_FILTER_LABEL } from "../../actions/types";

class Consumables extends React.Component {
  state = {
    confirmDeleteOpen: false,
    consumableDeleteId: null,
  };

  componentDidMount() {
    if (this.props.consumables.length === 0) {
      this.props.getConsumables({});
    }
  }

  onConsumablesFilterChange = (filter) => {
    const { clearConsumable, allConsumables, updateConsumablesState, consumableSearchTerms, setConsumablesFilter } =
      this.props;
   
    const _newfilter = filter === "#clear#" ? "" : consumableSearchTerms.filter.label;

    if (_newfilter !== consumableSearchTerms.filter.value) {
      clearConsumable();

      if (_newfilter !== "") {
        updateConsumablesState(filterArray(allConsumables, "l_name", _newfilter));
      } else {
        updateConsumablesState(allConsumables);
      }
    }
    setConsumablesFilter(_newfilter);
  };

  onSelectConsumable = (id) => {
    const { selectConsumable, consumables, clearConsumableBatch } = this.props;
    selectConsumable(consumables.find((consumable) => consumable.id === id));
    clearConsumableBatch();
  };

  onAddConsumableToBatch = (consumable) => {
    if (this.props.consumableBatch.find((item) => item === consumable)) {
      this.props.removeConsumableFromSelectedBatch(consumable);
    } else {
      this.props.addConsumableToSelectedBatch(consumable);
    }
  };

  onAddAllConsumablesToSelectedBatch = () => {
    if (this.props.consumableBatch.length === this.props.consumables.length) {
      this.props.clearConsumableBatch();
    } else {
      this.props.addAllConsumablesToSelectedBatch(this.props.consumables);
    }
  };

  onBatchDeleteConsumables = () => {
    const {
      consumables,
      consumableBatch,
      deleteConsumables,
      clearConsumableBatch,
      clearConsumable,
      allConsumables,
      updateConsumablesState,
      updateAllConsumablesState
    } = this.props;
    if (consumableBatch.length > 0) {
      deleteConsumables(consumableBatch);
    }
    clearConsumableBatch();
    clearConsumable();
    updateConsumablesState(arrayDelta(consumables, consumableBatch));
    updateAllConsumablesState(arrayDelta(allConsumables, consumableBatch));
  };

  onDeleteConsumable = () => {
    const { clearConsumable, updateConsumablesState } = this.props;
    let _consumable = { ...this.props.selectedConsumable };
    //set to inactive
    _consumable.active = false;
    this.updateConsumable(_consumable);
    clearConsumable();
    updateConsumablesState(
      removeObjectFromArrayById(this.props.consumables, _consumable)
    );
  };

  updateConsumable = (consumable) => {
    const { patchConsumableInDb } = this.props;
    let _consumable = compileDataForSave(
      {
        consumablename: consumable.name,
        price: consumable.price,
        unit: consumable.unit,
      },
      consumable.id,
      consumable.active
    );
    patchConsumableInDb(_consumable);
  };

  //called from tables main menu to do ad-hoc save.
  onSaveChanges = () => {
    const _consumable = { ...this.props.selectedConsumable };
    if (!_.isEmpty(_consumable)) {
      this.updateConsumable(_consumable);
    }
  };

  renderConsumablesTable() {
    const menuItems = [
      {
        name: "Save Changes",
        key: SAVE,
        icon: "save",
        onClick: this.onSaveChanges,
        type: Button,
        disabled: this.props.consumables.length === 0,
      },
      {
        name: "New",
        key: NEW,
        icon: "add square",
        to: `/consumables/create`,
        type: Link,
        disabled: false,
      },
    ];

    const itemMenuActions = [
      {
        key: EDIT,
        text: "Edit Consumable",
        value: EDIT,
        icon: { name: "edit", color: "green" },
        idx: 0,
        executeAction: ({ itemId }) => {
          history.push(`/consumables/edit/${itemId}`);
        },
      },
      {
        key: DELETE,
        text: "Delete",
        value: DELETE,
        icon: { name: "delete", color: "red" },
        idx: 1,
        executeAction: ({ itemId }) => {
          this.setState({
            consumableDeleteId: itemId,
            confirmDeleteOpen: true,
          });
        },
      },
    ];

    const tableMenuActions = [
      {
        key: DELETE,
        confirmation: 1,
        text: "Delete",
        value: DELETE,
        icon: { name: "remove", color: "red" },
        idx: 0,
        action: this.onBatchDeleteConsumables,
        header: "Confirm Delete",
      },
    ];

    const gridColumns = "one";
    const tableColumns = [
      {
        width: "two",
        heading: "Price",
        displayValues: [{ columnName: "price" }],
        type: "Currency",
      },
      {
        width: "two",
        heading: "Unit",
        displayValues: [{ columnName: "unit" }],
      },
    ];

    const primaryColumn = {
      heading: "Consumable Name",
      width: "four",
      displayValues: [{ columnName: "name" }],
    };

    const copyConfig = {
      active: false,
    };

    //check if "model panels message must be displayed"

    return (
      <>
        <DisplayTable
          idFieldName="id"
          primaryHeading="Consumables"
          items={this.props.consumables}
          selectedItemId={this.props.selectedConsumable.id}
          menuItems={menuItems}
          tableMenuActions={tableMenuActions}
          itemMenuActions={itemMenuActions}
          showSearch={0}
          searchTerms={this.props.consumableSearchTerms}
          onSelectItem={this.onSelectConsumable}
          onSearchTermChange={() => {
            return;
          }}
          onFilterChange={this.onConsumablesFilterChange}
          filterAction={SET_CONSUMABLE_FILTER_LABEL}
          batchItems={this.props.consumableBatch}
          onAddItemToBatch={this.onAddConsumableToBatch}
          onAddAllItemsToBatch={this.onAddAllConsumablesToSelectedBatch}
          primaryColumn={primaryColumn}
          gridColumns={gridColumns}
          tableColumns={tableColumns}
          loading={this.props.loadingData}
          copyItemsConfig={copyConfig}
        ></DisplayTable>
      </>
    );
  }

  renderDeleteConfirm() {
    return (
      <Confirm
        open={this.state.confirmDeleteOpen}
        header="Item Actions"
        content={`Confirm Delete?`}
        onCancel={() => {
          this.setState({ confirmDeleteOpen: false });
        }}
        onConfirm={() => {
          this.setState({ confirmDeleteOpen: false });
          this.onDeleteConsumable();
        }}
      ></Confirm>
    );
  }

  render() {
    const className = `ui ${isMobile ? "" : "very padded"} segment`;
    return (
      <>
        <div className={className}>
          <div>{this.renderConsumablesTable()}</div>
          {this.renderDeleteConfirm()}
          <div className="spacer-very-wide"></div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    consumableSearchTerms: state.consumables.consumableSearchTerms,
    consumables: state.consumables.consumables,
    allConsumables: state.consumables.allConsumables,
    consumableBatch: state.consumables.consumableBatch,
    selectedConsumable: state.consumables.selectedConsumable,
    loadingData: state.api.loadingData,
  };
};

export default connect(mapStateToProps, {
  clearConsumable,
  clearConsumables,
  setConsumablesFilter,
  clearConsumableBatch,
  selectConsumable,
  removeConsumableFromSelectedBatch,
  addConsumableToSelectedBatch,
  addAllConsumablesToSelectedBatch,
  deleteConsumables,
  patchConsumableInDb,
  updateConsumablesState,
  updateAllConsumablesState,
  getConsumables,
})(Consumables);
