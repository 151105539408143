//Paint Locations
import Axios from "axios";
import { env } from "./env";

export const Locations = ({ all, id, name, locationIds }) => {
  const _env = env();
  const baseURL = _env.BASEURL_X;
  const key = _env.API_X_KEY;

  if (all) {
    return Axios.create({
      baseURL: `${baseURL}/vehicles/colorcodelocations?all=1`,
      headers: { "x-api-key": key },
    });
  } else if (id) {
    return Axios.create({
      baseURL: `${baseURL}/vehicles/colorcodelocations/${id}`,
      headers: { "x-api-key": key },
    });
  } else if (name) {
    return Axios.create({
      baseURL: `${baseURL}/vehicles/colorcodelocations?name=${name}`,
      headers: { "x-api-key": key },
    });
  } else if (locationIds) {
    return Axios.create({
      baseURL: `${baseURL}/vehicles/colorcodelocations?ids=${locationIds}`,
      headers: { "x-api-key": key },
    });
  }

  return Axios.create({
    baseURL: `${baseURL}/vehicles/colorcodelocations`,
    headers: { "x-api-key": key },
  });
};

export const compileLocationObject = ({ name }, id, state) => {
  const location = {};
  location.id = id;
  location.name = name;
  location.l_name = name.toLowerCase();
  location.active = state;
  return location;
};
