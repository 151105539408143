import React from "react";
import { connect } from "react-redux";
import GoogleSignIn from "./GoogleSignIn";
import { Segment, Grid, Header, Icon, Divider, Image, Label } from "semantic-ui-react";
import AWSSignIn from "./AWSSignIn";
import { signIn } from "../../actions";
import { env } from "../../apis/env";

class SignIn extends React.Component {
  renderLogo = () => {
    return (
      <div>
       <div className="margin-bottom-medium">
        <Image
          centered
          width="200px"
          src="https://paintwizz.s3.af-south-1.amazonaws.com/Logo_3780x756_v2.webp"
        ></Image>
      </div>
      </div>
    );
  };

  renderEnvironmentTag = () => {
    const _env = env();
    if (_env.ENV === "uat") {
      return (
        <div style={{ textAlign: "left", marginBottom: "2em" }}>
          <Label
            size="small"
            as="a"
            color="green"
            tag
          >
            User Acceptance Testing Environment
          </Label>
        </div>
      );
    }
  };

  render() {
    return (
      //remove style from Divider if Google Auth is required
      <div style={{ textAlign: "center" }}>
        <Grid
          stackable
          textAlign="center"
        >
          <Grid.Column width="eight">
            <Segment
              raised
              padded="very"
            >
              {this.renderEnvironmentTag()}
              {this.renderLogo()}
              <Header icon>
                <Icon name="sign in" />
                Sign in
              </Header>
              <GoogleSignIn />
              <Divider
                horizontal
                style={{ display: "none" }}
              >
                OR
              </Divider>
              <AWSSignIn />
            </Segment>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default connect(null, { signIn: signIn })(SignIn);
