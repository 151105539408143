import React from "react";
import Menu from "../components/Menu";
import { currentDateAsInt, updateStateAfterCreate } from "../utilities";
import { compileDataForSave } from "../apis/Toners";
import {
  getToners,
  updateTonersState,
  updateAllTonersState,
  selectToner,
  postTonerToDb
} from "../actions";
import { connect } from "react-redux";
import CreateTonerForm from "../components/paint/CreateTonerForm";

import { nanoid } from "nanoid";
import { Fragment } from "react";

class createTonerView extends React.Component {
  componentDidMount() {
    if (this.props.allToners.length === 0) {
      const { supplierId } = this.props.match.params;
      this.props.getToners({ supplierId });
    }
  }

  onSubmit = (values, toner) => {
    const {
      toners,
      allToners,
      postTonerToDb,
      selectToner,
      updateTonersState,
      updateAllTonersState
    } = this.props;
    const _startdate = currentDateAsInt();
    let tonerData = compileDataForSave(
      values, //form values
      { id: null, state: true, startdate: _startdate } //id not needed - set by API
    );
    postTonerToDb(tonerData).then(result => {
      tonerData.id = result.data.id;
      tonerData.skey = result.data.skey;
      tonerData.usedByColorCount = 0;
      tonerData.usedByColors = [];

      updateStateAfterCreate(
        selectToner,
        updateTonersState,
        updateAllTonersState,
        toners,
        allToners,
        tonerData
      );
    });
  };

  render() {
    const { supplierId } = this.props.match.params;
    return (
      <Fragment>
        <Menu
          contentView={
            <CreateTonerForm
              edit={false}
              onSubmit={this.onSubmit}
              tonerId={nanoid()}
              supplierId={supplierId}
            />
          }
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    toners: state.toners.toners,
    allToners: state.toners.allToners,
    supplierId: state.toners.tonerSearchTerms.term.value
  };
};

export default connect(mapStateToProps, {
  getToners,
  selectToner,
  updateTonersState,
  updateAllTonersState,
  postTonerToDb
})(createTonerView);
