import React from "react";
import Menu from "../components/Menu";
import Locations from "../components/vehicles/Locations";

const locationsView = () => {
  return (
    <>
      <Menu contentView={<Locations />} />
    </>
  );
};

export default locationsView;
