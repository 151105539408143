import React from "react";
import Menu from "../components/Menu";
import { compileColorGroupObject } from "../apis/ColorGroups";
import {
  getColorGroups,
  updateColorGroupsState,
  updateAllColorGroupsState,
  selectColorGroup,
  postColorGroupToDb,
} from "../actions";
import { connect } from "react-redux";
import CreateColorGroupForm from "../components/paint/CreateColorGroupForm";
import { nanoid } from "nanoid";
import { updateStateAfterCreate } from "../utilities";

class createColorGroupView extends React.Component {
  componentDidMount() {
    if (this.props.allColorGroups.length === 0) {
      this.props.getColorGroups({});
    }
  }

  onSubmit = (values, colorGroup) => {
    const {
      colorGroups,
      allColorGroups,
      postColorGroupToDb,
      selectColorGroup,
      updateColorGroupsState,
      updateAllColorGroupsState,
    } = this.props;
    const colorGroupData = compileColorGroupObject(values, colorGroup.id, true);
    postColorGroupToDb(colorGroupData).then((result) => {
      colorGroupData.id = result.data.id;
      updateStateAfterCreate(
        selectColorGroup,
        updateColorGroupsState,
        updateAllColorGroupsState,
        colorGroups,
        allColorGroups,
        colorGroupData
      );
    });
  };

  render() {
    return (
      <>
        <Menu
          contentView={
            <CreateColorGroupForm
              edit={false}
              onSubmit={this.onSubmit}
              colorGroupId={nanoid()}
            />
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    colorGroups: state.colorgroups.colorGroups,
    allColorGroups: state.colorgroups.allColorGroups,
  };
};

export default connect(mapStateToProps, {
  getColorGroups,
  selectColorGroup,
  updateColorGroupsState,
  updateAllColorGroupsState,
  postColorGroupToDb,
})(createColorGroupView);
