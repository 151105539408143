import {
    GET_PRIMERPRICES,
    SELECT_PRIMERPRICE,
    CLEAR_PRIMERPRICE,
    GET_PRIMERPRICE,
    SET_PRIMERPRICE_FILTER,
    SET_PRIMERPRICE_FILTER_LABEL,
    CLEAR_PRIMERPRICES,
    CLEAR_PRIMERPRICE_BATCH,
    REMOVE_PRIMERPRICE_FROM_BATCH,
    ADD_PRIMERPRICE_TO_BATCH,
    UPDATE_PRIMERPRICES,
    DELETE_PRIMERPRICE,
    ADD_ALL_PRIMERPRICES_TO_BATCH,
    CLONE_PRIMERPRICES,
  } from "../actions/types";
  import _ from "lodash";
  import { removeObjectFromArray } from "../utilities";
  
  const INITIAL_STATE = {
    primerPrices: [],
    allPrimerPrices: [],
    primerPrice: {},
    selectedPrimerPrice: {},
    primerPriceSearchTerms: {
      filter: { value: "", label: "" },
      term: {
        value: "",
        label: "",
      },
    },
    primerPriceBatch: [],
  };
  
  const primerPricesReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case GET_PRIMERPRICES:
        return {
          ...state,
          primerPrices: action.payload,
        };
      case SET_PRIMERPRICE_FILTER:
        return {
          ...state,
          primerPriceSearchTerms: {
            ...state.primerPriceSearchTerms,
            filter: {
              ...state.primerPriceSearchTerms.filter,
              value: action.payload,
            },
          },
        };
      case SET_PRIMERPRICE_FILTER_LABEL:
        return {
          ...state,
          primerPriceSearchTerms: {
            ...state.primerPriceSearchTerms,
            filter: {
              ...state.primerPriceSearchTerms.filter,
              label: action.payload,
            },
          },
        };
      case SELECT_PRIMERPRICE:
        return {
          ...state,
          selectedPrimerPrice: action.payload,
        };
      case GET_PRIMERPRICE:
        return {
          ...state,
          primerPrice: action.payload,
        };
      case CLEAR_PRIMERPRICE:
        return { ...state, selectedPrimerPrice: {} };
      case CLEAR_PRIMERPRICES:
        return { ...state, primerPrices: [] };
      case CLEAR_PRIMERPRICE_BATCH:
        return {
          ...state,
          primerPriceBatch: [],
        };
      case REMOVE_PRIMERPRICE_FROM_BATCH:
        return {
          ...state,
          //removes 2nd argument from array and returns new array
          primerPriceBatch: _.remove(
            state.primerPriceBatch,
            (primerprice) => primerprice !== action.payload
          ),
        };
      case ADD_PRIMERPRICE_TO_BATCH:
        return {
          ...state,
          primerPriceBatch: [...state.primerPriceBatch, action.payload],
        };
      case ADD_ALL_PRIMERPRICES_TO_BATCH:
        return {
          ...state,
          primerPriceBatch: action.payload,
        };
      case UPDATE_PRIMERPRICES:
        return {
          ...state,
          primerPrices: action.payload,
        };
      case DELETE_PRIMERPRICE:
        return {
          ...state,
          primerPrices: removeObjectFromArray(
            ...state.primerPrices,
            action.payload
          ),
        };
      case CLONE_PRIMERPRICES:
        return {
          ...state,
          allPrimerPrices: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default primerPricesReducer;
  