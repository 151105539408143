import React from "react";
import Menu from "../components/Menu";
import CreatePrimerPriceForm from "../components/paint/CreatePrimerPriceForm";
import { compilePrimerPriceObject } from "../apis/PrimerPrices";
import {
  getPrimerPrice,
  getPrimerPrices,
  selectPrimerPrice,
  updatePrimerPricesState,
  updateAllPrimerPricesState,
  patchPrimerPriceInDb,
} from "../actions";
import { replaceArrayObjectByKey } from "../utilities";
import { connect } from "react-redux";

class editPrimerPriceView extends React.Component {
  onSubmit = (values, primerPriceId) => {
    const {
      patchPrimerPriceInDb,
      primerPrices,
      allPrimerPrices,
      updatePrimerPricesState,
      updateAllPrimerPricesState,
    } = this.props;
    const primerPricesData = compilePrimerPriceObject(values, primerPriceId, true);
    patchPrimerPriceInDb(primerPricesData);
    //update primer price changes back to state
    selectPrimerPrice(primerPricesData);
    updatePrimerPricesState(
      replaceArrayObjectByKey(primerPrices, "id", primerPricesData)
    );
    updateAllPrimerPricesState(
      replaceArrayObjectByKey(allPrimerPrices, "id", primerPricesData)
    );
  };

  componentDidMount() {
    const { primerpriceId } = this.props.match.params;
    if (primerpriceId) {
      this.props.getPrimerPrice(primerpriceId);
    }
    if (this.props.allPrimerPrices.length === 0) {
      this.props.getPrimerPrices({});
    }
  }

  render() {
    const { primerpriceId } = this.props.match.params;
    return (
      <>
        <Menu
          contentView={
            <CreatePrimerPriceForm
              edit={true}
              onSubmit={this.onSubmit}
              primerPriceId={primerpriceId}
            />
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    primerPrices: state.primerprices.primerPrices,
    allPrimerPrices: state.primerprices.allPrimerPrices,
  };
};

export default connect(mapStateToProps, {
  getPrimerPrice,
  getPrimerPrices,
  selectPrimerPrice,
  updatePrimerPricesState,
  updateAllPrimerPricesState,
  patchPrimerPriceInDb,
})(editPrimerPriceView);
