import React from "react";
import { Menu, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import "./components.css";

class ActionsMenu extends React.Component {
  render() {
    const { menuItems, icon_size, visible } = this.props;

    if (menuItems && visible) {
      return menuItems.map((item) => {
        //menu item constains save in text
        const _menuItemIsDoSave = item.name.toLowerCase().includes("sav");
        //only change menu item icon when saving if save item
        const _icon =
          this.props.saveSuccess && _menuItemIsDoSave ? "check" : item.icon;
        const _loading = this.props.busySaving && _menuItemIsDoSave;
        let linkClassName = "";
        if (item.disabled) {
          linkClassName = "menu-button disabled-link";
        } else {
          linkClassName = "menu-button";
        }
        return (
          <Menu.Item
            as={item.type}
            key={item.key}
            to={item.to}
            name={item.name}
            onClick={item.onClick}
            disabled={item.disabled}
            className={linkClassName}
          >
            <Icon
              color="orange"
              size={icon_size}
              name={_icon}
              circular
              inverted
              loading={_loading}
            />
            {item.name}
          </Menu.Item>
        );
      });
    } else return <span></span>;
  }
}

const mapStateToProps = (state) => {
  return {
    busySaving: state.api.busySaving,
    saveSuccess: state.api.saveSuccess,
  };
};

export default connect(mapStateToProps, null)(ActionsMenu);
