import React from "react";
import "../components.css";
import {
  getReferencePanel,
  clearReferenceQuantityBatch,
  selectReferenceQuantity,
  addReferenceQuantityToSelectedBatch,
  removeReferenceQuantityFromSelectedBatch,
  addAllReferenceQuantitiesToSelectedBatch,
  clearReferenceQuantity,
  updateReferenceQuantitiesState,
  updateAllReferenceQuantitiesState,
  patchReferencePanelInDb,
  setReferenceQuantitiesFilter,
  updateReferencePanelState
} from "../../actions";
import { connect } from "react-redux";
import { compileReferencePanel } from "../../apis/ReferencePanel";
import _ from "lodash";
import { EDIT, NEW, DELETE, SET_REFERENCEQUANTITY_FILTER_LABEL } from "../../actions/types";
import { Link } from "react-router-dom";
import { Icon, Confirm } from "semantic-ui-react";
import history from "../../history";
import DisplayTable from "../displayTable";
import { isMobile } from "react-device-detect";
import { filterArrayOnConcatenatedKeys, arrayDelta } from "../../utilities";
import EditRefPanelForm from "./EditRefPanelForm";
import { toastr } from "react-redux-toastr";

class ReferencePanel extends React.Component {
  state = {
    confirmDeleteOpen: false,
    quantityDeleteId: null
  };

  componentDidMount() {
    if (_.isEmpty(this.props.referencePanel)) {
      this.props.getReferencePanel();
    }
  }

  onPanelQuantitiesFilterChange = (filter) => {
    const {
      clearReferenceQuantity,
      referenceQuantitySearchTerms,
      setReferenceQuantitiesFilter,
      updateReferenceQuantitiesState,
      allReferenceQuantities
    } = this.props;
    const _filter = filter !== undefined ? filter : referenceQuantitySearchTerms.filter.label;
    if (_filter !== referenceQuantitySearchTerms.filter.value) {
      clearReferenceQuantity();
      if (_filter !== "") {
        let _referenceQuantities = [...allReferenceQuantities];
        const _filteredQuantities = [
          ...filterArrayOnConcatenatedKeys(
            _referenceQuantities,
            ["scopeName", "stageName", "paint", "labour"],
            _filter
          )
        ];
        updateReferenceQuantitiesState(_filteredQuantities);
      } else {
        updateReferenceQuantitiesState([...allReferenceQuantities]);
      }
    }
    setReferenceQuantitiesFilter(_filter);
  };

  onSelectReferencePanelQuantity = (id) => {
    const scopeId = id.substring(0, id.indexOf("-"));
    const stageId = id.substring(id.indexOf("-") + 1, id.length);
    const { selectReferenceQuantity, quantities, clearReferenceQuantityBatch } = this.props;
    selectReferenceQuantity(
      quantities.find((quantity) => {
        return quantity.stage === stageId && quantity.scope === scopeId;
      })
    );
    clearReferenceQuantityBatch();
  };

  onAddReferenceQuantityToBatch = (refQuantity) => {
    if (this.props.referenceQuantityBatch.find((item) => item === refQuantity)) {
      this.props.addReferenceQuantityToSelectedBatch(refQuantity);
    } else {
      this.props.removeReferenceQuantityFromSelectedBatch(refQuantity);
    }
  };

  onAddAllReferenceQuantitiesToSelectedBatch = () => {
    if (this.props.referenceQuantityBatch.length === this.props.quantities.length) {
      this.props.clearReferenceQuantityBatch();
    } else {
      this.props.addAllReferenceQuantitiesToSelectedBatch(this.props.quantities);
    }
  };

  onBatchDeleteReferencePanelQuantities = () => {
    const { clearReferenceQuantityBatch, clearReferenceQuantity } = this.props;
    clearReferenceQuantityBatch();
    clearReferenceQuantity();
  };

  updateReferencePanelOld = (referencePanel) => {
    const { patchReferencePanelInDb } = this.props;
    let _referencePanel = compileReferencePanel(
      {
        area: referencePanel.area,
        manufacturerId: referencePanel.manufacturer.value,
        manufacturerName: referencePanel.manufacturer.label,
        modelId: referencePanel.model.value,
        modelName: referencePanel.model.label,
        partId: referencePanel.part.value,
        partName: referencePanel.part.label,
        quantities: referencePanel.quantities
      },
      referencePanel.id
    );
    patchReferencePanelInDb(_referencePanel);
  };


  renderReferencePanelQuantitiesTable() {
    const menuItems = [
      {
        name: "New Quantity",
        key: NEW,
        icon: "add square",
        to: `/refpanelquantities/create`,
        type: Link,
        disabled: false
      }
    ];

    const itemMenuActions = [
      {
        key: EDIT,
        text: "Edit Quantity",
        value: EDIT,
        icon: { name: "edit", color: "green" },
        idx: 0,
        executeAction: ({ itemId }) => {
          history.push(`/refpanelquantities/edit/${itemId}`);
        }
      },
      {
        key: DELETE,
        text: "Delete",
        value: DELETE,
        icon: { name: "delete", color: "red" },
        idx: 1,
        executeAction: ({ itemId }) => {
          this.setState({ quantityDeleteId: itemId, confirmDeleteOpen: true });
        }
      }
    ];

    const tableMenuActions = [
      {
        key: DELETE,
        confirmation: 1,
        text: "Delete",
        value: DELETE,
        icon: { name: "remove", color: "red" },
        idx: 0,
        action: this.onBatchDeleteReferencePanelQuantities,
        header: "Confirm Delete"
      }
    ];

    const gridColumns = "five";
    const tableColumns = [
      {
        width: "two",
        heading: "Labour Units",
        displayValues: [{ columnName: "labour" }]
      },
      {
        width: "two",
        heading: "Paint Qty",
        displayValues: [{ columnName: "paint" }]
      },
      {
        width: "two",
        heading: "Scope",
        displayValues: [{ columnName: "scopeName" }]
      },
      {
        width: "two",
        heading: "Stage (Activity)",
        displayValues: [{ columnName: "stageName" }]
      }
    ];

    const primaryColumn = {
      heading: "ID",
      width: "three",
      displayValues: [{ columnName: "id" }]
    };

    const copyConfig = {
      active: false
    };

    //check if "model panels message must be displayed"

    return (
      <>
        <DisplayTable
          idFieldName="id"
          primaryHeading="Reference Panel Quantities"
          items={this.props.quantities}
          selectedItemId={this.props.selectedReferenceQuantity.id}
          menuItems={menuItems}
          tableMenuActions={tableMenuActions}
          itemMenuActions={itemMenuActions}
          showSearch={0}
          searchTerms={this.props.referenceQuantitySearchTerms}
          onSelectItem={this.onSelectReferencePanelQuantity}
          onSearchTermChange={() => {
            return;
          }}
          onFilterChange={this.onPanelQuantitiesFilterChange}
          filterAction={SET_REFERENCEQUANTITY_FILTER_LABEL}
          batchItems={this.props.referenceQuantityBatch}
          onAddItemToBatch={this.onAddReferenceQuantityToBatch}
          onAddAllItemsToBatch={this.onAddAllReferenceQuantitiesToSelectedBatch}
          primaryColumn={primaryColumn}
          gridColumns={gridColumns}
          tableColumns={tableColumns}
          loading={this.props.loadingData}
          copyItemsConfig={copyConfig}></DisplayTable>
      </>
    );
  }


  onDeleteReferenceQuantity = () => {
    const {quantities, clearReferenceQuantityBatch, selectedReferenceQuantity, referencePanel, patchReferencePanelInDb, updateAllReferenceQuantitiesState, updateReferenceQuantitiesState } = this.props;
    const referenceQuantityBatch = [];
    referenceQuantityBatch.push(selectedReferenceQuantity);
    if (referenceQuantityBatch.length > 0) {
      let _referencePanel = { ...referencePanel };
      let _referenceQuantities = arrayDelta(referencePanel.quantities, referenceQuantityBatch);
      let _filteredRefQuantities = arrayDelta(quantities, referenceQuantityBatch);
      _referencePanel.quantities = _referenceQuantities;
      patchReferencePanelInDb(_referencePanel);
      clearReferenceQuantityBatch();
      updateReferenceQuantitiesState(_filteredRefQuantities);
      updateAllReferenceQuantitiesState(_referenceQuantities);
    }
  };


  renderDeleteConfirm() {
    return (
      <Confirm
        open={this.state.confirmDeleteOpen}
        header="Item Actions"
        content={`Confirm Delete?`}
        onCancel={() => {
          this.setState({ confirmDeleteOpen: false });
        }}
        onConfirm={() => {
          this.setState({ confirmDeleteOpen: false });
          this.onDeleteReferenceQuantity();
        }}></Confirm>
    );
  }

  onPanelSaveClick = () => {
    try {
      this.props.onSave();
      toastr.success("Success", "Reference panel data saved.", {
        icon: "success"
      });
    } catch (error) {
      toastr.error("Error", error);
    }
  };

  renderRefPanelSaveButton = () => {
    const _enabled = this.props.referencePanel.area > 0 ? "inline-block" : "none";
    return (
      <div style={{ display: _enabled, cursor: "pointer" }} onClick={this.onPanelSaveClick}>
        <Icon color="orange" size="large" name="save" circular inverted />
        <h4 style={{ display: "inline" }}> Save</h4>
      </div>
    );
  };

  render() {
    const className = `ui ${isMobile ? "" : "very padded"} segment`;
    return (
      <>
        <div className={className}>
          <div style={{ textAlign: "right" }}>{this.renderRefPanelSaveButton()}</div>
          <EditRefPanelForm />
          <div>{this.renderReferencePanelQuantitiesTable()}</div>
          {this.renderDeleteConfirm()}
          <div className="spacer-very-wide"></div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    referenceQuantitySearchTerms: state.referencePanel.referenceQuantitySearchTerms,
    allReferenceQuantities: state.referencePanel.allReferenceQuantities,
    referencePanel: state.referencePanel.referencePanel,
    referenceQuantityBatch: state.referencePanel.referenceQuantityBatch,
    loadingData: state.api.loadingData,
    quantities: state.referencePanel.referencePanelQuantities,
    selectedReferenceQuantity: state.referencePanel.selectedReferenceQuantity
  };
};

export default connect(mapStateToProps, {
  clearReferenceQuantityBatch,
  getReferencePanel,
  selectReferenceQuantity,
  addReferenceQuantityToSelectedBatch,
  removeReferenceQuantityFromSelectedBatch,
  addAllReferenceQuantitiesToSelectedBatch,
  clearReferenceQuantity,
  updateReferenceQuantitiesState,
  updateAllReferenceQuantitiesState,
  patchReferencePanelInDb,
  setReferenceQuantitiesFilter,
  updateReferencePanelState
})(ReferencePanel);
