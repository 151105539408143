import React from "react";
import Menu from "../components/Menu";
import PrimerPrices from "../components/paint/PrimerPrices";

const PrimerPricesView = () => {
  return (
    <>
      <Menu contentView={<PrimerPrices />} />
    </>
  );
};

export default PrimerPricesView;
