import { React, useState, useEffect } from "react";
import Select from "react-select";
import SearchBarFilter from "./SearchBarFilter";
import "./components.css";

const Searchbar = ({
  onSearchTermChange,
  onFilterChange,
  filterAction,
  options,
  placeholder,
  searchTerms,
  showSearch,
}) => {
  const [term, setTerm] = useState("");
  const [label, setLabel] = useState("");

  useEffect(() => {
    const timeId = setTimeout(() => {
      onSearchTermChange({ value: term, label: label });
    }, 800);

    return () => {
      clearTimeout(timeId);
    };
    // eslint-disable-next-line
  }, [term]);

  const handleChange = (data) => {
    if (data) {
      setTerm(data.value);
      setLabel(data.label);
    }
  };

  const selectDisplayStyle = showSearch ? "inline" : "none";

  return (
    <>
      <div className="eight wide column">
        <div style={{ display: selectDisplayStyle }}>
          <Select
            options={options}
            onChange={handleChange}
            placeholder={placeholder}
            blurInputOnSelect
            value={{
              label: searchTerms.term.label,
              value: searchTerms.term.value,
            }}
          ></Select>
        </div>
      </div>
      <SearchBarFilter
        searchTerms={searchTerms}
        onFilterChange={onFilterChange}
        filterAction={filterAction}
      ></SearchBarFilter>
    </>
  );
};

export default Searchbar;
