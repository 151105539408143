import Axios from "axios";
import { env } from "./env";

export const Suppliers = ({ all, id, name, supplierIds }) => {
  const _env = env();
  const baseURL = _env.BASEURL;
  const key = _env.API_KEY;

  if (all) {
    return Axios.create({
      baseURL: `${baseURL}/paint/suppliers?all=1`,
      headers: { "x-api-key": key },
    });
  } else if (id) {
    return Axios.create({
      baseURL: `${baseURL}/paint/suppliers/${id}`,
      headers: { "x-api-key": key },
    });
  } else if (name) {
    return Axios.create({
      baseURL: `${baseURL}/paint/suppliers?name=${name}`,
      headers: { "x-api-key": key },
    });
  } else if (supplierIds) {
    return Axios.create({
      baseURL: `${baseURL}/paint/suppliers?ids=${supplierIds}`,
      headers: { "x-api-key": key },
    });
  }

  return Axios.create({
    baseURL: `${baseURL}/paint/suppliers`,
    headers: { "x-api-key": key },
  });
};
