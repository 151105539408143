import React from "react";
import "../components.css";
import {
  clearLabourAdjustment,
  setLabourAdjustmentsFilter,
  clearLabourAdjustmentBatch,
  selectLabourAdjustment,
  removeLabourAdjustmentFromSelectedBatch,
  addLabourAdjustmentToSelectedBatch,
  addAllLabourAdjustmentsToSelectedBatch,
  deleteLabourAdjustments,
  patchLabourAdjustmentInDb,
  updateLabourAdjustmentsState,
  getLabourAdjustments,
  clearLabourAdjustments,
} from "../../actions";
import { connect } from "react-redux";
import { compileDataForSave } from "../../apis/LabourAdjustments";
import _ from "lodash";
import { SAVE, EDIT, NEW, DELETE } from "../../actions/types";
import { Link } from "react-router-dom";
import { Button, Confirm } from "semantic-ui-react";
import history from "../../history";
import DisplayTable from "../displayTable";
import { isMobile } from "react-device-detect";
import {
  removeObjectFromArrayById,
  filterArrayOnConcatenatedKeys,
} from "../../utilities";
import { SET_LABOUR_ADJUSTMENT_FILTER_LABEL } from "../../actions/types";

class LabourAdjustments extends React.Component {
  state = {
    confirmDeleteOpen: false,
    labourAdjustmentDeleteId: null,
  };

  componentDidMount() {
    if (this.props.labourAdjustments.length === 0) {
      this.props.getLabourAdjustments({});
    }
    //create a clone for filter search purposes. This full set used to "reset" filter to full set of objects
  }

  onLabourAdjustmentsFilterChange = (filter) => {
    const {
      clearLabourAdjustment,
      labourAdjustmentSearchTerms,
      setLabourAdjustmentsFilter,
      updateLabourAdjustmentsState,
      allLabourAdjustments,
    } = this.props;
    const _filter =
      filter !== undefined ? filter : labourAdjustmentSearchTerms.filter.label;
    if (_filter !== labourAdjustmentSearchTerms.filter.value) {
      clearLabourAdjustment();
      if (_filter !== "") {
        let _labourAdjustments = [...allLabourAdjustments];
        const _filteredLabourAdjustments = [
          ...filterArrayOnConcatenatedKeys(
            _labourAdjustments,
            ["start", "end"],
            _filter
          ),
        ];
        updateLabourAdjustmentsState(_filteredLabourAdjustments);
      } else {
        updateLabourAdjustmentsState([...allLabourAdjustments]);
      }
    }
    setLabourAdjustmentsFilter(_filter);
  };

  onSelectLabourAdjustment = (id) => {
    const {
      selectLabourAdjustment,
      labourAdjustments,
      clearLabourAdjustmentBatch,
    } = this.props;
    selectLabourAdjustment(
      labourAdjustments.find((labourAdjustment) => labourAdjustment.id === id)
    );
    clearLabourAdjustmentBatch();
  };

  onAddLabourAdjustmentToBatch = (labourAdjustment) => {
    if (
      this.props.labourAdjustmentBatch.find((item) => item === labourAdjustment)
    ) {
      this.props.removeLabourAdjustmentFromSelectedBatch(labourAdjustment);
    } else {
      this.props.addLabourAdjustmentToSelectedBatch(labourAdjustment);
    }
  };

  onAddAllLabourAdjustmentsToSelectedBatch = () => {
    if (
      this.props.labourAdjustmentBatch.length ===
      this.props.labourAdjustments.length
    ) {
      this.props.clearLabourAdjustmentBatch();
    } else {
      this.props.addAllLabourAdjustmentsToSelectedBatch(
        this.props.labourAdjustments
      );
    }
  };

  onBatchDeleteLabourAdjustments = () => {
    const {
      labourAdjustments,
      labourAdjustmentBatch,
      deleteLabourAdjustments,
      clearLabourAdjustmentBatch,
      clearLabourAdjustment,
    } = this.props;
    if (labourAdjustmentBatch.length > 0) {
      deleteLabourAdjustments(labourAdjustments, labourAdjustmentBatch);
    }
    clearLabourAdjustmentBatch();
    clearLabourAdjustment();
  };

  onDeleteLabourAdjustment = () => {
    const { clearLabourAdjustment, updateLabourAdjustmentsState } = this.props;
    let _labourAdjustment = { ...this.props.selectedLabourAdjustment };
    //set to inactive
    _labourAdjustment.active = false;
    this.updateLabourAdjustment(_labourAdjustment);
    clearLabourAdjustment();
    updateLabourAdjustmentsState(
      removeObjectFromArrayById(this.props.labourAdjustments, _labourAdjustment)
    );
  };

  updateLabourAdjustment = (labourAdjustment) => {
    const { patchLabourAdjustmentInDb } = this.props;
    let _labourAdjustment = compileDataForSave(
      {
        start: labourAdjustment.start,
        end: labourAdjustment.end,
        factor: labourAdjustment.factor,
      },
      labourAdjustment.id,
      labourAdjustment.active
    );
    patchLabourAdjustmentInDb(_labourAdjustment);
  };

  //called from tables main menu to do ad-hoc save.
  onSaveChanges = () => {
    const _labourAdjustment = { ...this.props.selectedLabourAdjustment };
    if (!_.isEmpty(_labourAdjustment)) {
      this.updateLabourAdjustment(_labourAdjustment);
    }
  };

  renderLabourAdjustmentsTable() {
    const menuItems = [
      {
        name: "Save Changes",
        key: SAVE,
        icon: "save",
        onClick: this.onSaveChanges,
        type: Button,
        disabled: this.props.labourAdjustments.length === 0,
      },
      {
        name: "New",
        key: NEW,
        icon: "add square",
        to: `/labouradjustments/create`,
        type: Link,
        disabled: false,
      },
    ];

    const itemMenuActions = [
      {
        key: EDIT,
        text: "Edit Labour Adjustment",
        value: EDIT,
        icon: { name: "edit", color: "green" },
        idx: 0,
        executeAction: ({ itemId }) => {
          history.push(`/labouradjustments/edit/${itemId}`);
        },
      },
      {
        key: DELETE,
        text: "Delete",
        value: DELETE,
        icon: { name: "delete", color: "red" },
        idx: 1,
        executeAction: ({ itemId }) => {
          this.setState({
            labourAdjustmentDeleteId: itemId,
            confirmDeleteOpen: true,
          });
        },
      },
    ];

    const tableMenuActions = [
      {
        key: DELETE,
        confirmation: 1,
        text: "Delete",
        value: DELETE,
        icon: { name: "remove", color: "red" },
        idx: 0,
        action: this.onBatchDeleteLabourAdjustments,
        header: "Confirm Delete",
      },
    ];

    const gridColumns = "one";
    const tableColumns = [
      {
        width: "two",
        heading: "Start",
        displayValues: [{ columnName: "start" }],
      },
      {
        width: "two",
        heading: "End",
        displayValues: [{ columnName: "end" }],
      },
      {
        width: "two",
        heading: "Factor",
        displayValues: [{ columnName: "factor" }],
      },
    ];

    const primaryColumn = {
      heading: "Description",
      width: "five",
      displayValues: [{ columnName: "name" }],
    };

    const copyConfig = {
      active: false,
    };

    //check if "model panels message must be displayed"

    return (
      <>
        <DisplayTable
          idFieldName="id"
          primaryHeading="Labour Adjustments"
          items={this.props.labourAdjustments}
          selectedItemId={this.props.selectedLabourAdjustment.id}
          menuItems={menuItems}
          tableMenuActions={tableMenuActions}
          itemMenuActions={itemMenuActions}
          showSearch={0}
          searchTerms={this.props.labourAdjustmentSearchTerms}
          onSelectItem={this.onSelectLabourAdjustment}
          onSearchTermChange={() => {
            return;
          }}
          onFilterChange={this.onLabourAdjustmentsFilterChange}
          filterAction={SET_LABOUR_ADJUSTMENT_FILTER_LABEL}
          batchItems={this.props.labourAdjustmentBatch}
          onAddItemToBatch={this.onAddLabourAdjustmentToBatch}
          onAddAllItemsToBatch={this.onAddAllLabourAdjustmentsToSelectedBatch}
          primaryColumn={primaryColumn}
          gridColumns={gridColumns}
          tableColumns={tableColumns}
          loading={this.props.loadingData}
          copyItemsConfig={copyConfig}
        ></DisplayTable>
      </>
    );
  }

  renderDeleteConfirm() {
    return (
      <Confirm
        open={this.state.confirmDeleteOpen}
        header="Item Actions"
        content={`Confirm Delete?`}
        onCancel={() => {
          this.setState({ confirmDeleteOpen: false });
        }}
        onConfirm={() => {
          this.setState({ confirmDeleteOpen: false });
          this.onDeleteLabourAdjustment();
        }}
      ></Confirm>
    );
  }

  render() {
    const className = `ui ${isMobile ? "" : "very padded"} segment`;
    return (
      <>
        <div className={className}>
          <div>{this.renderLabourAdjustmentsTable()}</div>
          {this.renderDeleteConfirm()}
          <div className="spacer-very-wide"></div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    labourAdjustmentSearchTerms:
      state.labourAdjustments.labourAdjustmentSearchTerms,
    labourAdjustments: state.labourAdjustments.labourAdjustments,
    allLabourAdjustments: state.labourAdjustments.allLabourAdjustments,
    labourAdjustmentBatch: state.labourAdjustments.labourAdjustmentBatch,
    selectedLabourAdjustment: state.labourAdjustments.selectedLabourAdjustment,
    loadingData: state.api.loadingData,
  };
};

export default connect(mapStateToProps, {
  clearLabourAdjustment,
  clearLabourAdjustments,
  setLabourAdjustmentsFilter,
  clearLabourAdjustmentBatch,
  selectLabourAdjustment,
  removeLabourAdjustmentFromSelectedBatch,
  addLabourAdjustmentToSelectedBatch,
  addAllLabourAdjustmentsToSelectedBatch,
  deleteLabourAdjustments,
  patchLabourAdjustmentInDb,
  updateLabourAdjustmentsState,
  getLabourAdjustments,
})(LabourAdjustments);
