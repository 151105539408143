import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Button, Input } from "semantic-ui-react";
import "../components.css";
import { Link } from "react-router-dom";
import _ from "lodash";
import { currencyMask } from "../../utilities";

import {
  getLabourAdjustments,
  resetSaveSuccess,
  updateSelectedLabourAdjustmentState,
  updateLabourAdjustmentsState,
} from "../../actions";

class CreateLabourAdjustmentForm extends React.Component {
  componentDidMount() {
    if (!this.props.edit) {
      this.props.getLabourAdjustments({});
    }
  }

  renderError({ submitFailed, error }) {
    if (submitFailed && error) {
      return (
        <div className="ui error message">
          <div className="header">{error}</div>
        </div>
      );
    }
  }

  renderResult = () => {
    if (this.props.busySaving) {
      return (
        <div className="ui success message" style={{ marginTop: "1em" }}>
          Saving..
        </div>
      );
    } else if (this.props.saveError) {
      return (
        <div className="ui error message" style={{ marginTop: "1em" }}>
          {this.props.saveError}
        </div>
      );
    } else if (this.props.successMessage) {
      return (
        <div className="ui success message" style={{ marginTop: "1em" }}>
          {this.props.successMessage}
        </div>
      );
    }
  };

  renderInput = ({
    input,
    id,
    style,
    meta,
    placeholder,
    disabled,
    label,
    autoFocus,
  }) => {
    const className = `field ${meta.error && meta.submitFailed ? "error" : ""}`;
    return (
      <div className={className}>
        <div className="formlabel">{label}</div>
        <Input
          {...input}
          id={id}
          style={style}
          placeholder={placeholder}
          disabled={disabled}
          autoFocus={autoFocus}
          autoComplete="off"
          ref={
            autoFocus
              ? (input) => {
                  this.focusInput = input;
                }
              : input
          }
        ></Input>
        {this.renderError(meta, id)}
      </div>
    );
  };

  createLabourAdjustmentForDisplay = (values) => {
    const _labourAdjustment = {};
    _labourAdjustment.name = `Panel size from ${_labourAdjustment.start} dm² to ${_labourAdjustment.end} dm²`;
    _labourAdjustment.id = this.props.labourAdjustmentId;
    _labourAdjustment.start = values.start;
    _labourAdjustment.end = values.end;
    _labourAdjustment.factor = values.factor;
    _labourAdjustment.active = this.props.selectedLabourAdjustment
      ? this.props.selectedLabourAdjustment.active
      : true;
    return _labourAdjustment;
  };

  onSubmit = (values) => {
    this.props.onSubmit(values, this.createLabourAdjustmentForDisplay(values));
    setTimeout(() => {
      this.props.resetSaveSuccess();
    }, 2500);
    if (!this.props.edit) {
      this.props.reset();
      if (this.focusInput) {
        this.focusInput.focus();
      }
    }
  };

  render() {
    return (
      <div>
        <div className="content margin-bottom-small">{this.renderResult()}</div>
        <form
          className="ui form error"
          onSubmit={this.props.handleSubmit(this.onSubmit)}
        >
          <Field
            name="start"
            id="start"
            label="Start Surface Area (dm²)"
            component={this.renderInput}
            placeholder="Enter start surface are (dm²)"
            autoFocus={true}
            {...currencyMask}
          ></Field>
          <Field
            name="end"
            id="end"
            label="End Surface Area (dm²)"
            component={this.renderInput}
            placeholder="Enter end surface are (dm²)"
            {...currencyMask}
          ></Field>
          <Field
            name="factor"
            id="factor"
            label="End Multiplication Factor"
            component={this.renderInput}
            placeholder="Enter multiplication factor"
            {...currencyMask}
          ></Field>

          <div className="spacer-very-wide"></div>
          <div className="spacer-very-wide"></div>
          <div style={{ textAlign: "right" }}>
            <Button.Group>
              <Link to="/labourAdjustments">
                <Button color="black" type="button">
                  Cancel
                </Button>
              </Link>
              <Button.Or />
              <Button primary>Save</Button>
            </Button.Group>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  //if editing set the default values

  let labourAdjustmentData = {};

  if (props.edit) {
    labourAdjustmentData = {
      labourAdjustments: state.labourAdjustments.labourAdjustments,
      selectedLabourAdjustment:
        state.labourAdjustments.selectedLabourAdjustment,
      initialValues: {
        start: state.labourAdjustments.selectedLabourAdjustment.start,
        end: state.labourAdjustments.selectedLabourAdjustment.end,
        factor: state.labourAdjustments.selectedLabourAdjustment.factor,
      },
    };
  } else {
    labourAdjustmentData = {
      labourAdjustments: state.labourAdjustments.labourAdjustments,
    };
  }

  return {
    ...labourAdjustmentData,
    //api operations
    successMessage: state.api.saveSuccess,
    saveError: state.api.saveError,
    busySaving: state.api.busySaving,
  };
};

const validate = (formValues, props) => {
  const errors = {};

  if (!formValues.start) {
    //keys of object must match field names
    errors.start = "Please enter a start panel size in dm².";
  }

  if (!formValues.end) {
    //keys of object must match field names
    errors.end = "Please enter an end panel size in dm².";
  }

  if (!formValues.factor) {
    //keys of object must match field names
    errors.factor = "Please enter a multiplication factor.";
  }

  if (formValues.start && formValues.end && formValues.factor && !props.edit) {
    if (
      _.find(props.labourAdjustments, (labourAdjustment) => {
        return (
          (parseFloat(formValues.start) >= labourAdjustment.start &&
            parseFloat(formValues.start) <= labourAdjustment.end) ||
          (parseFloat(formValues.end) >= labourAdjustment.start &&
            parseFloat(formValues.end) <= labourAdjustment.end)
        );
      }) !== undefined
    ) {
      //keys of object must match field names
      errors.start =
        "Cannot add this record. Start and end surface areas supplied are already included in an existing adjustment record.";
    }
    if (parseFloat(formValues.start) >= parseFloat(formValues.end)) {
      errors.start =
        "The start surface value must be smaller than the end surface value";
    }
  }

  return errors;
};

const formWrapped = reduxForm({
  form: "CreateLabourAdjustmentForm",
  enableReinitialize: true,
  validate: validate,
})(CreateLabourAdjustmentForm);

export default connect(mapStateToProps, {
  resetSaveSuccess,
  updateSelectedLabourAdjustmentState,
  updateLabourAdjustmentsState,
  getLabourAdjustments,
})(formWrapped);
