import React from "react";
import Menu from "../components/Menu";
import { compileModelObject } from "../apis/Models";
import {
  getModels,
  getBodyTypes,
  getLocations,
  getBodySubTypes,
  getParts,
  getManufacturers,
  getManufacturer,
  setModelSearchTerm,
  updateModelsState,
  updateAllModelsState,
  selectModel,
  postModelToDb,
} from "../actions";
import { connect } from "react-redux";
import CreateModelForm from "../components/vehicles/CreateModelForm";
import { emptyModel } from "../defaults";
import { nanoid } from "nanoid";
import { updateStateAfterCreate } from "../utilities";

class createModelView extends React.Component {
  componentDidMount() {
    const { manufacturerId } = this.props.match.params;
    if (this.props.allModels.length === 0) {
      this.props.getModels({ manufacturerid: manufacturerId });
    }
    this.props.getManufacturer(manufacturerId).then(() => {
      this.props.setModelSearchTerm({
        value: this.props.selectedManufacturer.id,
        label: this.props.selectedManufacturer.name,
      });
    }); //to display manufacturer name in form
    this.props.getBodyTypes({}); //dropdown list
    this.props.getLocations({});
    this.props.getBodySubTypes(); //dropdown list
  }

  onSubmit = (values, model) => {
    const {
      models,
      allModels,
      selectModel,
      updateModelsState,
      updateAllModelsState,
      postModelToDb,
    } = this.props;
    let modelData = compileModelObject(values, { state: true });
    postModelToDb(modelData).then((result) => {
      modelData.id = result.data.id; //get new id returned by API
      updateStateAfterCreate(
        selectModel,
        updateModelsState,
        updateAllModelsState,
        models,
        allModels,
        modelData
      );
    });
  };

  render() {
    const { manufacturerId } = this.props.match.params;
    return (
      <>
        <Menu
          contentView={
            <CreateModelForm
              edit={false}
              initialValues={emptyModel}
              onSubmit={this.onSubmit}
              manufacturerId={manufacturerId}
              modelId={nanoid()}
            />
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    models: state.models.models,
    allModels: state.models.allModels,
    parts: state.parts.parts,
    bodytypes: state.bodytypes.bodytypes,
    manufacturers: state.manufacturers.manufacturers,
    selectedManufacturer: state.manufacturers.selectedManufacturer
  };
};

export default connect(mapStateToProps, {
  getModels,
  getBodyTypes,
  getLocations,
  getBodySubTypes,
  getParts,
  getManufacturers,
  getManufacturer,
  selectModel,
  updateModelsState,
  updateAllModelsState,
  setModelSearchTerm,
  postModelToDb,
})(createModelView);
