import React from "react";
import Menu from "../components/Menu";
import CreateLabourAdjustmentForm from "../components/repairs/CreateLabourAdjustmentForm";
import { compileDataForSave } from "../apis/LabourAdjustments";
import {
  getLabourAdjustment,
  selectLabourAdjustment,
  updateLabourAdjustmentsState,
  patchLabourAdjustmentInDb,
} from "../actions";
import { replaceArrayObjectByKey } from "../utilities";
import { connect } from "react-redux";

class editLabourAdjustmentView extends React.Component {
  onSubmit = (values, labourAdjustment) => {
    const {
      patchLabourAdjustmentInDb,
      selectLabourAdjustment,
      labourAdjustments,
      updateLabourAdjustmentsState,
    } = this.props;
    const labourAdjustmentsData = compileDataForSave(
      values,
      labourAdjustment.id,
      labourAdjustment.active
    );

    patchLabourAdjustmentInDb(labourAdjustmentsData).then((result) => {
      let _labourAdjustment = result.data;
      _labourAdjustment.name = `Panel size from ${_labourAdjustment.start} dm² to ${_labourAdjustment.end} dm²`;
      selectLabourAdjustment(_labourAdjustment);
      let _labourAdjustments = replaceArrayObjectByKey(
        labourAdjustments,
        "id",
        _labourAdjustment
      );
      updateLabourAdjustmentsState(_labourAdjustments);
    });
  };

  componentDidMount() {
    const { adjustmentId } = this.props.match.params;
    if (adjustmentId) {
      this.props.getLabourAdjustment(adjustmentId);
    }
  }

  render() {
    const { adjustmentId } = this.props.match.params;
    return (
      <>
        <Menu
          contentView={
            <CreateLabourAdjustmentForm
              edit={true}
              onSubmit={this.onSubmit}
              labourAdjustmentId={adjustmentId}
            />
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    labourAdjustments: state.labourAdjustments.labourAdjustments,
  };
};

export default connect(mapStateToProps, {
  getLabourAdjustment,
  selectLabourAdjustment,
  updateLabourAdjustmentsState,
  patchLabourAdjustmentInDb,
})(editLabourAdjustmentView);
