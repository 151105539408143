import React from "react";
import Menu from "../components/Menu";
import ActivityConsumables from "../components/repairs/ActivityConsumables";

const ActivityConsumablesView = () => {
  return (
    <>
      <Menu contentView={<ActivityConsumables />} />
    </>
  );
};

export default ActivityConsumablesView;
