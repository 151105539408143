import Axios from "axios";
import { env } from "./env";

export const BodyTypes = ({ cls, name, all }) => {
  const _env = env();
  const baseURL = _env.BASEURL;
  const key = _env.API_KEY;

  if (all) {
    return Axios.create({
      baseURL: `${baseURL}/vehicles/bodytypes?all=1`,
      headers: { "x-api-key": key },
    });
  }

  if (cls === 0 || cls === 1) {
    if (name) {
      return Axios.create({
        baseURL: `${baseURL}/vehicles/bodytypes?class=${cls}&name=${name}`,
        headers: { "x-api-key": key },
      });
    } else {
      return Axios.create({
        baseURL: `${baseURL}/vehicles/bodytypes?class=${cls}`,
        headers: { "x-api-key": key },
      });
    }
  }
};
