import React from "react";
import "./components.css";
import { Message } from "semantic-ui-react";

class Dashboard extends React.Component {
  render() {
    return (
      <>
        <div className="wide-padding">
          <Message info size="big">
            <div className="margin-bottom-small">
              <Message.Header>
                PaintWizz Administrator Dashboard.
              </Message.Header>
            </div>
            <p>
              In future we can use this space for analytics, or any other useful
              information.
            </p>
            <p>Start by picking a section using the main menu.</p>
          </Message>
        </div>
        <div className="spacer-very-wide"></div>
      </>
    );
  }
}

export default Dashboard;
