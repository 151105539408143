import Axios from "axios";
import { env } from "./env";

export const Stages = ({ id }) => {
  const _env = env();
  const baseURL = _env.BASEURL;
  const key = _env.API_KEY;

  if (id) {
    return Axios.create({
      baseURL: `${baseURL}/repairs/stages/${id}`,
      headers: { "x-api-key": key },
    });
  }

  return Axios.create({
    baseURL: `${baseURL}/repairs/stages`,
    headers: { "x-api-key": key },
  });
};

export const compileDataForSave = (
  { stageName, paint, minmixvol, adjustlabour },
  id,
  state
) => {
  const stage = {};
  stage.id = id;
  stage.name = stageName;
  stage.l_name = stageName.toLowerCase();
  stage.paint = paint;
  stage.adjustlabour = adjustlabour;
  stage.minmixvol = parseInt(minmixvol);
  stage.active = state;
  return stage;
};
