import React from "react";
import { CognitoUser } from "amazon-cognito-identity-js";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Form, Input, Button, Icon } from "semantic-ui-react";
import {
  busySaving,
  saveSuccess,
  saveFailed,
  resetSaveSuccess,
} from "../../actions";
import userPool from "./AWSUserPool";
import history from "../../history";

class AWSResetPassword extends React.Component {
  state = {
    passwordVisible: false,
    passwordToggleIcon: "eye",
  };

  renderUpdateError(error) {
    if (error) {
      return <div className="ui negative message">{error}</div>;
    }
    return "";
  }

  renderError = ({ submitFailed, error, visited }, id) => {
    if (id === "newPassword") {
      if (visited && error) {
        return (
          <div className="ui negative message">
            <div>{error}</div>
          </div>
        );
      }
    } else {
      if (submitFailed && error) {
        return (
          <div className="ui negative message">
            <div>{error}</div>
          </div>
        );
      }
    }
  };

  renderSuccessMessage() {
    if (this.props.passwordUpdateSuccessMessage) {
      return (
        <div className="ui postive message">
          <div className="header">
            {this.props.passwordUpdateSuccessMessage}
          </div>
        </div>
      );
    }
  }

  renderInput = ({
    input,
    type,
    meta,
    placeholder,
    id,
    validateOn,
    autoFocus,
    icon,
  }) => {
    const validateWhen = !validateOn ? meta.submitFailed : meta.visited;
    const className = `field ${meta.error && validateWhen ? "error" : ""}`;
    return (
      <div className={className}>
        <Input
          type={type}
          fluid
          {...input}
          id={id}
          placeholder={placeholder}
          autoFocus={autoFocus}
          icon={icon}
        ></Input>
        {this.renderError(meta, id)}
      </div>
    );
  };

  onSubmit = (formValues) => {
    //confirm password with code
    this.props.busySaving();
    const userData = {
      Username: formValues.email,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.confirmPassword(
      formValues.verificationCode,
      formValues.newPassword,
      {
        onSuccess: () => {
          this.props.saveSuccess("Password set successfully.");
          setTimeout(() => {
            this.props.resetSaveSuccess();
            history.push("/");
          }, 1500);
        },
        onFailure: (err) => {
          this.props.saveFailed(err);
        },
      }
    );
  };

  onShowPasswordClick = () => {
    this.setState({
      passwordVisible: !this.state.passwordVisible,
      passwordToggleIcon:
        this.state.passwordToggleIcon === "eye" ? "eye slash" : "eye",
    });
  };

  render() {
    return (
      <div>
        <Form onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <Form.Field>
            <Field
              name="email"
              type="text"
              component={this.renderInput}
              id="email"
              placeholder="Email address"
            />
          </Form.Field>
          <Form.Field>
            <Field
              name="verificationCode"
              type="text"
              component={this.renderInput}
              id="verificationCode"
              placeholder="Verification Code"
              autoFocus
            />
          </Form.Field>
          <Form.Field>
            <Field
              name="newPassword"
              type={this.state.passwordVisible ? "text" : "password"}
              component={this.renderInput}
              id="newPassword"
              placeholder="New Password"
              onChange={this.onPasswordInputChange}
              icon={
                <Icon
                  name={this.state.passwordToggleIcon}
                  onClick={this.onShowPasswordClick}
                  color="blue"
                  inverted
                  bordered
                  link
                />
              }
            />
          </Form.Field>
          <Button
            fluid
            loading={this.props.updatingPassword}
            primary
            icon
            labelPosition="right"
          >
            Reset Password
            <Icon name="lock open"></Icon>
          </Button>
        </Form>
        {this.renderSuccessMessage()}
        {this.renderUpdateError(this.props.passwordUpdateError)}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {

  let authData = {
    initialValues: { email: state.auth.passwordResetUsername },
    passwordUpdateError: state.api.saveError,
    updatingPassword: state.api.busySaving,
    passwordUpdateSuccessMessage: state.api.successMessage,
  };
  return authData;
};

const validate = (formValues) => {
  const pwErrors = [];
  const errors = {};
  if (!formValues.verificationCode) {
    //keys of object must match field names
    errors.verificationCode = "Please enter your verification code";
  }
  if (!formValues.newPassword) {
    //keys of object must match field names
    errors.newPassword = "Please enter your new password";
  }
  const containsNumber = new RegExp("[0-9]");
  if (!containsNumber.test(formValues.newPassword)) {
    pwErrors.push("Must contain 1 number");
  }
  const containsUpperCase = new RegExp("[A-Z]");
  if (!containsUpperCase.test(formValues.newPassword)) {
    pwErrors.push("Must contain 1 uppercase character");
  }
  const minEightLong = new RegExp(".{8,}");
  if (!minEightLong.test(formValues.newPassword)) {
    pwErrors.push("Must be at least 8 long");
  }
  errors.newPassword = pwErrors.join(" | ");

  return errors;
};

const formWrapped = reduxForm({
  form: "AWSResetPassword",
  enableReinitialize: true,
  validate: validate,
})(AWSResetPassword);

export default connect(mapStateToProps, {
  busySaving,
  saveFailed,
  saveSuccess,
  resetSaveSuccess,
})(formWrapped);
